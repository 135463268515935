
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      hasMoreItems: true,
      isLoadingPlayerInfo: false,
      pagination: {
        skip: 0,
        items: 10
      },
      player: null,
      team: null,
      query: this.$route.query,
      data: null,
      teamlead: { id: null }
    };
  },
  methods: {
    selectTeamlead(id) {
      const html = window.event.target.closest('.player');
      const t = this.data.team.players.map(el => {
        el.isTeamlead = el.id === id;
        return el;
      });
      this.data.team.players = t;
      this.$setState({
        data: this.data,
        teamlead: { id }
      });
      for (const item of this.$el[0].querySelector('.players-list .block').children) {
        item.classList.remove('selected');
      }
      html.classList.add('selected');
    },
    infoPTS: function (e) {
      this.$root.showTooltip(e.target, 'PTS - cреднее количество очков, полученные игроком за все предыдущие игры в этом сезоне.');
    },
    tryToTeam: function (e) {
      this.$root.showTooltip(e.target, 'На данного игрока вам не хватает бюджета');
    },
    addToTeam: function (id, key) {
      this.$root.pushToTeam(id, key);
      this.$router.clearPreviousPages();
      this.$router.back('/team/create-0/?id=' + this.$route.query.id + '&entry_id=' + this.$route.query.entry_id);
    },
    close: function () {
      this.$app.popup.close();
    },
    infiniteEvent() {
      if (this.isLoadingPlayerInfo || !this.hasMoreItems)
        return false;
      this.$setState({
        pagination: {
          items: this.pagination.items,
          skip: this.player.stats.length
        }
      });
      this.load();
    },
    load: function () {
      this.$setState({ data: JSON.parse(this.$router.currentRoute.query.data) });
      console.log(this.data);
    },
    positionToHuman() {
    },
    addTeamLead() {
      const self = this;
      self.$app.preloader.show();
      const teamId = this.$router.currentRoute.query.teamId;
      let team = this.$root.getTeam();
      team = team.map(el => {
        if (el.player_id == self.teamlead.id) {
          el.is_captain = true;
        } else {
          el.is_captain = false;
        }
        return el;
      });
      this.$root.api('app/tournaments/' + teamId + '/team', { team }, 'POST', function (data, status, xhr) {
        if (data.status === 'ok') {
          self.approve();
        } else {
          self.$app.dialog.alert(data.message, 'Упс!');
        }
      }, true);
    },
    approve: function () {
      var self = this;
      self.$app.preloader.hide();
      if (this.entryId) {
        self.approveSend('coint', this.$root.getKey('fromTour') == '1');
        return;
      }
      if (this.$root.getKey('fromTour') == '1') {
        self.approveConfirm('coins', true);
        return;
      }
      var buttons = [];
      buttons.push({
        text: 'Выберите счет для списания',
        label: true
      });
      var oneAccount = null;
      for (let account in this.data.client.balance) {
        oneAccount = account;
        var data = this.data.client.balance[account];
        if (data.show) {
          if (self.$app.methods.getTypeApp() === 'olimp' && oneAccount == 'coins') {
            let currencyAmount = {
              value: parseInt(self.$root.params.profile.currency.rate * data.value),
              currency: self.$root.params.profile.currency.sign
            };
            let text = '';
            if (self.$root.params.profile.use_native_currency) {
              text = data.label + ': ' + currencyAmount.value + currencyAmount.currency + ' (' + data.value + data.currency + ')';
            } else {
              text = data.label + ': ' + data.value + data.currency + ' (' + currencyAmount.value + currencyAmount.currency + ')';
            }
            buttons.push({
              text: text,
              onClick: function (actions, e) {
                self.approveSend(account, false);
              }
            });
          } else {
            buttons.push({
              text: data.label + ': ' + data.value + data.currency,
              onClick: function (actions, e) {
                self.approveSend(account, false);
              }
            });
          }
          console.log(data.value);
        }
      }
      if (buttons.length == 2 || this.data.tournament.ingress.value == 0) {
        self.approveConfirm(oneAccount, false);
        return;
      }
      self.$app.actions.create({
        buttons: [
          buttons,
          [{
              text: 'Отмена',
              color: 'red'
            }]
        ]
      }).open();
    },
    approveConfirm: function (account, fromTour) {
      var self = this;
      console.log(1, account);
      self.$app.dialog.confirm('Создать команду?', 'Подтверждение', function () {
        self.approveSend(account, fromTour);
      });
    },
    approveSend: function (account, fromTour) {
      var self = this;
      self.$app.preloader.show();
      self.$root.api('app/tournaments/' + self.$route.query.teamId + '/team/confirm', { account: account }, 'POST', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          if (self.$app.methods.getTypeApp() === 'olimp') {
            let can_entry_again = data.same_tournament.find(el => el.can_entry_again === true && el.sufficient_balance === true);
            if (typeof can_entry_again !== 'undefined') {
              self.$router.navigate({
                name: 'category_tournaments',
                query: {
                  'tournaments': JSON.stringify(data.same_tournament),
                  'team_hash': data.team_hash
                }
              });
            } else {
              self.closeAction();
              if (self.$app.views['games'].router.history.length == 1) {
                self.$app.views['games'].router.refreshPage();
              } else {
                self.$app.views['games'].router.navigate('/games/', { reloadAll: true });
              }
              self.$app.data.lastViewClick = 'games';
              self.$app.tab.show('#view-games');
              self.$app.dialog.alert('Вы успешно вошли в турнир со своей командой', 'Успешно!', function () {
                self.$root.events.emit('team-created');
              });
            }
          } else {
            self.closeAction();
            if (self.$app.views['games'].router.history.length == 1) {
              self.$app.views['games'].router.refreshPage();
            } else {
              self.$app.views['games'].router.navigate('/games/', { reloadAll: true });
            }
            self.$app.data.lastViewClick = 'games';
            self.$app.tab.show('#view-games');
            self.$app.dialog.alert('Вы успешно вошли в турнир со своей командой', 'Успешно!', function () {
              self.$root.events.emit('team-created');
            });
          }
        }
      }, true);
    },
    closeAction: function () {
      var self = this;
      self.$root.clearTeam();
      self.$root.events.emit('lobby.load');
      self.$app.popup.close();
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      self.load();
    }
  },
  id: '46c70a3a46',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-with-subnavbar">\n        <div class="navbar">\n            <div class="navbar-inner navbar-popup">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n\n                </div>\n                <div class="right">\n\n                </div>\n                <div class="md subnavbar" id="subnavbar-create-2">\n                    <div class="subnavbar-inner">\n                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div class="row-bottom display-flex justify-content-space-between width-100">\n                                <span class="row-bottom-left">\n                                    <span class="row-bottom-title">В игре</span> <span class="row-bottom-count">';
              r += c(ctx_3.tournament.inputs.current, ctx_3);
              r += '</span>\n                                </span>\n                            <span class="row-bottom-center">\n                                <span class="row-bottom-title">Макс</span> <span class="row-bottom-count">';
              r += c(ctx_3.tournament.inputs.max, ctx_3);
              r += '</span>\n                            </span>\n                            <span class="row-bottom-right">\n                                    <span class="row-bottom-title">Вход</span> <span class="row-bottom-count">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.tournament.ingress.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += c(ctx_3.tournament.ingress.currency, ctx_3);
              r += '</span>\n                            </span>\n                        </div>\n                        <div class="display-flex title-block">\n                            <span class="title">Выберите капитана</span>\n                            <span class="description">Он получит в два раза больше очков</span>\n                        </div>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!--        <div class="toolbar toolbar-bottom" id="toolbar-create-2"-->\n        <!--             style="display: flex;flex-direction: column;justify-content: center;">-->\n        <!--            <div class="toolbar-inner">-->\n        <!--                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.team, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '-->\n        <!--                <div id="toolbar-bottom-top">Осталось ';
          r += Template7Helpers.formatPrice.call(ctx_2, ctx_2.team.balance.value, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.team.balance.currency, ctx_2);
          r += '</div>-->\n        <!--                <div id="toolbar-bottom-bottom">';
          r += Template7Helpers.formatPrice.call(ctx_2, ctx_2.team.average_salary.value, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '-->\n        <!--                    ';
          r += c(ctx_2.team.average_salary.currency, ctx_2);
          r += ' за открытую позицию (';
          r += c(ctx_2.team.open_position_count, ctx_2);
          r += ')-->\n        <!--                </div>-->\n        <!--                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '-->\n        <!--            </div>-->\n        <!--        </div>-->\n        <div class="toolbar toolbar-bottom no-hairline" id="toolbar-team">\n            <div class="toolbar-inner">\n                ';
      r += Template7Helpers.with.call(ctx_1, ctx_1.teamlead, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <button id="button-create2" @click="addTeamLead" class=\'button button-fill ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.id === null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'disabled';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\'>\n                    Утвердить команду\n                </button>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n        </div>\n        <div class="page-content infinite-scroll-content" id="page-content-create-3">\n            <div class="players-list">\n                <div class="block no-margin-vertical">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.team.players, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                    <div class="player" @click="selectTeamlead(';
                  r += c(ctx_4.id, ctx_4);
                  r += ')" id="';
                  r += c(ctx_4.id, ctx_4);
                  r += '">\n                        <div class="img">\n                            <img src="';
                  r += c(ctx_4.image, ctx_4);
                  r += '">\n                            ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.isTeamlead', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                            <i class="icon star"></i>\n                            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                        </div>\n                        <div class="info">\n                            <span class="name">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</span>\n                            <span class="team">';
                  r += c(ctx_4.team, ctx_4);
                  r += '</span>\n                            <span class="position">';
                  r += c(ctx_4.position, ctx_4);
                  r += '</span>\n                        </div>\n                        <div class="stat">\n                            <span class="cost">';
                  r += Template7Helpers.formatPrice.call(ctx_4, ctx_4.salary.value, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += c(ctx_4.salary.currency, ctx_4);
                  r += '</span>\n                            <span class="score-average">';
                  r += c(ctx_4.score_average, ctx_4);
                  r += '</span>\n                            <span class="category-points">PTS</span>\n                        </div>\n                    </div>\n                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    #button-create2 {
        background-color: #32BF61;
        height: 46px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: #FFFFFF;
    }
    #page-content-create-3 .player .star {
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9806 6.30158L14.9805 6.3011C14.8049 5.76118 14.3251 5.37751 13.7591 5.3262C13.7591 5.3262 13.759 5.32619 13.7589 5.32618L10.5679 5.03648L9.30633 2.08293L9.3058 2.08171C9.08181 1.55986 8.56991 1.2207 8.00021 1.2207C7.43109 1.2207 6.91811 1.55946 6.69467 2.08293C6.69464 2.08302 6.6946 2.08311 6.69456 2.08319C6.69455 2.08323 6.69453 2.08326 6.69452 2.08329L5.43312 5.03647L2.2413 5.3262L2.24018 5.3263C1.6746 5.3785 1.19663 5.76184 1.02046 6.29949L1.01994 6.3011C0.844466 6.84073 1.00638 7.43372 1.43399 7.80875L1.43405 7.8088L3.84772 9.92524L3.13592 13.0602L3.13574 13.061C3.01066 13.6147 3.2256 14.1909 3.68808 14.5254C3.93629 14.7054 4.22852 14.7953 4.52043 14.7953C4.77219 14.7953 5.02437 14.7283 5.24891 14.594L8.00018 12.9489L10.7511 14.594L10.7513 14.5942C11.2384 14.8853 11.8518 14.8587 12.3121 14.5256C12.7747 14.1912 12.9898 13.6148 12.8647 13.061L12.8645 13.0602L12.1527 9.92524L14.5664 7.8088L14.5666 7.80863C14.9938 7.43374 15.1559 6.84164 14.9806 6.30158Z' fill='%23760AFF' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }

    #page-content-create-3 .player {
        background: #FFFFFF;
        border: 1px solid #CFD6DB;
        border-radius: 8px;
        padding: 9px 12px;
        display: flex;
    }

    #page-content-create-3 .player + .player {
        margin-top: 8px;
    }

    #page-content-create-3 .player.selected {
        border: 1px solid #760AFF;
    }

    #page-content-create-3 .player .img {
        position: relative;
        width: 48px;
        height: 48px;
    }

    #page-content-create-3 .player .img .star {
        position: absolute;
        top: -2px;
        right: -8px;
    }

    #page-content-create-3 .player .img img {
        width: inherit;
        height: inherit;
    }

    #page-content-create-3 .player .info {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 18px;
    }

    #page-content-create-3 .player .info .name {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #007AFF;
    }

    #page-content-create-3 .player .info .team {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #445058;
        margin-top: 1px;
    }

    #page-content-create-3 .player .info .position {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.35px;
        color: #818E95;
    }

    #page-content-create-3 .player .stat .cost {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #445058;
    }

    #page-content-create-3 .player .stat .score-average {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.35px;
        margin-top: 1px;
        color: #445058;
    }

    #page-content-create-3 .player .stat .category-points {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.35px;
        text-transform: uppercase;
        color: #818E95;
    }

    #page-content-create-3 .player .stat {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

    }

    #page-content-create-3 .players-list {
        background-color: #FFF;
        padding: 16px 0px;
    }

    #subnavbar-create-2 {
        --f7-subnavbar-height: auto;
        --f7-subnavbar-inner-padding-left: 0;
        --f7-subnavbar-inner-padding-right: 0;
        background-color: white;
    }

    #subnavbar-create-2 .subnavbar-inner {
        padding-bottom: 0;
        justify-content: flex-start;
    }

    #page-content-create-3 {
        padding-top: 172px;
        padding-bottom: calc(var(--f7-safe-area-bottom) + 78px);
    }

    #subnavbar-create-2 .subnavbar-inner {
        flex-direction: column;
    }

    #subnavbar-create-2 .title-block {
        flex-direction: column;
        padding: 8px 16px;
    }

    #subnavbar-create-2 .title-block .title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.41px;
        color: #445058;
        align-self: center;
    }

    #subnavbar-create-2 .title-block .description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.41px;
        color: #445058;
        align-self: center;
    }

    .page-with-subnavbar .list-scores .title-row {

    }

    .page-with-subnavbar .list-scores .item-title {
        padding-top: 2px;
        color: #000000;
    }

    .page-with-subnavbar .list-scores .right {
        margin-left: auto;
        margin-right: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.35px;
        color: #AAADB3;
        display: flex;
        gap: 24px;
    }

    .page-with-subnavbar .list-scores .right span {
        display: block;
        text-align: right;
    }

    .page-with-subnavbar .list-scores .item-content .item-title {

    }

    .page-with-subnavbar .list-scores .item-content .item-title .first {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #445058;
        min-width: 40px;
        text-align: left;
        display: block;
    }

    .page-with-subnavbar .list-scores .item-content .item-title .second {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.35px;
        color: #AAADB3;
        min-width: 40px;
        text-align: left;
        display: block;
    }

    .page-with-subnavbar .list-scores .item-content .item-after {

    }

    .page-with-subnavbar .list-scores .item-content .item-after .first {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #445058;
        min-width: 55px;
        text-align: right;
        display: block;
    }

    .page-with-subnavbar .list-scores .item-content .item-after .second {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #445058;
        text-align: right;
        min-width: 55px;
        display: block;
    }

    :root {

    }

    .stat-block {
        display: flex;
        justify-content: space-between;
    }

    .stat-block-item {
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }

    .stat-item-points {
        width: 120px;
    }

    .block-empty-image {
        margin-top: 80px;
    }

    .block-empty-row1 {
        font-size: 24px;
        color: #818E95;
    }

    .block-empty-row2 {
        font-size: 16px;
        text-align: center;
        color: #ACB4BE;
    }

    .score-box + .score-box {
        margin-left: 8px;
    }

    .table-player-title {
        padding: 3px 4px;
        text-transform: uppercase;
        color: #818E95;
        height: 15px;
        border-bottom: 1px #CFD6DB solid;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .table-player-value {
        font-size: 14px;
        color: #445058;
        padding-right: 4px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .table-time {
        font-size: 11px;
        color: #818E95;
    }

    .table-title {
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }

    .table-points-title {
        color: #818E95;
        font-size: 11px;
        text-transform: uppercase;
    }

    .table-points-value {
        color: #445058;
        font-size: 14px;
        font-weight: bold;
    }

    #page-content-create-3 {
        --f7-toolbar-height: 44px;
        --f7-table-collapsible-cell-padding: 0;
        --f7-table-cell-border-color: #CFD6DB;
        padding-top: 123px;
    }

    #subnavbar-create-2 {
        /*--f7-subnavbar-height: 100px;*/
    }

    #page-content-create-3 {
        --f7-subnavbar-height: 100px;
    }

    .list-full-bottom-left-top {
        font-size: 14px;
        color: #445058;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }

    .list-full-bottom-left-bottom {
        font-size: 11px;
        color: #818E95;
    }

    .list-full-bottom-center-top {
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        text-transform: uppercase;
    }

    .list-full-bottom-center-bottom {
        font-size: 11px;
        color: #818E95;
        text-transform: uppercase;
    }

    .list-full-bottom-right-top {
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        text-transform: uppercase;
    }

    .list-full-bottom-right-bottom {
        font-size: 11px;
        color: #818E95;
        text-transform: uppercase;
    }

    .list-full-content {
        height: 100px;
    }

    .list-full-top {
        height: 29px;
        flex-direction: row;


    }

    .list-full-bottom {
        height: 33px;

    }

    .list-full-top-left-badge {
        position: relative;
        top: 0;
        font-size: 13px;
        padding: 4px;
        text-transform: capitalize;
        color: #818E95;
        background-color: #F0F3F8;
    }

    .list-full-top-left {
        font-size: 15px;
        color: #007AFF;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }

    .list-full-top-left a {

    }

    .list-full-top-right {
        position: relative;
        top: 0;
        text-align: right;
        flex-shrink: 0;
    }

    .list-full-top-right {
        font-weight: bold;
        color: #445058;
    }

    .list-create-1.list {
        --f7-list-bg-color: F0F3F8;
        --f7-list-item-title-line-height: 100px;
        --f7-list-item-padding-vertical: 0;
    }

    .list-create-1.list .item-title {
        color: #445058;
    }

    #toolbar-create-2 {
        /*height: 44px;*/
    }

    #toolbar-create-2 .toolbar-inner {
        flex-direction: column;
        justify-content: center;
        /*height: 44px;*/
    }

    #toolbar-bottom-top {
        height: 22px;
        text-align: center;
        color: #445058;
        font-weight: bold;
        font-size: 14px;
    }

    #toolbar-bottom-bottom {
        height: 18px;
        text-align: center;
        color: #445058;
        font-size: 12px;
    }

    #subnavbar-create-2 {
        background-color: white;
    }

    #subnavbar-create-2 .subnavbar-inner {

    }

    #subnavbar-create-2 a, #subnavbar-create-2 .icon {

    }

    #subnavbar-create-2 .icon {

    }

    .scores-block {
    }

    .scores-block ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .scores-block li {
        flex: 1 0 50px;
        height: 50px;
        border: 1px solid #CFD6DB;
        margin-top: -1px;
        margin-right: -1px;
        background-color: white;
    }

    .scores-block li:empty {
        height: 0;
        border: none;
    }

    .scores-block,
    :before,
    :after {
        box-sizing: border-box;
    }

    .scores-block ul,
    :before,
    :after {
        box-sizing: border-box;
    }

    .scores-block li,
    :before,
    :after {
        box-sizing: border-box;
    }

    /*.page-content-create-3 */


`,
  styleScoped: false
};
    
export default {
  methods: {
    setSubmitButtonLoading: function (loading) {
      if (loading) {
        this.$el.find('#button-submit').addClass('disabled').html('<div class="preloader color-white">' + this.$app.utils.iosPreloaderContent + '</div>');
      } else {
        this.$el.find('#button-submit').removeClass('disabled').html('Войти');
      }
    },
    close() {
      this.$root.login(localStorage.getItem('token'), 'p');
    },
    submit() {
      const _this = this;
      const password = this.$el.find('#input-password')[0];
      const payload = { 'password': password.value };
      _this.setSubmitButtonLoading(true);
      _this.$root.api('app/profile/password/nocheck', payload, 'POST', function (data, status, xhr) {
        _this.setSubmitButtonLoading(false);
        if (data.status == 'error') {
          _this.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          _this.$root.login(localStorage.getItem('token'), 'p');
        }
      }, true, {
        messageCallback: function (response) {
          _this.setSubmitButtonLoading(false);
          const responseJSON = JSON.parse(response);
          _this.$app.dialog.alert(responseJSON.message, 'Ошибка');
        }
      });
    }
  },
  id: '47dae6b9b0',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page change-password-after-login-page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link icon-only" @click="close">\n                        <i class="icon icon-close"></i>\n                    </a>\n                </div>\n                <div class="title"></div>\n                <div class="right"></div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            <div class="block no-margin-vertical">\n                <div class="row display-flex flex-direction-column">\n                    <span class="title">Придумайте пароль</span>\n                    <span class="subtitle">Не очень простой, но запоминающийся</span>\n                </div>\n                <div class="list no-hairline-top">\n                    <ul>\n                        <li class="item-content item-input">\n                            <div class="item-inner">\n                                <div class="item-title item-floating-label">Введите новый пароль</div>\n                                <div class="item-input-wrap">\n                                    <input type="text" id="input-password" name="password" />\n                                    <span class="input-clear-button"></span>\n                                </div>\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n                <button class="button button-fill" id="button-submit" @click="submit">\n                    Готово\n                </button>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .change-password-after-login-page .page-content {
        background-color: #FFFFFF;
    }
    .change-password-after-login-page .row .title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 28px;
        color: #202122;
        margin-top: 24px;
    }
    .change-password-after-login-page .row .subtitle {
        margin-top: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: #445058;
    }
    .change-password-after-login-page .list {
        margin-top: 32px;
    }
    .change-password-after-login-page .list .item-input {
        padding-left: 0px;
    }
    #button-submit {
        height: 44px;
        font-size: 17px;
        font-weight: 500;
        letter-spacing: -0.41px;
        line-height: 22px;
        background-color: #007AFF;
        margin-top: 24px;
    }
`,
  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return { friends: [] };
  },
  methods: {
    tryGetInfoReferal() {
      var self = this;
      this.$root.api('app/promo/info', {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          data.referals.sort(self.sortFriendList);
          window.friendsList = data.referals;
          self.$setState({ friends: window.friendsList });
        }
      }, true);
    },
    sortFriendList(a, b) {
      const A = a.created_at;
      const B = b.created_at;
      let comparison = 0;
      if (A > B) {
        comparison = -1;
      } else if (A < B) {
        comparison = 1;
      }
      return comparison;
    }
  },
  on: {
    pageBeforeIn: function (event, page) {
      document.querySelector('.toolbar').style.display = 'none';
      this.$setState({ friends: window.friendsList });
      page.$el.addClass('no-toolbar');
    },
    pageBeforeOut: function (event, page) {
    },
    pageInit: function () {
      var self = this;
      var ptrContent = self.$el.find('.ptr-content');
      ptrContent.on('ptr:refresh', function (e) {
        setTimeout(() => {
          self.tryGetInfoReferal();
          e.detail();
        }, 1400);
      });
    }
  },
  id: 'ac9d9e9363',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Приглашено\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content ptr-content" data-ptr-distance="55" data-ptr-mousewheel="true">\n            <div class="ptr-preloader">\n                <div class="preloader"></div>\n                <div class="ptr-arrow"></div>\n            </div>\n            <div class="block-title" style="margin-top: 35px;">Мои приглашенные друзья</div>\n            <div class="list no-chevron">\n                <ul>\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.friends, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.friends, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <li>\n                                <a href="#" class="item-link item-content">\n                                <div class="item-media">\n                                    <div class="icon-player icon-player--modifier" style="background-image:url(';
              r += c(ctx_3.avatar.url, ctx_3);
              r += ')">\n                                        ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.verified === false', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                            <i class="f7-icons">exclamationmark_circle_fill</i>\n                                        ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                    </div>\n                                </div>\n                                <div class="item-inner">\n                                    <div class="item-title">\n                                    ';
              r += c(ctx_3.name, ctx_3);
              r += '\n                                    <div class="item-footer">';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.created_at, {
                hash: { 'type': 'full' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                    </div>\n                                    ';
              r += Template7Helpers.js_if.call(ctx_3, '+this.amount.value === 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="item-after text--grey">Не пополнил</div>\n                                    ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="item-after">+';
                  r += c(ctx_4.amount.value, ctx_4);
                  r += c(ctx_4.amount.currency, ctx_4);
                  r += '</div>\n                                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </div>\n                                </a>\n                            </li>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </ul>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-ac9d9e9363] .item-after {
        color: #32BF61;
    }
[data-f7-ac9d9e9363] .text--grey {
        color: var(--f7-list-item-footer-text-color);
    }
[data-f7-ac9d9e9363] .item-title {
        color: #445058;
    }
[data-f7-ac9d9e9363] .icon-player--modifier {
        background-size: cover;
        border-radius: 30px;
        position: relative;
    }
[data-f7-ac9d9e9363] .icon-player--modifier .f7-icons {
        position: absolute;
        right: -3px;
        bottom: -3px;
        font-size: 17px;
        color: #C1272D;
        width: 17px;
        height: 17px;
    }
`,
  styleScoped: true
};
    
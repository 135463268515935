export default {
  data: function () {
    return { sum: null };
  },
  methods: {
    next(type) {
      var self = this;
      self.$router.navigate({
        name: 'withdrawal.purse',
        query: {
          'type': type,
          'sum': self.sum
        }
      });
    },
    checkQuery(sum) {
      var self = this;
      self.$setState({ sum });
    }
  },
  on: {
    pageInit: function () {
      this.checkQuery(this.$router.currentRoute.query.sum);
    }
  },
  id: 'e97217e9d3',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Способы вывода\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block-title">Куда вывести</div>\n            <div class="list links-list">\n                <ul>\n                    <li><a href="#" @click="next(\'qiwi\')">Qiwi кошелек</a></li>\n                    <li><a href="#" @click="next(\'yandex\')">Яндекс кошелек</a></li>\n                    <!-- <li><a href="#" @click="next(\'mobile\')">Мобильный телефон</a></li> -->\n                </ul>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-e97217e9d3] .list a {
        color: rgb(109, 109, 114);
    }

[data-f7-e97217e9d3] .button--success {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
    }

[data-f7-e97217e9d3] .block--f7-block-margin-vertical + .toolbar {
        margin-top: 35px;
    }
[data-f7-e97217e9d3] .block-title {
        margin-top: 35px;
    }
[data-f7-e97217e9d3] .list+.button_send {
            margin-top: 35px;
        }
[data-f7-e97217e9d3] .button_send {
        padding: 0 8px;
    }
`,
  styleScoped: true
};
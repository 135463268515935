
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return { email: this.$route.query.email };
  },
  methods: {
    setNextButtonLoading: function (loading) {
      if (loading) {
        this.$el.find('#button-next').addClass('disabled').html('<div class="preloader color-white">' + this.$app.utils.iosPreloaderContent + '</div>');
      } else {
        this.$el.find('#button-next').removeClass('disabled').html('Далее');
      }
    },
    remember: function () {
      var self = this;
      var data = { email: this.$el.find('#remember-input-email').val() };
      this.setNextButtonLoading(true);
      this.$root.api('app/reset', data, 'POST', function (data, status, xhr) {
        self.setNextButtonLoading(false);
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          self.$router.navigate('/auth/remember-success/?email=' + self.$el.find('#remember-input-email').val());
        }
      }, false, {
        callback: function () {
          self.setNextButtonLoading(false);
        }
      });
    },
    change: function (e) {
      var buttonEl = this.$el.find('#button-next');
      var inputEl = this.$el.find('#remember-input-email');
      if (buttonEl.hasClass('disabled') && this.$root.isValidEmail(inputEl.val())) {
        buttonEl.removeClass('disabled');
      } else if (!buttonEl.hasClass('disabled') && !this.$root.isValidEmail(inputEl.val())) {
        buttonEl.addClass('disabled');
      }
    }
  },
  on: {
    pageInit: function () {
      this.change();
    }
  },
  id: 'dc708a60c7',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    <i class="icon-step2-1"></i>\n                </div>\n            </div>\n        </div>\n        <div class="page-content bg-color-white remember">\n            <div class="block">\n                Введите Email, который вы использовали для Регистрации\n            </div>\n\n            <div class="list list-registration-input">\n                <ul>\n                    <li class="item-content item-input item-input-with-info">\n                        <div class="item-inner">\n                            <div class="item-title item-floating-label">Email</div>\n                            <div class="item-input-wrap">\n                                <input id="remember-input-email" value="';
      r += Template7Helpers.escape.call(ctx_1, ctx_1.email, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" name="email" type="text" required validate @input="change">\n                                <div class="item-input-info">Мы вышлем проверочный код на этот e-mail</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li class="item-content item-input" style="visibility: hidden;min-height:30px;height: 0px; overflow: hidden;"></li>\n                </ul>\n            </div>\n\n            <div class="block">\n                <button class="button button-fill disabled" id="button-next" @click="remember">Далее</button>\n            </div>\n\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    /*:root{
        --f7-navbar-bg-color: #EFEFF4;
        --f7-navbar-border-color: #EFEFF4;
        --f7-navbar-link-color: #445058;
        --f7-navbar-text-color: #445058;
    }*/
    .remember .list-registration-input {
        --f7-block-font-size: 18px;
        --f7-block-text-color: #445058;
        --f7-label-text-color: #007AFF;
        --f7-list-border-color: white;
    }
    .remember .list {
        margin-left: 16px;
        margin-right: 16px;
    }
    .remember .icon-step2-1{
        width: 48px;
        height: 22px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='22' viewBox='0 0 48 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='10' y='10' width='38' height='1' fill='%23445058' fill-opacity='0.4'/%3E%3Ccircle cx='11' cy='11' r='10.5' fill='%23EFEFF4' stroke='%23445058'/%3E%3Ccircle cx='40' cy='11' r='8' fill='%23EFEFF4'/%3E%3Ccircle cx='40' cy='11' r='7.5' stroke='%23445058' stroke-opacity='0.4'/%3E%3Cpath d='M10.9101 16H12.2284V5.43115H10.9174L8.1049 7.45264V8.84424L10.7929 6.896H10.9101V16Z' fill='%23445058'/%3E%3C/svg%3E%0A");
     }
    .remember #button-next{
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
    }
`,
  styleScoped: false
};
    
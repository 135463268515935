export default {
  data: function () {
    return { email: null };
  },
  methods: {
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    next() {
      let self = this;
      self.$app.dialog.preloader('Ожидайте...');
      var email = self.$el.find('#email').val();
      if (email.length == 0) {
        self.$app.dialog.close();
        self.$app.dialog.alert('', 'Укажите ваш email');
        return 0;
      }
      if (self.validateEmail(email)) {
        self.sendEmailCode(email).then(response => {
          self.$app.dialog.close();
          if (response.status == 'error') {
            self.$app.dialog.alert(response.message, 'Ошибка');
            return 0;
          }
          self.$app.dialog.alert(response.message, 'Успешно', () => {
            self.$router.navigate({
              name: 'verificated.email.step-two',
              query: { 'email': email }
            });
          });
        });
      }
    },
    sendEmailCode(email) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.$root.api('/app/profile/verified/send-code-email?email=' + email, {}, 'GET', function (data, status, xhr) {
          resolve(data);
        }, true);
      });
    }
  },
  on: {
    pageBeforeIn: function (event, page) {
    },
    pageInit: function () {
      this.$setState({ email: this.$root.params.profile.email });
    }
  },
  id: '16a53a1f09',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back item-back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Подтверждение email\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block-title">Укажите ваш email</div>\n            <div class="list inline-labels no-hairlines-md">\n                <ul>\n                    <li class="item-content item-input">\n                        <div class="item-inner">\n                            <div class="item-input-wrap">\n                                <input id="email" type="email" value="';
      r += c(ctx_1.email, ctx_1);
      r += '" placeholder="Ваш email" novalidate>\n                            </div>\n                        </div>\n\n                    </li>\n                </ul>\n            </div>\n            <div class="block block-footer block--f7-block-margin-vertical" style="margin-bottom: 35px;">\n                На указанный адрес придет код подтверждения\n            </div>\n\n            <div class="button_send">\n                <button @click="next" class="button button-fill button-large button--success button button-fill button-large">\n                    Отправить\n                </button>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

[data-f7-16a53a1f09] .pointer {
        cursor: pointer;
    }

[data-f7-16a53a1f09] .list+.list {
        margin-top: 27px;
    }

[data-f7-16a53a1f09] .fa-star--grey {
        width: 15px;
        height: 14.7px;
        background: #818E95;
    }

[data-f7-16a53a1f09] .item--success {
        color: #32BF61 !important;
    }


[data-f7-16a53a1f09] .black {
        color: black !important;
    }
[data-f7-16a53a1f09] body {
        display:none !important;
    }
[data-f7-16a53a1f09] .ml-mini {
        margin-left: 0.2em;
    }

[data-f7-16a53a1f09] .button--success {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
        border-radius: 4px;
    }

[data-f7-16a53a1f09] .button--success:hover {
        background-color: #3fd06f;
    }


[data-f7-16a53a1f09] .list+.button_send {
        margin-top: 35px;
    }

[data-f7-16a53a1f09] .button_send {
        padding: 0 8px;
    }

[data-f7-16a53a1f09] .block-title {
        margin-top: 35px;
    }

[data-f7-16a53a1f09] .preloader {
        width: 24px;
        height: 20px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
        right: calc(var(--f7-safe-area-right) + 10px);
    }

[data-f7-16a53a1f09] .item-inner--other_amount {
        justify-content: center;
    }

[data-f7-16a53a1f09] .item-inner--other_amount>.item-title {
        color: #007AFF;
    }

[data-f7-16a53a1f09] .page-content {
        background-color: unset;
    }

[data-f7-16a53a1f09] .grecaptcha-badge {
        display: none !important;
    }


[data-f7-16a53a1f09] #code-space + .button_send {
        margin-top: 35px;
    }

[data-f7-16a53a1f09] .button + div {
        margin-top: 5px;
    }
[data-f7-16a53a1f09] .grey-collor {
        background-color: grey;
    }

[data-f7-16a53a1f09] .preloader {
        right: unset !important;
    }
`,
  styleScoped: true
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      referral_rules: null,
      gauge: null,
      sheet: null,
      popup: null,
      info: null
    };
  },
  on: {
    pageBeforeIn: function (event, page) {
      page.$el.addClass('no-toolbar');
      this.tryGetInfoReferal();
      this.getReferralRuels();
    },
    pageBeforeOut: function (event, page) {
      this.sheet.close();
      document.querySelector('.toolbar').style.display = 'block';
    },
    pageAfterOut(event, page) {
    },
    pageAfterIn: function (event, page) {
      document.querySelector('.toolbar').style.display = 'none';
    }
  },
  methods: {
    getReferralRuels() {
      var self = this;
      var remoteConfig = this.$app.data.firebase.remoteConfig();
      remoteConfig.settings = { minimumFetchIntervalMillis: 100 };
      remoteConfig.fetchAndActivate().then(() => {
        let referral_rules = JSON.parse(remoteConfig.getValue('referral_rules')._value);
        self.$setState({ referral_rules });
      });
    },
    copyPromocode(e) {
      let copyText = e.target.innerText;
      const el = document.createElement('textarea');
      el.value = copyText;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$app.dialog.alert('', 'Промокод скопирован');
    },
    back() {
      document.querySelector('.toolbar').style.display = 'block';
      this.$router.back({ name: 'profile' });
    },
    share() {
      const shareData = { text: `Скачай приложение Fan Battle - Фэнтези футбол\nhttps://fanbattle.app.link/install\nУкажи при регистрации промокод ${ this.info.promocode } и получи 200 кристалов!` };
      if (navigator.share) {
        navigator.share(shareData);
      }
    },
    tryGetInfoReferal() {
      var self = this;
      this.$root.api('app/promo/info', {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          data.referals.sort(self.sortFriendList);
          self.$setState({ info: data });
          let iconMain = data.referals.slice(0, 4);
          let dimmyMainCount = 4 - data.referals.length;
          let blockMainIconPlayers = ``;
          for (let i = 0; i < iconMain.length; i++) {
            if (!!data.referals[i].verified) {
              blockMainIconPlayers = blockMainIconPlayers.concat(`
                                    <div class="icon-player icon-player--modifier" style="background-image:url(${ data.referals[i].avatar.url })"></div>
                                `);
            } else {
              blockMainIconPlayers = blockMainIconPlayers.concat(`
                                    <div class="icon-player icon-player--modifier" style="background-image:url(${ data.referals[i].avatar.url })">
                                        <i class="f7-icons">exclamationmark_circle_fill</i>
                                    </div>
                                `);
            }
          }
          if (dimmyMainCount > 0) {
            for (let i = 0; i < dimmyMainCount; i++) {
              blockMainIconPlayers = blockMainIconPlayers.concat(`
                                    <div class="icon-player"></div>
                                `);
            }
          }
          let stackBlockMainIconPlayers = `
                            <div class="stack-icons-player">
                                <div class="icon-player" style="z-index: 3;"></div>
                                <div class="icon-player" style="z-index: 2;right: 8px;"></div>
                                <div class="icon-player" style="right:0; z-index: 1;"></div>
                            </div>
                        `;
          blockMainIconPlayers = blockMainIconPlayers.concat(stackBlockMainIconPlayers);
          self.$el.find('.invited-friends-body').html(blockMainIconPlayers);
          self.$el.find('.invited-friends-header-count').html(data.referals.length + ' ' + self.num2str(data.referals.length, [
            'друг',
            'друга',
            'друзей'
          ]));
          self.createGauge();
          self.createSheet();
          self.createPopupRules();
        }
      }, true);
    },
    sortFriendList(a, b) {
      const A = a.created_at;
      const B = b.created_at;
      let comparison = 0;
      if (A > B) {
        comparison = -1;
      } else if (A < B) {
        comparison = 1;
      }
      return comparison;
    },
    num2str(n, text_forms) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return text_forms[2];
      }
      if (n1 > 1 && n1 < 5) {
        return text_forms[1];
      }
      if (n1 == 1) {
        return text_forms[0];
      }
      return text_forms[2];
    },
    createGauge() {
      this.gauge = this.$app.gauge.create({
        el: '.gauge',
        type: 'circle',
        value: 1,
        size: 176,
        borderColor: '#2196f3',
        borderWidth: 19
      });
      this.setGradientOnGauge();
    },
    createSheet() {
      this.sheet = this.$app.sheet.create({
        el: '.my-sheet-swipe-to-step',
        swipeToClose: false,
        backdrop: false
      });
      this.sheet.open();
    },
    createPopupRules() {
      this.popup = this.$app.popup.create({ el: '.popup-rules' });
    },
    closePopupRules() {
      this.popup.close();
      this.sheet.open();
    },
    openPopupRules() {
      let divRules = this.$(document).find('.referals-rules')[0];
      divRules.innerHTML = '';
      for (let i = 0; i < this.referral_rules.length; i++) {
        let info = this.referral_rules[i];
        if (!!info.tag) {
          var element = document.createElement(info.tag);
          if (!!info.text) {
            element.innerText = info.text;
          }
          if (info.tag === 'ul') {
            for (let j = 0; j < info.child.length; j++) {
              let li = document.createElement('li');
              li.innerText = info.child[j].text;
              element.append(li);
            }
          }
        }
        divRules.append(element);
      }
      this.sheet.close();
      this.popup.open();
    },
    toList() {
      if (this.info.referals.length > 0) {
        var self = this;
        window.friendsList = this.info.referals;
        self.$router.navigate({ name: 'list-invite-friend' });
      }
    },
    setGradientOnGauge() {
      this.$el.find('.gauge-front-circle').attr('stroke', 'url(#gradient)');
      this.$el.find('.gauge-svg').html(`<defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stop-color="#82b7f5" />
                    <stop offset="100%" stop-color="#103a6e" />
                    </linearGradient>
                </defs>` + this.$el.find('.gauge-svg').html());
    }
  },
  id: '829eb17b43',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link icon-only" @click="back">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Пригласить друга\n                </div>\n                <div class="right">\n<!--                    <a href="#" class="link" @click="openPopupRules">-->\n<!--                        <i class="f7-icons">info_circle</i>-->\n<!--                    </a>-->\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.info, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block">\n                    <!-- Блок "Измеритель" -->\n                    <div class="circle-block">\n                        <div class="circle">\n                            <div class="gauge"></div>\n                            <div class="gauge-inner-block">\n                                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.info, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                    ';
              r += Template7Helpers.with.call(ctx_3, ctx_3.info, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <p class="gauge-inner-text mt-0 mb-0">вcего<br>заработано</p>\n                                        <p class="gauge-inner-text-cost mt-0 mb-0">';
                  r += c(ctx_4.total.value, ctx_4);
                  r += c(ctx_4.total.currency, ctx_4);
                  r += '</p>\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                            </div>\n                        </div>\n                    </div>\n                    <!-- Блок "Приглашенные друзья" -->\n                    <div class="invited-friends" @click="toList">\n                        <div class="invited-friends-header">\n                            <div class="title">Приглашено</div>\n                            <div class="invited-friends-header-count">1 друг</div>\n                        </div>\n                        <div class="invited-friends-body">\n\n                        </div>\n                    </div>\n                    <!-- Блок "Зови друзей" -->\n                    <div class="text-box-title">\n                        ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.info, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            ';
              r += Template7Helpers.with.call(ctx_3, ctx_3.info, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                <div class="text-box-title-header">';
                  r += c(ctx_4.info[0], ctx_4);
                  r += '</div>\n                                <div class="text-box-title-subheader">';
                  r += c(ctx_4.info[1], ctx_4);
                  r += '</div>\n                            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    </div>\n                    <!-- Блок с картинкой -->\n                    <div class="picture-box">\n                        <img src="../../../static/app/invite-friends.svg" style="width: 100%;" alt="">\n                    </div>\n                </div>\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block block-strong text-align-center">\n                    <div class="preloader"></div>\n                </div>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            <!-- Sheet-modal для кнопки "Поделиться" -->\n            <div class="sheet-modal my-sheet-swipe-to-step" style="height:calc(70px + var(--f7-safe-area-bottom)); --f7-sheet-bg-color: #fff;box-shadow: 0 10px 30px 5px rgba(0, 0, 0, .5);">\n                <div class="sheet-modal-inner">\n                <div class="sheet-modal-swipe-step">\n                    <div class="display-flex padding justify-content-space-between align-items-center">\n                        <div class="box-promocode">\n                            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.info, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.info, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                    <div class="promocode" @click="copyPromocode">';
              r += c(ctx_3.promocode, ctx_3);
              r += '</div>\n                                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                            <div class="description">Ваш промокод</div>\n                        </div>\n                        <div>\n                            <button id="share-button" @click="share" class="button button-fill">\n                                <i class="icon icon-shared-old"></i>\n                                Поделиться\n                            </button>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <!-- Popup "Правила" -->\n            <div class="popup popup-rules">\n                <div class="navbar">\n                    <div class="navbar-bg"></div>\n                    <div class="navbar-inner">\n                        <div class="left">\n                            <a class="link" @click="closePopupRules">Закрыть</a>\n                        </div>\n                        <div class="title">Реферальная программа</div>\n                    </div>\n                </div>\n                <div class="block">\n                    <div class="referals-rules">\n\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .picture-box {
        margin-top: 18px;
    }
    .text-box-title {
        margin: 18px 39px;
        color: #445058;
        text-align: center;
    }
    .text-box-title-header {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
    }
    .text-box-title-subheader {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    .invited-friends-body {
        margin: 8px 12px;
        display: flex;
        justify-content: space-between;
    }
    .stack-icons-player {
        position: relative;
        width: 58px;
    }
    .stack-icons-player .icon-player {
        position: absolute;
    }
    .invited-friends-body .avatar {
        height: 40px;
        width: 40px;
        background-color: red;
    }
    .invited-friends-header {
        display: flex;
        margin: 8px 12px;
        justify-content: space-between;
        color: #445058;
    }
    .invited-friends-header .title{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    .invited-friends-header .count{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    .invited-friends {
        background-color: #FFFFFF;
        border: 1px solid #CFD6DB;
        border-radius: 10px;
        height: 84px;
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .circle-block {
        margin-top: 24px;
        margin-bottom: 20px;
        position: relative;
    }
    .circle {
        height: 176;
        width: 100%;
        display: flex;
    }

    .gauge-inner-block {
        margin-top: 0px;
        margin-bottom: 0px;
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #445058;
    }
    .gauge-inner-text {
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        font-variant: small-caps;
    }
    .gauge-inner-text-cost {
        letter-spacing: -0.41px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }
    .icon-player--modifier {
        background-size: cover;
        border-radius: 30px;
        position: relative;
    }
    .icon-player--modifier .f7-icons {
        position: absolute;
        right: -3px;
        bottom: -3px;
        font-size: 17px;
        color: #C1272D;
    }
`,
  styleScoped: false
};
    
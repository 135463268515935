import AuthLoginPage from '../pages/auth/login.f7.html';
import AuthRegistrationPage from '../pages/auth/registration.f7.html';

/*  Sign in stack pages */
import SignInEnterPhone from '../pages/signin/signin-enter-phone.f7.html';
import SignInEnterCode from '../pages/signin/signin-enter-code.f7.html';
import SignInEmail from '../pages/signin/signin-enter-email.f7.html';

import AuthRememberPage from '../pages/auth/remember.f7.html';
import AuthRememberSuccessPage from '../pages/auth/remember-success.f7.html';
import AuthProfilePage from '../pages/auth/profile.f7.html';
import AuthProfileDetailsPage from '../pages/auth/profile-details.f7.html';

import PopupTeamCreatePage from '../pages/popups/team-create.f7.html';

import GamesIndexPage from '../pages/games/index.f7.html';

import LiveIndexPage from '../pages/live/index.f7.html';
import LiveScoresPage from '../pages/live/scores.f7.html';
import LiveProfilePage from '../pages/live/profile.f7.html';

import ChangePasswordPage from '../pages/change-password/change-password-after-login.f7.html';
import AddPhonePage from '../pages/add-phone/add-phone.f7.html';

import WithFriendsPage from '../pages/with-friends/index.f7.html';

import InputOut from '../pages/auth/input-out.f7.html';
import InputIn from '../pages/auth/input-in.f7.html';

import Withdrawal from '../pages/auth/withdrawal/withdrawal.f7.html';
import WithdrawalSelectPayment from '../pages/auth/withdrawal/payments.f7.html';
import WithdrawalPurse from '../pages/auth/withdrawal/purse.f7.html';


import IncreaseAmountPage from '../pages/auth/billing-olimp/increase-balance/increase-amount.f7.html';
import IncreaseDetailsPage from '../pages/auth/billing-olimp/increase-balance/increase-details.f7.html';
import IncreasePopupPage from '../pages/auth/billing-olimp/increase-balance/increase-popup.f7.html';

import DecreaseAmountPage from '../pages/auth/billing-olimp/decrease-balance/decrease-amount.f7.html';
import DecreaseDetailsPage from '../pages/auth/billing-olimp/decrease-balance/decrease-details.f7.html';
import DecreasePopupPage from '../pages/auth/billing-olimp/decrease-balance/decrease-popup.f7.html';

import BuyCrystalsPage from '../pages/auth/buy-cristals.f7.html';

import HistoryOperationsPage from '../pages/auth/history-operations/index.f7.html';
import HistoryOperationsCommentary from '../pages/auth/history-operations/commentary.f7.html';
import HistoryOperationsOperation from '../pages/auth/history-operations/operation.f7.html';


import TeamCreate0Page from '../pages/team/create-0.f7.html';
import TeamCreate1Page from '../pages/team/create-1.f7.html';
import TeamCreate2Page from '../pages/team/create-2.f7.html';
import TeamCreate3Page from '../pages/team/create-3.f7.html';
import CategoryTournaments from '../pages/team/category-tournaments.f7.html';

import PlayerInfoView  from '../pages/player-info/index.f7.html';

import TournamentInfoPage from '../pages/tournament/info.f7.html';
import TournamentInfoPopupPage from '../pages/popups/tournament-info.f7.html';

import LobbyPage from '../pages/lobby/index.f7.html';
import LobbyTournamentsPage from '../pages/lobby/tournaments.f7.html';

import LeaderboardPage from '../pages/leaderboard/index.f7.html';


import GuidePage from '../pages/guide.f7.html';

import InfoIndex from '../pages/info/index.f7.html';
import InfoRules from '../pages/info/rules.f7.html';
import InfoTerms from '../pages/info/terms.f7.html';

import AppPage from '../pages/app.f7.html';
import Support from '../pages/info/support.f7.html';
import SupportMessage from '../pages/info/support-message.f7.html';

import SelectCurrency from '../pages/auth/select-currency/select-currenct.f7.html';

/* Дуэли */
import DuelsView from '../pages/duels/view.f7.html';
import DuelCreation from '../pages/lobby/duels/duel-creation.f7.html';

/* Угадай состав */
import QuizInfoPage from '../pages/quiz/info.f7.html';
import QuizInfoPopupPage from '../pages/popups/quiz-info.f7.html';

/* Игры с друзьями */
// import FriendsTournamentView from '../pages/duels/view.f7.html';
import FriendsTournamentCreation from '../pages/with-friends/friends-tournament/tournament-creation.f7.html';

/* Верификация */
import VerificatedPagePhoneStepOne from '../pages/auth/verificated/phone/step-one.f7.html';
import VerificatedPagePhoneStepTwo from '../pages/auth/verificated/phone/step-two.f7.html';
import VerificatedPagePhoneSuccess from '../pages/auth/verificated/phone/success.f7.html';
import VerificatedPageEmailStepOne from '../pages/auth/verificated/email/step-one.f7.html';
import VerificatedPageEmailStepTwo from '../pages/auth/verificated/email/step-two.f7.html';

/* Техническая информация */
import TechnicalInfoPage from '../pages/auth/technical-info.f7.html';

/* Пригласить друга */
import InviteFriendPage     from '../pages/auth/invite-friend/index.f7.html';
import ListInviteFriendPage from '../pages/auth/invite-friend/invited-friends.f7.html';

/* "Кто выйдет на поле?" */
import WhoWillPlay from '../pages/who_will_play/index.f7.html';
import ProfileChangePassword from '../pages/auth/change-password.f7.html';

import SomethingWrongPage from '../pages/something-wrong/something-wrong.f7.html';

/* Изменить ник */
import ChangeNicknamePopup from '../pages/auth/change-nickname/change-nickname-popup.f7.html';
import ChangeNickname from '../pages/auth/change-nickname/change-nickname.f7.html';

function checkNoSession() {
    console.log(this)
}

export default [
    {
        path: '/profile/change-nickname-popup',
        name: 'profile.change-nickname-popup',
        popup: {
            component: ChangeNicknamePopup,
        }
    },
    {
        path: '/profile/change-nickname',
        name: 'profile.change-nickname',
        component: ChangeNickname,
    },
    {
        path: '/auth/something-wrong',
        name: 'auth.something-wrong',
        component: SomethingWrongPage,
    },
    {
        path: '/auth/sign-in/email',
        name: 'auth.sing-in.email',
        component: SignInEmail,
    },
    {
        path: '/auth/add-phone',
        name: 'auth.add-phone',
        component: AddPhonePage,
    },
    {
        path: '/auth/select-currency',
        name: 'auth.select-currency',
        component: SelectCurrency,
    },
    {
        path: '/auth/sign-in/',
        name: 'auth.sing-in.enter-phone',
        component: SignInEnterPhone,
    },
    {
        path: '/auth/sign-in/enter-code',
        name: 'auth.sing-in.enter-code',
        component: SignInEnterCode,
    },
    {
        path: '/profile/change-password',
        name: 'profile.change-password',
        component: ProfileChangePassword,
    },
    {
        path: '/auth/registration/',
        name: 'auth.registration',
        component: AuthRegistrationPage,
    },
    {
        path: '/auth/change-password/',
        name: 'auth.change-password',
        component: ChangePasswordPage,
    },
    {
        path: '/auth/remember/',
        component: AuthRememberPage,
    },
    {
        path: '/auth/remember-success/',
        component: AuthRememberSuccessPage,
    },
    {
        path : '/auth/support/',
        component: Support
    },
    {
        path : '/support/message',
        name: 'support.message',
        component: SupportMessage
    },
    {
        path : '/profile/',
        name: 'profile',
        component: AuthProfilePage,
    },
    {
        path : '/profile/details/',
        name: 'profile-details',
        history: false,
        component: AuthProfileDetailsPage,
    },
    {
        path : '/technical-info/',
        name: 'technical.info',
        component: TechnicalInfoPage,
    },
    {
        path : '/profile/withdrawals/payments',
        name: 'withdrawal.payment',
        component: WithdrawalSelectPayment,
    },
    {
        path : '/profile/withdrawal/purse',
        name: 'withdrawal.purse',
        component: WithdrawalPurse,
    },
    {
        path : '/profile/withdrawal/main',
        name: 'withdrawal.main',
        component: Withdrawal,
    },
    {
        path : '/profile/increase-balance-popup',
        name: 'increase-amount',
        popup: {
            component: IncreasePopupPage,
        }
    },
    {
        path : '/profile/increase-balance/amount',
        name: 'increase-amount',
        component: IncreaseAmountPage,
    },
    {
        path : '/profile/increase-balance/details',
        name: 'increase-details',
        component: IncreaseDetailsPage,
    },
    {
        path : '/profile/decrease-balance-popup',
        name: 'decrease-amount',
        popup: {
            component: DecreasePopupPage,
        }
    },
    {
        path : '/profile/decrease-balance/amount',
        name: 'decrease-amount',
        component: DecreaseAmountPage,
    },
    {
        path : '/profile/decrease-balance/details',
        name: 'decrease-details',
        component: DecreaseDetailsPage,
    },
    {
      path : '/profile/buy-cristals/',
      name: 'buy-cristals',
      component: BuyCrystalsPage,
    },
    {
        path : '/profile/withdrawals/',
        component: HistoryOperationsPage,
    },
    {
        path : '/profile/withdrawals/commentary',
        name: 'withdrawals.commentary',
        component: HistoryOperationsCommentary,
    },
    {
        path : '/profile/invite-friend',
        name: 'invite-friend',
        component: InviteFriendPage,
    },
    {
        path : '/profile/invite-friend/list',
        name: 'list-invite-friend',
        component: ListInviteFriendPage,
    },
    {
        path : '/profile/hostory-operations/operation',
        name: 'profile.history_operations.operation',
        component: HistoryOperationsOperation,
    },
    {
        path : '/profile/check-',
        component: AuthProfilePage,
    },
    {
        path : '/profile/input-out',
        component: InputOut,
    },
    {
        path : '/profile/input-in',
        component: InputIn,
    },
    {
        path : '/games/',
        component: GamesIndexPage,
    },
    {
      path : '/games/scores/',
      component: LiveScoresPage,
    },
    {
        path : '/games/profile/',
        component: LiveProfilePage,
    },
    {
        path: '/lobby/',
        name: 'lobby',
        component: LobbyPage,
    },
    {
        path: '/who-will-play/',
        name: 'who-will-play',
        component: WhoWillPlay,
    },
    {
      path: '/lobby/duel-creation',
      name: 'duel-creation',
      component: DuelCreation,
    },
    {
      path: '/lobby/friends-tournament-creation',
      name: 'friends-tournament-creation',
      component: FriendsTournamentCreation,
    },
    {
        path : '/lobby/tournaments/',
        component: LobbyTournamentsPage,
    },
    {
        path : '/auth/profile/verificated/phone/step-1',
        name: 'auth.verificated.phone.step-one',
        component: VerificatedPagePhoneStepOne,
    },
    {
        path : '/auth/profile/verificated/phone/step-2',
        name: 'auth.verificated.phone.step-two',
        component: VerificatedPagePhoneStepTwo,
    },
    {
        path : '/auth/profile/verificated/phone/success',
        name: 'auth.verificated.phone.success',
        component: VerificatedPagePhoneSuccess,
    },
    {
        path : '/profile/verificated/phone/step-1',
        name: 'verificated.phone.step-one',
        component: VerificatedPagePhoneStepOne,
    },
    {
        path : '/profile/verificated/phone/step-2',
        name: 'verificated.phone.step-two',
        component: VerificatedPagePhoneStepTwo,
    },
    {
        path : '/profile/verificated/email/step-1',
        name: 'verificated.email.step-one',
        component: VerificatedPageEmailStepOne,
    },
    {
        path : '/profile/verificated/email/step-2',
        name: 'verificated.email.step-two',
        component: VerificatedPageEmailStepTwo,
    },
    {
        path : '/team/create/:id/:type/',
        name: 'team.create',
        popup : {
            component: PopupTeamCreatePage,
        },
        beforeEnter: function (routeTo, routeFrom, resolve, reject) {
            var app = this.app;

            if (!routeTo.query.entry_id) {
                app.methods.clearTeam();
            }
            app.preloader.show();
            app.methods.api(routeTo.query.entry_id ? `app/client_tournaments/${routeTo.params.id}/entry/${routeTo.query.entry_id}/team` : 'app/tournaments/' + routeTo.params.id + '/team', {team : app.methods.getTeam()}, 'POST', function (data, status, xhr) {
                if (data.status == 'error') {
                    if (data.exception == 'teamBuild' && app.methods.getTeam().length) {
                        app.methods.$root.clearTeam();
                        app.dialog.alert(data.message, 'Ошибка');
                    } else {
                        if (data.type == 'balance') {
                            app.dialog.create({
                                text: data.message,
                                title: data.title,
                                destroyOnClose: true,
                                buttons : [
                                    {
                                        text: 'Закрыть',
                                    },
                                    {
                                        text: 'Пополнить',
                                        cssClass: 'text-bold',
                                        onClick: function(dialog, e) {
                                          app.tab.show('#view-profile');
                                          app.views.profile.router.refreshPage();
                                          app.views.profile.router.navigate('/profile/increase-balance/');
                                          // app.$router.navigate('/profile/increase-balance/');
                                          // /profile/increase-balance/
                                            // app.tab.show('#view-profile');
                                            // app.views.profile.router.refreshPage();
                                        }
                                    }
                                ]
                            }).open();
                        } else if (data.type == 'confirm') {
                            app.dialog.create({
                              text: data.message,
                              title: data.title,
                              destroyOnClose: true,
                              buttons : [
                                {
                                  text: 'Закрыть',
                                },
                                {
                                  text: 'Профиль',
                                  cssClass: 'text-bold',
                                  onClick: function(dialog, e){
                                    app.tab.show('#view-profile');
                                    app.views.profile.router.refreshPage();
                                  }
                                }
                              ]
                            }).open();
                        } else {
                            app.dialog.alert(data.message, 'Ошибка');
                        }
                    }
                    reject();
                } else {
                    app.methods.setKey('team.create', JSON.stringify(data));
                    resolve();
                }
            }, true);
        },
    },
    {
        path : '/quiz/info/:id/',
        component: QuizInfoPage,
    },
    {
        path : '/quiz-popup/info/:id/',
        popup: {
            component: QuizInfoPopupPage,
        }
    },
    {
      path : '/player/info',
      component: PlayerInfoView,
    },
    {
        path : '/team/create-0/',
        name: 'team_create_0',
        component: TeamCreate0Page,
    },
    {
        path : '/team/category-tournaments/',
        name: 'category_tournaments',
        component: CategoryTournaments,
    },
    {
        path : '/team/create-1/',
        component: TeamCreate1Page,
    },
    {
        path : '/team/create-2/',
        component: TeamCreate2Page,
    },
    {
        path : '/team/create-3/',
        name: 'team-create-3',
        component: TeamCreate3Page,
    },
    {
        path : '/tournament/info/:id/',
        component: TournamentInfoPage,
    },

    {
        path : '/tournament-popup/info/:id/',
        popup: {
            component: TournamentInfoPopupPage,
        }
    },
    {
        path : '/with-friends/',
        component: WithFriendsPage,
    },
    {
        path : '/leaderboard/',
        component: LeaderboardPage,
    },
    {
        path: '/guide/',
        component: GuidePage,
    },
    {
        path : '/info/',
        component: InfoIndex,
    },
    {
        path : '/info/rules/',
        component: InfoRules,
    },
    {
        path : '/info/terms/',
        component: InfoTerms,

    },
    {
        path : '/app/',
        popup : {
            component: AppPage,
        }

    },
    {
        path : '/duels/view/',
        component: DuelsView,

    },
];


      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      sum: null,
      type: null,
      wallet: null,
      stringType: ''
    };
  },
  methods: {
    next() {
      var self = this;
      var messageError = self.type === 'mobile' ? 'Пожалуйста введите номер телефона' : 'Пожалуйста введите номер кошелька';
      var wallet = self.$el.find('input[name="wallet"]').val();
      if (wallet.length === 0) {
        self.$app.dialog.alert(messageError);
      } else {
        self.$router.navigate({
          name: 'withdrawal.main',
          query: {
            'type': self.type,
            'sum': self.sum,
            'wallet': wallet
          }
        });
      }
    },
    handlerWallet(type) {
      switch (type) {
      case 'qiwi':
        var stringType = 'Qiwi кошелек';
        break;
      case 'yandex':
        var stringType = 'Яндекс.Кошелек';
        break;
      case 'mobile':
        var stringType = 'Мобильный телефон';
        break;
      }
      return stringType;
    },
    checkQuery(data) {
      var self = this;
      self.$setState({
        'sum': data.sum,
        'type': data.type,
        'wallet': data.wallet,
        'stringType': self.handlerWallet(data.type)
      });
    },
    sumbitWithdrawal() {
      var self = this;
      self.$app.preloader.show();
      let params = {
        'type': self.type,
        'wallet': self.wallet,
        'amount': +self.sum,
        'percent': 1
      };
      self.requestCreateWithdrawal(params);
    },
    requestCreateWithdrawal(params) {
      var self = this;
      self.$root.api('/app/balance/coins/request-withdrawal', params, 'POST', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.preloader.hide();
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          self.$app.preloader.hide();
          self.$app.dialog.alert('Операция будет выполнена в течение 1-3 дней', 'Успешно', () => {
            self.$router.navigate('/profile/');
          });
        }
      }, true);
    }
  },
  on: {
    pageInit: function () {
      this.checkQuery(this.$router.currentRoute.query);
    }
  },
  id: '967938ef16',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Заявка на вывод\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block-title">Проверьте заявку</div>\n            <div class="list no-hairlines-md">\n                <ul>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Сумма</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, '+this.sum', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\u20BD</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Куда</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, 'this.stringType', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Кошелек</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, '+this.wallet', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n            <div class="list no-hairlines-md" style="margin-top: 35px;">\n                <ul>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Комиссия (1%)</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, '(+this.sum * + 1 / 100).toFixed(0)', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\u20BD</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Итого к оплате</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, '(+this.sum + +this.sum * + 1 / 100).toFixed(0)', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\u20BD</div>\n                            </div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n            <div class="button_send">\n                <a class="button button-fill button-large button--success button button-fill button-large button--success" @click="sumbitWithdrawal"\n                    style="border-radius: 4px; margin-top: 35px;">\n                    Отправить заявку\n                </a>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-967938ef16] .list a {
        color: rgb(109, 109, 114);
    }

[data-f7-967938ef16] .button--success {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
    }

[data-f7-967938ef16] .block--f7-block-margin-vertical + .toolbar {
        margin-top: 35px;
    }
[data-f7-967938ef16] .block-title {
        margin-top: 35px;
    }
[data-f7-967938ef16] .list+.button_send {
            margin-top: 35px;
        }
[data-f7-967938ef16] .button_send {
        padding: 0 8px;
    }
`,
  styleScoped: true
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      require('./style.css');
import HeaderBlockController from './header-block-controller.js';
export default {
  data: function () {
    return {
      urlNavbarLogo: null,
      viewNicknameSheetModal: false,
      whoWillPlay: [],
      intervalFT: null,
      hi: null,
      banners: null,
      categories: null,
      categoriesFull: null,
      swipers: [],
      timer: null,
      guide: null,
      guideTimer: null,
      networkError: false,
      loadingDuelsAfterCreated: false,
      friendlyTournaments: [],
      duelsDataLoading: false,
      sheetInterDuelData: {
        player: { name: '' },
        duel: {},
        data: {
          amount: 100,
          priz: 200
        }
      },
      allowInfinite: true,
      allCategories: null,
      gamesWithFriends: {
        enable: true,
        title: 'Игры с друзьями',
        description: 'Закрытые турниры только со "своими"',
        tournaments: [],
        interval: null,
        swiper: null
      }
    };
  },
  methods: {
    openSheetShareFriendsGame(id) {
      let self = this;
      let tournament = this.gamesWithFriends.tournaments.find(el => el.id == id);
      this.$setState({ sheetShareFriendsGameData: tournament });
      this.sheetShareFriendsGame = this.$app.sheet.create({
        el: self.$el.find('.sheet-share-friends-game')[0],
        backdrop: true
      }).open();
    },
    setStateDuelsDataLoading(state) {
      this.$setState({ duelsDataLoading: state });
    },
    openDuel(id) {
      let superDuel = this.duelsData.find(el => el.id == id);
      let firstDuel = superDuel.clients_duels[0];
      this.$router.navigate(`/duels/view/?id=${ firstDuel.id }`);
    },
    copyPinCode(code) {
      const el = document.createElement('textarea');
      el.value = code;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$app.dialog.alert('', 'Пин-код скопирован');
      this.sheetShareFriendsGame.close();
    },
    sharePinCode(pincode) {
      let self = this;
      if (self.$app.methods.getTypeApp() == 'olimp') {
        let shareData = { text: `ПИН ${ pincode }\nСобери команду и сыграй против меня в FanBattle Fantasy:\nhttps://fantasysports.app.link/go` };
        if (navigator.share) {
          navigator.share(shareData);
        }
        setTimeout(() => {
          self.sheetShareFriendsGame.close();
        }, 300);
      } else {
        this.$app.preloader.show();
        self.$app.methods.generateDinamicLinkForFriendTournament(pincode).then(function (data) {
          self.$app.preloader.hide();
          let shareData = { text: `Собери команду и сыграй против меня тут: ${ data.shortLink }` };
          if (navigator.share) {
            navigator.share(shareData);
          }
          setTimeout(() => {
            self.sheetShareFriendsGame.close();
          }, 300);
        });
      }
    },
    updateTotal(e) {
      let amount = +e.target.value;
      let data = {
        amount: amount,
        priz: amount * 2
      };
      let sheetInterDuelData = this.sheetInterDuelData;
      sheetInterDuelData.data = data;
      this.$setState({ sheetInterDuelData });
    },
    selectPLayerByDuel(id) {
      console.log(id);
    },
    toCreateDuel() {
      try {
        if (this.$el.find('.duels-ptr') && this.$el.find('.duels-ptr')[0].querySelector('.ptr-preloader')) {
          this.$el.find('.duels-ptr')[0].querySelector('.ptr-preloader').style.display = 'none';
        }
        if (document.querySelector('.toolbar')) {
          document.querySelector('.toolbar').style.display = 'none';
        }
        setTimeout(() => {
          this.$router.navigate('/lobby/duel-creation');
        }, 0);
      } catch (e) {
        this.$app.dialog.alert('Что-то пошло не так, попробуйте позже', '', () => {
          this.$router.navigate('/lobby/duel-creation');
        });
      }
    },
    checkMyDuel() {
      return true;
    },
    joinToExistingDuel(pairID, playerOrder) {
      console.log('pairID', pairID);
      try {
        let targetElem = window.event.target;
        let duelElem = targetElem.closest('.duel');
        let duelID = parseInt(duelElem.getAttribute('super-duel-id'));
        if (!duelID) {
          throw Error('duelID is undefined');
        }
        const duelData = this.duelsData.find(d => d.id == duelID);
        if (!duelData) {
          throw Error('duelData is undefined');
        }
        let playerData = null;
        switch (playerOrder) {
        case 1:
          playerData = duelData.player_1;
          break;
        case 2:
          playerData = duelData.player_2;
          break;
        default:
          break;
        }
        if (!playerData) {
          throw Error('playerData is undefined');
        }
        let pairData = duelData.clients_duels.find(el => el.id == pairID);
        if (typeof pairData === 'undefined') {
          pairData = null;
        }
        const defaultData = {
          amount: 100,
          priz: 200
        };
        if (pairData) {
          defaultData.amount = pairData.self.amount.value;
          defaultData.priz = pairData.self.prize.value;
        }
        let name = playerData.firstname + ' ' + playerData.lastname;
        let fullLength = 21;
        const w = window.innerWidth;
        if (w <= 426 && w >= 376) {
        } else if (w < 376 && w >= 321) {
          fullLength = 19;
        } else {
          fullLength = 16;
        }
        if (name.length > fullLength) {
          name = name.slice(0, fullLength).concat('..');
        }
        playerData.h_name = name;
        this.$setState({
          sheetInterDuelData: {
            player: playerData,
            duel: duelData,
            pair: pairData,
            data: defaultData
          }
        });
        this.$app.sheet.create({
          el: '.sheet-inter-duel',
          backdrop: true
        }).open();
      } catch (e) {
        console.error(e.message);
        this.$app.dialog.alert('Что-то пошло не так, попробуйте позже');
      }
    },
    plus() {
      this.sheetInterDuelData.data.amount += 100;
      this.sheetInterDuelData.data.priz = this.sheetInterDuelData.data.amount * 2;
      this.$setState({ sheetInterDuelData: this.sheetInterDuelData });
    },
    minus() {
      if (this.sheetInterDuelData.data.amount > 100) {
        this.sheetInterDuelData.data.amount -= 100;
        this.sheetInterDuelData.data.priz = this.sheetInterDuelData.data.amount * 2;
        this.$setState({ sheetInterDuelData: this.sheetInterDuelData });
      }
    },
    createPairInDuel() {
      const self = this;
      const duelID = parseInt(this.sheetInterDuelData.duel.id);
      const params = {
        selected_player_id: parseInt(this.sheetInterDuelData.player.id),
        amount: parseInt(this.sheetInterDuelData.data.amount)
      };
      this.$app.preloader.show();
      this.$root.api(`app/duels/${ duelID }/client/confirm`, params, 'POST', function (data, status, xhr) {
        self.$app.sheet.close();
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          self.$app.dialog.alert('Вы успешно создали дуэль', 'Успешно!', () => {
            self.loadDuels();
            if (self.$app.views['games'].router.history.length == 1) {
              self.$app.views['games'].router.refreshPage();
            } else {
              self.$app.views['games'].router.navigate('/games/', { reloadAll: true });
            }
            self.$app.data.lastViewClick = 'games';
            self.$app.tab.show('#view-games');
          });
        }
      }, true);
    },
    joinThePairInDuel() {
      const self = this;
      const duelID = parseInt(this.sheetInterDuelData.duel.id);
      const params = {
        selected_player_id: parseInt(this.sheetInterDuelData.player.id),
        amount: parseInt(this.sheetInterDuelData.data.amount),
        client_duel_id: parseInt(this.sheetInterDuelData.pair.id)
      };
      this.$app.preloader.show();
      this.$root.api(`app/duels/${ duelID }/client/confirm`, params, 'POST', function (data, status, xhr) {
        self.$app.sheet.close();
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          self.$app.dialog.alert('Вы успешно присоединились к дуэли', 'Успешно!', () => {
            self.loadDuels();
            if (self.$app.views['games'].router.history.length == 1) {
              self.$app.views['games'].router.refreshPage();
            } else {
              self.$app.views['games'].router.navigate('/games/', { reloadAll: true });
            }
            self.$app.data.lastViewClick = 'games';
            self.$app.tab.show('#view-games');
          });
        }
      }, true);
    },
    createDuel: function (e) {
      const self = this;
      const params = {
        selected_player_id: parseInt(this.selectedDuel.playerId),
        amount: parseInt(this.$('.duel-button-amount').find('.button-fill').data('amount'))
      };
      const duelId = this.selectedDuel.id;
      this.$app.preloader.show();
      this.$root.api(`app/duels/${ duelId }/client/confirm`, params, 'POST', function (data, status, xhr) {
        self.$app.sheet.close();
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          if (self.$app.views['games'].router.history.length == 1) {
            self.$app.views['games'].router.refreshPage();
          } else {
            self.$app.views['games'].router.navigate('/games/', { reloadAll: true });
          }
          self.$app.data.lastViewClick = 'games';
          self.$app.tab.show('#view-games');
          self.$app.dialog.alert('Вы успешно создали дуэль', 'Успешно!', () => {
            self.loadDuels();
          });
        }
      }, true);
    },
    selectDuelAmount: function (e) {
      const el = this.$(e.currentTarget);
      this.$(el).parent().find('.button').removeClass('button-fill');
      this.$(el).addClass('button-fill');
      this.updateDuelPrize();
    },
    updateDuelPrize: function () {
      const amount = this.$('.duel-button-amount').find('.button-fill').data('amount');
      const prize = amount * 2;
      this.$setState({ selectedDuelPrize: prize });
    },
    showTabDuels: function () {
      if (typeof this.duelsData === 'undefined') {
        this.$app.preloader.show();
      }
      this.loadDuels();
    },
    selectDuel: function (e) {
      const id = this.$(e.currentTarget).data('id');
      const playerId = this.$(e.currentTarget).data('player-id');
      const duel = this.duels.find(d => d.id == id);
      const amounts = duel.amounts.map(a => ({
        value: a,
        selected: false
      }));
      amounts[Math.round((amounts.length - 1) / 2)].selected = true;
      this.updateDuelPrize();
      this.$app.sheet.create({
        el: '#sheet-inter-duel',
        backdrop: true
      }).open();
    },
    makeShortDate(rawDate) {
      let date = new Date(rawDate);
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let year = date.getFullYear();
      year = String(year).slice(-2);
      let hours = date.getHours();
      if (hours < 10) {
        hours = '0' + hours;
      }
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      return `${ day }.${ month }.${ year } ${ hours }:${ minutes }`;
    },
    fetchProfile() {
      return new Promise((resolve, reject) => {
        const self = this;
        self.$root.api('/app/profile', {}, 'GET', function (data, status, xhr) {
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Ошибка');
            reject();
          } else {
            self.$root.params.profile = data.profile;
            resolve();
          }
        }, true);
      });
    },
    loadDuels(success) {
      var self = this;
      self.$el.find('.duels-ptr')[0].querySelector('.ptr-preloader').style.display = 'block';
      self.$root.api('app/duels', {}, 'GET', function (data, status, xhr) {
        if (typeof success !== 'undefined') {
          success();
        }
        let duelsData = self.prepareDuels(data.duels);
        if (duelsData.length > 0) {
          self.$setState({ duelsData });
        }
        self.$app.preloader.hide();
      }, true);
    },
    prepareDuels(data) {
      try {
        const self = this;
        if (typeof data === 'undefined' || data.length === 0) {
          return false;
        }
        const prepareClientsDuels = [];
        data.map(el => {
          if (el.status.value == 'open') {
            el.match_1.shortDate = self.makeShortDate(el.match_1.started_at);
            el.match_2.shortDate = self.makeShortDate(el.match_2.started_at);
            el.match_1.localteam = el.match_1.localteam.slice(0, 3).replace(/\s/g, '').toUpperCase();
            el.match_1.visitorteam = el.match_1.visitorteam.slice(0, 3).replace(/\s/g, '').toUpperCase();
            el.match_2.localteam = el.match_2.localteam.slice(0, 3).replace(/\s/g, '').toUpperCase();
            el.match_2.visitorteam = el.match_2.visitorteam.slice(0, 3).replace(/\s/g, '').toUpperCase();
            let fullLength = 13;
            const w = window.innerWidth;
            if (w <= 426 && w >= 376) {
            } else if (w < 376 && w >= 321) {
              fullLength = 9;
            } else {
              fullLength = 8;
            }
            el.player_1.h_firstname = el.player_1.firstname;
            el.player_1.h_lastname = el.player_1.lastname;
            el.player_2.h_firstname = el.player_2.firstname;
            el.player_2.h_lastname = el.player_2.lastname;
            if (el.player_1.h_firstname.length > fullLength) {
              el.player_1.h_firstname = el.player_1.firstname.slice(0, fullLength).concat('..');
            }
            if (el.player_1.h_lastname.length > fullLength) {
              el.player_1.h_lastname = el.player_1.h_lastname.slice(0, fullLength).concat('..');
            }
            if (el.player_2.h_firstname.length > fullLength) {
              el.player_2.h_firstname = el.player_2.h_firstname.slice(0, fullLength).concat('..');
            }
            if (el.player_2.h_lastname.length > fullLength) {
              el.player_2.h_lastname = el.player_2.h_lastname.slice(0, fullLength).concat('..');
            }
            if (el.player_1.team == el.match_1.localteam) {
              el.match_1.myTeam = 'localteam';
            } else {
              el.match_1.myTeam = 'visitorteam';
            }
            if (el.player_2.team == el.match_2.localteam) {
              el.match_2.myTeam = 'localteam';
            } else {
              el.match_2.myTeam = 'visitorteam';
            }
            el.clients_duels.map(clientData => {
              if (!self.$root.params.profile) {
                throw Error('profile is undefined');
              }
              if (clientData.self.client.id == self.$root.params.profile.id) {
                clientData.self.me = true;
              }
              if (clientData.self.player.id == el.player_1.id) {
                clientData.self.order = 1;
              }
              if (clientData.self.player.id == el.player_2.id) {
                clientData.self.order = 2;
              }
            });
            if (el.clients_duels.length > 0) {
              prepareClientsDuels.push(el);
            }
          }
        });
        return prepareClientsDuels;
      } catch (e) {
        console.error(e);
        self.$app.dialog.alert('Что-то пошло не так, попробуйте позже', '', () => {
          self.loadDuels();
        });
      }
    },
    openAll: function (index) {
      if (index == 'gamesWithFriends') {
        this.$router.navigate('/lobby/tournaments/', {
          context: {
            category: {
              title: 'Игры с друзьями',
              tournaments: this.gamesWithFriends.tournaments,
              friendsMode: true
            }
          }
        });
        return;
      }
      this.$router.navigate('/lobby/tournaments/', { context: { category: this.categoriesFull[index] } });
    },
    updateGuide: function (firstRequest) {
      var self = this;
      self.$root.api('/app/profile/guide', {}, 'GET', function (data, status, xhr) {
        if (data.status == 'ok') {
          if (data.guide.is_finished) {
            if (!firstRequest) {
              self.$el.find('.tour-banner').remove();
            }
          } else {
            self.$setState({ guide: data.guide });
            self.guideTimer = setTimeout(function () {
              self.updateGuide(false);
            }, 15000);
          }
        }
      }, true);
    },
    goToGuide: function () {
      this.$router.navigate('/guide/');
    },
    openTournament(id) {
      var self = this;
      if (self.$root.isNosession()) {
        self.$root.alertNoSession();
        return false;
      }
      var type = 'for_points';
      self.categories.map(category => {
        if (category.name === 'for_coins') {
          category.tournaments.map(tournament => {
            if (tournament.id === id) {
              type = 'for_coins';
            }
          });
        }
      });
      this.$root.openTournament(this, id, type);
    },
    openBanner: function (id, url) {
      var self = this;
      var content = '';
      for (var i = 0; i < self.banners.length; i++) {
        if (self.banners[i].id == id) {
          content = self.banners[i].content;
        }
      }
      self.$app.popup.create({
        content: '<div class="popup"><div class="page-content"> ' + '<a href="#" class="link popup-close color-white" style="z-index:1000;position: absolute; right: 15px; top: 15px">' + '<i class="icon f7-icons">close_round_fill</i>' + '</a>' + '<img src="' + url + '" style="width: 100%;">' + '<div class="block">' + content + '</div>' + '</div></div>',
        on: {
          closed: function (popup) {
            popup.destroy();
          }
        }
      }).open();
    },
    refreshTournaments: function () {
      var self = this;
      setTimeout(function () {
        self.load(function () {
          self.$app.ptr.done(self.$el.find('#tab-lobby-tournaments'));
        });
      }, 1400);
      self.swipers = [];
      self.allowInfinite = true;
    },
    refreshDuels: function () {
      var self = this;
      self.loadDuels(function () {
        setTimeout(function () {
          self.$app.ptr.done(self.$el.find('#tab-lobby-duels'));
        }, 700);
      });
    },
    renderColorTimer(el, hours) {
      if (hours >= 1 && hours < 12) {
        el.parent().css('background', '#FAF4A8');
        el.parent().css('color', '#818E95');
      } else if (hours < 1) {
        el.parent().css('background', '#DC5955');
        el.parent().css('color', '#fff');
      } else {
        el.parent().css('background', '#EFEFF4');
        el.parent().css('color', '#818E95');
      }
    },
    updateTimer: function () {
      var self = this;
      self.$el.find('.tournament_timer,.guide_timer').each(function () {
        var el = self.$(this);
        var timeStarted = new Date(el.data('value'));
        var timeCurrent = new Date();
        var distance = timeStarted.getTime() - timeCurrent.getTime();
        if (distance <= 0)
          return;
        var hours = Math.floor(distance / (1000 * 60 * 60));
        var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
        var seconds = Math.floor(distance % (1000 * 60) / 1000);
        if (self.$(this).hasClass('tournament_timer')) {
          self.renderColorTimer(el, hours);
        }
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        el.html(hours + ':' + minutes + ':' + seconds);
      });
    },
    clearTimer: function () {
      if (this.interval !== null) {
        clearInterval(this.interval);
      }
    },
    destroyInfinite: function () {
      this.$el.find('.page-content').find('.infinite-scroll-preloader').remove();
    },
    artificalPagination() {
      let self = this;
      let newCategories = [
        ...self.categories,
        ...self.categoriesFull.slice(self.categories.length, self.categories.length + 6)
      ];
      setTimeout(() => {
        if (self.categories.length < self.categoriesFull.length) {
          self.$setState({ categories: newCategories });
          self.$('.swiper-item').each(function (index, el) {
            if (index + 1 >= self.categories.length - 5) {
              self.swipers.push(self.$app.swiper.create(el, {
                spaceBetween: 9,
                slidesPerView: 'auto',
                observer: true,
                observeParents: true
              }));
            }
          });
          self.allowInfinite = true;
        } else {
          self.allowInfinite = false;
          self.destroyInfinite();
        }
      }, 700);
    },
    getTime(call_for_before) {
      var timeStarted = new Date(call_for_before);
      var timeCurrent = new Date();
      var distance = timeStarted.getTime() - timeCurrent.getTime();
      if (distance <= 0)
        return false;
      var hours = Math.floor(distance / (1000 * 60 * 60));
      var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
      var seconds = Math.floor(distance % (1000 * 60) / 1000);
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      return hours + ':' + minutes + ':' + seconds;
    },
    load: function (success) {
      var self = this;
      let infinityScroll = self.$el.find('.infinite-scroll-preloader')[0];
      if (infinityScroll)
        infinityScroll.style.display = 'none';
      let url = 'app/tournaments';
      if (self.$root.isNosession()) {
        url = 'app/public/tournaments';
      }
      self.$root.api(url, { short: true }, 'GET', function (data, status, xhr) {
        if (!self.$root.isNosession()) {
          self.$root.updateLiveCount();
        }
        if (typeof success !== 'undefined') {
          success();
        }
        self.categoriesFull = data.categories;
        self.$setState({
          categories: self.categoriesFull.map(c => {
            c.tournaments = c.tournaments.map(el => {
              if (el.is_virtual) {
                el.title = '* ' + el.title;
              }
              return el;
            });
            c = self.$app.utils.extend({}, c);
            c.tournaments = c.tournaments.slice(0, 6);
            return c;
          }).slice(0, 6)
        });
        if (self.categories.length <= 3) {
          self.allowInfinite = false;
          self.destroyInfinite();
        }
        self.$('.swiper-item').each(function (index, el) {
          let swiper = self.$app.swiper.create(el, {
            spaceBetween: 9,
            slidesPerView: 'auto',
            observer: true,
            observeParents: true
          });
          self.swipers.push(swiper);
        });
        let infinityScroll = self.$el.find('.infinite-scroll-preloader')[0];
        if (infinityScroll)
          infinityScroll.style.display = 'none';
        try {
          self.clearTimer();
          self.updateTimer();
          self.interval = setInterval(function () {
            try {
              self.updateTimer();
            } catch (e) {
              console.log(e);
            }
          }, 1000);
        } catch (e) {
          console.log(e);
        }
      }, true);
      if (self.$root.isNosession())
        return false;
      let headerBlockController = new HeaderBlockController(self);
      headerBlockController.fetchMiniGames().then(data => {
        self.$setState({ whoWillPlay: data });
        if (!self.bannerSwiper) {
          let bannerSwiper = self.$app.swiper.create('.swiper-container-who-will-play', {
            freeMode: false,
            centeredSlides: true,
            spaceBetween: 8,
            slidesPerView: 'auto',
            observer: true,
            observeSlideChildren: true,
            observeParents: true
          });
          self.$setState({ bannerSwiper: bannerSwiper });
        }
      });
    },
    runWithNoSession() {
      console.log('runWithNoSession');
    },
    loadBanners: function () {
      var self = this;
      self.$root.api('app/banners', {}, 'GET', function (data, status, xhr) {
        self.$setState({ banners: data.banners.length ? data.banners : null });
        self.$app.swiper.create('.swiper-container-banners', {
          centeredSlides: true,
          spaceBetween: 9,
          slidesPerView: 'auto',
          observer: true,
          observeParents: true
        });
      }, true);
    },
    openPageWhoWillPlay(hash) {
      let match = this.whoWillPlay.find(el => el.hash == hash);
      let team = match.localteamHighlight ? 'localteam' : 'visitorteam';
      this.$router.navigate({
        name: 'who-will-play',
        query: {
          'matchId': match.matchId,
          'team': team
        }
      });
    },
    changeNickname(name) {
      const _this = this;
      return new Promise((resolve, reject) => {
        this.$root.api('app/profile/name', { name }, 'POST', function (data, status, xhr) {
          if (data.status === 'error') {
            reject(data);
          }
          resolve(name);
        }, true);
      });
    },
    setLoadingNicknameSheetModal(el, state) {
      const btn = el.querySelector('.submit');
      const preloader = el.querySelector('.preloader');
      const text = btn.querySelector('.text');
      const input = el.querySelector('input');
      this.$app.preloader.init(preloader);
      if (state) {
        preloader.classList.remove('hide');
        text.classList.add('hide');
      } else {
        preloader.classList.add('hide');
        text.classList.remove('hide');
      }
      setTimeout(() => {
        input.focus();
      }, 300);
    },
    initNicknameSheetModal() {
      const _this = this;
      _this.$app.methods.setCookie('setNickname', false);
      const content = `
              <div class="sheet-modal enter-nickname-sheet-modal">
                <div class="swipe-handler"></div>
                <div class="sheet-modal-inner">
                  <div class="block">
                    <div>
                      <span class="title">Ваш никнейм</span>
                      <span class="description">Ваше имя, которое будет видно другим игрокам</span>
                    </div>
                    <div>
                      <div class="input-wrapper">
                        <input type="text" value="" placeholder="Hero123">
                        <span class="input-clear-button"></span>
                      </div>
                      <span class="hint">от 4 до 20 символов</span>
                    </div>
                    <button class="submit">
                      <span class="preloader color-white hide"></span>
                      <span class="text">Готово</span>
                    </button>
                  </div>
                </div>
              </div>
            `;
      const sheet = this.$app.sheet.create({
        content,
        swipeToClose: true,
        backdrop: true,
        swipeToStep: true,
        on: {
          close: function () {
            console.log('close');
          }
        }
      });
      sheet.open();
      const el = document.querySelector('.enter-nickname-sheet-modal');
      const input = el.querySelector('input');
      const hint = el.querySelector('.hint');
      input.classList.add('input-with-value');
      input.value = _this.$root.params.profile.name;
      setTimeout(() => {
        input.focus();
      }, 300);
      const btn = el.querySelector('.submit');
      btn.addEventListener('click', () => {
        _this.setLoadingNicknameSheetModal(el, true);
        _this.changeNickname(input.value).then(name => {
          _this.$root.params.profile.name = name;
          sheet.close();
        }).catch(data => {
          input.classList.add('error');
          hint.classList.add('shake-horizontal');
          hint.classList.add('error');
          hint.innerText = data.message;
        }).finally(() => {
          _this.setLoadingNicknameSheetModal(el, false);
        });
      });
      input.addEventListener('input', () => {
        input.classList.remove('error');
        hint.classList.remove('shake-horizontal');
        hint.classList.remove('error');
        hint.innerText = 'от 4 до 20 символов';
      });
    },
    setUrlNavbarLogo() {
      switch (this.$root.getTypeApp()) {
      default:
        return './static/logo_main.svg';
      }
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      self.$setState({ urlNavbarLogo: self.setUrlNavbarLogo() });
      let infinityScroll = this.$el.find('.infinite-scroll-preloader')[0];
      if (infinityScroll)
        infinityScroll.style.display = 'none';
      this.$root.events.on('lobby.loadDuels', function () {
        self.$app.preloader.show();
        self.fetchProfile().then(() => {
          self.$app.preloader.show();
          self.loadDuels();
        });
      });
      this.$root.events.on('lobby.updateMiniGames', function () {
        let headerBlockController = new HeaderBlockController(self);
        headerBlockController.fetchMiniGames().then(data => {
          self.$setState({ whoWillPlay: data });
        });
      });
      self.$root.events.off('lobby.load');
      self.$root.events.on('lobby.load', function () {
        console.log(self);
        self.load();
      });
      self.$el.find('.page-content').on('infinite', function () {
        if (!self.allowInfinite) {
          return false;
        } else {
          self.allowInfinite = false;
          self.artificalPagination();
        }
      });
      setTimeout(() => {
        try {
          self.$('#view-lobby').on('tab:show', function () {
            if (self.$el && self.$el.find && self.$el.find('.page-content') && self.$el.find('.page-content')[0] && self.$el.find('.page-content')[0].scrollTop) {
              self.$el.find('.page-content')[0].scrollTop = 0;
            }
          });
        } catch (e) {
          console.log('ERROR', e);
        }
      }, 0);
      try {
        self.$('#tab-lobby-duels').on('tab:show', function () {
        });
      } catch (e) {
      }
      if (self.$root.isNosession()) {
        self.load();
        self.$('.toolbar').hide();
        return false;
      } else {
        self.fetchProfile().then(() => {
          self.load();
          if (self.$app.methods.getCookie('setNickname') === 'true') {
            self.$router.navigate('/profile/change-nickname-popup');
          }
        });
      }
    },
    pageAfterIn: function () {
      const self = this;
    },
    pageBeforeIn: function () {
      let self = this;
    },
    pageBeforeremove: function () {
      var self = this;
      for (var i = 0; i < self.swipers.length; i++) {
        self.swipers[i].destroy();
      }
      self.$app.swiper.destroy('.swiper-container-banners');
      this.clearTimer();
      if (this.guideTimer !== null) {
        clearTimeout(this.guideTimer);
      }
    }
  },
  id: '1294d33097',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page lobby-page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="title">\n                    <div class="lobby-logo" style="height: 20px;padding-top: 2px;"></div>\n                </div>\n                ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.$app.methods.getTypeApp() === \'origin\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                  <div class="subnavbar md subnavbar-menu" style="height: 55px;">\n                      <div class="subnavbar-inner ios">\n                          <div class="segmented">\n                              <a class="button tab-link tab-link-active" href="#tab-lobby-tournaments">Турниры</a>\n                              <a class="button tab-link" href="#tab-lobby-duels">Дуэли</a>\n                          </div>\n                      </div>\n                  </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n        </div>\n        ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.$app.methods.getTypeApp() === \'origin\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n          <div class="tabs">\n        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            <div class="tab page-content ptr-content tab-active infinite-scroll-content" @ptr:refresh="refreshTournaments" id="tab-lobby-tournaments">\n\n                <div class="ptr-preloader">\n                    <div class="preloader"></div>\n                    <div class="ptr-arrow"></div>\n                </div>\n\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers._partial.call(ctx_2, 'network-error', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.guide, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.hi, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.categories, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.categories.length, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.prize.message, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.guide, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                <div class="tour-banner" ket="tour-banner">\n                    <div class="tour-progress">\n                        <div class="tour-progress-1" style=""></div>\n                        <div class=\'tour-progress-2-3-4 ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.guide.progress == 50 || this.guide.progress == 75', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'tour-progress-complete';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\'></div>\n                        <div class=\'tour-progress-2-3-4 ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.guide.progress == 75', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'tour-progress-complete';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\'></div>\n                        <div class=\'tour-progress-2-3-4\'></div>\n                        <i class="tour-progress-dollar"></i>\n                    </div>\n                    <div class="tour-info">\n                        <div>\n                            <div class="tour-info-left" style="">\n                                ';
              r += c(ctx_3.guide.progress, ctx_3);
              r += '% Завершено\n                            </div>\n                            <div class="tour-info-right">\n                                <i class="tour-clock"></i> <span class="guide_timer" data-value="';
              r += c(ctx_3.guide.offer_ends_at, ctx_3);
              r += '"></span>\n                            </div>\n                        </div>\n                        <div>\n                            <i class="tour-bonus"></i>\n                        </div>\n                    </div>\n                    <div class="tour-button-block">\n                        <button class="button button-fill" @click="goToGuide">Получить бонус</button>\n                    </div>\n                </div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.hi, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                <div class="block block-strong">\n                    <div class="float-left">\n                        <img src="';
              r += c(ctx_3.hi.avatar, ctx_3);
              r += '">\n                    </div>\n                    <div class="block-top">\n                        <div class="block-top-title">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.hi.title, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' <a href="/team/create/">popup</a></div>\n                        <div class="block-top-text">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.hi.text, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' <a href="/auth/" class="link">Узнать больше</a></div>\n                    </div>\n                </div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n                <!-- Header block (блок содержит мини игры "Кто выйдет на поле" и баннеры) -->\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.$app.methods.getTypeApp() === \'origin\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div class="header-block">\n                    ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.whoWillPlay.length > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                      <div class="who-will-play-block">\n                          <div class="swiper-container swiper-container-who-will-play fade-in" key="swiper-container-who-will-play">\n                            <div class="swiper-wrapper">\n                              ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.whoWillPlay, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                <div class="swiper-slide">\n                                  <div class="slide-main">\n                                    <div class="header">\n                                      <div class="datetime">\n                                        <i class="icon icon-clock-svg"></i>\n                                        <span>';
                      r += c(ctx_5.startedAt, ctx_5);
                      r += '</span>\n                                      </div>\n                                      <div class="priz">\n                                        <i class="fas fa-star"></i>\n                                        <span>';
                      r += c(ctx_5.prize, ctx_5);
                      r += '</span>\n                                      </div>\n                                    </div>\n                                    <div class="body">\n                                      <ul>\n                                        <li class="';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.localteamHighlight == true', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'selected';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '">\n                                          <i class="icon icon-ellipse-svg"></i>\n                                          <span>';
                      r += c(ctx_5.localteamName, ctx_5);
                      r += '</span>\n                                        </li>\n                                        <li class="';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.visitorteamHighlight == true', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'selected';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '">\n                                          <i class="icon icon-ellipse-svg"></i>\n                                          <span>';
                      r += c(ctx_5.visitorteamName, ctx_5);
                      r += '</span>\n                                        </li>\n                                      </ul>\n                                    </div>\n                                    <div class="league-name">';
                      r += c(ctx_5.leagueName, ctx_5);
                      r += '</div>\n                                    <div class="footer">\n                                      <div class="button" @click="openPageWhoWillPlay(';
                      r += c(ctx_5.hash, ctx_5);
                      r += ')">\n                                        <span>Угадай состав</span>\n                                      </div>\n                                    </div>\n                                  </div>\n                                </div>\n                              ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                            </div>\n                          </div>\n                      </div>\n                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                  </div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n\n                <!-- ТУРНИРЫ -->\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.categories, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.categories.length, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.categories, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                <div class="block block-strong">\n                    <div class="row" id="block-swiper-topbar">\n                        <div class="col-70">\n                            <div class="block-recommend-title">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.title, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</div>\n                            <div class="block-recommend-text">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.description, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</div>\n                        </div>\n                        <div class="col-30 text-align-right">\n                            <a href="#" @click="openAll(';
                      r += c(data_5 && data_5.index, ctx_5);
                      r += ')" class="link">См. все</a>\n                        </div>\n                    </div>\n                    <div class="swiper-container swiper-item">\n                        <div class="swiper-wrapper">\n                            ';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.tournaments, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                            <div class="swiper-slide">\n                                <div class="slide-main">\n                                    <div class="display-flex justify-content-space-between">\n                                        <span class="badge slide-tag">Осталось <span class="tournament_timer" data-value="';
                          r += c(ctx_6.call_for_before, ctx_6);
                          r += '"></span></span>\n                                        <a href="/tournament-popup/info/';
                          r += c(ctx_6.id, ctx_6);
                          r += '/" class="link icon-only slide-info"><i class="f7-icons">info_circle</i></a>\n                                    </div>\n                                    <a href="/tournament-popup/info/';
                          r += c(ctx_6.id, ctx_6);
                          r += '/" class="slide-title tour-2">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.title, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</a>\n\n                                    <div class="slide-progress">\n\n                                        <div class="slide-progress-text">\n\n                                            ';
                          r += Template7Helpers.with.call(ctx_6, ctx_6.participants, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.min == 0 && this.max != 0', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                            ';
                                  r += Template7Helpers.js_if.call(ctx_8, 'this.current < this.max', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                            <b>';
                                      r += c(ctx_9.current, ctx_9);
                                      r += '</b> из ';
                                      r += c(ctx_9.max, ctx_9);
                                      r += ' max\n                                            ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n\n                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.min != 0 && this.max != 0', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                            ';
                                  r += Template7Helpers.js_if.call(ctx_8, 'this.current < this.min', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                            <b>';
                                      r += c(ctx_9.current, ctx_9);
                                      r += '</b> из ';
                                      r += c(ctx_9.min, ctx_9);
                                      r += ' min\n                                            ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                            ';
                                  r += Template7Helpers.js_if.call(ctx_8, 'this.current >= this.min && this.current < this.max', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                            <b>';
                                      r += c(ctx_9.current, ctx_9);
                                      r += '</b> из ';
                                      r += c(ctx_9.max, ctx_9);
                                      r += ' max\n                                            ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.min != 0 && this.max == 0', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                            ';
                                  r += Template7Helpers.js_if.call(ctx_8, 'this.current < this.min', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                            <b>';
                                      r += c(ctx_9.current, ctx_9);
                                      r += '</b> из ';
                                      r += c(ctx_9.min, ctx_9);
                                      r += ' min\n                                            ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                            ';
                                  r += Template7Helpers.js_if.call(ctx_8, 'this.current >= this.min', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                            <div class="slide-progress-complete-text">Розыгрыш состоится!</div>\n                                            ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                            ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n\n                                        </div>\n\n                                        <div class="slide-progress-prize">\n                                            <div class="slide-prize-value">\n                                                ';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.prize.message, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                TBD\n                                                ';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                ';
                              r += Template7Helpers.formatPrice.call(ctx_7, ctx_7.prize.value, {
                                hash: {},
                                data: data_7 || {},
                                fn: function empty() {
                                  return '';
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += c(ctx_7.prize.currency, ctx_7);
                              r += '\n                                                ';
                              return r;
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                            </div>\n                                        </div>\n                                    </div>\n\n                                    <div class="slide-progress">\n\n                                        <div class="slide-progress-text">\n\n                                            <div class="slide-progress-graph display-flex align-items-center">\n                                                <span>';
                          r += c(ctx_6.participants.percent, ctx_6);
                          r += '%</span>\n                                                <div class="progressbar" data-progress="';
                          r += c(ctx_6.participants.percent, ctx_6);
                          r += '">\n                                                    <span></span>\n                                                </div>\n                                                ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.participants.percent==\'100\'', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                <i class="icon-progress-complete"></i>\n                                                ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                            </div>\n\n                                        </div>\n\n                                        <div class="slide-progress-prize">\n                                            <div class="slide-prize-text">ПРИЗ. ФОНД</div>\n                                        </div>\n                                    </div>\n\n                                </div>\n\n                                <div class="slide-go">\n                                    <a href="#" @click="openTournament(';
                          r += c(ctx_6.id, ctx_6);
                          r += ')" class="link green">Войти ';
                          r += Template7Helpers.formatPrice.call(ctx_6, ctx_6.ingress.value, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += c(ctx_6.ingress.currency, ctx_6);
                          r += '</a>\n                                </div>\n                            </div>\n                            ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                        </div>\n                    </div>\n                </div>\n                ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                <div class="lobby-empty">\n                    <div class="lobby-empty-image"><span class="image-no-lobby"></span></div>\n                    <div class="lobby-empty-top">Турниры скоро появятся</div>\n                    <div class="lobby-empty-bottom">Интересных матчей пока нет</div>\n                </div>\n                ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                <div class="block block-strong text-align-center" key="pr-tournaments">\n                    <div class="preloader"></div>\n                </div>\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n                <div class="preloader infinite-scroll-preloader"></div>\n            </div>\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.$app.methods.getTypeApp() === \'origin\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n              <div class="tab duels-ptr page-content ptr-content" @ptr:refresh="refreshDuels" id="tab-lobby-duels" style="padding-top: 100px;" @tab:show="showTabDuels">\n                  <div class="ptr-preloader">\n                      <div class="preloader"></div>\n                      <div class="ptr-arrow"></div>\n                  </div>\n                  <!-- Кнопка "Создать дуэль" -->\n                  <div class="mx-6 my-4">\n                    <button class="button success" @click="toCreateDuel">Создать дуэль</button>\n                  </div>\n\n                  <!-- Список дуэлей -->\n                  ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.duelsData, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="list-duels">\n\n                      ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.duelsData, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n\n                        <div class="duel" super-duel-id="';
                  r += c(ctx_4.id, ctx_4);
                  r += '">\n\n                          <!-- Информация об игроках в дуэли -->\n                          <div class="players-box" @click="openDuel(';
                  r += c(ctx_4.id, ctx_4);
                  r += ')">\n\n                            <div class="duel-player-box">\n                              <div class="body">\n                                <div class="player-with-data">\n                                  ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.player_1, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                    <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                  ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                </div>\n                                <div class="description">\n                                  ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.player_1, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                    <!-- <a href="/player/info?player_id=';
                      r += c(ctx_5.id, ctx_5);
                      r += '" class="name">\n                                      <span>';
                      r += c(ctx_5.h_firstname, ctx_5);
                      r += '</span>\n                                      <span>';
                      r += c(ctx_5.h_lastname, ctx_5);
                      r += '</span>\n                                    </a> -->\n                                    <a href="#" class="name">\n                                      <span>';
                      r += c(ctx_5.h_firstname, ctx_5);
                      r += '</span>\n                                      <span>';
                      r += c(ctx_5.h_lastname, ctx_5);
                      r += '</span>\n                                    </a>\n                                    </span>\n                                  ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                  ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.match_1, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                    <span class="match">\n                                      ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.myTeam == \'localteam\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span class="bold">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.localteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span>';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.localteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' @ ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.myTeam == \'visitorteam\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span class="bold">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.visitorteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span>';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.visitorteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>\n                                      ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                    </span>\n                                    <span class="short-date">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.shortDate, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                  ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                </div>\n                              </div>\n                            </div>\n\n\n                            <div class="duel-player-box">\n                              <div class="body">\n                                <div class="description">\n                                  ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.player_2, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                    <!-- <a href="/player/info?player_id=';
                      r += c(ctx_5.id, ctx_5);
                      r += '" class="name">\n                                      <span>';
                      r += c(ctx_5.h_firstname, ctx_5);
                      r += '</span>\n                                      <span>';
                      r += c(ctx_5.h_lastname, ctx_5);
                      r += '</span>\n                                    </a> -->\n                                    <a href="#" class="name">\n                                      <span>';
                      r += c(ctx_5.h_firstname, ctx_5);
                      r += '</span>\n                                      <span>';
                      r += c(ctx_5.h_lastname, ctx_5);
                      r += '</span>\n                                    </a>\n                                  ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                  ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.match_2, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                    <span class="match">\n                                      ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.myTeam == \'localteam\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span class="bold">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.localteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span>';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.localteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' @ ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.myTeam == \'visitorteam\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span class="bold">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.visitorteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '<span>';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.visitorteam, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>\n                                      ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                    </span>\n                                    <span class="short-date">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.shortDate, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                  ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                </div>\n                                <div class="player-with-data">\n                                  ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.player_2, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                    <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                  ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                </div>\n                              </div>\n                            </div>\n                          </div>\n\n                          <div class="duel-offers list">\n                            <ul>\n                              <li class="accordion-item">\n                                <a class="item-content item-link" href="#">\n                                  <div class="item-inner">\n                                    <div class="item-title">Предложения (';
                  r += c(ctx_4.clients_duels.length, ctx_4);
                  r += ')</div>\n                                  </div>\n                                </a>\n                                <div class="accordion-item-content">\n                                  <div class="block">\n                                    <ul>\n\n                                      <!-- Перечисление игроков вступивших в дуэль -->\n                                      ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.clients_duels.length, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        ';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.clients_duels, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                          <li class="exists-duel">\n                                            <div class="players-box">\n                                              <div class="duel-player-box">\n                                                ';
                          r += Template7Helpers.js_if.call(ctx_6, '(this.self.order == 1 || this.partner.order == 1)', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                  ';
                              r += Template7Helpers.with.call(ctx_7, ctx_7.self, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                    ';
                                  r += Template7Helpers.js_if.call(ctx_8, 'this.order == 1', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                                      <div class="client left">\n                                                        ';
                                      r += Template7Helpers.with.call(ctx_9, ctx_9.client, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                                          <img class="avatar" src="';
                                          r += c(ctx_10.avatar, ctx_10);
                                          r += '" alt="">\n                                                        ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                                        <div class="box">\n                                                          ';
                                      r += Template7Helpers.with.call(ctx_9, ctx_9.client, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                                            <span class="name">';
                                          r += c(ctx_10.name, ctx_10);
                                          r += '</span>\n                                                          ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                                          <span class="amount">\n                                                            ';
                                      r += Template7Helpers.with.call(ctx_9, ctx_9.amount, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += c(ctx_10.value, ctx_10);
                                          r += c(ctx_10.currency, ctx_10);
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                                          </span>\n                                                        </div>\n                                                      </div>\n                                                    ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                                  ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                ';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                <div class="join-block">\n                                                  ';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.self.me, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                    <span class="disable">Выбрать</span>\n                                                  ';
                                  return r;
                                },
                                inverse: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                    <span class="" @click="joinToExistingDuel(\'';
                                  r += c(ctx_8.id, ctx_8);
                                  r += '\', 1)">Выбрать</span>\n                                                  ';
                                  return r;
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                </div>\n                                                ';
                              return r;
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                              </div>\n                                              <div class="duel-player-box">\n                                                ';
                          r += Template7Helpers.js_if.call(ctx_6, '(this.self.order == 2 || this.partner.order == 2)', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                  ';
                              r += Template7Helpers.with.call(ctx_7, ctx_7.self, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                    ';
                                  r += Template7Helpers.js_if.call(ctx_8, 'this.order == 2', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                                      <div class="client right">\n                                                        <div class="box">\n                                                          ';
                                      r += Template7Helpers.with.call(ctx_9, ctx_9.client, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                                            <span class="name">';
                                          r += c(ctx_10.name, ctx_10);
                                          r += '</span>\n                                                          ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                                          <span class="amount">\n                                                            ';
                                      r += Template7Helpers.with.call(ctx_9, ctx_9.amount, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += c(ctx_10.value, ctx_10);
                                          r += c(ctx_10.currency, ctx_10);
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                                          </span>\n                                                        </div>\n                                                        ';
                                      r += Template7Helpers.with.call(ctx_9, ctx_9.client, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                                          <img class="avatar" src="';
                                          r += c(ctx_10.avatar, ctx_10);
                                          r += '" alt="">\n                                                        ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                                      </div>\n                                                    ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                                  ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                ';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                <div class="join-block">\n                                                  ';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.self.me, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                    <span class="disable">Выбрать</span>\n                                                  ';
                                  return r;
                                },
                                inverse: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                    <span class="" @click="joinToExistingDuel(\'';
                                  r += c(ctx_8.id, ctx_8);
                                  r += '\', 2)">Выбрать</span>\n                                                  ';
                                  return r;
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                </div>\n                                                ';
                              return r;
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                              </div>\n                                            </div>\n                                          </li>\n                                        ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                      ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\n                                      <li class="new-duel-offer">\n                                        <div class="duel-player-box duel-player-box__first">\n                                          <span @click="joinToExistingDuel(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\', 1)">Создать</span>\n                                        </div>\n                                        <div class="duel-player-box duel-player-box__second">\n                                          <span @click="joinToExistingDuel(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\', 2)">Создать</span>\n                                        </div>\n                                      </li>\n                                    </ul>\n                                  </div>\n                                </div>\n                              </li>\n                            </ul>\n                          </div>\n\n                        </div>\n\n                      ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n                    </div>\n                  ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="lobby-empty">\n                      <div class="lobby-empty-image"><span class="image-no-lobby"></span></div>\n                      <div class="lobby-empty-top">Дуэли скоро появятся</div>\n                      <div class="lobby-empty-bottom">Интересных дуэлей пока нет</div>\n                    </div>\n                  ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              </div>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.$app.methods.getTypeApp() === \'origin\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n          </div>\n        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n        <!-- Модальное окно с суммой дуэли -->\n      ';
      r += Template7Helpers.with.call(ctx_1, ctx_1.sheetInterDuelData, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n        <div class="sheet-modal sheet-inter-duel ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pair !== null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'large';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">\n          <div class="sheet-modal-inner">\n            <div class="page-content">\n              <div class="block">\n                <div class="header">\n                  <span class="player-name">';
          r += Template7Helpers.with.call(ctx_2, ctx_2.player, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.h_name, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span>\n                  ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pair !== null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <span class="player-description">ваш соперник выбрал противоположного игрока</span>\n                  ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <span class="player-description">ваш соперник выберет противоположного игрока</span>\n                  ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                </div>\n                <div class="selected">\n                  ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pair !== null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div class="context pair">\n                    <div class="amount">\n                      <span class="total">';
              r += Template7Helpers.with.call(ctx_3, ctx_3.data, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.amount, ctx_4);
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>\n                      <span class="hint">Сумма взноса</span>\n                    </div>\n                  </div>\n                  <span class="priz">Возможный выигрыш ';
              r += Template7Helpers.with.call(ctx_3, ctx_3.data, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.priz, ctx_4);
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '<i class="fas fa-star"></i></span>\n                  ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="context paid">\n                      <div class="minus" @click="minus">\n                        <i class="icon icon-minus-svg"></i>\n                      </div>\n                      <div class="amount">\n                        ';
              r += Template7Helpers.with.call(ctx_3, ctx_3.data, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                          <input type="number" class="total" name="amount" pattern="[0-9]*" value="';
                  r += c(ctx_4.amount, ctx_4);
                  r += '" @change="updateTotal">\n                        ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        <span class="hint">Сумма взноса</span>\n                      </div>\n                      <div class="plus" @click="plus">\n                        <i class="icon icon-plus-svg"></i>\n                      </div>\n                    </div>\n                  ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                </div>\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pair == null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div class="details">\n                    <div class="priz">\n                      <span class="count">';
              r += Template7Helpers.with.call(ctx_3, ctx_3.data, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.priz, ctx_4);
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += Template7Helpers.with.call(ctx_3, ctx_3.duel, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += c(ctx_4.currency, ctx_4);
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>\n                      <span class="text">Возможный выигрыш</span>\n                    </div>\n                  </div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              </div>\n              <div class="footer">\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pair !== null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <button class="button success" @click="joinThePairInDuel">Присоединиться к дуэли</button>\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <button class="button success" @click="createPairInDuel">Создать дуэль</button>\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              </div>\n            </div>\n          </div>\n        </div>\n      ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n    </div>\n\n';
      return r;
    }(this);
  },
  style: `
[data-f7-1294d33097] .list-duels {
    margin-bottom: 16px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box {
    background: #FFFFFF;
    display: flex;
  }
[data-f7-1294d33097] .list-duels .duel + .duel {
    margin-top: 16px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box {
    display: flex;
    flex-direction: column;
    width: 50%;

  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .visitorteam {
    color: #818E95;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .localteam {
    color: #818E95;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box span {
    color: #007AFF;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box span.disable {
    opacity: 0.3;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box span:active:not(.disable) {
    opacity: 0.6;
  }
[data-f7-1294d33097] .list-duels .duel .players-box {
    border-bottom: 1px solid #CFD6DB;
  }
  /* duel-offers */
[data-f7-1294d33097] .list-duels .duel-offers {
    margin-top: 0px;
    margin-bottom: 0px;
  }
[data-f7-1294d33097] .list-duels .duel-offers ul::before {
    display: none;
  }
[data-f7-1294d33097] .list-duels .duel-offers ul:after {
    background-color:#CFD6DB;
    height: 2px;
  }
[data-f7-1294d33097] .list-duels .item-link {
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #818E95;
    height: 49px;
    /* border-bottom: 1px solid #CFD6DB; */
  }
[data-f7-1294d33097] .list-duels .item-link:after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #CFD6DB;
    top: 0;
    left: 49.9%;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box + .duel-player-box {
    border-left: 1px solid #CFD6DB;
    border-width: thin;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(1) .body {
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    margin: 0px 6px 0px 12px;
    padding: 12px 0px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(2) .body {
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    margin: 0px 12px 0px 6px;
    padding: 12px 0px;
  }
[data-f7-1294d33097] .list-duels .new-duel-offer {
    display: flex;
    height: 49px;
  }
[data-f7-1294d33097] .list-duels .new-duel-offer span {
    color: #007AFF;
  }
[data-f7-1294d33097] .list-duels .new-duel-offer span:active {
    opacity: 0.7;
  }
[data-f7-1294d33097] .list-duels .new-duel-offer .duel-player-box {
   /*  border-top: 1px solid #CFD6DB;
    border-width: thin; */
  }
[data-f7-1294d33097] .list-duels .new-duel-offer .duel-player-box + .duel-player-box {
    border-left: 1px solid #CFD6DB;
    border-width: thin;
  }
[data-f7-1294d33097] .list-duels .new-duel-offer .duel-player-box__first {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
[data-f7-1294d33097] .list-duels .new-duel-offer .duel-player-box__second {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
[data-f7-1294d33097] /*   .list-duels .new-duel-offer {
    border-top: 1px solid #CFD6DB;
  } */
[data-f7-1294d33097] .list-duels .exists-duel + .new-duel-offer {
    border-top: none;
  }

  /* Строка дуэли в списке */
[data-f7-1294d33097] /* .list-duels .duel-offer {
    display: flex;
    height: 49px;
  } */
[data-f7-1294d33097] .list-duels .duel-player-box {

  }
[data-f7-1294d33097] .list-duels .duel-player-boxs .duel-player-box + .duel-player-box {
    border-left: 1px solid #CFD6DB;
    border-width: thin;
  }
[data-f7-1294d33097] .list-duels .players-box .duel-player-box__first {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
[data-f7-1294d33097] .list-duels .players-box .duel-player-box__second {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }


[data-f7-1294d33097] /*   .list-duels .duel-offer {
    display: flex;
    height: 49px;
    background-color: lightblue;
    border-top: 1px solid #CFD6DB;
    border-width: thin;
  } */
[data-f7-1294d33097] .list-duels .duel-player-box {
   /*  border-top: 1px solid #CFD6DB;
    border-width: thin; */
  }
[data-f7-1294d33097] .list-duels .duel-player-box__first {
    flex: 1;
  }
[data-f7-1294d33097] .list-duels .duel-player-box__second {
    flex: 1;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(1) .body {
    justify-content: flex-start;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(2) .body {
    justify-content: flex-end;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    height: 48px;
    border-width: thin;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .join-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-width: thin;
    padding: 0 auto;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .join-block i {
    width: 24px;
    height: 24px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client.left {
    justify-content: flex-start;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client.right {
    justify-content: flex-end;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client .box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client.right .box {
    align-items: flex-end;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client.left .box {
    align-items: flex-start;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client.right .box + img {
    margin-left: 8px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client.left img + .box {
    margin-left: 8px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client .fa-star {
    font-size: calc(1em - 2px);
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client .box .name {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.35px;
    color: #007AFF;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client .box .name + .amount {
    margin-top: 4px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client .box .amount {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.08px;
    color: #445058;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .client img {
    height: 36px;
    object-fit: cover;
    border-radius: 50px;
  }
  @media (max-width: 375px) {
[data-f7-1294d33097] /* .list-duels .duel .players-box .duel-player-box:nth-child(1) .body {
      margin: 0px 8px 0px 12px;
    }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(2) .body {
      margin: 0px 12px 0px 8px;
    } */
  }
  @media (max-width: 320px) {
[data-f7-1294d33097] /*  .list-duels .duel .players-box .duel-player-box:nth-child(1) .body {
      margin: 0px 6px 0px 12px;
    }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(2) .body {
      margin: 0px 12px 0px 6px;
    } */
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .player {
    width: 50px;
    height: 50px;
    border: 1px dashed #CFD6DB;
    background-color: #F0F3F8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .player i {
    color: #CFD6DB;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .player + .description {
    margin-left: 12px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description + .player {
    margin-left: 12px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description {
    display: flex;
    flex-direction: column;
    flex: 1;
   /*  white-space: nowrap;
    overflow: hidden; */
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .add-player-text {
    color: #007AFF;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .name {
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.35px;
    white-space: normal;
    color: #007AFF;
    height: 30px;
    display: flex;
    flex-direction: column;
/*     overflow: hidden;
    text-overflow: ellipsis; */
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .match {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.35px;
    color: #818E95;
    margin-top: 2px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .match .bold {
    font-weight: 600;
    color: #445058;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .match span {
    color: #818E95;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .short-date {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.35px;
    color: #818E95;
    margin-top: 2px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(1) .body .description {
    text-align: left;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(2) .body .description {
    text-align: right;
  }
[data-f7-1294d33097] .duel-tabs .players-box .duel-player-box:nth-child(1) .body {
    border-top-left-radius: 9px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(2) .body {
    border-top-right-radius: 9px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body + .footer {
    border-top: 1px solid #CFD6DB;
    border-width: thin;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .footer {
    background-color: #F5F8FC;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(1) .footer {
    border-bottom-left-radius: 9px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box:nth-child(2) .footer {
    border-bottom-right-radius: 9px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .footer span.disabled {
    font-size: 15px;
    line-height: 15px;
    letter-spacing: -0.24px;
    color: #CFD6DB;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .player-with-data {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: #F0F3F8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .player-with-data img {
    width: inherit;
    object-fit: cover;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .player-with-data + .description {
    margin-left: 8px;
  }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description + .player-with-data {
    margin-left: 8px;
  }

[data-f7-1294d33097] .sheet-modal-bottom:before, .sheet-modal:not(.sheet-modal-top):before{
      background: unset !important;
  }
[data-f7-1294d33097] .duel-left .duel-player{
      display: flex;
      padding:15px;
      background-color: white;
      border: 1px solid #CFD6DB;
      border-left: unset;
  }
[data-f7-1294d33097] .duel-right .duel-player{
      display: flex;
      justify-content: flex-end;
      padding: 15px;
      background-color: white;
      border: 1px solid #CFD6DB;
      border-left: unset;
  }
[data-f7-1294d33097] .duel-left .duel-player-avatar{
      margin-right: 8px;
  }
[data-f7-1294d33097] .duel-right .duel-player-avatar{
      margin-left: 8px;
  }
[data-f7-1294d33097] .duel-player-avatar img{
      width: 50px;
      height: 50px;
      border-radius: 5px;
  }
[data-f7-1294d33097] .duel-player-info{
      min-width: 0;
  }
[data-f7-1294d33097] .duel-player-name{
      margin-bottom: 8px;
      color: #007AFF;
      font-size: 15px;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 1.3em;
      white-space: nowrap;
  }
[data-f7-1294d33097] .duel-player-team{
      color: #445058;
      font-size: 10px;
  }
[data-f7-1294d33097] .duel-player-time{
      color: #818E95;
      font-size: 8px;
  }
[data-f7-1294d33097] .duel-left .duel-action{
      background-color: #F5F8FC;
      padding: 10px;
      text-align:center;
      border: 1px solid #CFD6DB;
      border-top: unset;
      border-left: unset;
  }
[data-f7-1294d33097] .duel-right .duel-action{
      background-color: #F5F8FC;
      padding: 10px;
      text-align: center;
      border: 1px solid #CFD6DB;
      border-top: unset;
      border-left: unset;
  }
[data-f7-1294d33097] .duel-action a {
      color:#818E95;
      font-size: 15px;
  }
[data-f7-1294d33097] .block {
      padding-left: 0;
      padding-right: 0;
  }
  @media (max-width: 320px) {
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body {
      margin: 0px 8px;
      padding: 8px 0px;
    }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .player + .description {
      margin-left: 8px;
    }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description + .player {
      margin-left: 8px;
    }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .name {
      font-size: 11px;
      line-height: 15px;
    }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .match {
      font-size: 9px;
      line-height: 13px;
    }
[data-f7-1294d33097] .list-duels .duel .players-box .duel-player-box .body .description .short-date {
      font-size: 9px;
      line-height: 13px;
    }
  }
`,
  styleScoped: true
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      stepper: null,
      commission: 0,
      balances: {
        olimp: {
          value: 0,
          currency: ''
        },
        fanbattle: {
          value: 0,
          currency: ''
        }
      }
    };
  },
  methods: {
    calculateRate() {
      let full = this.stepper.value * this.$root.params.profile.currency.rate;
      let commisions = full * this.$root.params.profile.params.fb_to_olimp_commission_percent / 100;
      this.$setState({ rate: parseInt(full - commisions) });
    },
    calculateCommission() {
      if (this.$root.params.profile.params.fb_to_olimp_commission_percent === null)
        return false;
      const c = this.$root.params.profile.params.fb_to_olimp_commission_percent;
      this.commission = this.stepper.value * c / 100;
      this.$setState({ commission: this.commission });
    },
    updateNavbarAfterHeight: function (value) {
      setTimeout(() => {
        document.documentElement.style.setProperty('--navbar-after-height', value);
      }, 100);
    },
    focusStepperValue() {
      if (this.stepper.value != 0) {
        this.stepper.$valueEl[0].value = this.stepper.value;
      } else {
        this.stepper.$valueEl[0].value = '';
      }
      this.calculateCommission();
      this.calculateRate();
    },
    blurStepperValue() {
      if (this.stepper.value < 5)
        this.stepper.value = 5;
      this.stepper.$valueEl[0].value = this.stepper.value + ` ${ this.$root.params.profile.balance.coins.currency }`;
      this.calculateCommission();
      this.calculateRate();
    },
    changeStepperValue(e) {
      this.stepper.setValue(e.target.value);
      this.calculateCommission();
      this.calculateRate();
    },
    inputStepperValue(e) {
      if (e.target.value == 0) {
        this.stepper.$valueEl[0].value = '';
      }
    },
    minus(e) {
      let r = this.stepper.value - 1;
      if (r <= 5) {
        e.target.closest('.c-stepper-button-minus').classList.add('disabled');
        this.stepper.$valueEl[0].value = 5 + ` ${ this.$root.params.profile.balance.coins.currency }`;
        this.stepper.setValue(5);
      } else {
        this.stepper.$valueEl[0].value = r + ` ${ this.$root.params.profile.balance.coins.currency }`;
        this.stepper.setValue(r);
      }
      this.calculateCommission();
      this.calculateRate();
    },
    plus(e) {
      document.querySelector('.c-stepper-button-minus').classList.remove('disabled');
      let r = this.stepper.value + 1;
      this.stepper.$valueEl[0].value = r + ` ${ this.$root.params.profile.balance.coins.currency }`;
      this.stepper.setValue(r);
      this.calculateCommission();
      this.calculateRate();
    },
    submit: function () {
      const _this = this;
      if (this.stepper.value == 0) {
        _this.$app.dialog.alert('Сумма не может быть равна 0');
        return false;
      }
      const url = `app/balance/olimp/fanbattle-olimp`;
      _this.$app.preloader.show();
      _this.$root.api(url, { amount: _this.stepper.value }, 'POST', function (data, status, xhr) {
        _this.$app.preloader.hide();
        if (data.status == 'error') {
          _this.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          _this.$app.dialog.alert('Ваш баланс пополнен', 'Успешно', () => {
            window.APP_FANBATTLE.data.events.emit('profile.update');
            _this.$router.back();
          });
        }
      }, true);
    },
    createSteper() {
      const _this = this;
      this.stepper = this.$f7.stepper.create({
        el: '#stepper',
        valueEl: '#stepper-value',
        min: 0,
        max: 9999999,
        step: 1,
        value: 5,
        formatValue: function (value) {
          if (_this.stepper && _this.stepper.$valueEl[0].classList.contains('input-focused')) {
            return value;
          }
          return value + ` ${ _this.$root.params.profile.balance.coins.currency }`;
        }
      });
      this.stepper.$valueEl[0].value = 5 + ` ${ this.$root.params.profile.balance.coins.currency }`;
      this.calculateRate();
    },
    initBalances() {
      this.$setState({
        balances: {
          olimp: {
            value: this.$root.params.profile.balance.olimp.value,
            currency: this.$root.params.profile.balance.olimp.currency
          },
          fanbattle: {
            value: this.$root.params.profile.balance.coins.value,
            currency: this.$root.params.profile.balance.coins.currency
          }
        }
      });
    }
  },
  on: {
    pageInit: function () {
      this.createSteper();
      this.initBalances();
      this.calculateCommission();
    },
    pageBeforeOut: function () {
      this.updateNavbarAfterHeight('0px');
    },
    pageBeforeIn: function () {
      this.updateNavbarAfterHeight('1px');
    }
  },
  id: '994068dfda',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n      <div class="navbar">\n          <div class="navbar-inner">\n              <div class="left">\n                  <a href="#" class="link back icon-only">\n                      <i class="icon icon-back"></i>\n                  </a>\n              </div>\n              <div class="title">Снять с баланса</div>\n          </div>\n      </div>\n\n      <div class="page-content increase-balance">\n        <div class="block no-margin-vertical">\n          <div class="balances__wrapper">\n            <div class="balance-row">\n              <div class="app-icon fanbattle"></div>\n              <div class="app-name">Fanbattle</div>\n              ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.balances, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.balances, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div class="app-balance">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.fanbattle.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.fanbattle.currency, ctx_3);
              r += '</div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n            <div class="separator">\n              <hr>\n              <div class="icon__wrapper">\n                <i class="icon-arrow-down"></i>\n              </div>\n            </div>\n            <div class="balance-row">\n              <div class="app-icon olimp"></div>\n              <div class="app-name">Olimp</div>\n              ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.balances, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.balances, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div class="app-balance">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.olimp.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.olimp.currency, ctx_3);
              r += '</div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n          </div>\n          <div class="input__wrapper">\n            <div class="stepper stepper-large stepper-fill stepper-init" id="stepper">\n              <div class="c-stepper-button-minus disabled" @click="minus">\n                <i class="icon"></i>\n              </div>\n              <div class="stepper-input-wrap">\n                <input\n                  id="stepper-value"\n                  type="tel"\n                  pattern="[0-9]+"\n                  value="100"\n                  min=""\n                  max="9999999"\n                  @focus="focusStepperValue"\n                  @blur="blurStepperValue"\n                  @change="changeStepperValue"\n                  @input="inputStepperValue"\n                />\n                <span>СУММА</span>\n              </div>\n              <div class="c-stepper-button-plus" @click="plus">\n                <i class="icon"></i>\n              </div>\n            </div>\n<!--            <span class="hint">Нажмите на сумму, чтобы ввести вручную</span>-->\n            <div class="commission-hint__wrapper">\n              <span class="commission-hint">На баланс Олимпа будет зачислено</span>\n              <span class="commission">';
      r += c(ctx_1.rate, ctx_1);
      r += ' ';
      r += c(ctx_1.balances.olimp.currency, ctx_1);
      r += '</span>\n            </div>\n          </div>\n          <div class="action_wrapper">\n            <button @click="submit">Снять</button>\n          </div>\n          ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.$root.params.profile.params.fb_to_olimp_commission_percent !== null', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <div class="commission-hint__wrapper">\n              <span class="commission-hint">Комиссия сервиса ';
          r += c(ctx_2.$root.params.profile.params.fb_to_olimp_commission_percent, ctx_2);
          r += '%</span>\n              <span class="commission">';
          r += c(ctx_2.commission, ctx_2);
          r += c(ctx_2.$root.params.profile.balance.coins.currency, ctx_2);
          r += '</span>\n            </div>\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n      </div>\n  </div>\n';
      return r;
    }(this);
  },
  style: `
.increase-balance {

}
.increase-balance .block {
  padding-top: 22.5px;
}
.increase-balance .balances__wrapper {
  height: auto;
  border-radius: 12px;
  position: relative;
}

.increase-balance .balances__wrapper .balance-row {
  height: 32px;
  background-color: #FFFFFF;
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  justify-content: space-between;
}
.increase-balance .balances__wrapper .balance-row .app-name {
  flex: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #445058;
  margin-left: 14px;
}
.increase-balance .balances__wrapper .balance-row .app-balance, .sum {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #445058;
}
.increase-balance .balances__wrapper .balance-row:nth-child(1) {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.increase-balance .balances__wrapper .balance-row .app-icon {
  width: 32px;
  height: 32px;
  display: block;
}
.increase-balance .balances__wrapper .balance-row .app-icon.olimp {
  background: url("/static/icons/olimp-bet.svg") no-repeat;
}
.increase-balance .balances__wrapper .balance-row .app-icon.fanbattle {
  background: url("/static/icons/fanbattle.svg") no-repeat;
}
.increase-balance .balances__wrapper hr {
  margin: 0px;
  height: 1px;
  border: none;
  background:  #CFD6DB;
}
.increase-balance .balances__wrapper .icon__wrapper {
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  position: absolute;
  background: #FFF;
  padding: 0px 4px;
}
.increase-balance .balances__wrapper .icon-arrow-down {
  background: url("/static/icons/arrow-down.svg") no-repeat;
  width: 24px;
  height: 24px;
  display: block;
}
.increase-balance .balances__wrapper .balance-row:nth-child(3) {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.increase-balance .input__wrapper {
  margin-top: 32px;
}
.increase-balance .input__wrapper {
  height: auto;
}
.increase-balance .input__wrapper .stepper {
  width: 100%;
  height: 56px;
}
.increase-balance .input__wrapper .stepper .stepper-input-wrap {
  width: 100%;
}
.increase-balance .input__wrapper .stepper .c-stepper-button-minus {
  width: 76px;
  border: 1px solid #CFD6DB;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #FFFFFF;
  color: var(--f7-stepper-button-text-color, var(--f7-theme-color));
  line-height: calc(var(--f7-stepper-height) - var(--f7-stepper-border-width, 0px));
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.increase-balance .input__wrapper .stepper .c-stepper-button-minus:active {
  background-color: rgba(var(--f7-theme-color-rgb),.15);
}
.increase-balance .input__wrapper .stepper .c-stepper-button-plus:active {
  background-color: rgba(var(--f7-theme-color-rgb),.15);
}
.increase-balance .input__wrapper .stepper .c-stepper-button-minus::after, .c-stepper-button-minus::before,
.c-stepper-button-plus::after, .c-stepper-button-plus::before {
  content: none;
}
.increase-balance .input__wrapper .stepper .c-stepper-button-minus .icon {
  background: url("/static/icons/minus.svg") no-repeat;
  width: 32px;
  height: 32px;
  display: block;
}
.increase-balance .input__wrapper .stepper .c-stepper-button-plus .icon {
  background: url("/static/icons/plus.svg") no-repeat;
  width: 32px;
  height: 32px;
  display: block;
}
.increase-balance .input__wrapper .stepper .stepper-input-wrap {
  border-top: 1px solid #CFD6DB;
  border-bottom: 1px solid #CFD6DB;
  background-color: #FFF;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.increase-balance .input__wrapper .stepper .stepper-input-wrap input {
  width: 100%;
  height: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.38px;
  color: #445058;
}
.increase-balance .input__wrapper .stepper .stepper-input-wrap span {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.07px;
  text-transform: uppercase;
  color: #818E95;
}
.increase-balance .input__wrapper .stepper .stepper-input-wrap input + span {
  margin-top: 2px;
}
.increase-balance .input__wrapper .stepper .c-stepper-button-minus::before, .c-stepper-button-minus::after {
  background-color: var(--f7-theme-icon-color);
}
.increase-balance .input__wrapper .stepper .c-stepper-button-plus {
  width: 76px;
  border: 1px solid #CFD6DB;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #FFFFFF;
  color: var(--f7-stepper-button-text-color, var(--f7-theme-color));
  line-height: calc(var(--f7-stepper-height) - var(--f7-stepper-border-width, 0px));
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.increase-balance .input__wrapper .stepper .c-stepper-button-plus::before, .c-stepper-button-plus::after {
  background-color: var(--f7-theme-text);
}
.increase-balance .commission-hint__wrapper {
  display: flex;
  justify-content: space-between;
}
.increase-balance .commission-hint, .commission {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.07px;
  color: #818E95;
  margin-top: 4px;
  display: block;
}
.increase-balance .input__wrapper .hint {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.07px;
  color: #818E95;
  margin-top: 4px;
  display: block;
}
.increase-balance .input__wrapper + .action_wrapper {
  margin-top: 24px;
}
.increase-balance .action_wrapper button {
  height: 46px;
  background: #007AFF;
  border-radius: 7px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #FFFFFF;
}
`,
  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      viewNicknameSheetModal: false,
      avatarUploadUrl: this.$root.apiBaseUrl + 'app/profile/avatar',
      profile: null,
      isSmartSelectWithdreawal: false,
      guest: false,
      utc: -new Date().getTimezoneOffset() / 60,
      checkPoint: false,
      networkError: false,
      key: null,
      time: null,
      dailyBonus: {
        counter: null,
        loading: false
      },
      click: 0
    };
  },
  methods: {
    changeNickname(name) {
      const _this = this;
      return new Promise((resolve, reject) => {
        this.$root.api('app/profile/name', { name }, 'POST', function (data, status, xhr) {
          if (data.status === 'error') {
            reject(data);
          }
          resolve(name);
        }, true);
      });
    },
    setLoadingNicknameSheetModal(el, state) {
      const btn = el.querySelector('.submit');
      const preloader = btn.querySelector('.preloader');
      const text = btn.querySelector('.text');
      this.$app.preloader.init(preloader);
      if (state) {
        preloader.classList.remove('hide');
        text.classList.add('hide');
      } else {
        preloader.classList.add('hide');
        text.classList.remove('hide');
      }
    },
    initNicknameSheetModal() {
      const _this = this;
      const content = `
                <div class="sheet-modal enter-nickname-sheet-modal">
                  <div class="swipe-handler"></div>
                  <div class="sheet-modal-inner">
                    <div class="block">
                      <div>
                        <span class="title">Ваш никнейм</span>
                        <span class="description">Ваше имя, которое будет видно другим игрокам</span>
                      </div>
                      <div>
                        <div class="input-wrapper">
                          <input type="text" value="" placeholder="Hero123">
                          <span class="input-clear-button"></span>
                        </div>
                        <span class="hint">от 4 до 20 символов</span>
                      </div>
                      <button class="submit">
                        <span class="preloader color-white hide"></span>
                        <span class="text">Готово</span>
                      </button>
                    </div>
                  </div>
                </div>
              `;
      const sheet = this.$app.sheet.create({
        content,
        swipeToClose: true,
        backdrop: true,
        swipeToStep: true
      });
      sheet.open();
      const el = document.querySelector('.enter-nickname-sheet-modal');
      const input = el.querySelector('input');
      const hint = el.querySelector('.hint');
      input.classList.add('input-with-value');
      input.value = _this.profile.name;
      setTimeout(() => {
        input.focus();
      }, 300);
      const btn = el.querySelector('.submit');
      input.addEventListener('blur', e => {
      });
      btn.addEventListener('click', () => {
        _this.setLoadingNicknameSheetModal(el, true);
        _this.changeNickname(input.value).then(name => {
          _this.profile.name = name;
          _this.$setState({ profile: _this.profile });
          sheet.close();
        }).catch(data => {
          input.classList.add('error');
          hint.classList.add('shake-horizontal');
          hint.classList.add('error');
          hint.innerText = data.message;
        }).finally(() => {
          _this.setLoadingNicknameSheetModal(el, false);
        });
      });
      input.addEventListener('input', () => {
        input.classList.remove('error');
        hint.classList.remove('shake-horizontal');
        hint.classList.remove('error');
        hint.innerText = 'от 4 до 20 символов';
      });
    },
    toProfileDetails(e) {
      if (e.target.classList.contains('mini-ask-icon-svg')) {
        this.$app.dialog.alert('На бонусы можно участвовать в платных турнирах - выводить их нельзя', 'Зачем мне бонусы?');
        return false;
      }
      this.$router.navigate({
        name: 'profile-details',
        query: { 'id': this.profile.id }
      });
    },
    toInviteFriend() {
      this.$router.navigate({ name: 'invite-friend' });
    },
    hideBtnDailyBonus() {
      this.$el.find('.page-content').find('.daily-bonus').remove();
    },
    animateValue(id, start, end, duration) {
      if (start === end)
        return;
      var range = end - start;
      var current = start;
      var increment = end > start ? 1 : -1;
      var stepTime = Math.abs(Math.floor(duration / range));
      var obj = document.getElementById(id);
      var timer = setInterval(function () {
        current += increment;
        current = '' + current;
        current = current.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        obj.innerHTML = current;
        current = current.replace(/\s/g, '');
        current = parseInt(current);
        if (current == end) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    updateValuePoints(newValue) {
      const obj = document.getElementById('value-points');
      var value = +obj.innerHTML.replace(/\s/g, '');
      this.animateValue('value-points', value, newValue, 1000);
    },
    disableBtnDailyBonus() {
      this.$setState({ dailyBonus: { loading: true } });
      this.$el.find('.page-content').find('.daily-bonus').find('.value').find('.money-count').remove();
      this.$el.find('.page-content').find('.daily-bonus').find('.value').prepend(`
              <div class="preloader daily-bonus-gold">
                <span class="preloader-inner">
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                </span>
              </div>
              `);
      this.$el.find('.page-content').find('.daily-bonus').find('.title').css('opacity', 0.5);
      this.$el.find('.page-content').find('.daily-bonus').find('.value').find('.icon-3d-money').css('opacity', 0.5);
    },
    updateValueBonuses(newValue) {
      const obj = document.getElementById('value-bonuses');
      if (!obj) {
        return false;
      }
      var value = +obj.innerHTML.replace(/\s/g, '');
      this.animateValue('value-bonuses', value, newValue, 1000);
    },
    updateValueCoins(newValue) {
      const obj = document.getElementById('value-coins');
      if (!obj) {
        return false;
      }
      var value = +obj.innerHTML.replace(/\s/g, '');
      this.animateValue('value-coins', value, newValue, 1000);
    },
    disableBtnDailyBonus() {
      this.$setState({ dailyBonus: { loading: true } });
      this.$el.find('.page-content').find('.daily-bonus').find('.value').find('.money-count').remove();
      this.$el.find('.page-content').find('.daily-bonus').find('.value').prepend(`
              <div class="preloader daily-bonus-gold">
                <span class="preloader-inner">
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                  <span class="preloader-inner-line"></span>
                </span>
              </div>
              `);
      this.$el.find('.page-content').find('.daily-bonus').find('.title').css('opacity', 0.5);
      this.$el.find('.page-content').find('.daily-bonus').find('.value').find('.icon-3d-money').css('opacity', 0.5);
    },
    getDailyBonus() {
      const self = this;
      if (!self.dailyBonus.loading) {
        self.disableBtnDailyBonus();
      }
      setTimeout(() => {
        self.$root.api('/app/balance/points/bonus', {}, 'GET', function (data, status, xhr) {
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Не успешно');
          }
          if (data.status == 'ok') {
            if (data.profile.balance.points && !data.profile.balance.points.can_get_bonus) {
              setTimeout(() => {
                self.getTimeNextDailyBonus(data.profile.balance.points.next_bonus_timestamp);
                self.$setState({
                  dailyBonus: {
                    counter: setInterval(() => {
                      self.getTimeNextDailyBonus(data.profile.balance.points.next_bonus_timestamp);
                    }, 1000),
                    loading: false
                  }
                });
              }, 0);
            }
            self.updateProfile();
            try {
              if (data.profile.balance.bonuses) {
                data.profile.balance.bonuses = self.updateProfileBonuses(data.profile);
              }
              if (data.profile.balance.coins) {
                data.profile.balance.coins = self.updateProfileCoins(data.profile);
              }
            } catch (e) {
            }
            self.$setState({ profile: data.profile });
            self.$root.params.profile = data.profile;
            self.$el.find('.update-profile').children('svg').css('display', 'block');
            self.$el.find('.update-profile').children('div .preloader').css('display', 'none');
          }
        }, true);
      }, 700);
    },
    enterPromocode() {
      let self = this;
      self.$app.dialog.create({
        title: 'Введите промокод',
        text: '',
        destroyOnClose: true,
        content: `<div class="dialog-input-field input"><input type="text" class="dialog-input" style="text-transform: uppercase;" value=""></div>`,
        buttons: [
          { text: 'Отмена' },
          {
            text: 'OK',
            bold: true,
            onClick: function (dialog, e) {
              const inputValue = dialog.$el.find('.dialog-input').val();
              self.$app.preloader.show();
              self.$root.api('app/promo/activate', { promocode: inputValue }, 'POST', function (data, status, xhr) {
                self.$app.preloader.hide();
                if (data.status == 'error') {
                  if (typeof data.title !== 'undefined') {
                    self.$app.dialog.alert(data.message, data.title, () => {
                      if (data.type === 'redirect-login') {
                        self.$root.logout();
                      }
                    });
                  } else {
                    self.$app.dialog.alert(data.message, 'Не успешно');
                  }
                }
                if (data.status == 'ok') {
                  self.$app.dialog.alert('Вы активировали промокод', 'Успешно');
                  self.updateProfile();
                }
              }, true);
            }
          }
        ]
      }).open();
    },
    openTechInfo() {
      if (this.time == null) {
        this.time = new Date().getTime();
        this.openTechInfo();
      } else {
        this.click++;
        if (this.time + 1000 < new Date().getTime()) {
          this.time = null;
          this.click = 0;
        } else {
          if (this.click >= 5) {
            this.$router.navigate('/technical-info/');
          }
        }
      }
    },
    openVerificatedPagePhone(e) {
      e.preventDefault();
      this.$router.navigate('/profile/verificated/phone/step-1');
    },
    openVerificatedPageEmail(e) {
      this.$router.navigate('/profile/verificated/email/step-1');
    },
    openSupportPage(e) {
      e.preventDefault();
      this.$router.navigate('/auth/support/');
    },
    onAvatarBeforeSend: function (e) {
      var self = this;
      self.$app.preloader.show();
      var xhr = e.detail.xhr;
      xhr.setRequestHeader('Authorization', 'Bearer ' + self.$root.getToken('token'));
    },
    onAvatarComplete: function (e) {
      var self = this;
      self.$app.preloader.hide();
    },
    onAvatarSuccess: function (e, data, xhr) {
      var self = this;
      var data = JSON.parse(data.xhr.response);
      if (data.status == 'error') {
        self.$app.dialog.alert(data.message, 'Ошибка');
      }
      if (data.status == 'ok') {
        self.load();
      }
    },
    deleteAvatar: function () {
      var self = this;
      self.$app.dialog.confirm('Удалить аватар?', 'Подтверждение', function () {
        self.$app.preloader.show();
        self.$root.api('app/profile/avatar', {}, 'DELETE', function (data, status, xhr) {
          self.$app.preloader.hide();
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Ошибка');
          }
          if (data.status == 'ok') {
            self.load();
          }
        }, true);
      });
    },
    updateAvatar: function () {
      if (document.getElementById('input-avatar').files.length == 0) {
      } else {
        this.$('#form-profile-avatar').trigger('submit');
      }
    },
    pull: function () {
      var self = this;
      self.$app.dialog.create({
        title: 'Снять деньги',
        text: 'Введите к-во денег для снятия',
        destroyOnClose: true,
        content: `<div class="dialog-input-field input"><input type="tel" class="dialog-input" value=""></div>`,
        buttons: [
          { text: 'Отмена' },
          {
            text: 'OK',
            bold: true,
            onClick: function (dialog, e) {
              const inputValue = dialog.$el.find('.dialog-input').val();
              self.$app.dialog.alert(`Вы ввели: ${ inputValue }`, 'Ошибка');
            }
          }
        ]
      }).open();
    },
    help: function () {
      var self = this;
      self.$app.dialog.create({
        title: 'Помощь',
        text: 'Введите сообщение',
        destroyOnClose: true,
        content: `<div class="dialog-input-field input"><textarea class="dialog-textarea"></textarea></div>`,
        buttons: [
          { text: 'Отмена' },
          {
            text: 'OK',
            bold: true,
            onClick: function (dialog, e) {
              const inputValue = dialog.$el.find('.dialog-textarea').val();
              self.$app.dialog.alert(`Вы ввели: ${ inputValue }`, 'Ошибка');
            }
          }
        ]
      }).open();
    },
    animationUpdateProfile() {
      this.$el.find('.profile-top').css('animation', 'scaling 1s steps(10) 0s 1');
      this.$el.find('.update-profile').children('svg').css('display', 'none');
      this.$el.find('.update-profile').children('div .preloader').css('display', 'block');
      setTimeout(() => {
        this.$el.find('.profile-top').css('animation', null);
      }, 600);
    },
    updateProfile() {
      let self = this;
      return new Promise((resolve, reject) => {
        self.$root.api('/app/profile', { payments_force: true }, 'GET', function (data, status, xhr) {
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Ошибка');
            reject();
          } else {
            setTimeout(() => {
              if (data.profile.balance.points && !data.profile.balance.points.can_get_bonus) {
                setTimeout(() => {
                  self.getTimeNextDailyBonus(data.profile.balance.points.next_bonus_timestamp);
                  self.$setState({
                    dailyBonus: {
                      counter: setInterval(() => {
                        self.getTimeNextDailyBonus(data.profile.balance.points.next_bonus_timestamp);
                      }, 1000)
                    }
                  });
                }, 0);
              }
              if (data.profile.balance.bonuses) {
                console.log(data.profile.balance.bonuses, 'bbb');
              }
              if (data.profile.balance.coins) {
              }
              try {
                if (data.profile.balance.bonuses) {
                  data.profile.balance.bonuses = self.updateProfileBonuses(data.profile);
                }
                if (data.profile.balance.coins) {
                  data.profile.balance.coins = self.updateProfileCoins(data.profile);
                }
              } catch (e) {
              }
              (() => {
                if (data.profile.currency) {
                  data.profile.currency.use_native_currency = data.profile.use_native_currency;
                }
              })();
              self.$setState({ profile: data.profile });
              self.$root.params.profile = data.profile;
              self.$el.find('.update-profile').children('svg').css('display', 'block');
              self.$el.find('.update-profile').children('div .preloader').css('display', 'none');
              resolve();
            }, 0);
          }
        }, true, {});
      });
    },
    openSmartSelectMethodsPayment(sumValue) {
      let self = this;
      self.$setState({ isSmartSelectWithdreawal: true });
      var smartSelect = self.$app.smartSelect.create({
        el: '.smart-select--methods-payment',
        pageTitle: 'Способы вывода',
        pageBackLinkText: '',
        renderItem: function (item, index) {
          switch (index) {
          case 0:
            var persent = '3%';
            var type = 'qiwi';
            break;
          case 1:
            var persent = '0.5%';
            var type = 'yandex';
            break;
          case 2:
            var persent = '0%';
            var type = 'mobile';
            break;
          }
          return `
                        <a href="/profile/withdrawal/?sum=${ sumValue }&type=${ type }" class="item-link item-content">
                            <div class="item-inner">
                                <div class="item-title">${ item.text }</div>
                                <div class="item-after">
                                    ${ persent }
                                </div>
                            </div>
                        </a>
                        `;
        }
      });
      smartSelect.open();
    },
    viewWithdrawals() {
      let self = this;
      self.$router.navigate('/profile/withdrawals/');
    },
    reduceBalance() {
      let self = this;
      self.$router.navigate('/profile/input-out/');
    },
    changePassword: function () {
      const self = this;
      self.$app.dialog.create({
        title: 'Изменение пароля',
        text: '',
        destroyOnClose: true,
        content: `
                      <div class="dialog-input-field dialog-input-double input">
                        <input type="text" name="dialog-password-old" placeholder="Текущий пароль" class="dialog-input">
                      </div>
                      <div class="dialog-input-field dialog-input-double input">
                        <input type="password" name="dialog-password-new1" placeholder="Новый пароль" class="dialog-input">
                      </div>
                      <div class="dialog-input-field dialog-input-double input">
                        <input type="password" name="dialog-password-new2" placeholder="Повторите новый пароль" class="dialog-input">
                      </div>`,
        buttons: [
          { text: 'Отмена' },
          {
            text: 'OK',
            bold: true,
            onClick: function (dialog, e) {
              const oldValue = dialog.$el.find('[name="dialog-password-old"]').val();
              const new1Value = dialog.$el.find('[name="dialog-password-new1"]').val();
              const new2Value = dialog.$el.find('[name="dialog-password-new2"]').val();
              self.$app.preloader.show();
              var params = {
                current_password: oldValue,
                password: new1Value,
                password_confirmation: new2Value
              };
              self.$root.api('/app/profile/password', params, 'POST', function (data, status, xhr) {
                if (data.status == 'error') {
                  self.$app.dialog.alert(data.message, 'Ошибка');
                } else {
                  self.$app.dialog.alert('Пароль изменен.', 'Успешно!');
                }
              }, true, {
                messageCallback: function () {
                  self.$app.dialog.alert('Ошибка подключения: пароль не изменен.', 'Ошибка');
                }
              });
            }
          }
        ]
      }).open();
    },
    avatar: function () {
      var self = this;
      if (self.guest === false) {
        this.$app.actions.create({
          buttons: [
            [
              {
                text: 'Аватарка',
                label: true
              },
              {
                text: 'Изменить изображение',
                bold: true,
                onClick: function (actions, e) {
                  document.getElementById('input-avatar').click();
                }
              },
              {
                text: 'Удалить изображение',
                onClick: function (actions, e) {
                  self.deleteAvatar();
                }
              }
            ],
            [{
                text: 'Отмена',
                color: 'red'
              }]
          ]
        }).open();
      }
    },
    load: function (background) {
      var self = this;
      if (typeof ptrContent === 'undefined') {
        var ptrContent = self.$el.find('.ptr-content');
        ptrContent.on('ptr:refresh', function (e) {
          setTimeout(() => {
            self.updateProfile();
            setTimeout(() => {
              e.detail();
            }, 0);
          }, 700);
        });
      }
      this.$root.api('app/profile', {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          if (data.profile.balance.points && !data.profile.balance.points.can_get_bonus) {
            setTimeout(() => {
              self.getTimeNextDailyBonus(data.profile.balance.points.next_bonus_timestamp);
              self.$setState({
                dailyBonus: {
                  counter: setInterval(() => {
                    self.getTimeNextDailyBonus(data.profile.balance.points.next_bonus_timestamp);
                  }, 1000)
                }
              });
            }, 0);
          }
          try {
            if (data.profile.balance.bonuses) {
              data.profile.balance.bonuses = self.updateProfileBonuses(data.profile);
            }
            if (data.profile.balance.coins) {
              data.profile.balance.coins = self.updateProfileCoins(data.profile);
            }
          } catch (e) {
            console.log(e);
          }
          console.log(data.profile);
          (() => {
            if (data.profile.currency) {
              data.profile.currency.use_native_currency = data.profile.use_native_currency;
            }
          })();
          self.$setState({
            profile: data.profile,
            guest: data.profile.is_guest,
            checkPoint: data.profile.params.can_play_only_for_points == 1 ? true : false,
            key: self.$root.getToken('token')
          });
          self.$root.params.profile = data.profile;
          self.$app.data.is_guest = data.profile.is_guest;
          self.$app.data.params.visibleStarIncreaseBalance = data.profile.params.can_play_only_for_points == 1 ? true : false;
          self.createSmartSelect();
        }
      }, true, {});
    },
    createSmartSelect() {
      const self = this;
      const smartSelect = this.$app.smartSelect.create({
        el: this.$el.find('.smart-select-change-currency')[0],
        pageTitle: 'Отображение валюты',
        pageBackLinkText: '',
        closeOnSelect: true,
        cssClass: 'select-currency',
        setValueText: true,
        formatValueText(text) {
          let result = text[0].split('(')[1].split(')')[0];
          return result;
        },
        on: {
          close: function () {
            self.$app.preloader.show();
            let use_native_currency = true;
            console.log(this.$valueEl[0].innerText);
            if (this.$valueEl[0].innerText === 'USD') {
              use_native_currency = false;
            }
            self.$root.api('app/profile/native_currency', { use_native_currency }, 'POST', function (data, status, xhr) {
              if (data.status == 'error') {
                self.$app.dialog.alert(data.message, 'Ошибка');
              }
              if (data.status == 'ok') {
                self.updateProfile();
                self.$app.views.games.router.navigate('/games/', {
                  reloadAll: true,
                  reloadCurrent: true,
                  ignoreCache: true
                });
                self.$app.views['with-friends'].router.navigate('/with-friends/', {
                  reloadAll: true,
                  reloadCurrent: true,
                  ignoreCache: true
                });
                self.$app.views.profile.router.navigate('/profile', { reloadAll: true });
                self.$app.views.lobby.router.navigate('/lobby/', {
                  reloadAll: true,
                  reloadCurrent: true,
                  ignoreCache: true
                });
              }
            }, true);
          },
          open: function () {
            const div = document.createElement('div');
            div.classList.add('title');
            const text = document.createTextNode('Выберите валюту входов и призов');
            div.appendChild(text);
            const page = document.getElementsByClassName('select-currency')[0].querySelector('.page-content');
            page.prepend(div);
            document.querySelector('.link.back').classList.add('icon-only');
          }
        }
      });
    },
    updateProfileCoins(profile) {
      const usa = profile.balance.coins.value * parseFloat(profile.currency.rate).toFixed(2);
      profile.balance.coins.konvert = {
        value: parseInt(usa),
        currency: profile.currency.sign
      };
      return profile.balance.coins;
    },
    updateProfileBonuses(profile) {
      const usa = profile.balance.bonuses.value * parseFloat(profile.currency.rate).toFixed(2);
      profile.balance.bonuses.konvert = {
        value: parseInt(usa),
        currency: profile.currency.sign
      };
      return profile.balance.bonuses;
    },
    getTimeNextDailyBonus(time) {
      const self = this;
      var dateDailyBonus = new Date(time * 1000);
      var dateNow = new Date(Date.now());
      var total = dateDailyBonus - dateNow;
      let seconds = Math.floor(total / 1000 % 60);
      let minutes = Math.floor(total / 1000 / 60 % 60);
      let hours = Math.floor(total / (1000 * 60 * 60) % 24);
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      if (hours < 10) {
        hours = '0' + hours;
      }
      setTimeout(() => {
        this.$el.find('.page-content').find('#daily-bonus-time').html(`${ hours }:${ minutes }:${ seconds }`);
      }, 0);
    },
    logout: function (e) {
      var self = this;
      self.$app.dialog.confirm('Выйти из учетной записи?', 'Подтверждение', function () {
        self.$root.api('app/logout', {}, 'POST', function (data, status, xhr) {
          if (data.status == 'error') {
            if (data.exception == 'AuthenticationException') {
              self.$root.logout();
            } else {
              self.$app.dialog.alert(data.message, 'Ошибка выхода');
            }
          }
          if (data.status == 'ok') {
            self.$root.logout();
          }
        }, true);
      });
      clearInterval(self.dailyBonus.counter);
    },
    showCalendar: function (e) {
    }
  },
  on: {
    pageBeforeIn: function (event, page) {
      if (localStorage.getItem('update-profile') === 'true') {
        localStorage.removeItem('update-profile');
        this.updateProfile();
      }
      delete window.friendsList;
    },
    pageInit: function () {
      let self = this;
      if (this.$route.query.noload) {
        this.$root.removeNoLoadFromUrl(this.$router);
      } else {
        this.load();
      }
      try {
        delete self.$root.events.eventsListeners['profile.update'];
        self.$root.events.on('profile.update', () => {
          self.updateProfile();
        });
      } catch (e) {
        alert('Error in event: \'profile.update\'');
      }
    },
    pageAfterOut: function () {
    }
  },
  id: '491796d39e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="title">\n                    Профиль\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content ptr-content" data-ptr-distance="75" data-ptr-mousewheel="true">\n            <div class="ptr-preloader">\n                <div class="preloader"></div>\n                <div class="ptr-arrow"></div>\n            </div>\n\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            ';
          r += Template7Helpers._partial.call(ctx_2, 'network-error', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.profile, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.coins, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.coins.show, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.coins.konvert, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += Template7Helpers.if.call(ctx_5, ctx_5.coins.konvert, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += Template7Helpers.if.call(ctx_5, ctx_5.points, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.bonuses, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.bonuses.show, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.bonuses.konvert, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += Template7Helpers.if.call(ctx_5, ctx_5.bonuses.konvert, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.points, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.points.show, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.bonuses, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.bonuses.show, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += Template7Helpers.if.call(ctx_2, ctx_2.profile, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.profile.balance.points, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.profile.balance.points.can_get_bonus, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += Template7Helpers.if.call(ctx_3, ctx_3.profile.params.email_verified, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.profile, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <div class="block block-strong block-profile-main no-margin no-hairline-top">\n                <form @formajax:beforesend="onAvatarBeforeSend" @formajax:complete="onAvatarComplete"\n                      @formajax:success="onAvatarSuccess" style="position: absolute; top: -300px;"\n                      id="form-profile-avatar" action="';
              r += c(ctx_3.avatarUploadUrl, ctx_3);
              r += '" method="POST" class="form-ajax-submit"\n                      enctype="multipart/form-data">\n                    <input type="file" @change="updateAvatar" id="input-avatar" name="avatar">\n                </form>\n                <div class="profile-top">\n                    ';
              r += Template7Helpers.with.call(ctx_3, ctx_3.profile, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                    <img src="';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.avatar.url, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '" @click="avatar" class="margin-right profile-avatar">\n                    <div class="profile-top-info" @click="toProfileDetails">\n\n                        <div class="profile-top-name">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                        <div class="profile-top-info-data">\n                            ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.params.can_play_only_for_points == 0', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n\n                            ';
                      r += Template7Helpers.with.call(ctx_5, ctx_5.balance, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                            ';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.coins, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n\n                            ';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.coins.show, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                            <div class="profile-top-data">\n                                Баланс\n                                <br>\n                                ';
                                  r += Template7Helpers.js_if.call(ctx_8, '../../../use_native_currency == true', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                    ';
                                      r += Template7Helpers.if.call(ctx_9, ctx_9.coins.konvert, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                        ';
                                          r += Template7Helpers.with.call(ctx_10, ctx_10.coins.konvert, {
                                            hash: {},
                                            data: data_10 || {},
                                            fn: function (ctx_11, data_11) {
                                              var r = '';
                                              r += '\n                                            <span id="value-coins-convert">';
                                              r += Template7Helpers.formatPrice.call(ctx_11, ctx_11.value, {
                                                hash: {},
                                                data: data_11 || {},
                                                fn: function empty() {
                                                  return '';
                                                },
                                                inverse: function empty() {
                                                  return '';
                                                },
                                                root: root,
                                                parents: [
                                                  ctx_10,
                                                  ctx_9,
                                                  ctx_8,
                                                  ctx_7,
                                                  ctx_6,
                                                  ctx_5,
                                                  ctx_4,
                                                  ctx_3,
                                                  ctx_2,
                                                  ctx_1
                                                ]
                                              });
                                              r += c(ctx_11.currency, ctx_11);
                                              r += '</span>\n                                        ';
                                              return r;
                                            },
                                            inverse: function empty() {
                                              return '';
                                            },
                                            root: root,
                                            parents: [
                                              ctx_9,
                                              ctx_8,
                                              ctx_7,
                                              ctx_6,
                                              ctx_5,
                                              ctx_4,
                                              ctx_3,
                                              ctx_2,
                                              ctx_1
                                            ]
                                          });
                                          r += '\n                                    ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                    <span id="value-coins">(';
                                      r += Template7Helpers.formatPrice.call(ctx_9, ctx_9.coins.value, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function empty() {
                                          return '';
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '</span>';
                                      r += c(ctx_9.coins.currency, ctx_9);
                                      r += ')\n                                ';
                                      return r;
                                    },
                                    inverse: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                    <span id="value-coins">';
                                      r += Template7Helpers.formatPrice.call(ctx_9, ctx_9.coins.value, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function empty() {
                                          return '';
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '</span>';
                                      r += c(ctx_9.coins.currency, ctx_9);
                                      r += '\n                                    ';
                                      r += Template7Helpers.if.call(ctx_9, ctx_9.coins.konvert, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                        ';
                                          r += Template7Helpers.with.call(ctx_10, ctx_10.coins.konvert, {
                                            hash: {},
                                            data: data_10 || {},
                                            fn: function (ctx_11, data_11) {
                                              var r = '';
                                              r += '\n                                            <span id="value-coins-convert">(';
                                              r += Template7Helpers.formatPrice.call(ctx_11, ctx_11.value, {
                                                hash: {},
                                                data: data_11 || {},
                                                fn: function empty() {
                                                  return '';
                                                },
                                                inverse: function empty() {
                                                  return '';
                                                },
                                                root: root,
                                                parents: [
                                                  ctx_10,
                                                  ctx_9,
                                                  ctx_8,
                                                  ctx_7,
                                                  ctx_6,
                                                  ctx_5,
                                                  ctx_4,
                                                  ctx_3,
                                                  ctx_2,
                                                  ctx_1
                                                ]
                                              });
                                              r += c(ctx_11.currency, ctx_11);
                                              r += ')</span>\n                                        ';
                                              return r;
                                            },
                                            inverse: function empty() {
                                              return '';
                                            },
                                            root: root,
                                            parents: [
                                              ctx_9,
                                              ctx_8,
                                              ctx_7,
                                              ctx_6,
                                              ctx_5,
                                              ctx_4,
                                              ctx_3,
                                              ctx_2,
                                              ctx_1
                                            ]
                                          });
                                          r += '\n                                    ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                ';
                                      return r;
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                ';
                                  r += Template7Helpers.if.call(ctx_8, ctx_8.points, {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                    <span class="balance">\n                                        <span class="value">';
                                      r += Template7Helpers.formatPrice.call(ctx_9, ctx_9.points.value, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function empty() {
                                          return '';
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '</span>\n                                    </span>\n                                ';
                                      return r;
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                            </div>\n                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                            ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                            ';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.bonuses, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                            ';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.bonuses.show, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                            <div class="profile-top-data">\n                                <span>';
                                  r += Template7Helpers.escape.call(ctx_8, ctx_8.bonuses.label, {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '<i class="mini-ask-icon-svg icon"></i></span>\n                                <br>\n                                ';
                                  r += Template7Helpers.js_if.call(ctx_8, '../../../use_native_currency == true', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                    ';
                                      r += Template7Helpers.if.call(ctx_9, ctx_9.bonuses.konvert, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                        ';
                                          r += Template7Helpers.with.call(ctx_10, ctx_10.bonuses.konvert, {
                                            hash: {},
                                            data: data_10 || {},
                                            fn: function (ctx_11, data_11) {
                                              var r = '';
                                              r += '\n                                            <span>';
                                              r += Template7Helpers.formatPrice.call(ctx_11, ctx_11.value, {
                                                hash: {},
                                                data: data_11 || {},
                                                fn: function empty() {
                                                  return '';
                                                },
                                                inverse: function empty() {
                                                  return '';
                                                },
                                                root: root,
                                                parents: [
                                                  ctx_10,
                                                  ctx_9,
                                                  ctx_8,
                                                  ctx_7,
                                                  ctx_6,
                                                  ctx_5,
                                                  ctx_4,
                                                  ctx_3,
                                                  ctx_2,
                                                  ctx_1
                                                ]
                                              });
                                              r += c(ctx_11.currency, ctx_11);
                                              r += '</span>\n                                        ';
                                              return r;
                                            },
                                            inverse: function empty() {
                                              return '';
                                            },
                                            root: root,
                                            parents: [
                                              ctx_9,
                                              ctx_8,
                                              ctx_7,
                                              ctx_6,
                                              ctx_5,
                                              ctx_4,
                                              ctx_3,
                                              ctx_2,
                                              ctx_1
                                            ]
                                          });
                                          r += '\n                                    ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                    <span>(';
                                      r += Template7Helpers.formatPrice.call(ctx_9, ctx_9.bonuses.value, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function empty() {
                                          return '';
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += c(ctx_9.bonuses.currency, ctx_9);
                                      r += ')</span>\n                                ';
                                      return r;
                                    },
                                    inverse: function (ctx_9, data_9) {
                                      var r = '';
                                      r += '\n                                    <span>';
                                      r += Template7Helpers.formatPrice.call(ctx_9, ctx_9.bonuses.value, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function empty() {
                                          return '';
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += c(ctx_9.bonuses.currency, ctx_9);
                                      r += '</span>\n                                    ';
                                      r += Template7Helpers.if.call(ctx_9, ctx_9.bonuses.konvert, {
                                        hash: {},
                                        data: data_9 || {},
                                        fn: function (ctx_10, data_10) {
                                          var r = '';
                                          r += '\n                                        ';
                                          r += Template7Helpers.with.call(ctx_10, ctx_10.bonuses.konvert, {
                                            hash: {},
                                            data: data_10 || {},
                                            fn: function (ctx_11, data_11) {
                                              var r = '';
                                              r += '\n                                            <span>(';
                                              r += Template7Helpers.formatPrice.call(ctx_11, ctx_11.value, {
                                                hash: {},
                                                data: data_11 || {},
                                                fn: function empty() {
                                                  return '';
                                                },
                                                inverse: function empty() {
                                                  return '';
                                                },
                                                root: root,
                                                parents: [
                                                  ctx_10,
                                                  ctx_9,
                                                  ctx_8,
                                                  ctx_7,
                                                  ctx_6,
                                                  ctx_5,
                                                  ctx_4,
                                                  ctx_3,
                                                  ctx_2,
                                                  ctx_1
                                                ]
                                              });
                                              r += c(ctx_11.currency, ctx_11);
                                              r += ')</span>\n                                        ';
                                              return r;
                                            },
                                            inverse: function empty() {
                                              return '';
                                            },
                                            root: root,
                                            parents: [
                                              ctx_9,
                                              ctx_8,
                                              ctx_7,
                                              ctx_6,
                                              ctx_5,
                                              ctx_4,
                                              ctx_3,
                                              ctx_2,
                                              ctx_1
                                            ]
                                          });
                                          r += '\n                                    ';
                                          return r;
                                        },
                                        inverse: function empty() {
                                          return '';
                                        },
                                        root: root,
                                        parents: [
                                          ctx_8,
                                          ctx_7,
                                          ctx_6,
                                          ctx_5,
                                          ctx_4,
                                          ctx_3,
                                          ctx_2,
                                          ctx_1
                                        ]
                                      });
                                      r += '\n                                ';
                                      return r;
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                            </div>\n                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                            ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                            ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                            ';
                      r += Template7Helpers.with.call(ctx_5, ctx_5.balance, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                            ';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.points, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                            ';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.points.show, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                            <div class="profile-top-data">\n                                Баланс\n                                <br>\n                                <span class="balance">\n                                                      <span class="value value-points-line">\n                                                        <span id="value-points">';
                                  r += Template7Helpers.formatPrice.call(ctx_8, ctx_8.points.value, {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '</span>\n                                                        ';
                                  r += c(ctx_8.points.currency, ctx_8);
                                  r += '\n                                                      </span>\n                                                    </span>\n                            </div>\n                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                            ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                            ';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.bonuses, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                            ';
                              r += Template7Helpers.if.call(ctx_7, ctx_7.bonuses.show, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                            <div class="profile-top-data">';
                                  r += Template7Helpers.escape.call(ctx_8, ctx_8.bonuses.label, {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                                <br>\n                                <span class="score">\n                                                        <span class="value value-bonuses-line">\n                                                          <span id="value-bonuses">';
                                  r += Template7Helpers.formatPrice.call(ctx_8, ctx_8.bonuses.value, {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += ' ';
                                  r += c(ctx_8.bonuses.currency, ctx_8);
                                  r += '</span>\n                                                        </span>\n                                                      </span>\n                            </div>\n                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                            ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                            ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n                            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.$app.methods.getTypeApp() === \'origin\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            <div class="score-top-data">\n                                Опыт\n                                <br>\n                                <span class="score">\n                                        <span class="value value-points-line">\n                                          ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.profile, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                            <span>';
                      r += Template7Helpers.formatPrice.call(ctx_5, ctx_5.leaderboard.total_exp, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                          ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                        </span>\n                                      </span>\n                            </div>\n                            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        </div>\n                    </div>\n                    <div class="update-profile">\n                        <i class="icon icon-forward to-profile-details" style="color: #D1D1D6;"></i>\n                    </div>\n                </div>\n            </div>\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.profile, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.$app.methods.getTypeApp() === \'origin\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n            ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.profile.balance.points, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n            ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.profile.balance.points.can_get_bonus, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n            <button @click="getDailyBonus" class="daily-bonus button button-raised">\n                <span class="title"><i class="icon icon-prize mr-3"></i><span>Ежедневный бонус</span></span>\n                <span class="value"><span class="money-count">50</span>\n                    <!--<i class="icon fa-star" style="font-size:13px;"></i>--></span>\n            </button>\n            ';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n            <div class="no-daily-bonus">\n                <span class="title"><i class="icon icon-clock"></i><span\n                    class="text">Следующий бонус через </span></span></span>\n                <span class="text" id="daily-bonus-time"></span>\n            </div>\n            ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.guest === false && this.$app.methods.getTypeApp() === \'origin\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n            <div class="list profile-list money-block">\n                <ul>\n                    <a href="/profile/buy-cristals/" class="item-content item-link">\n                        <div class="item-media"><i class="icon icon-profile-push"></i></div>\n                        <div class="item-inner">\n                            <div class="item-title">Магазин кристалов</div>\n                        </div>\n                    </a>\n\n                    <a class="item-content item-link" @click="viewWithdrawals">\n                        <div class="item-media"><i class="icon icon-profile-withdrawal"></i></div>\n                        <div class="item-inner">\n                            <div class="item-title">История операций</div>\n                        </div>\n                    </a>\n\n                    <!--                            <a @click="toInviteFriend" class="item-link item-content">-->\n                    <!--                                <div class="item-media"><i class="icon icon-profile-invite-friend"></i></div>-->\n                    <!--                                <div class="item-inner">-->\n                    <!--                                    <div class="item-title">Пригласить друга</div>-->\n                    <!--                                </div>-->\n                    <!--                            </a>-->\n                </ul>\n            </div>\n            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.guest === false && this.$app.methods.getTypeApp() !== \'origin\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n            <div class="list profile-list money-block">\n                <ul>\n                    <a href="/profile/increase-balance-popup" class="item-content item-link">\n                        <div class="item-media"><i class="icon icon-profile-push"></i></div>\n                        <div class="item-inner">\n                            <div class="item-title">Пополнить баланс</div>\n                        </div>\n                    </a>\n                    <a href="/profile/decrease-balance-popup" class="item-content item-link">\n                        <div class="item-media"><i class="icon icon-profile-pull"></i></div>\n                        <div class="item-inner">\n                            <div class="item-title">Снять с баланса</div>\n                        </div>\n                    </a>\n                    <a class="item-content item-link" @click="viewWithdrawals">\n                        <div class="item-media"><i class="icon icon-profile-withdrawal"></i></div>\n                        <div class="item-inner">\n                            <div class="item-title">История операций</div>\n                        </div>\n                    </a>\n                </ul>\n            </div>\n            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.guest === false', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n            <!--                        <div class="list profile-list no-chevron">-->\n            <!--                            <ul>-->\n            <!--                                <li>-->\n            <!--                                    <a href="#" class="item-link item-content" @click="enterPromocode">-->\n            <!--                                        <div class="item-inner justify-content-center">-->\n            <!--                                            <div class="item-title" id="item-title-enter-promocode">Ввести промокод</div>-->\n            <!--                                        </div>-->\n            <!--                                    </a>-->\n            <!--                                </li>-->\n            <!--                            </ul>-->\n            <!--                        </div>-->\n            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n            <div class="list profile-list">\n                <ul>\n                    ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.guest === false', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.$app.methods.getTypeApp() === \'origin\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                        <li>\n                            ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.profile.params.email_verified, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                            <a href="javascript:void(0)" class="item-content">\n                                <div class="item-media"><i class="icon icon-profile-email"></i></div>\n                                <div class="item-inner">\n                                    <div class="item-title">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.profile.email, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</div>\n                                    <span class="verificated-status verificated"><i class="f7-icons size-28 v-center">checkmark_alt_circle</i></span>\n                                </div>\n                            </a>\n                            ';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                            <a href="javascript:void(0)" @click="openVerificatedPageEmail" class="item-content item-link">\n                                <div class="item-media"><i class="icon icon-profile-email"></i></div>\n                                <div class="item-inner">\n                                    <div class="item-title">Email</div>\n                                    <span class="verificated-status not-verificated">Не подтвержден</span>\n                                </div>\n                            </a>\n                            ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                        </li>\n                    ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.$app.methods.getTypeApp() === \'origin\' && (this.$root.params.profile.phone !== \'\')', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                    <li>\n                        <a href="javascript:void(0)" class="item-content">\n                            <div class="item-media"><i class="icon icon-profile-email"></i></div>\n                            <div class="item-inner">\n                                <div class="item-title">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.profile.phone, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</div>\n                                <span class="verificated-status verificated"><i class="f7-icons size-28 v-center">checkmark_alt_circle</i></span>\n                            </div>\n                        </a>\n                    </li>\n                    ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                    <li>\n                        <a href="/profile/change-nickname-popup" class="item-link item-content">\n                            <div class="item-media"><i class="icon icon-tab-profile"></i></div>\n                            <div class="item-inner">\n                                <div class="item-title">Изменение никнейма</div>\n                            </div>\n                        </a>\n                    </li>\n                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.$app.methods.getTypeApp() === \'origin\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                    <li>\n                        <a href="/profile/change-password" class="item-link item-content">\n                            <div class="item-media"><i class="icon icon-profile-password"></i></div>\n                            <div class="item-inner">\n                                <div class="item-title">Установить пароль</div>\n                            </div>\n                        </a>\n                    </li>\n                    ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n<!--                    <li>-->\n<!--                        <a href="#" @click="changeCurrency" class="item-link item-content">-->\n<!--                            <div class="item-media"><i class="icon icon-profile-password"></i></div>-->\n<!--                            <div class="item-inner">-->\n<!--                                <div class="item-title">Отображение валюты</div>-->\n<!--                            </div>-->\n<!--                        </a>-->\n<!--                    </li>-->\n                    ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.$app.methods.getTypeApp() !== \'origin\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                        <li>\n                            <a href="#" class="item-link smart-select smart-select-change-currency">\n                                <select name="currency">\n                                    <option value="USA" ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.use_native_currency === false', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'selected';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '>Доллары США (USD)</option>\n                                    ';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.profile, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <option value=\'';
                      r += Template7Helpers.js.call(ctx_5, 'this.currency.abbr', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\' ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.use_native_currency === true', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += 'selected';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '>\n                                            ';
                      r += Template7Helpers.js.call(ctx_5, 'this.currency.name', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' (';
                      r += Template7Helpers.js.call(ctx_5, 'this.currency.abbr', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ')\n                                        </option>\n                                    ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                </select>\n                                <div class="item-content">\n                                    <div class="item-media"><i class="icon icon-currency"></i></div>\n                                    <div class="item-inner">\n                                        <div class="item-title">Отображение валюты</div>\n                                        <div class="item-after"></div>\n                                    </div>\n                                </div>\n                            </a>\n                        </li>\n                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n                    <li class="item-content">\n                        <div class="item-media"><i class="icon icon-profile-timezone"></i></div>\n                        <div class="item-inner">\n                            <div class="item-title">Временная зона</div>\n                            <div class="item-after">UTC';
              r += Template7Helpers.js_if.call(ctx_3, 'this.utc>0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '+';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '-';
              r += c(ctx_3.utc, ctx_3);
              r += '\n                            </div>\n                        </div>\n                    </li>\n\n                </ul>\n            </div>\n\n            <div class="list profile-list">\n                <ul>\n                    <li>\n                        <a id="linkSupport" href="https://fan-battle.com/support.html" target="_blank"\n                           class="external item-link item-content">\n                            <div class="item-media"><i class="icon icon-profile-help"></i></div>\n                            <div class="item-inner">\n                                <div class="item-title">Служба поддержки</div>\n                            </div>\n                        </a>\n                    </li>\n                    <li>\n                        <a href="/info/" class="item-content item-link">\n                            <div class="item-media"><i class="icon icon-profile-rules"></i></div>\n                            <div class="item-inner">\n                                <div class="item-title">Информация о сервисе</div>\n                            </div>\n                        </a>\n                    </li>\n\n                    <li>\n                        <a href="#" @click="openTechInfo" target="_blank" class="item-content">\n                            <div class="item-media"><i class="icon-info"></i></div>\n                            <div class="item-inner">\n                                <div class="item-title">Версия</div>\n                                <div class="item-after">';
              r += c(ctx_3.$app.version, ctx_3);
              r += '</div>\n                            </div>\n                        </a>\n                    </li>\n                </ul>\n            </div>\n\n            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.$app.methods.getTypeApp() === \'origin\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n            <div class="list profile-list no-chevron">\n                <ul>\n                    <li>\n                        <a href="#" @click="logout" class="item-link item-content">\n                            <div class="item-inner justify-content-center">\n                                <div class="item-title" id="item-title-logout"><i class="icon icon-profile-logout"></i>\n                                    Выйти\n                                </div>\n                            </div>\n                        </a>\n                    </li>\n                </ul>\n            </div>\n            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <div class="block block-strong text-align-center">\n                <div class="preloader"></div>\n            </div>\n            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n        <!-- Модальное окно "Введите ник" -->\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .mini-ask-icon-svg {
        width: 16px;
        top: -1px;
        margin-left: 4px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00008 15.0002C11.8288 15.0002 15.0002 11.8288 15.0002 8.00008C15.0002 4.17135 11.8216 1 7.99286 1C4.17135 1 1 4.17135 1 8.00008C1 11.8288 4.17135 15.0002 8.00008 15.0002ZM8.00008 13.7649C4.80706 13.7649 2.24976 11.2003 2.24976 8.00008C2.24976 4.79984 4.79984 2.23531 7.99286 2.23531C11.1931 2.23531 13.7576 4.79984 13.7649 8.00008C13.7721 11.2003 11.2003 13.7649 8.00008 13.7649ZM7.8556 9.39432C8.20958 9.39432 8.4263 9.1776 8.43352 8.90308C8.43352 8.88141 8.43352 8.85252 8.43352 8.82362C8.44075 8.44797 8.67192 8.20958 9.13425 7.90617C9.80609 7.4655 10.2612 7.06818 10.2612 6.25909C10.2612 5.12492 9.24984 4.49643 8.07232 4.49643C6.8948 4.49643 6.11461 5.04545 5.91956 5.68117C5.88344 5.79675 5.86177 5.90511 5.86177 6.0207C5.86177 6.33855 6.10739 6.51916 6.353 6.51916C6.5914 6.51916 6.7431 6.4108 6.87313 6.23742L6.98872 6.08571C7.22711 5.68839 7.57386 5.47167 8.01453 5.47167C8.61412 5.47167 9.00422 5.81843 9.00422 6.31688C9.00422 6.772 8.72248 6.99594 8.13011 7.40771C7.63888 7.74724 7.27768 8.10122 7.27768 8.7586V8.83807C7.27768 9.20649 7.47995 9.39432 7.8556 9.39432ZM7.84115 11.4532C8.25292 11.4532 8.59968 11.1425 8.59968 10.7308C8.59968 10.319 8.26015 10.0084 7.84115 10.0084C7.42938 10.0084 7.08263 10.3262 7.08263 10.7308C7.08263 11.1353 7.43661 11.4532 7.84115 11.4532Z' fill='%23AAADB3'/%3E%3C/svg%3E%0A");
    }
    .select-currency .title {
        margin: 24px 16px 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #818E95;
    }

    .profile-list {
        margin-top: 28px;
    }

    /*{
        color: #818E95 !important;
    }*/
    .profile-top-info-data {
        flex-direction: row;
        display: flex;

    }

    .profile-top-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .profile-avatar {
        height: 59px;
        border-radius: 50%;
    }

    .star i {
        height: 15px;
    }

    .dialog-textarea {
        border-radius: var(--f7-dialog-input-border-radius);
        border: var(--f7-dialog-input-border-width) solid var(--f7-dialog-input-border-color);
    }

    #item-title-logout {
        color: #EB4036 !important;
    }

    .profile-list .item-title {
        color: black;
    }

    .profile-top {
        display: flex;
        font-weight: 500;
        justify-content: start;
    }

    .profile-top-name {
        font-size: 16px;
        line-height: 18px;
        color: #445058;
        margin-bottom: 5px;

    }

    .profile-top-data {
        font-size: 13px;
        color: #818E95;
        margin-right: 20px;
        white-space: nowrap;
    }

    .score-top-data {
        font-size: 13px;
        color: #818E95;
        margin-right: 30px;
        white-space: nowrap;
    }

    .block-profile-main .button {
        height: 39px;
    }

    .update-profile {
        margin-left: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-right: 7px;
    }

    .update-profile > i {
        color: #445058;
    }

    .update-profile > .preloader {
        display: none;
        width: 24px;
        height: 20px
    }

    @keyframes scaling {
        0% {
            transform: scale(1);
        }
        10% {
            transform: scale(0.985);
        }
        20% {
            transform: scale(1.01);
        }
        30% {
            transform: scale(1);
        }
        100% {
            transform: scale(1);
        }
    }

    .profile-top-data .mr {
        margin-right: 5%;
    }



    @media (max-width: 340px) {
        .profile-top-data {
            margin-right: 10px;
        }

        .profile-top-data .mr {
            margin-right: 3px;
        }
    }

    .not-verificated {
        color: #C1272D;
    }

    .verificated {
        color: #32BF61;
    }

    .v-center {
        vertical-align: middle;
    }

    #item-title-enter-promocode {
        color: #007AFF;
    }

    .balance {
        height: 18px;
        display: inline-flex;
        align-items: baseline;
    }

    .daily-bonus + .profile-list {
        margin-top: 0px;
    }

    .no-daily-bonus + .profile-list {
        margin-top: 0px;
    }
    .daily-bonus {
      margin: 16px;
      width: calc(100% - 32px);
      height: 44px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 16px;
    }
    .daily-bonus .title, .value {
      display: inline-flex;
      align-items: center;
    }
    .daily-bonus .title span {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.41px;
      color: #445058;
      margin-left: 8px;
    }
    .daily-bonus .value span {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
      text-align: right;
      letter-spacing: -0.41px;
      color: #4FA1E8;
      margin-right: 0px;
    }
    .no-daily-bonus {
      margin: 16px 26px;
      width: calc(100% - 52px);
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .no-daily-bonus i {
      margin-right: 8px;
    }
    .no-daily-bonus .title {
      display: inline-flex;
      align-items: center;
      margin-right: 4px;
    }
    .no-daily-bonus .text {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #CFD6DB;
    }
`,
  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      commentary: null,
      id: null,
      type: null,
      wallet: null,
      status: null,
      amount: null,
      percent: null,
      created_at: null,
      typeText: null,
      classColor: null,
      tournament_id: null,
      currency: null
    };
  },
  methods: {
    openTournament() {
      this.$router.navigate('/games/scores/?id=' + this.tournament_id, { reloadCurrent: true });
    },
    ucFirst(str) {
      if (!str)
        return str;
      return str[0].toUpperCase() + str.slice(1);
    },
    checkQuery(data) {
      var self = this;
      self.$setState({
        'commentary': data.commentary,
        'id': data.id,
        'status': data.status,
        'amount': Math.abs(data.amount),
        'percent': data.percent,
        'created_at': data.created_at,
        'tournament_id': data.tournament_id,
        'currency': data.type == 'points' ? '<i class="fas fa-star"></i>' : '\u20BD'
      });
    }
  },
  on: {
    pageInit: function () {
      this.checkQuery(this.$router.currentRoute.query);
    }
  },
  id: '3780d271bd',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    \u2116';
      r += Template7Helpers.js.call(ctx_1, '+this.id', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            <div class="block-title">Детали операции</div>\n            <div class="list no-hairlines-md">\n                <ul>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Сумма</div>\n                                <div class="item-after" style="display: inline-flex;align-items: center;">';
      r += Template7Helpers.js.call(ctx_1, '+this.amount', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += c(ctx_1.currency, ctx_1);
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Дата</div>\n                                <div class="item-after">';
      r += Template7Helpers.formatDate.call(ctx_1, ctx_1.created_at, {
        hash: { 'type': 'full' },
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.commentary != \'\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block-title">Комментарий</div>\n                <div class="block block-strong">\n                    <p class="commentary">';
          r += Template7Helpers.js.call(ctx_2, 'this.commentary', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p>\n                </div>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.tournament_id != \'\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="list">\n                    <ul>\n                        <li>\n                            <a @click="openTournament" class="item-link list-button button-tournament"\n                                href="javascript:void(0)">\n                                Перейти\n                            </a>\n                        </li>\n                    </ul>\n                </div>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-3780d271bd] .block-title {
        margin-top: 35px;
    }

[data-f7-3780d271bd] .button-tournament {
        color: #007aff;
    }

[data-f7-3780d271bd] .block + .list {
        margin-top: 35px;
    }

[data-f7-3780d271bd] .commentary {
        font-size: 15px;
        word-break: break-all;
        font-weight: 400;
        line-height: 21px;
    }

`,
  styleScoped: true
};
    
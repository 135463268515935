export default {
  data: function () {
    return { phone: null };
  },
  methods: {
    isCodeNumberValid(codeNumber) {
      var pattern = /^\[0-9\s\-\(\)]+$/;
      return codeNumber.search(pattern) !== -1;
    },
    checkQuery(data) {
      var self = this;
      self.$setState({ 'phone': data.phone });
    },
    confirmCode() {
      var self = this;
      self.$app.dialog.preloader('Ожидайте...');
      window.confirmationResult.confirm(self.$el.find('#code').val()).then(function (result) {
        self.sendServerVerificatedPhoneStatus().then(responce => {
          if (!self.$app.methods.isAuth()) {
            self.$app.dialog.close();
            self.$router.navigate('/auth/profile/verificated/phone/success/');
          } else {
            self.$app.dialog.close();
            self.$app.dialog.alert('Вы подтвердили ваш номер телефона', 'Успешно', function () {
              self.$router.navigate('/profile/');
            });
          }
        }).catch(error => {
          if (!self.$app.methods.isAuth()) {
            self.$app.dialog.close();
            self.$app.dialog.alert('Временно недоступно', 'Ошибка');
          } else {
            self.$app.dialog.close();
            self.$app.dialog.alert('Временно недоступно', 'Ошибка', function () {
              self.$router.navigate('/profile/');
            });
          }
        });
      }).catch(function (error) {
        self.$app.dialog.close();
        self.$app.dialog.alert('Вы ввели неверный код', 'Увы!');
      });
    },
    sendServerVerificatedPhoneStatus() {
      var self = this;
      return new Promise((resolve, reject) => {
        self.$root.api('/app/profile/verified/phone', {
          'phone_verified': true,
          'phone': self.phone
        }, 'PATCH', function (data, status, xhr) {
          if (data.status == 'error') {
            reject();
          }
          if (data.status == 'ok') {
            resolve();
          }
        }, true);
      });
    },
    updateCodeButtonUI(e) {
      if (e.target.value.length > 0) {
        this.$el.find('#confirmCode').removeClass('disabled');
      } else {
        this.$el.find('#confirmCode').addClass('disabled');
      }
    },
    back() {
      if (this.$app.methods.isAuth()) {
        this.$router.back({
          name: 'verificated.phone.step-one',
          reloadCurrent: true,
          ignoreCache: true,
          force: true
        });
      } else {
        this.$router.back({
          name: 'auth.verificated.phone.step-one',
          reloadCurrent: true,
          ignoreCache: true,
          force: true
        });
      }
    }
  },
  on: {
    pageInit: function () {
      this.checkQuery(this.$router.currentRoute.query);
    }
  },
  id: '7fa266a084',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left"></div>\n                <div class="title">\n                    Подтверждение телефона\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block-title">Код отправлен на ';
      r += c(ctx_1.phone, ctx_1);
      r += '</div>\n            <div class="list inline-labels no-hairlines-md">\n                <ul>\n                    <li class="item-content item-input">\n                        <div class="item-inner">\n                            <div class="item-input-wrap">\n                                <input id="code" type="text" @keyup="updateCodeButtonUI" pattern="[0-9]*" placeholder="Код из СМС" value="" novalidate>\n                            </div>\n                        </div>\n                        \n                    </li>\n                </ul>\n            </div>\n            <div class="button_send">\n                <button id ="confirmCode" @click="confirmCode" class="button button-fill button-large button--success button button-fill button-large disabled">\n                    Подтвердить\n                </button>\n            </div>\n            <div class="block text-align-center">\n                <a href="#" class="link" @click="back">Не пришел код?</a>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

[data-f7-7fa266a084] .pointer {
        cursor: pointer;
    }

[data-f7-7fa266a084] .list+.list {
        margin-top: 27px;
    }

[data-f7-7fa266a084] .fa-star--grey {
        width: 15px;
        height: 14.7px;
        background: #818E95;
    }

[data-f7-7fa266a084] .item--success {
        color: #32BF61 !important;
    }

    
[data-f7-7fa266a084] .black {
        color: black !important;
    }

[data-f7-7fa266a084] .ml-mini {
        margin-left: 0.2em;
    }

[data-f7-7fa266a084] .button--success {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
        border-radius: 4px;
    }

[data-f7-7fa266a084] .button--success:hover {
        background-color: #3fd06f;
    }


[data-f7-7fa266a084] .list+.button_send {
        margin-top: 35px;
    }

[data-f7-7fa266a084] .button_send {
        padding: 0 8px;
    }

[data-f7-7fa266a084] .block-title {
        margin-top: 35px;
    }

[data-f7-7fa266a084] .preloader {
        width: 24px;
        height: 20px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
        right: calc(var(--f7-safe-area-right) + 10px);
    }

[data-f7-7fa266a084] .item-inner--other_amount {
        justify-content: center;
    }

[data-f7-7fa266a084] .item-inner--other_amount>.item-title {
        color: #007AFF;
    }

[data-f7-7fa266a084] .page-content {
        background-color: unset;
    }

[data-f7-7fa266a084] .grecaptcha-badge {
        display: none !important;
    }


[data-f7-7fa266a084] #code-space + .button_send {
        margin-top: 35px;
    }

[data-f7-7fa266a084] .button + div {
        margin-top: 5px;
    }
[data-f7-7fa266a084] .grey-collor {
        background-color: grey;
    }
`,
  styleScoped: true
};
export default {
  data: function () {
    return {};
  },
  methods: {},
  on: {
    pageInit: function (page) {
    }
  },
  id: 'fd9aa619ff',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Связаться с нами\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            <div class="item-inner">\n                <div class="list inline-labels no-hairlines-md">\n                    <ul>\n                        <li class="item-content item-input">\n                            <div class="item-inner">\n                                <div class="item-title item-label"></div>\n                                <div class="item-input-wrap">\n                                    <div class="dialog-input-field input"><textarea class="dialog-textarea"></textarea></div>\n                                </div>\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .text-black .item-subtitle {
        color: black;
    }
    .toolbar {
        display: none;
    }
`,
  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      sum: null,
      type: null
    };
  },
  methods: {
    next() {
      var self = this;
      var messageError = self.type === 'mobile' ? 'Пожалуйста введите номер телефона' : 'Пожалуйста введите номер кошелька';
      var wallet = self.$el.find('input[name="wallet"]').val();
      if (wallet.length === 0) {
        self.$app.dialog.alert(messageError);
      } else {
        self.$router.navigate({
          name: 'withdrawal.main',
          query: {
            'type': self.type,
            'sum': self.sum,
            'wallet': wallet
          }
        });
      }
    },
    checkQuery(data) {
      var self = this;
      self.$setState({
        'sum': data.sum,
        'type': data.type
      });
    }
  },
  on: {
    pageInit: function () {
      this.checkQuery(this.$router.currentRoute.query);
    }
  },
  id: '1581c6918e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Способы вывода\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.type!=\'mobile\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block-title">Номер кошелька</div>\n                <div class="list no-hairlines-md">\n                    <ul>\n                        <li class="item-content item-input">\n                            <div class="item-inner">\n                                <div class="item-input-wrap">\n                                <input type="tel" pattern="[0-9]*" name="wallet" placeholder="Введите номер кошелька" novalidate>\n                                <span class="input-clear-button"></span>\n                                </div>\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block-title">Номер телефона</div>\n                <div class="list no-hairlines-md">\n                    <ul>\n                        <li class="item-content item-input">\n                            <div class="item-inner">\n                                <div class="item-input-wrap">\n                                <input type="number" name="wallet" placeholder="Введите номер телефона">\n                                <span class="input-clear-button"></span>\n                                </div>\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            <div class="button_send">\n                <a class="button button-fill button-large button--success button button-fill button-large button--success" @click="next"\n                    style="border-radius: 4px;">\n                    Далее\n                </a>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-1581c6918e] .list a {
        color: rgb(109, 109, 114);
    }

[data-f7-1581c6918e] .button--success {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
    }

[data-f7-1581c6918e] .block--f7-block-margin-vertical + .toolbar {
        margin-top: 35px;
    }
[data-f7-1581c6918e] .block-title {
        margin-top: 35px;
    }
[data-f7-1581c6918e] .list+.button_send {
            margin-top: 35px;
        }
[data-f7-1581c6918e] .button_send {
        padding: 0 8px;
    }
`,
  styleScoped: true
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {};
  },
  methods: {
    confirmCodeEl: function () {
      return this.$el.find('#input-confirm-code');
    },
    passwordEl: function () {
      return this.$el.find('#input-password');
    },
    passwordConfirmationEl: function () {
      return this.$el.find('#input-password-confirmation');
    },
    change: function () {
      if (this.confirmCodeEl().val() != '' && this.passwordEl().val() != '' && this.passwordConfirmationEl().val() != '' && this.passwordEl().val() == this.passwordConfirmationEl().val()) {
        this.$el.find('#button-next').removeClass('disabled');
      } else {
        this.$el.find('#button-next').addClass('disabled');
      }
    },
    setNextButtonLoading: function (loading) {
      if (loading) {
        this.$el.find('#button-next').addClass('disabled').html('<div class="preloader color-white">' + this.$app.utils.iosPreloaderContent + '</div>');
      } else {
        this.$el.find('#button-next').removeClass('disabled').html('Готово');
      }
    },
    success: function () {
      var self = this;
      var data = {
        email: this.$route.query.email,
        password: this.passwordEl().val(),
        password_confirmation: this.passwordConfirmationEl().val(),
        confirm_code: this.confirmCodeEl().val()
      };
      this.setNextButtonLoading(true);
      this.$root.api('app/reset/confirm', data, 'POST', function (data, status, xhr) {
        self.setNextButtonLoading(false);
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          self.$app.popup.close();
          self.$root.login(data.token, data.login_parameter);
        }
      }, false, {
        callback: function () {
          self.setNextButtonLoading(false);
        }
      });
    }
  },
  on: {
    pageInit: function () {
      this.change();
    }
  },
  id: 'b51720275d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    <i class="icon-step2-2"></i>\n                </div>\n            </div>\n        </div>\n        <div class="page-content bg-color-white remember">\n\n            <div class="list list-registration-input">\n                <ul>\n                    <li class="item-content item-input item-input-with-info">\n                        <div class="item-inner">\n                            <div class="item-title item-floating-label">Проверочный код</div>\n                            <div class="item-input-wrap">\n                                <input id="input-confirm-code" value="';
      r += Template7Helpers.escape.call(ctx_1, ctx_1.email, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" name="confirm_code" type="text" required validate @input="change">\n                            </div>\n                        </div>\n                    </li>\n\n                    <li class="item-content item-input item-input-with-info">\n                        <div class="item-inner">\n                            <div class="item-title item-floating-label">Новый пароль</div>\n                            <div class="item-input-wrap">\n                                <input id="input-password" name="password" type="password" required validate @input="change">\n                            </div>\n                        </div>\n                    </li>\n\n                    <li class="item-content item-input item-input-with-info">\n                        <div class="item-inner">\n                            <div class="item-title item-floating-label">Повторите пароль</div>\n                            <div class="item-input-wrap">\n                                <input id="input-password-confirmation" name="password_confirmation" type="password" required validate @input="change">\n                            </div>\n                        </div>\n                    </li>\n\n\n                    <li class="item-content item-input" style="visibility: hidden;min-height:30px;height: 0px; overflow: hidden;"></li>\n                </ul>\n            </div>\n\n\n            <div class="block">\n                <button class="button button-fill" id="button-next" @click="success">Готово</button>\n            </div>\n\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .remember .block-remember-image{
        margin-top: 70px;
    }
    .remember .block-remember-1{
        margin-top: 50px;
        font-size: 18px;
        text-align: center;
        color: #445058;
    }
    .remember .block-remember-2{
        font-size: 15px;
        text-align: center;
        color: #818E95;
        margin-bottom: 100px;
    }
    /*:root{
        --f7-navbar-bg-color: #EFEFF4;
        --f7-navbar-border-color: #EFEFF4;
        --f7-navbar-link-color: #445058;
        --f7-navbar-text-color: #445058;
    }*/
    .remember .list-registration-input {
        --f7-block-font-size: 18px;
        --f7-block-text-color: #445058;
        --f7-label-text-color: #007AFF;
        --f7-list-border-color: white;
    }

    .remember .list-registration-input li:last-child>.item-inner:after{
        display: block!important;
        height: 2px;
    }

    .remember .list-registration-input {
        --f7-block-font-size: 18px;
        --f7-block-text-color: #445058;
        --f7-label-text-color: #007AFF;
        --f7-list-border-color: white;
    }
    .remember .list {
        margin-left: 16px;
        margin-right: 16px;
    }
    .remember .icon-step2-2 {
        width: 48px;
        height: 22px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='22' viewBox='0 0 48 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='5' y='11' width='27' height='1' fill='%23445058'/%3E%3Ccircle cx='8' cy='11' r='7.5' fill='%23EFEFF4' stroke='%23445058'/%3E%3Ccircle cx='37' cy='11' r='10.5' fill='%23EFEFF4' stroke='%23445058'/%3E%3Cpath d='M33.2846 8.40479V8.41211H34.559V8.40479C34.559 7.15967 35.394 6.33203 36.6318 6.33203C37.7963 6.33203 38.6972 7.14502 38.6972 8.19971C38.6972 9.04932 38.3676 9.62061 37.0053 11.1001L33.3505 15.0918V16H40.1913V14.8135H35.2402V14.6963L37.7817 11.9644C39.5175 10.104 40.0155 9.24707 40.0155 8.14844C40.0155 6.47119 38.5654 5.18213 36.6684 5.18213C34.7055 5.18213 33.2846 6.52979 33.2846 8.40479Z' fill='%23445058'/%3E%3C/svg%3E%0A");
    }
    .remember #button-next {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
    }
`,
  styleScoped: false
};
    
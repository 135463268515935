
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      commentary: null,
      id: null,
      type: null,
      wallet: null,
      status: null,
      amount: null,
      percent: null,
      created_at: null,
      typeText: null,
      classColor: null
    };
  },
  methods: {
    ucFirst(str) {
      if (!str)
        return str;
      return str[0].toUpperCase() + str.slice(1);
    },
    checkQuery(data) {
      var self = this;
      self.$setState({
        'commentary': data.commentary,
        'id': data.id,
        'type': self.getType(data.type),
        'wallet': data.wallet,
        'status': data.status,
        'amount': data.amount,
        'percent': data.percent,
        'created_at': data.created_at,
        'typeText': self.getTypeText(data.type),
        'classColor': self.renderStatus(data.status)
      });
    },
    getType(type) {
      switch (type) {
      case 'Tel':
        var type = 'Мобильный телефон';
        break;
      case 'qiwi':
        var type = 'Qiwi кошелек';
        break;
      case 'yandex':
        var type = 'Яндекс кошелек';
        break;
      }
      return type;
    },
    getTypeText(type) {
      switch (type) {
      case 'Tel':
        var text = 'Номер телефона';
        break;
      case 'qiwi':
        var text = 'Номер кошелька';
        break;
      case 'yandex':
        var text = 'Номер кошелька';
        break;
      }
      return text;
    },
    renderStatus(element) {
      switch (element) {
      case 'Выполнено':
        var classColor = 'item--success';
        break;
      case 'Отклонено':
        var classColor = 'item--rejected';
        break;
      case 'В обработке':
        var classColor = 'item--warning';
        break;
      }
      return classColor;
    }
  },
  on: {
    pageInit: function () {
      this.checkQuery(this.$router.currentRoute.query);
    }
  },
  id: 'f7d0f51b17',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    \u2116';
      r += Template7Helpers.js.call(ctx_1, '+this.id', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            <div class="block-title">Детали операции</div>\n            <div class="list no-hairlines-md">\n                <ul>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Сумма вывода</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, '+this.amount', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\u20BD</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Комиссия</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, '+this.percent', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\u20BD</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Куда</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, 'this.type', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">';
      r += c(ctx_1.typeText, ctx_1);
      r += '</div>\n                                <div class="item-after">';
      r += Template7Helpers.js.call(ctx_1, 'this.wallet', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Создана</div>\n                                <div class="item-after">';
      r += Template7Helpers.formatDate.call(ctx_1, ctx_1.created_at, {
        hash: { 'type': 'full' },
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Статус</div>\n                                <div class="item-after ';
      r += c(ctx_1.classColor, ctx_1);
      r += '">';
      r += Template7Helpers.js.call(ctx_1, 'this.status', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                </ul>\n                ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.status == \'В обработке\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="block-footer">\n                        <p class="">Время исполнения заявки от 1 до 3 дней</p>\n                    </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.commentary != \'\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block-title">Комментарий администратора</div>\n                <div class="block block-strong">\n                    <p class="commentary">';
          r += Template7Helpers.js.call(ctx_2, 'this.commentary', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p>\n                </div>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-f7d0f51b17] .block-title {
        margin-top: 35px;
    }
[data-f7-f7d0f51b17] .item--success {
        fill:  #32BF61;
        color: #32BF61;
    }

[data-f7-f7d0f51b17] .item--rejected {
        fill: #C1272D;
        color: #C1272D;
    }
[data-f7-f7d0f51b17] .item--warning {
        fill: #FAB030;
        color: #FAB030;
    }

[data-f7-f7d0f51b17] .commentary {
        font-size: 15px;
        word-break: break-all;
        font-weight: 400;
        line-height: 21px;
    }

`,
  styleScoped: true
};
    
export default {
  data: function () {
    return {
      storeName: this.$app.device.android ? 'Google Play' : 'App Store',
      storeImage: this.$app.device.android ? 'download_android.svg' : 'download_ios.svg',
      downloadUrl: 'https://fanbattle.app.link/install'
    };
  },
  methods: {
    close: function () {
      this.$root.setSessionKey('app.offer.show', 1);
      this.$router.navigate('/lobby/', {
        reloadAll: true,
        animate: false
      });
    },
    download: function () {
      if (this.$app.device.cordova) {
        window.open(this.androidUrl, '_system');
      } else {
        window.location.href = this.downloadUrl;
      }
    }
  },
  on: {
    popupOpen: function () {
    },
    popupClose: function () {
      this.$(this.$app.navbar.getElByPage(this.$el)).parent().removeClass('no-hairline');
    }
  },
  id: '2ce8ec246d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="popup">\n        <div class="page">\n            <div class="navbar no-hairline">\n                <div class="navbar-inner" style="background-color: white;">\n                    <div class="title">\n                        <img src="./static/app/logo.svg" style="width: 100px;">\n                    </div>\n                    <div class="right">\n                        <a href="" @click="close" class="icon-only" style="width: 24px;"><img src="./static/close.svg"></a>\n                    </div>\n                </div>\n            </div>\n\n\n            <div class="page-content bg-color-white">\n\n                <div class="block text-align-center">\n                    <img src="./static/app/star_fill.svg">\n                    <img src="./static/app/star_fill.svg">\n                    <img src="./static/app/star_fill.svg">\n                    <img src="./static/app/star_fill.svg">\n                    <img src="./static/app/star.svg">\n                    <span style="color: #000000;opacity: 0.5;font-size: 12px; position: relative; top: -2px;left: 5px;">';
      r += c(ctx_1.storeName, ctx_1);
      r += '</span>\n                </div>\n\n                <div class="block text-align-center" style="margin-top: 20px;color: #000000;font-size: 23px; font-weight: bold;">\n                    Скачай официальное<br>\n                    мобильное приложение\n                </div>\n\n                <div class="" style="margin-top: 20px;">\n                    <img src="./static/app/main.png" style="width: 100%;">\n                </div>\n\n                <div class="block text-align-center">\n                    <a href="" @click="download"><img src="./static/app/';
      r += c(ctx_1.storeImage, ctx_1);
      r += '"></a>\n                </div>\n\n                <div class="block text-align-center">\n                    <a style="color: #000000;font-size: 15px;" href="" @click="close">Остаться на сайте</a>\n                </div>\n\n            </div>\n\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .toolbar {
        display: none;
    }

`,
  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      player: null,
      team: null,
      query: this.$route.query
    };
  },
  methods: {
    infoPTS: function (e) {
    },
    tryToTeam: function (e) {
    },
    addToTeam: function (id, key) {
    },
    close: function () {
      this.$app.popup.close();
    },
    load: function () {
      var self = this;
      this.$root.api('app/personal/player/' + self.$route.query.player_id, {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          console.log(data);
          self.$setState({ player: data.player });
        }
      }, true);
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      self.load();
    }
  },
  id: '8845a2b539',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page page-with-subnavbar">\n      <div class="navbar">\n          <div class="navbar-inner navbar-popup">\n              <div class="left">\n                  <a href="#" class="link back icon-only">\n                      <i class="icon icon-back"></i>\n                  </a>\n              </div>\n              <div class="title"></div>\n              <div class="md subnavbar" id="subnavbar-create-2">\n                  <div class="list list-create-1 no-chevron width-100 no-hairline no-border">\n                      <ul>\n                          ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.player, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                          ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.player, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                          <li class="bg-color-white">\n                              <div class="item-content list-full-content">\n                                  <div class="item-media">\n                                      <img src="';
              r += c(ctx_3.image, ctx_3);
              r += '" class="game-avatar">\n                                  </div>\n                                  <div class="item-inner">\n                                      <div class="width-100 min-width-0">\n                                          <div class="list-full-top display-flex justify-content-space-between align-content-center">\n                                              <div class="list-full-top-left">\n                                                  <span>';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>\n                                              </div>\n                                              <!-- <div class="list-full-top-right">\n                                                  ';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.salary.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.salary.currency, ctx_3);
              r += '\n                                              </div> -->\n                                          </div>\n\n                                          <div class="list-full-bottom display-flex justify-content-space-between">\n                                              <div class="list-full-bottom-left min-width-0">\n                                                  <div class="list-full-bottom-left-top">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.team, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                                  <div class="list-full-bottom-left-bottom">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.position, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                              </div>\n                                              <div class="list-full-bottom-center text-align-right display-flex" style="justify-content: flex-end;">\n                                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.pgp != \'0.00\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                  <div class="score-box">\n                                                    <div class="list-full-bottom-center-top">';
                  r += c(ctx_4.pgp, ctx_4);
                  r += '</div>\n                                                    <div class="list-full-bottom-center-bottom">\n                                                      <span class="param-wrapper"><span class="info-icon" @click="infoPTS"></span> <span>PGP</span></span>\n                                                    </div>\n                                                  </div>\n                                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.score_average != \'0.00\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                  <div class="score-box">\n                                                    <div class="list-full-bottom-center-top">';
                  r += c(ctx_4.score_average, ctx_4);
                  r += '</div>\n                                                    <div class="list-full-bottom-center-bottom">\n                                                      <span class="param-wrapper"><span class="info-icon" @click="infoPTS"></span> <span>PTS</span></span>\n                                                    </div>\n                                                  </div>\n                                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                              </div>\n                                          </div>\n                                      </div>\n\n\n                                  </div>\n                              </div>\n                          </li>\n                          ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                      </ul>\n                  </div>\n              </div>\n\n          </div>\n\n      </div>\n\n      <div class="toolbar toolbar-bottom" id="toolbar-create-2" style="display: flex;flex-direction: column;justify-content: center;">\n          <div class="toolbar-inner">\n              ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.team, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n              <div id="toolbar-bottom-top">Осталось ';
          r += Template7Helpers.formatPrice.call(ctx_2, ctx_2.team.balance.value, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.team.balance.currency, ctx_2);
          r += '</div>\n              <div id="toolbar-bottom-bottom">';
          r += Template7Helpers.formatPrice.call(ctx_2, ctx_2.team.average_salary.value, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.team.average_salary.currency, ctx_2);
          r += ' за открытую позицию (';
          r += c(ctx_2.team.open_position_count, ctx_2);
          r += ')</div>\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n          </div>\n      </div>\n\n      <div class="page-content" id="page-content-create-2">\n          ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.player, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n              ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.player.is_injured, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div style="background-color: #BA3C3C;padding: 5px 15px;position: relative; top: -5px; display: flex; justify-content: left; align-items: center;">\n                      <div>\n                      <img src="./static/injured.svg" style="width: 25px; height: 25px;">\n                      </div>\n                      <div style="padding-left: 10px;">\n                      <div style="font-size: 14px; color:white;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.player.injury_description, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                      <div style="font-size: 12px;color:#FFFFFF;">Игрок не сможет выйти на поле</div>\n                      </div>\n                  </div>\n              ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.player.stats.length', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.player.stats, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                      <div class="block">\n                          <div class="stat-block">\n                              <div class="stat-block-item min-width-0">\n                                  <span class="table-time">';
                  r += Template7Helpers.formatDate.call(ctx_4, ctx_4.match.started_at, {
                    hash: { 'type': 'date' },
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</span>\n                                  <span class="table-title">\n                                      ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.match.localteam == this.@root.player.team', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                          @ ';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.match.visitorteam, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                      ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                          @ ';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.match.localteam, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                      ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                  </span>\n                              </div>\n                              <div class="text-align-right stat-item-points">\n                                  <span class="table-points-title">очки</span>\n                                  <span class="table-points-value">';
                  r += c(ctx_4.score.total, ctx_4);
                  r += '</span>\n                              </div>\n                          </div>\n                      </div>\n                      <div class="block">\n                          <div class="scores-block">\n                              <ul>\n                                  ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.score.scores, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                  <li>\n                                      <div class="table-player-title">';
                      r += Template7Helpers.escape.call(ctx_5, data_5 && data_5.key, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</div>\n                                      <div class="table-player-value">';
                      r += c(ctx_5, ctx_5);
                      r += '</div>\n                                  </li>\n                                  ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                                  <li></li>\n                              </ul>\n                          </div>\n                      </div>\n                  ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n              ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div class="block block-empty-image text-align-center">\n                      <img src="./static/player-stat-empty.png">\n                  </div>\n                  <div class="block block-empty-row1 text-align-center">Статистики пока нет</div>\n                  <div class="block block-empty-row2 text-align-center">Тут мы скоро разместим статистику по предыдущим матчам игрока</div>\n              ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n          ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n              <div class="block block-strong text-align-center" key="preloader">\n                  <div class="preloader"></div>\n              </div>\n          ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n      </div>\n  </div>\n';
      return r;
    }(this);
  },
  style: `
  :root {

  }
  .stat-block{
      display:flex;
      justify-content: space-between;
  }
  .stat-block-item{
      white-space: nowrap;
      overflow: hidden;
      height: 1.3em;
      text-overflow: ellipsis;
  }
  .stat-item-points{
      width: 120px;
  }
  .block-empty-image{
      margin-top: 80px;
  }
  .block-empty-row1{
      font-size: 24px;
      color: #818E95;
  }
  .block-empty-row2{
      font-size: 16px;
      text-align: center;
      color: #ACB4BE;
  }
  .score-box + .score-box {
    margin-left: 8px;
  }

  .table-player-title{
      padding: 3px 4px;
      text-transform: uppercase;
      color: #818E95;
      height: 15px;
      border-bottom: 1px #CFD6DB solid;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  }
  .table-player-value{
      font-size: 14px;
      color: #445058;
      padding-right: 4px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  }

  .table-time{
      font-size: 11px;
      color: #818E95;
  }

  .table-title{
      font-size: 14px;
      color: #445058;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      height: 1.3em;
      text-overflow: ellipsis;
  }

  .table-points-title{
      color: #818E95;
      font-size: 11px;
      text-transform: uppercase;
  }

  .table-points-value{
      color: #445058;
      font-size: 14px;
      font-weight: bold;
  }

  #page-content-create-2{
      --f7-toolbar-height: 44px;
      --f7-table-collapsible-cell-padding: 0;
      --f7-table-cell-border-color: #CFD6DB;
      padding-top: 150px;
  }

  #subnavbar-create-2{
      --f7-subnavbar-height: 100px;
  }

  #page-content-create-2{
      --f7-subnavbar-height: 100px;
  }

  .list-full-bottom-left-top{
      font-size: 14px;
      color: #445058;
      white-space: nowrap;
      overflow: hidden;
      height: 1.3em;
      text-overflow: ellipsis;
  }
  .list-full-bottom-left-bottom{
      font-size: 11px;
      color: #818E95;
  }

  .list-full-bottom-center-top{
      font-size: 14px;
      color: #445058;
      font-weight: 500;
      text-transform:uppercase;
  }
  .list-full-bottom-center-bottom{
      font-size: 11px;
      color: #818E95;
      text-transform:uppercase;
      display: flex;
      align-items: center;
  }
  .list-full-bottom-center-bottom > img {
    margin-right: 2px;
  }

  .list-full-bottom-right-top{
      font-size: 14px;
      color: #445058;
      font-weight: 500;
      text-transform:uppercase;
  }
  .list-full-bottom-right-bottom{
      font-size: 11px;
      color: #818E95;
      text-transform:uppercase;
  }

  .list-full-content{
      height: 100px;
  }

  .list-full-top{
      height: 29px;
      flex-direction: row;


  }
  .list-full-bottom{
      height: 33px;

  }

  .list-full-top-left-badge{
      position: relative;
      top: 0;
      font-size: 13px;
      padding: 4px;
      text-transform: capitalize;
      color:#818E95;
      background-color: #F0F3F8;
  }

  .list-full-top-left{
      font-size: 15px;
      color: #007AFF;
      white-space: nowrap;
      overflow: hidden;
      height: 1.3em;
      text-overflow: ellipsis;
  }
  .list-full-top-left a {

  }
  .list-full-top-right{
      position: relative;
      top: 0;
      text-align: right;
      flex-shrink: 0;
  }

  .list-full-top-right{
      font-weight: bold;
      color: #445058;
  }

  .list-create-1.list{
      --f7-list-bg-color: F0F3F8;
      --f7-list-item-title-line-height: 100px;
      --f7-list-item-padding-vertical: 0;
  }
  .list-create-1.list .item-title{
      color: #445058;
  }

  #toolbar-create-2{
      /*height: 44px;*/
  }

  #toolbar-create-2 .toolbar-inner{
      flex-direction: column;
      justify-content: center;
      /*height: 44px;*/
  }
  #toolbar-bottom-top{
      height: 22px;
      text-align: center;
      color: #445058;
      font-weight: bold;
      font-size: 14px;
  }
  #toolbar-bottom-bottom{
      height: 18px;
      text-align: center;
      color: #445058;
      font-size: 12px;
  }

  #subnavbar-create-2{
      background-color: white;
  }

  #subnavbar-create-2 .subnavbar-inner{

  }
  #subnavbar-create-2 a, #subnavbar-create-2 .icon{

  }
  #subnavbar-create-2 .icon{

  }

  .scores-block {
  }
  .scores-block ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .scores-block li {
      flex: 1 0 50px;
      height: 50px;
      border: 1px solid #CFD6DB;
      margin-top: -1px;
      margin-right: -1px;
      background-color: white;
  }
  .scores-block li:empty {
      height: 0;
      border: none;
  }
  .scores-block,
  :before,
  :after {
      box-sizing: border-box;
  }
  .scores-block ul,
  :before,
  :after {
      box-sizing: border-box;
  }
  .scores-block li,
  :before,
  :after {
      box-sizing: border-box;
  }




`,
  styleScoped: false
};
    
export default {
  data: function () {
    return {
      api_url: '',
      apiBaseUrl: '',
      setNickname: null
    };
  },
  methods: {
    share() {
      alert(navigator.userAgent);
      alert(Navigator);
    },
    logout() {
      if (window.webkit) {
        window.webkit.messageHandlers.iosListener.postMessage('refresh');
      }
      this.$router.navigate({
        name: 'something-wrong',
        animate: 'f7-flip'
      });
    },
    remove_token() {
      localStorage.removeItem('token');
    },
    pay(id) {
      let self = this;
      if (window.webkit) {
        try {
          window.webkit.messageHandlers.iosListenerPurchases.postMessage(id);
        } catch (e) {
          this.$app.dialog.alert('Ошибка', '');
        }
      } else {
        try {
          store.order('product.16');
        } catch (e) {
          alert('Ошибка');
          alert(e);
        }
      }
    }
  },
  on: {
    pageInit: function () {
      this.$setState({
        api_url: this.$root.getDomain(),
        apiBaseUrl: this.$root.apiBaseUrl,
        setNickname: this.$app.methods.getCookie('setNickname')
      });
    }
  },
  id: '10e15b0dd0',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Техническая информация\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            <div class="block-title">Основные параметры</div>\n            <div class="list no-hairlines-md">\n                <ul>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Домен</div>\n                                <div class="item-after">';
      r += c(ctx_1.api_url, ctx_1);
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Адрес апи</div>\n                                <div class="item-after">';
      r += c(ctx_1.apiBaseUrl, ctx_1);
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li @click="pay(\'com.product.100\')">\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Продукт 1</div>\n                                <div class="item-after">com.product.100</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li @click="pay(\'com.product.200\')" @click="logout">\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Продукт 2</div>\n                                <div class="item-after">com.product.200</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li @click="pay(\'com.product.500\')">\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Продукт 3</div>\n                                <div class="item-after">com.product.500</div>\n                            </div>\n                        </div>\n                    </li>\n                    <li @click="pay(\'com.product.500\')">\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">setNickname</div>\n                                <div class="item-after">';
      r += c(ctx_1.setNickname, ctx_1);
      r += '</div>\n                            </div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n            <div class="block">\n                <button class="col button button-fill" @click="logout">REFRESH</button>\n            </div>\n            <div class="block">\n                <button class="col button button-fill" @click="remove_token">REMOVE TOKEN</button>\n            </div>\n            <div class="block">\n                <button class="col button button-fill" @click="share">SHARE</button>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-10e15b0dd0] .block-title {
        margin-top: 35px;
    }
`,
  styleScoped: true
};
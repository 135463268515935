
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      duel: null,
      timeout: null
    };
  },
  methods: {
    openPlayerInfo(id) {
      console.log(id);
      this.$router.navigate(`/player/info?player_id=${ id }`);
    },
    refresh: function () {
      var self = this;
      setTimeout(function () {
        self.$app.ptr.done(self.$el.find('.ptr-content'));
        self.load(true);
      }, 1400);
    },
    clearTimeout: function () {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
    },
    startTimeout: function () {
      var self = this;
      self.timeout = setTimeout(function () {
        self.load(true);
      }, 15000);
    },
    isLive: function () {
      if (this.duel !== null && this.duel.duel.status.value === 'live') {
        return true;
      } else {
        return false;
      }
    },
    updateDuel: function (duel) {
      const self = this;
      let isCompare = () => {
      };
      if (this.duel == null) {
        self.$setState({ duel: this.$app.utils.extend({}, duel) });
        return;
      }
      let interval = setInterval(() => {
        let isChange = false;
        let hasNew = false;
        [
          '1',
          '2'
        ].forEach(j => {
          const oldStat = this.duel.duel['player_' + j].stat;
          const newStat = duel.duel['player_' + j].stat;
          if (oldStat.total < newStat.total) {
            this.duel.duel['player_' + j].stat.total = (parseFloat(oldStat.total) + 0.1).toFixed(1);
            isChange = true;
          }
          for (let i = 0; i < newStat.scores.length; i++) {
            const isExist = oldStat.scores.find(e => e.title == newStat.scores[i].title);
            if (isExist === undefined) {
              hasNew = true;
            } else {
              let newScore = newStat.scores[i];
              let oldScore = oldStat.scores.find(e => e.title == newScore.title);
              if (oldScore.count < newScore.count) {
                oldScore.count++;
                isChange = true;
              }
              if (oldScore.total < newScore.total) {
                oldScore.total = (parseFloat(oldScore.total) + 0.1).toFixed(1);
                isChange = true;
              }
            }
          }
          if (isChange) {
            self.$setState({ duel: this.duel });
          }
        });
        if (!isChange) {
          if (hasNew) {
            self.$setState({ duel: duel });
          }
          clearInterval(interval);
        }
      }, 100);
    },
    back() {
      setTimeout(() => {
        this.$router.back();
      }, 0);
    },
    load: function (reload) {
      var self = this;
      self.clearTimeout();
      var errorOptions = {
        callback: function (xhr, status) {
          self.startTimeout();
        }
      };
      if (reload) {
        errorOptions.messageCallback = null;
      } else {
        self.$setState({ duel: null });
      }
      this.$root.api('app/duels/client/' + self.$route.query.id, {}, 'GET', function (data, status, xhr) {
        let duel = data.duels;
        duel.amount = data.duels.self.amount.value * 2;
        duel = self.$root.prepareDuel(duel);
        if (Array.isArray(duel.duel.player_1.stat) && duel.duel.player_1.stat.length == 0) {
          duel.duel.player_1.stat = null;
        }
        if (Array.isArray(duel.duel.player_2.stat) && duel.duel.player_2.stat.length == 0) {
          duel.duel.player_2.stat = null;
        }
        if (duel.duel.match_1.id == duel.duel.match_2.id) {
          duel.duel.matches = [duel.duel.match_1];
        } else {
          duel.duel.matches = [
            duel.duel.match_1,
            duel.duel.match_2
          ];
        }
        self.updateDuel(duel);
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
        }
      }, true, errorOptions);
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      setTimeout(function () {
        self.load(false);
      }, 300);
    },
    pageAfterIn: function () {
      console.log(document.querySelector('#view-profile').querySelector('.navbar').querySelector('.navbar-previous'));
      document.querySelector('#view-profile').querySelector('.navbar').querySelector('.navbar-previous').querySelector('.title').classList.remove('fade-in');
    },
    pageBeforeRemove: function () {
      this.clearTimeout();
    }
  },
  id: '7d972627f7',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-with-subnavbar">\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left">\n                    <a @click="back" class="link icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                    <!-- <a href="#" class="link back">\n                        <i class="icon icon-back icon-only"></i>\n                    </a> -->\n                </div>\n                <div class="title">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.duel, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'Дуэль #';
          r += c(ctx_2.duel.duel.id, ctx_2);
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '...';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.info, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="right">\n                        <a href="/tournament-popup/info/';
          r += c(ctx_2.info.id, ctx_2);
          r += '/" class="link icon-only"><i class="f7-icons">info_circle</i></a>\n                    </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n                <div class="subnavbar md subnavbar-menu" style="height: 64px;">\n                    <div class="subnavbar-inner ios" style="padding: 12px 14px;">\n                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.duel, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.with.call(ctx_2, ctx_2.duel, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <div class="row" style="width: 100%; align-items:center;">\n                                <div class="col-50 duel-item-bottom-left" style="height: 100%;display: flex;align-items: center;">\n                                    ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.left, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="duel-item-user" style="height: 100%; display: flex;align-items: center;min-width:0;">\n                                            <div class="duel-item-user-avatar" style="margin-right: 16px;"><img src="';
                  r += c(ctx_4.left.client.avatar, ctx_4);
                  r += '"></div>\n                                            <span class="';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.left.is_winner, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'icon-cup';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '" style="background-repeat: no-repeat;width: 20px;height: 20px;position: relative; top: 9px; left: -26px;"></span>\n                                            <div class="duel-user-info" style="margin-left: -22px;min-width:0;">\n                                                <a href="/profile/details/?id=';
                  r += c(ctx_4.left.client.id, ctx_4);
                  r += '" class="duel-item-user-name">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.left.client.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</a>\n                                                <div class="duel-item-user-amount" style="';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.left.is_winner, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'color: #32BF61;';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '">';
                  r += c(ctx_4.left.amount.value, ctx_4);
                  r += c(ctx_4.left.amount.currency, ctx_4);
                  r += '</div>\n                                            </div>\n                                        </div>\n                                    ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="duel-item-search" style="color: #445058;">Поиск соперника....</div>\n                                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </div>\n                                <div class="col-50 duel-item-bottom-right" style="height: 100%; display: flex; align-items: center; justify-content: flex-end;">\n                                    ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.right, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="duel-item-user" style="height: 100%; display: flex;align-items: center;justify-content: right; min-width:0;">\n                                            <div class="duel-user-info" style="min-width:0;margin-right: -22px;">\n                                                <a href="/profile/details/?id=';
                  r += c(ctx_4.right.client.id, ctx_4);
                  r += '" class="duel-item-user-name" style="text-align: right">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.right.client.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</a>\n                                                <div class="duel-item-user-amount" style="';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.right.is_winner, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'color: #32BF61;';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += 'justify-content: flex-end;">';
                  r += c(ctx_4.right.amount.value, ctx_4);
                  r += c(ctx_4.right.amount.currency, ctx_4);
                  r += '</div>\n                                            </div>\n                                            <span class="';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.right.is_winner, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'icon-cup';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '" style="background-repeat:no-repeat;width: 20px;height: 20px;position: relative; top: 9px; left: 26px;"></span>\n                                            <div class="duel-item-user-avatar" style="margin-left: 16px;"><img src="';
                  r += c(ctx_4.right.client.avatar, ctx_4);
                  r += '"></div>\n                                        </div>\n                                    ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="duel-item-search" style="color: #445058;">Поиск соперника....</div>\n                                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </div>\n                            </div>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="page-content ptr-content" style="padding-top: 112px;" @ptr:refresh="refresh">\n            <div class="ptr-preloader">\n                <div class="preloader"></div>\n                <div class="ptr-arrow"></div>\n            </div>\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.duel, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.with.call(ctx_2, ctx_2.duel, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                <div class="row no-gap" style="background-color: white; border-bottom: 1px solid #CFD6DB; padding-bottom: 10px;">\n                    <div class="col-50 duel-item-left" style="">\n                        <div class="duel-item-player" @click="openPlayerInfo(';
              r += c(ctx_3.duel.player_1.id, ctx_3);
              r += ')" style="padding-right: 4px;border-bottom: unset;border-right: unset;border-top: unset; padding-bottom: 0px;">\n                            <div class="duel-item-player-avatar">\n                                <img src="';
              r += c(ctx_3.duel.player_1.image, ctx_3);
              r += '">\n                            </div>\n                            <div class="duel-item-player-info">\n                                <div style="color: #007AFF;font-size: 15px;font-weight: 600;line-height: 95%; height: 2em; overflow:hidden;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.duel.player_1.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                <div class="duel-item-player-team"><span class="';
              r += Template7Helpers.js_if.call(ctx_3, 'this.duel.match_1.localteam == this.duel.player_1.team', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'text-font-weight-bold';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.duel.match_1.localteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>@<span class="';
              r += Template7Helpers.js_if.call(ctx_3, 'this.duel.match_1.visitorteam == this.duel.player_1.team', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'text-font-weight-bold';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.duel.match_1.visitorteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span></div>\n                                <div class="duel-item-player-time">';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.duel.match_1.started_at, {
                hash: { 'type': 'full' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                            </div>\n                        </div>\n\n                        ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.duel.player_1.stat, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.duel.player_1.stat, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                            <div class="game-player-points" style="text-align:left;padding: 0 15px 5px 15px;">\n                                <span class="game-player-points-main';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.is_self, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '-i';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' point-part-1">';
                      r += Template7Helpers.js.call(ctx_5, 'this.total.toString().split(\'.\')[0]', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                ';
                      r += Template7Helpers.js_if.call(ctx_5, 'typeof this.total.toString().split(\'.\')[1] !== \'undefined\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                .<span class="game-player-points-add';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.is_self, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '-i';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += ' point-part-2">';
                          r += Template7Helpers.js.call(ctx_6, 'this.total.toString().split(\'.\')[1]', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>\n                                ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            </div>\n\n                            <div style="padding: 0 15px 15px 20px;">\n                                ';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.scores, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.count != 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                    <div key="';
                              r += c(ctx_7.title, ctx_7);
                              r += '" style="height: 15px;line-height: 15px;display: flex;">\n                                        <span style="display: inline-block;color: #445058;font-size: 12px; min-width: 28px;" key="count-';
                              r += c(ctx_7.title, ctx_7);
                              r += '">';
                              r += c(ctx_7.total, ctx_7);
                              r += '</span>\n                                        <span data-new="';
                              r += c(ctx_7.new, ctx_7);
                              r += '" style="font-size: 12px;color: #818E95;">\n                                            ';
                              r += c(ctx_7.count, ctx_7);
                              r += ' ';
                              r += c(ctx_7.title, ctx_7);
                              r += '\n                                        </span>\n                                    </div>\n                                ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            </div>\n\n                        ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n                    </div>\n                    <div class="col-50 duel-item-right">\n                        <div class="duel-item-player" @click="openPlayerInfo(';
              r += c(ctx_3.duel.player_2.id, ctx_3);
              r += ')" style="border-top: unset;border-bottom: unset; padding-bottom: 0px; padding-left: 4px;">\n\n                            <div class="duel-item-player-info text-align-right">\n                                <div style="color: #007AFF;font-size: 15px;font-weight: 600;line-height: 15px; height: 30px; overflow:hidden;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.duel.player_2.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                <div class="duel-item-player-team"><span class="';
              r += Template7Helpers.js_if.call(ctx_3, 'this.duel.match_2.localteam == this.duel.player_2.team', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'text-font-weight-bold';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.duel.match_2.localteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>@<span class="';
              r += Template7Helpers.js_if.call(ctx_3, 'this.duel.match_2.visitorteam == this.duel.player_2.team', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'text-font-weight-bold';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.duel.match_2.visitorteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span></div>\n                                <div class="duel-item-player-time">';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.duel.match_2.started_at, {
                hash: { 'type': 'full' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                            </div>\n                            <div class="duel-item-player-avatar">\n                                <img src="';
              r += c(ctx_3.duel.player_2.image, ctx_3);
              r += '">\n                            </div>\n                        </div>\n\n                        ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.duel.player_2.stat, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.with.call(ctx_4, ctx_4.duel.player_2.stat, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                            <div class="game-player-points" style="text-align:right;padding: 0 15px 5px 15px;">\n                                <span class="game-player-points-main';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.is_self, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '-i';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' point-part-1">';
                      r += Template7Helpers.js.call(ctx_5, 'this.total.toString().split(\'.\')[0]', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                ';
                      r += Template7Helpers.js_if.call(ctx_5, 'typeof this.total.toString().split(\'.\')[1] !== \'undefined\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                .<span class="game-player-points-add';
                          r += Template7Helpers.if.call(ctx_6, ctx_6.is_self, {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '-i';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += ' point-part-2">';
                          r += Template7Helpers.js.call(ctx_6, 'this.total.toString().split(\'.\')[1]', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '</span>\n                                ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            </div>\n\n                            <div style="padding: 0 15px 15px 20px;">\n                                ';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.scores, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.count != 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                <div style="text-align: right;justify-content: flex-end; height: 15px;line-height: 15px;display: flex;" key="';
                              r += c(ctx_7.title, ctx_7);
                              r += '">\n                                    <span data-new="';
                              r += c(ctx_7.new, ctx_7);
                              r += '" style="font-size: 12px;color: #818E95;" data-key="c1-';
                              r += c(ctx_7.title, ctx_7);
                              r += '">\n                                        ';
                              r += c(ctx_7.count, ctx_7);
                              r += ' ';
                              r += c(ctx_7.title, ctx_7);
                              r += '\n                                    </span>\n                                    <span style="display: inline-block;color: #445058;font-size: 12px; min-width: 28px;" data-key="c2-';
                              r += c(ctx_7.title, ctx_7);
                              r += '">';
                              r += c(ctx_7.total, ctx_7);
                              r += '</span>\n                                </div>\n                                ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            </div>\n\n                        ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n\n                    </div>\n                </div>\n\n                <div class="list list-games">\n                    <ul>\n                        ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.duel.matches, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            <li class="item-content justify-content-space-between align-items-center">\n                                <div class="item-inner">\n                                    <div class="game-games-left">\n                                        ';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.localteam, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                    </div>\n                                    <div class="game-games-center">\n                                        <div class="game-games-center-top text-align-center">\n                                            ';
                  r += Template7Helpers.formatDate.call(ctx_4, ctx_4.started_at, {
                    hash: { 'type': 'date' },
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                        </div>\n                                        <div class="game-games-center-top">\n                                            ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.localteam_goals !== null', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                <span class="game-games-scores text-align-center">';
                      r += c(ctx_5.localteam_goals, ctx_5);
                      r += '</span>\n                                                <span class="game-games-scores text-align-center">:</span>\n                                                <span class="game-games-scores text-align-center">';
                      r += c(ctx_5.visitorteam_goals, ctx_5);
                      r += '</span>\n                                            ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                <span class="game-games-scores text-align-center">-</span>\n                                            ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                        </div>\n                                        <div class="game-games-center-bottom text-align-center">\n                                            ';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.current_state, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                        </div>\n                                    </div>\n                                    <div class="game-games-right">\n                                        ';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.visitorteam, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                    </div>\n                                </div>\n                            </li>\n                        ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                    </ul>\n                </div>\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n            ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.duel, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block block-strong text-align-center">\n                    <div class="preloader"></div>\n                </div>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

    .game-games-center{
        margin-left: 10px;
        margin-right: 10px;
        width:36%;
    }
    .game-games-center-top{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .game-games-center-top{
        position: relative;
        font-size: 11px;
        text-align: center;
        color: #99A8B1;
    }
    .list-games .item-content{
        padding-left:  calc(10px + var(--f7-safe-area-left));
        padding-right: calc(10px + var(--f7-safe-area-left));
    }

    .game-games-center-bottom{
        position: relative;
        /* top: -5px; */
        font-size: 11px;
        text-align: center;
        color: #99A8B1;
    }

    .game-games-scores{
        width: 28px;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        color: #445058;
    }
    .game-games-left{
        width:33%;
        font-size: 16px;
        text-align: center;
        color: #445058;
        line-height: 18px;
        overflow: hidden;
    }
    .game-games-right{
        width:33%;
        font-size: 16px;
        text-align: center;
        color: #445058;
        line-height: 18px;
        overflow: hidden;
    }
    .game-top-margin-scores{
        margin-left: 9px;
        margin-right: 23px;
    }
    .game-top-progress-marker-scores{
        text-align: left;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        height: 25px;
    }
    .game-top-progress-marker-scores .icon-marker{
        position: relative;
        top: 9px;
    }
    .game-top-progress-text-scores{
        font-size: 10px;
        color: #AAADB3;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        height: 12px;
    }
    .game-top-progress-text-scores>div>div{
        position: relative;
        left: -2px;
        top: 3px;
        width: 20px;
        text-align: left;
    }
    .game-top-progressbar-scores{
        background-color: #32BF61;
        border-radius: 12px;
        height: 8px;
        margin-left: 16px;
        margin-right: 16px;
    }
    .game-top-progressbar-scores>div {
        background-color: #E5E5E5;
        height: 8px;
        border-radius: 12px;

    }
    .game-top-progress-scores{
        width: 100%;

        height: 8px
    }
    .game-top-text-scores{
        width: 100%;
        margin-left: 30px;
        margin-right: 30px;
        justify-content: center;
        padding-top: 4px;
        text-align: center;
        display: flex;
        align-items: center;
        line-height: 16px;
        font-size: 14px;
        color: #445058;
        height: 38px
    }
    .game-top-text-scores .icon-cup {
        position: relative;
        top: -8px;
        margin-right: 5px;
    }
`,
  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      info: null,
      top: null,
      allEntries: null,
      selfEntries: null,
      matches: null,
      isTest: false,
      suffix: Math.floor(Math.random() * 999 + 1),
      timeout: null,
      vl: null
    };
  },
  methods: {
    refresh: function () {
      var self = this;
      setTimeout(function () {
        self.$app.ptr.done(self.$el.find('.ptr-content'));
        self.load(true);
      }, 1400);
    },
    destroyVl: function () {
      if (this.vl !== null) {
        this.vl.destroy();
      }
    },
    createVl: function () {
      var self = this;
      this.vl = this.$app.virtualList.create({
        el: '#all-entries',
        items: [],
        itemTemplate: '{{> game-view-player}}',
        height: 64,
        rowsBefore: 200,
        rowsAfter: 200
      });
    },
    clearTimeout: function () {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
    },
    animateCellMoving: function (data, entries) {
      var self = this;
      var promises = [];
      var indexTop = 0;
      var indexBottom = 0;
      var vlIndexFrom = self.vl.currentFromIndex;
      var vlIndexTo = self.vl.currentToIndex;
      for (var indexFrom in data) {
        var indexTo = data[indexFrom];
        var type = null;
        var duration = 1000;
        var liIndexFrom = null;
        var liIndexTo = null;
        if (indexFrom >= vlIndexFrom && indexTo <= vlIndexTo) {
          type = 'nothing';
          liIndexFrom = indexFrom;
          liIndexTo = indexTo;
        }
        if (indexFrom < vlIndexFrom && indexTo >= vlIndexFrom && indexTo <= vlIndexTo) {
          type = 'top.down';
        }
        if (indexFrom >= vlIndexFrom && indexFrom <= vlIndexTo && indexTo < vlIndexFrom) {
          type = 'top.up';
        }
        if (indexFrom > vlIndexTo && indexTo >= vlIndexFrom && indexTo <= vlIndexTo) {
          type = 'bottom.up';
        }
        if (indexFrom >= vlIndexFrom && indexFrom <= vlIndexTo && indexTo > vlIndexTo) {
          type = 'bottom.down';
        }
        if (type !== 'nothing')
          continue;
        if (type == 'top.down' || type == 'top.up') {
          var replaceIndex = vlIndexFrom + indexTop++;
          duration = 2000;
          if (type === 'top.up') {
            liIndexFrom = indexFrom;
            liIndexTo = replaceIndex;
          } else {
            liIndexFrom = replaceIndex;
            liIndexTo = indexTo;
            self.vl.replaceItem(liIndexFrom, self.$app.utils.extend({}, self.allEntries[liIndexTo]));
          }
        }
        if (type == 'bottom.down' || type == 'bottom.up') {
          var replaceIndex = vlIndexTo - indexBottom++;
          duration = 2000;
          if (type === 'bottom.down') {
            liIndexFrom = indexFrom;
            liIndexTo = replaceIndex;
          } else {
            liIndexFrom = replaceIndex;
            liIndexTo = indexTo;
            self.vl.replaceItem(replaceIndex, self.$app.utils.extend({}, self.allEntries[indexFrom]));
          }
        }
        promises.push(new Promise(function (resolve, reject) {
          self.$el.find('#all-entries li').eq(indexFrom).animate({ 'top': -1 * 64 * (liIndexFrom - liIndexTo) }, {
            duration: duration,
            easing: 'swing',
            begin: function (elements) {
              elements.eq(0).css('opacity', 0.8);
            },
            complete: function (elements) {
              elements.eq(0).css('opacity', 1);
              elements.eq(0).css('opacity', 1);
              resolve();
            }
          });
        }));
      }
      Promise.all(promises).then(function () {
        for (var indexFrom in data) {
          var indexTo = data[indexFrom];
          self.vl.replaceItem(indexTo, self.$app.utils.extend(entries[indexTo], {}));
        }
        self.allEntries = [];
        for (var i = 0; i < entries.length; i++) {
          self.allEntries.push(self.$app.utils.extend({}, entries[i]));
        }
      });
    },
    animateScoreChange: function (data) {
      var self = this;
      var promises = [];
      for (var indexFrom in data) {
        var oldScoreRaw = parseFloat(self.allEntries[indexFrom].score);
        var newScoreRaw = parseFloat(data[indexFrom]);
        promises.push(new Promise(function (resolve, reject) {
          var oldScore = oldScoreRaw;
          var newScore = newScoreRaw;
          var indexFromSave = indexFrom;
          var currentStep = oldScore > newScore ? -0.1 : 0.1;
          var direction = oldScore > newScore ? 'down' : 'up';
          var timerStep = 1200 / (Math.abs(oldScore - newScore) * 10);
          var current = oldScore;
          var elPart1 = self.$('#all-entries li').eq(indexFrom).find('.point-part-1');
          var elPart2 = self.$('#all-entries li').eq(indexFrom).find('.point-part-2');
          var timer = setInterval(function () {
            current = Math.round((current + currentStep) * 10) / 10;
            if (direction == 'up' && current >= newScore || direction == 'down' && current <= newScore) {
              var currentScore = self.$root.scoreSplit(newScore);
              elPart1.html(currentScore.main);
              elPart2.html(currentScore.add);
              clearInterval(timer);
              self.allEntries[indexFromSave].score = parseFloat(currentScore.main + '.' + currentScore.add).toFixed(1);
              resolve();
            } else {
              var currentScore = self.$root.scoreSplit(current);
              elPart1.html(currentScore.main);
              elPart2.html(currentScore.add);
            }
          }, timerStep);
        }));
      }
      return Promise.all(promises);
    },
    startTimeout: function () {
      var self = this;
      self.timeout = setTimeout(function () {
        self.load(true);
      }, 15000);
    },
    isLive: function () {
      if (this.info !== null && this.info.status === 'live') {
        return true;
      } else {
        return false;
      }
    },
    updateTop: function (top) {
      var self = this;
      if (top.text != this.top.text) {
        this.$('#top-pb-text').html(top.text);
      }
      if (top.position != this.top.position) {
        var left = parseInt(top.position * this.$('#top-pb-marker').parent().width() / 100);
        this.$('#top-pb-marker').animate({ 'left': left + 'px' }, { duration: 300 });
        this.$('#top-pb-text').animate({ 'left': left - 8 + 'px' }, { duration: 300 });
      }
      if (top.progress != this.top.progress) {
        var width = parseInt(top.progress * this.$('#top-pb-value').parent().width() / 100) + 'px';
        this.$('#top-pb-value').animate({ 'width': width }, { duration: 300 });
      }
      this.top = self.$app.utils.extend({}, top);
    },
    updateVl: function (entries) {
      var self = this;
      var cells = {};
      var cellsData = [];
      var scores = {};
      for (var i = 0; i < self.allEntries.length; i++) {
        var indexFrom = i;
        var indexTo = null;
        for (var j = 0; j < entries.length; ++j) {
          if (entries[j].uid == self.allEntries[i].uid) {
            indexTo = j;
            if (self.allEntries[indexFrom].score != entries[indexTo].score) {
              scores[indexFrom] = entries[indexTo].score;
            }
            if (indexFrom != indexTo) {
              cells[indexFrom] = indexTo;
            } else {
              if (entries[j].hash != self.allEntries[i].hash) {
                cellsData.push(indexFrom);
              }
            }
            break;
          }
        }
      }
      self.animateScoreChange(scores).then(function () {
        for (var i = 0; i < cellsData.length; i++) {
          var index = cellsData[i];
          self.allEntries[index] = self.$app.utils.extend({}, entries[index]);
          self.vl.replaceItem(index, self.allEntries[index]);
        }
        self.animateCellMoving(cells, entries);
      });
    },
    calculateSelfPrize(objs, selfID) {
      let prize = 0;
      objs.filter(el => {
        return el.id == selfID;
      }).map(el => {
        prize += el.prize.value;
      });
      return prize;
    },
    load: function (reload) {
      var self = this;
      self.clearTimeout();
      var errorOptions = {
        callback: function (xhr, status) {
          self.startTimeout();
        }
      };
      if (reload) {
        errorOptions.messageCallback = null;
      } else {
        self.destroyVl();
        self.$setState({
          info: null,
          top: null,
          allEntries: null,
          selfEntries: null,
          matches: null
        });
      }
      this.$root.api('app/client_tournaments/' + self.$route.query.id + '/entries', {}, 'GET', function (data, status, xhr) {
        try {
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Ошибка');
          } else {
            var info = {
              id: data.tournament.id,
              title: data.tournament.title,
              status: data.tournament.status,
              selfPrize: {
                is_prize: data.tournament.entries.self.is_prize,
                value: self.calculateSelfPrize(data.tournament.entries.all, data.tournament.entries.self.id),
                currency: data.tournament.entries.self.prize.currency
              }
            };
            var top = {
              position: data.tournament.entries.self.position_progress.current,
              progress: data.tournament.entries.self.position_progress.prize,
              text: data.tournament.entries.self.score
            };
            var matches = [];
            for (var i = 0; i < data.tournament.matches.length; i++) {
              matches.push(self.$app.utils.extend({}, data.tournament.matches[i]));
            }
            var allEntries = [];
            var selfEntries = [];
            for (var i = 0; i < data.tournament.entries.all.length; i++) {
              var entry = data.tournament.entries.all[i];
              entry['t_id'] = data.tournament.id;
              allEntries.push(self.$app.utils.extend({}, entry));
              if (entry.is_self) {
                selfEntries.push(self.$app.utils.extend({}, entry));
              }
            }
            if (self.info === null) {
              self.$setState({
                info: self.$app.utils.extend({}, info),
                top: self.$app.utils.extend({}, top),
                selfEntries: selfEntries,
                matches: matches
              });
              self.allEntries = [];
              self.allEntries.push(self.$app.utils.extend({}, allEntries[i]));
              self.createVl();
              self.vl.replaceAllItems(allEntries);
              self.startTimeout();
            } else {
              if (!self.isLive()) {
                self.$setState({
                  top: top,
                  selfEntries: selfEntries,
                  matches: matches
                });
              } else {
                self.updateTop(top);
                self.$setState({
                  selfEntries: selfEntries,
                  matches: matches
                });
              }
              self.updateVl(allEntries);
              if (self.isLive()) {
                self.startTimeout();
              }
            }
          }
        } catch (e) {
          alert(e);
        }
      }, true, errorOptions);
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      setTimeout(function () {
        self.load(false);
      }, 300);
    },
    pageBeforeRemove: function () {
      this.clearTimeout();
      this.destroyVl();
    }
  },
  id: '21199e9faf',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-with-subnavbar">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.info, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.info.title, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '...';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.info, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="right">\n                        <a href="/tournament-popup/info/';
          r += c(ctx_2.info.id, ctx_2);
          r += '/" class="link icon-only"><i class="f7-icons">info_circle</i></a>\n                    </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n                <div class="subnavbar md subnavbar-menu" style="flex-direction: column;height: 103px;">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.info, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.info.selfPrize.is_prize, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <div class="game-top-text-scores">\n                                <i class="icon-cup"></i>\n                                <span class="prize-title">\n                                    Поздравляем, вы выиграли! <br> ';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.info.selfPrize.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += c(ctx_3.info.selfPrize.currency, ctx_3);
              r += '\n                                </span>\n                            </div>\n                        ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <div class="game-top-progress-marker-scores">\n                                <div class="game-top-margin-scores">\n                                    <i class="icon-marker" id="top-pb-marker" style="left:';
              r += c(ctx_3.top.position, ctx_3);
              r += '%"></i>\n                                </div>\n                            </div>\n                            <div class="game-top-progress-scores">\n                                <div class="game-top-progressbar-scores"><div id="top-pb-value" style="width:';
              r += c(ctx_3.top.progress, ctx_3);
              r += '%;"></div></div>\n                            </div>\n                            <div class="game-top-progress-text-scores">\n                                <div class="game-top-margin-scores">\n                                    <div id="top-pb-text" style="text-align:center;width: 30px;left:calc(';
              r += c(ctx_3.top.position, ctx_3);
              r += '% - 8px)">';
              r += c(ctx_3.top.text, ctx_3);
              r += '</div>\n                                </div>\n                            </div>\n                        ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n                    <div class="subnavbar-inner ios" style="height: 54px;padding-bottom: 0;">\n                        <div class="segmented">\n                            <a class="button tab-link tab-link-active" href="#tab-game-all-';
      r += c(ctx_1.suffix, ctx_1);
      r += '">Все входы</a>\n                            <a class="button tab-link" href="#tab-game-my-';
      r += c(ctx_1.suffix, ctx_1);
      r += '">Мои входы</a>\n                            <a class="button tab-link" href="#tab-game-games-';
      r += c(ctx_1.suffix, ctx_1);
      r += '">Игры</a>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="page-content ptr-content" style="padding-top: 148px;" @ptr:refresh="refresh">\n            <div class="ptr-preloader">\n                <div class="preloader"></div>\n                <div class="ptr-arrow"></div>\n            </div>\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.info, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="tabs">\n                    <div class="tab tab-active" id="tab-game-all-';
          r += c(ctx_2.suffix, ctx_2);
          r += '">\n                        <div id="all-entries" class="list no-chevron virtual-list"></div>\n                    </div>\n\n                    <div class="tab" id="tab-game-my-';
          r += c(ctx_2.suffix, ctx_2);
          r += '">\n                        <div class="list no-chevron">\n                            <ul>\n                                ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.selfEntries, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                    ';
              r += Template7Helpers._partial.call(ctx_3, 'game-view-player', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                            </ul>\n                        </div>\n                    </div>\n\n                    <div class="tab" id="tab-game-games-';
          r += c(ctx_2.suffix, ctx_2);
          r += '">\n                        <div class="list list-games">\n                                <ul>\n                                    ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.matches, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <li class="item-content justify-content-space-between align-items-center">\n                                            <div class="item-inner">\n                                                <div class="game-games-left">\n                                                    ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.localteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                                </div>\n                                                <div class="game-games-center">\n                                                    <div class="game-games-center-top text-align-center">\n                                                        ';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.started_at, {
                hash: { 'type': 'date' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                                    </div>\n                                                    <div class="game-games-center-top">\n                                                        ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.localteam_goals !== null', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                        <span class="game-games-scores text-align-center">';
                  r += c(ctx_4.localteam_goals, ctx_4);
                  r += '</span>\n                                                        <span class="game-games-scores text-align-center">:</span>\n                                                        <span class="game-games-scores text-align-center">';
                  r += c(ctx_4.visitorteam_goals, ctx_4);
                  r += '</span>\n                                                        ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                        <span class="game-games-scores text-align-center">-</span>\n                                                        ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                                    </div>\n                                                    <div class="game-games-center-bottom text-align-center">\n                                                        ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.current_state, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                                    </div>\n                                                </div>\n                                                <div class="game-games-right">\n                                                ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.visitorteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                            </div>\n                                        </li>\n                                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                </ul>\n                            </div>\n                    </div>\n                </div>\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block block-strong text-align-center">\n                    <div class="preloader"></div>\n                </div>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

    .game-games-center{
        margin-left: 10px;
        margin-right: 10px;
        width:36%;
    }
    .game-games-center-top{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .game-games-center-top{
        position: relative;
        font-size: 11px;
        text-align: center;
        color: #99A8B1;
    }
    .list-games .item-content{
        padding-left:  calc(10px + var(--f7-safe-area-left));
        padding-right: calc(10px + var(--f7-safe-area-left));
    }

    .game-games-center-bottom{
        position: relative;
        /* top: -5px; */
        font-size: 11px;
        text-align: center;
        color: #99A8B1;
    }

    .game-games-scores{
        width: 28px;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        color: #445058;
    }
    .game-games-left{
        width:33%;
        font-size: 16px;
        text-align: center;
        color: #445058;
        line-height: 18px;
        overflow: hidden;
    }
    .game-games-right{
        width:33%;
        font-size: 16px;
        text-align: center;
        color: #445058;
        line-height: 18px;
        overflow: hidden;
    }
    .game-top-margin-scores{
        margin-left: 9px;
        margin-right: 23px;
    }
    .game-top-progress-marker-scores{
        text-align: left;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        height: 25px;
    }
    .game-top-progress-marker-scores .icon-marker{
        position: relative;
        top: 9px;
    }
    .game-top-progress-text-scores{
        font-size: 10px;
        color: #AAADB3;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        height: 12px;
    }
    .game-top-progress-text-scores>div>div{
        position: relative;
        left: -2px;
        top: 3px;
        width: 20px;
        text-align: left;
    }
    .game-top-progressbar-scores{
        background-color: #32BF61;
        border-radius: 12px;
        height: 8px;
        margin-left: 16px;
        margin-right: 16px;
    }
    .game-top-progressbar-scores>div {
        background-color: #E5E5E5;
        height: 8px;
        border-radius: 12px;

    }
    .game-top-progress-scores{
        width: 100%;

        height: 8px
    }
    .game-top-text-scores{
        width: 100%;
        margin-left: 30px;
        margin-right: 30px;
        justify-content: center;
        padding-top: 4px;
        text-align: center;
        display: flex;
        align-items: center;
        line-height: 16px;
        font-size: 14px;
        color: #445058;
        height: 38px
    }
    .game-top-text-scores .icon-cup {
        position: relative;
        top: -8px;
        margin-right: 5px;
    }
`,
  styleScoped: false
};
    
export default {
  methods: {
    passwordChange(e) {
      const password = this.$el.find('#password')[0];
      if (password.value.length > 0) {
        this.$el.find('#button-submit')[0].classList.remove('disabled');
      } else {
        this.$el.find('#button-submit')[0].classList.add('disabled');
      }
    },
    setSubmitButtonLoading: function (loading) {
      if (loading) {
        this.$el.find('#button-submit').addClass('disabled').html('<div class="preloader color-white">' + this.$app.utils.iosPreloaderContent + '</div>');
      } else {
        this.$el.find('#button-submit').removeClass('disabled').html('Сохранить');
      }
    },
    submit() {
      const _this = this;
      const password = this.$el.find('#password')[0];
      const payload = { 'password': password.value };
      _this.setSubmitButtonLoading(true);
      _this.$root.api('app/profile/password/nocheck', payload, 'POST', function (data, status, xhr) {
        _this.setSubmitButtonLoading(false);
        if (data.status == 'error') {
          _this.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          _this.$app.dialog.alert('Вы успешно сменили пароль', 'Успешно', () => {
            _this.$router.back();
          });
        }
      }, true, {
        messageCallback: function (response) {
          _this.setSubmitButtonLoading(false);
          const responseJSON = JSON.parse(response);
          _this.$app.dialog.alert(responseJSON.message, 'Ошибка');
        }
      });
    }
  },
  id: 'bcc83fcc84',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page change-password-page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Установить пароль</div>\n                <div class="right"></div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            <div class="row">\n                <div class="title">Новый пароль</div>\n                <div class="list no-margin-vertical">\n                    <ul>\n                        <li class="item-content item-input">\n                            <div class="item-inner">\n                                <div class="item-input-wrap">\n                                    <input type="text" id="password" @input="passwordChange" name="password" placeholder="Введите новый пароль"/>\n                                    <span class="input-clear-button"></span>\n                                </div>\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n            </div>\n            <div class="btn-wrapper">\n                <button class="button button-fill disabled" id="button-submit" @click="submit">\n                    Сохранить\n                </button>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .change-password-page {

    }
    .change-password-page .row {
        display: flex;
        flex-direction: column;
    }
    .change-password-page .row .list {
        width: 100%;
    }
    .change-password-page .row .title {
        margin: 24px 16px 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #818E95;
    }
    .btn-wrapper {
        margin: 24px 16px 0px;
    }
    #button-submit {
        height: 44px;
        font-size: 17px;
        font-weight: 500;
        letter-spacing: -0.41px;
        line-height: 22px;
        background-color: #007AFF;
    }
`,
  styleScoped: false
};
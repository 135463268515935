export default {
  data: function () {
    return {};
  },
  methods: {
    onSignInSubmit() {
      let self = this;
      var phoneNumber = self.$el.find('#phone').val();
      var appVerifier = window.recaptchaVerifier;
      self.checkAccessPhone().then(() => {
        self.$app.data.firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier).then(function (confirmationResult) {
          window.confirmationResult = confirmationResult;
          self.$app.dialog.close();
          if (!self.$app.methods.isAuth()) {
            self.$router.navigate({
              name: 'auth.verificated.phone.step-two',
              query: { 'phone': phoneNumber }
            });
          } else {
            self.$router.navigate({
              name: 'verificated.phone.step-two',
              query: { 'phone': phoneNumber }
            });
          }
        }).catch(function (error) {
          if (error.code == 'auth/invalid-phone-number') {
            self.$app.dialog.close();
            self.$app.dialog.alert('Укажите действующий номер телефона', 'Неверный номер', function () {
              self.$router.refreshPage();
            });
          } else if (error.code == 'auth/too-many-requests') {
            self.$app.dialog.close();
            self.$app.dialog.alert('Вы превысили максимальное количество попыток', 'Попробуйте позже', function () {
              self.$router.refreshPage();
            });
          }
          grecaptcha.reset(window.recaptchaWidgetId);
        });
      }).catch(() => {
        self.$app.dialog.close();
        self.$app.dialog.alert('Указанный номер телефона уже был подтвержден', 'Номер занят', function () {
          self.$router.refreshPage();
        });
      });
    },
    openDialogLoader() {
      this.$app.dialog.preloader('Ожидайте...');
    },
    resetReCaptcha() {
      var self = this;
      if (typeof recaptchaVerifier !== 'undefined') {
        recaptchaVerifier.clear();
      }
    },
    checkAccessPhone() {
      var self = this;
      var phoneNumber = self.$el.find('#phone').val();
      return new Promise((resolve, reject) => {
        self.$root.api('/app/profile/verified/check-phone?phone=' + phoneNumber, {}, 'GET', function (data, status, xhr) {
          if (data.status == false) {
            reject();
          }
          if (data.status == true) {
            resolve();
          }
        }, true);
      });
    },
    isPhoneNumberValid(phoneNumber) {
      var pattern = /^\+[0-9\s\-\(\)]+$/;
      return phoneNumber.search(pattern) !== -1;
    },
    updatePhoneButtonUI(e) {
      if (this.isPhoneNumberValid(e.target.value)) {
        this.$el.find('#sendCode').removeClass('disabled');
      } else {
        this.$el.find('#sendCode').addClass('disabled');
      }
    },
    initReCaptcha() {
      let self = this;
      self.$el.find('#sendCode').addClass('disabled').html('<div class="preloader color-white">' + self.$app.utils.iosPreloaderContent + '</div>');
      window.confirmationResult = null;
      self.$app.data.firebase.auth().languageCode = 'ru';
      window.recaptchaVerifier = new self.$app.data.firebase.auth.RecaptchaVerifier('sendCode', {
        'size': 'invisible',
        'callback': function (response) {
          self.onSignInSubmit();
        },
        'expired-callback': function (error) {
        }
      });
      recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
        self.$el.find('#sendCode').removeClass('disabled').html('Отправить');
      });
    }
  },
  on: {
    pageBeforeIn: function (event, page) {
      if (!this.$app.methods.isAuth()) {
        this.$(this.$app.navbar.getElByPage(this.$el)).find('.item-back')[0].style.display = 'none';
        this.$('.toolbar')[0].style.display = 'none';
      }
    },
    pageInit: function () {
      let self = this;
      self.initReCaptcha();
    }
  },
  id: '580a47f561',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back item-back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Подтверждение телефона\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block-title">Укажите ваш номер телефона</div>\n            <div class="list inline-labels no-hairlines-md">\n                <ul>\n                    <li class="item-content item-input">\n                        <div class="item-inner">\n                            <div class="item-input-wrap">\n                                <input id="phone" type="tel" pattern="[0-9]*" @keyup="updatePhoneButtonUI" placeholder="Номер телефона" value="+7" novalidate>\n                            </div>\n                        </div>\n\n                    </li>\n                </ul>\n            </div>\n            <div class="block block-footer block--f7-block-margin-vertical" style="margin-bottom: 35px;">\n                На указанный номер телефона придет смс-код\n            </div>\n\n            <div class="button_send">\n                <button id="sendCode" @click="openDialogLoader" class="button button-fill button-large button--success button button-fill button-large">\n                    Отправить\n                </button>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

[data-f7-580a47f561] .pointer {
        cursor: pointer;
    }

[data-f7-580a47f561] .list+.list {
        margin-top: 27px;
    }

[data-f7-580a47f561] .fa-star--grey {
        width: 15px;
        height: 14.7px;
        background: #818E95;
    }

[data-f7-580a47f561] .item--success {
        color: #32BF61 !important;
    }


[data-f7-580a47f561] .black {
        color: black !important;
    }
[data-f7-580a47f561] body {
        display:none !important;
    }
[data-f7-580a47f561] .ml-mini {
        margin-left: 0.2em;
    }

[data-f7-580a47f561] .button--success {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
        border-radius: 4px;
    }

[data-f7-580a47f561] .button--success:hover {
        background-color: #3fd06f;
    }


[data-f7-580a47f561] .list+.button_send {
        margin-top: 35px;
    }

[data-f7-580a47f561] .button_send {
        padding: 0 8px;
    }

[data-f7-580a47f561] .block-title {
        margin-top: 35px;
    }

[data-f7-580a47f561] .preloader {
        width: 24px;
        height: 20px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
        right: calc(var(--f7-safe-area-right) + 10px);
    }

[data-f7-580a47f561] .item-inner--other_amount {
        justify-content: center;
    }

[data-f7-580a47f561] .item-inner--other_amount>.item-title {
        color: #007AFF;
    }

[data-f7-580a47f561] .page-content {
        background-color: unset;
    }

[data-f7-580a47f561] .grecaptcha-badge {
        display: none !important;
    }


[data-f7-580a47f561] #code-space + .button_send {
        margin-top: 35px;
    }

[data-f7-580a47f561] .button + div {
        margin-top: 5px;
    }
[data-f7-580a47f561] .grey-collor {
        background-color: grey;
    }

[data-f7-580a47f561] .preloader {
        right: unset !important;
    }
`,
  styleScoped: true
};
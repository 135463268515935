function HeaderBlockController(app) {
    this.app = app;
    this.fetchBanners = function() {
        console.log("fetchBanners");
    }
    this.fetchMiniGames = function() {
        return new Promise((resolve, reject) => {
            this.app.$root.api(`app/quiz/banners?skip=0&item=6`, {}, 'GET', function (data, status, xhr){
                if (data.status == 'error') {
                    reject(data);
                } else {
                    let banners = prepareBanners(data.banners);
                    resolve(banners);
                }
            }, true);
        });

        function prepareBanners(banners) {
            banners = banners.map((element) => {
                let prepareElem = {
                    localteamHighlight: element.localteam_highlight,
                    localteamName: element.localteam_name,
                    leagueName: element.league_name_ru,
                    matchId: element.match_id,
                    players: element.players,
                    prize: element.prize,
                    startedAt: handlerDate(element.started_at),
                    visitorteamHighlight: element.visitorteam_highlight,
                    visitorteamName: element.visitorteam_name,
                    hash: element.hash,
                };
                return prepareElem;
            });
            return banners;
        }

        function handlerDate(startedAt) {
            let date = new Date(startedAt);

            let day = date.getDate();
            if (day < 10) {
                day = '0' + day;
            }
            let month = date.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            } else {
                month = "" + month;
            }

            switch (month) {
                case "01":
                    month = "января";
                    break;
                case "02":
                    month = "февраля";
                    break;
                case "03":
                    month = "марта";
                    break;
                case "04":
                    month = "апреля";
                    break;
                case "05":
                    month = "мая";
                    break;
                case "06":
                    month = "июня";
                    break;
                case "07":
                    month = "июля";
                    break;
                case "08":
                    month = "августа";
                    break;
                case "09":
                    month = "сентября";
                    break;
                case "10":
                    month = "октября";
                    break;
                case "11":
                    month = "ноября";
                    break;
                case "12":
                    month = "декабря";
                    break;
            }

            // console.log(1, month);

            let hours = date.getHours();
            if (hours < 10) {
                hours = '0' + hours;
            }
            let minutes = date.getMinutes();
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            return `${day} ${month} ${hours}:${minutes}`;
        }
    }
}

export default HeaderBlockController;


      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      comingTournaments: null,
      finishedTournaments: null,
      countByRequest: 10,
      allowInfiniteComing: true,
      allowInfiniteFinished: true,
      networkError: false,
      liveTournaments: null,
      isRefresh: false
    };
  },
  methods: {
    showTabLive() {
      if (this.liveTournaments !== null) {
        return false;
      }
      this.fetchLiveTournaments();
    },
    showTabHistory() {
      this.load('finished');
    },
    refreshLiveTab(e, done) {
      var self = this;
      if (!this.liveTournaments)
        done();
      setTimeout(function () {
        self.fetchLiveTournaments(false);
        done();
      }, 1400);
    },
    refreshHistori(e, done) {
      var self = this;
      if (!this.finishedTournaments)
        done();
      self.isRefresh = true;
      setTimeout(function () {
        self.finishedTournaments = [];
        self.load('finished');
        done();
      }, 1400);
    },
    refresh: function (e, done) {
      var self = this;
      if (!this.comingTournaments)
        done();
      self.isRefresh = true;
      setTimeout(function () {
        self.load('coming');
        done();
      }, 1400);
    },
    destroyInfinite: function (type) {
      this.$app.infiniteScroll.destroy(this.$el.find('#tab-scores-' + type));
      this.$el.find('#tab-scores-' + type).find('.infinite-scroll-preloader').remove();
    },
    detectActiveTab() {
      const self = this;
      if (self.$app.data.isLive) {
        self.$app.tab.show(self.$el.find('#tab-scores-live'), true);
      } else {
      }
    },
    load: function (type, background) {
      var self = this;
      if (!self.isRefresh && type == 'coming') {
        self.detectActiveTab();
      }
      if (typeof background !== 'undefined' && background) {
        if (type == 'coming') {
          this.comingTournaments = null;
        }
        if (type == 'finished') {
          this.finishedTournaments = null;
        }
      }
      if (type == 'coming') {
        var skip = this.comingTournaments === null ? 0 : this.comingTournaments.length;
      }
      if (type == 'finished') {
        var skip = this.finishedTournaments === null ? 0 : this.finishedTournaments.length;
      }
      var url = 'app/client_tournaments?live=false&item=' + this.countByRequest + '&' + type + '=true&skip=' + skip;
      this.$root.api(url, {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          self.$root.updateLiveCount();
          if (self.comingTournaments === null)
            self.comingTournaments = [];
          if (data.coming) {
            data.coming.tournaments.map(t => {
              const showUpdate = t.status != 'nomorebets' && t.status != 'live' && t.status != 'finished';
              t.entries.map(e => {
                e.showUpdate = showUpdate;
              });
              t.type = 'tournament';
            });
            data.coming.duels.map(t => {
              t.type = 'duel';
            });
            self.$root.prepareDuels(data.coming.duels);
          }
          if (data.finished) {
            data.finished.tournaments.map(t => {
              t.type = 'tournament';
            });
            data.finished.duels.map(t => {
              t.type = 'duel';
            });
            self.$root.prepareDuels(data.finished.duels);
          }
          if (type == 'coming') {
            self.comingTournaments = self.comingTournaments.concat(data.coming.tournaments).concat(data.coming.duels).concat(data.coming.quiz);
            self.$setState({ comingTournaments: self.comingTournaments });
            if (data.coming.tournaments.concat(data.coming.duels).length < self.countByRequest) {
              self.destroyInfinite('coming');
            }
          }
          if (type == 'finished') {
            if (self.finishedTournaments === null)
              self.finishedTournaments = [];
            const finishedTournamens = self.finishedTournaments.concat(data.finished.tournaments).concat(data.finished.duels).concat(data.finished.quiz);
            self.$setState({ finishedTournaments: finishedTournamens });
            if (data.finished.tournaments.concat(data.finished.duels).length < self.countByRequest) {
              self.destroyInfinite('finished');
            }
          }
          self.$app.ptr.done(self.$el.find('#tab-scores-' + type));
          self.allowInfiniteComing = true;
          self.allowInfiniteFinished = true;
        }
        self.isRefresh = false;
      }, true);
    },
    fetchLiveTournaments() {
      const self = this;
      this.$root.api('app/client_tournaments?live=true', {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          self.$root.updateLiveCount();
          self.liveTournaments = [];
          for (var i = 0; i < data.live.tournaments.length; i++) {
            var live = data.live.tournaments[i];
            data.live.tournaments[i].type = 'tournament';
            self.liveTournaments.push(live);
          }
          data.live.duels.map(t => {
            t.type = 'duel';
          });
          self.$root.prepareDuels(data.live.duels);
          for (var i = 0; i < data.live.duels.length; i++) {
            var live = data.live.duels[i];
            self.liveTournaments.push(live);
          }
          self.$root.sortTournaments(self.liveTournaments);
          self.$setState({ liveTournaments: self.liveTournaments });
          if (self.liveTournaments.length > 0) {
            self.$root.setLiveBadge(self.liveTournaments.length);
          }
          self.$app.ptr.done(self.$el.find('.ptr-content'));
        }
      }, true);
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      self.$el.find('#tab-scores-coming').on('infinite', function () {
        if (!self.allowInfiniteComing)
          return;
        self.allowInfiniteComing = false;
        self.load('coming');
      });
      self.$el.find('#tab-scores-finished').on('infinite', function () {
        if (!self.allowInfiniteFinished)
          return;
        self.allowInfiniteFinished = false;
        self.load('finished');
      });
      if (this.$route.query.noload) {
        this.$root.removeNoLoadFromUrl(this.$router);
      } else {
        this.load('coming');
      }
    },
    pageBeforeRemove: function () {
      this.$el.find('#tab-scores-coming').off('infinite');
      this.$el.find('#tab-scores-finished').off('infinite');
    }
  },
  id: 'e61b7f80e1',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-with-subnavbar">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="title">\n                    Игры\n                </div>\n                <div class="subnavbar md subnavbar-menu"  style="height: 55px;">\n                    <div class="subnavbar-inner ios">\n                        <div class="segmented">\n                            <a class="button tab-link tab-link-active" href="#tab-scores-coming">Будущие</a>\n                            <a class="button tab-link" href="#tab-scores-live">Лайв</a>\n                            <a class="button tab-link" href="#tab-scores-finished">История</a>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n\n        <div class="tabs games">\n            <div class="tab page-content infinite-scroll-content ptr-content tab-active" @ptr:refresh="refresh" data-type="coming" id="tab-scores-coming" style="padding-top: 100px;">\n                ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="ptr-preloader">\n                        <div class="preloader"></div>\n                        <div class="ptr-arrow"></div>\n                    </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    ';
          r += Template7Helpers._partial.call(ctx_2, 'network-error', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.comingTournaments, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.comingTournaments, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.comingTournaments.length', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.comingTournaments, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.type==\'tournament\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                    ';
                          r += Template7Helpers._partial.call(ctx_6, 'game-item', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.type==\'duel\'', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                  ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.type==\'duel\'', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                    ';
                              r += Template7Helpers._partial.call(ctx_7, 'duel-item', {
                                hash: {},
                                data: data_7 || {},
                                fn: function empty() {
                                  return '';
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                  ';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                    ';
                              r += Template7Helpers._partial.call(ctx_7, 'profile-details-quiz', {
                                hash: {},
                                data: data_7 || {},
                                fn: function empty() {
                                  return '';
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                  ';
                              return r;
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                            <div class="preloader infinite-scroll-preloader"></div>\n                        ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            ';
                  r += Template7Helpers._partial.call(ctx_4, 'no-games', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                        ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                    ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div class="block text-align-center" key="preloader-1">\n                            <div class="preloader"></div>\n                        </div>\n                    ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n            <div class="tab page-content infinite-scroll-content ptr-content" @ptr:refresh="refreshLiveTab" data-type="live" id="tab-scores-live" style="padding-top: 100px;" @tab:show="showTabLive">\n              ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="ptr-preloader">\n                    <div class="preloader"></div>\n                    <div class="ptr-arrow"></div>\n                </div>\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n              ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers._partial.call(ctx_2, 'network-error', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.liveTournaments, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n              ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.liveTournaments, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.liveTournaments.length', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                        ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.liveTournaments, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                            ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.type==\'tournament\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                ';
                          r += Template7Helpers._partial.call(ctx_6, 'game-item', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                            ';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                ';
                          r += Template7Helpers._partial.call(ctx_6, 'duel-item', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                            ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                        ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                    ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                        <div class="block">\n                          <div class="live-empty">\n                            <div class="live-empty-image"><span class="image-no-live"></span></div>\n                            <div class="live-empty-top">Игры не начались</div>\n                            <div class="live-empty-bottom">События еще не начались</div>\n                          </div>\n                        </div>\n                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  <div class="block text-align-center">\n                    <div class="preloader"></div>\n                  </div>\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n            <div class="tab page-content infinite-scroll-content ptr-content" @ptr:refresh="refreshHistori" data-type="finished" id="tab-scores-finished" style="padding-top: 100px;" @tab:show="showTabHistory">\n                ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="ptr-preloader">\n                        <div class="preloader"></div>\n                        <div class="ptr-arrow"></div>\n                    </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    ';
          r += Template7Helpers._partial.call(ctx_2, 'network-error', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.finishedTournaments, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.finishedTournaments, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.finishedTournaments.length', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.finishedTournaments, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.type==\'tournament\'', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                    ';
                          r += Template7Helpers._partial.call(ctx_6, 'game-item', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.type==\'duel\'', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                  ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.type==\'duel\'', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                    ';
                              r += Template7Helpers._partial.call(ctx_7, 'duel-item', {
                                hash: {},
                                data: data_7 || {},
                                fn: function empty() {
                                  return '';
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                  ';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                    ';
                              r += Template7Helpers._partial.call(ctx_7, 'profile-details-quiz', {
                                hash: {},
                                data: data_7 || {},
                                fn: function empty() {
                                  return '';
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                  ';
                              return r;
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                            <div class="preloader infinite-scroll-preloader"></div>\n                        ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            ';
                  r += Template7Helpers._partial.call(ctx_4, 'no-finished-games', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                        ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                    ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div class="block text-align-center" key="preloader-2">\n                            <div class="preloader"></div>\n                        </div>\n                    ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n        </div>\n    </div>\n\n';
      return r;
    }(this);
  },
  style: `
  .games.tabs .scores-item-block {
    margin-top: 20px;
    height: 22px;
  }
  .games.tabs .active-game .active-game__header {
    background: #F0F3F8;
    color: #818E95;
    height: 42px;
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
    padding-right: 16px;
    padding-left: 16px;
    margin-top: 14px;
  }
  .games.tabs .active-game .active-game__content {
    padding: 16px;
    padding-left: 0;
  }
  .games.tabs .active-game .active-game__content-title {
    font-size: 15px;
    font-weight: 600;
    color: #007AFF;
    margin-bottom: 16px;
  }
  .games.tabs .active-game .active-game__content-item:last-child .active-game__content-item-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .games.tabs .active-game .active-game__content-item .active-game__content-item-title {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
  }
  .games.tabs .active-game .active-game__content-item .active-game__content-item-subtitle {
    font-size: 10px;
    color: #818E95;
    text-transform: uppercase;
  }
  .games.tabs .active-game .active-game__content-item {
    flex: 1 0 33%;
  }
    /* Таб лайв */
    .page-content .tab-scores-live {
      padding-top: 100px;
    }

    .scores-no-block{
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .scores-no-title{
        font-size: 24px;
        text-align: center;
        color: #818E95;
    }

    .scores-no-text{
        font-size: 16px;
        text-align: center;
        color: #ACB4BE;
    }

    .subnavbar-menu .button{
        font-size: 16px;
    }
    .subnavbar-menu .subnavbar-inner{
        padding-left: 0;
        padding-right: 0;
    }
    #tab-scores-coming .no-games-block {
      margin-top: 100px;
      /*height: calc(100vh - 54px - 72px - 44px - var(--f7-safe-area-top) - var(--f7-safe-area-bottom));*/
      /*display: flex;*/
      /*align-items: center;*/
      /*justify-content: center;*/
    }
    #tab-scores-live .live-empty {
      margin-top: 100px;
      /*height: calc(100vh - 54px - 72px - 44px - var(--f7-safe-area-top) - var(--f7-safe-area-bottom));*/
      /*display: flex;*/
      /*align-items: center;*/
      /*justify-content: center;*/
    }
    #tab-scores-finished .no-finished-games-block {
      margin-top: 100px;
      /*height: calc(100vh - 54px - 72px - 44px - var(--f7-safe-area-top) - var(--f7-safe-area-bottom));*/
      /*display: flex;*/
      /*align-items: center;*/
      /*justify-content: center;*/
    }
`,
  styleScoped: false
};
    
export default {
  data: function () {
    return {
      token: this.$root.getToken(),
      amount: 2000,
      isSmartSelectWithdreawal: false
    };
  },
  methods: {
    setButtonDisable: function (disabled) {
      if (!disabled) {
        this.$el.find('.button--success').removeAttr('disabled').removeClass('disabled');
      } else {
        this.$el.find('.button--success').attr('disabled', 'disabled').addClass('disabled');
      }
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      this.setButtonDisable(true);
      this.$el.find('.button--success').on('click', function (e) {
        self.$router.back('/profile/', { force: true });
      });
      self.$(document).on('page:afterin', (e, page) => {
        page.$el.find('#sum').focus();
      });
      self.$(document).find('#sum').on('keyup keydown change', function (e) {
        var value = self.$el.find('#sum').val();
        if (value.trim() == '' || value == '0') {
          self.setButtonDisable(true);
        } else {
          self.setButtonDisable(false);
          self.$setState({ amount: value });
        }
      });
    },
    pageBeforeRemove: function () {
      this.$el.find('.button--success').off('click');
    }
  },
  id: 'b5f9df0939',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    Пополнение баланса\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content">\n            <div class="block-title">Укажите свою сумму для пополнения</div>\n                <div class="list inline-labels no-hairlines-md">\n                  <ul>\n                    <li class="item-content item-input">\n                      <div class="item-inner">\n                        <div class="item-input-wrap">\n                          <input id="sum" type="tel" pattern="[0-9]*" placeholder="Введите сумму" value="" novalidate>\n                          <span class="input-clear-button"></span>\n                        </div>\n                      </div>\n                    </li>\n                    </ul>\n                </div>\n                <div class="button_send">\n                    <a class="link external button button-fill button-large button--success button button-fill button-large button--success"\n                        style="border-radius: 4px;"\n                        target="_blank"\n                        href="';
      r += c(ctx_1.$root.apiBaseUrl, ctx_1);
      r += 'app/balance/coins/deposit?amount=';
      r += c(ctx_1.amount, ctx_1);
      r += '&token=';
      r += c(ctx_1.token, ctx_1);
      r += '">\n                        Оплатить\n                    </a>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

[data-f7-b5f9df0939] .pointer {
            cursor: pointer;
        }

[data-f7-b5f9df0939] .list+.list {
            margin-top: 27px;
        }

[data-f7-b5f9df0939] .fa-star--grey {
            width: 15px;
            height: 14.7px;
            background: #818E95;
        }

[data-f7-b5f9df0939] .item--success {
            color: #32BF61 !important;
        }

[data-f7-b5f9df0939] .black {
            color: black !important;
        }

[data-f7-b5f9df0939] .ml-mini {
            margin-left: 0.2em;
        }

[data-f7-b5f9df0939] .button--success {
            height: 46px;
            background-color: #32BF61;
            font-size: 17px;
        }

[data-f7-b5f9df0939] .list+.button_send {
            margin-top: 35px;
        }

[data-f7-b5f9df0939] .button_send {
            padding: 0 8px;
        }

[data-f7-b5f9df0939] .block-title {
            margin-top: 35px;
        }

[data-f7-b5f9df0939] .preloader {
            width: 24px;
            height: 20px;
            position: absolute;
            top: 50%;
            margin-top: -11px;
            right: calc(var(--f7-safe-area-right) + 10px);
        }

[data-f7-b5f9df0939] .item-inner--other_amount {
            justify-content: center;
        }

[data-f7-b5f9df0939] .item-inner--other_amount>.item-title {
            color: #007AFF;
        }

[data-f7-b5f9df0939] .page-content {
            background-color: unset;
        }
`,
  styleScoped: true
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      history_operations: 0,
      loading: false,
      page: 1,
      count: null,
      lastItem: 0
    };
  },
  methods: {
    updateNavbarAfterHeight: function (value) {
      setTimeout(() => {
        document.documentElement.style.setProperty('--navbar-after-height', value);
      }, 100);
    },
    openCommentary(transaction_id) {
      var self = this;
      let transaction = self.history_operations.find(function (element) {
        return element.id == transaction_id;
      });
      let {id, tournament_id, commentary, type, scope, wallet, amount, percent, status, created_at, message = false} = transaction;
      if (scope == 'withdrawal') {
        self.$router.navigate({
          name: 'withdrawals.commentary',
          query: {
            'commentary': commentary,
            'id': id,
            'type': type,
            'wallet': wallet,
            'percent': self.getAmountPercent(amount.value, percent),
            'status': status,
            'amount': self.renderOriginalAmount(amount.value, percent),
            'created_at': created_at
          }
        });
        return 0;
      } else {
        self.$router.navigate({
          name: 'profile.history_operations.operation',
          query: {
            'commentary': commentary,
            'id': id,
            'type': type,
            'amount': amount.value,
            'created_at': created_at,
            'commentary': message,
            'tournament_id': tournament_id
          }
        });
      }
    },
    renderStatus(element) {
      switch (element.status) {
      case 'in-progress':
        element.status = 'В обработке';
        element.classColor = 'item--warning';
        break;
      case 'success':
        element.status = 'Выполнено';
        element.classColor = 'item--success';
        break;
      case 'rejected':
        element.status = 'Отклонено';
        element.classColor = 'item--rejected';
        break;
      }
      return element;
    },
    renderWallet(element) {
      if (element.wallet) {
        element.wallet = '**' + element.wallet.substr(element.wallet.length - 4);
      }
      return element;
    },
    renderType(element) {
      switch (element.type) {
      case 'mobile':
        element.type = 'Tel';
        break;
      }
      return element;
    },
    renderFullAmount(element) {
      let amount = parseInt(element.amount.value);
      let percent = element.percent;
      let amountPercent = percent / 100 * amount;
      amountPercent = parseInt(amountPercent.toFixed(0));
      let fullAmount = amount + amountPercent;
      element.amount.value = fullAmount;
      return element;
    },
    getAmountPercent(amount, percent) {
      let amountPercent = parseInt(amount) / (parseInt(percent) + 100);
      amountPercent = amountPercent.toFixed(0);
      return amountPercent;
    },
    renderOriginalAmount(amount, percent) {
      let amountPercent = parseInt(amount) / (parseInt(percent) + 100);
      amountPercent = amountPercent.toFixed(0);
      let originalAmount = parseInt(amount) - parseInt(amountPercent);
      return originalAmount;
    },
    renderMessage(element) {
      if (element.amount.value > 0) {
        element.coins = true;
        element.amount.value = '+' + element.amount.value;
        element.classColorAmount = 'item--success';
      }
      return element;
    },
    splitHistoryOperations(history_operations) {
      var self = this;
      history_operations.map(element => {
        if (element.type == 'coins' || element.type == 'bonuses' || element.type == 'points') {
          self.renderMessage(element);
        } else {
          self.renderFullAmount(element);
          self.renderStatus(element);
          self.renderWallet(element);
          self.renderType(element);
        }
      });
    },
    load: function () {
      var self = this;
      var ptrContent = self.$el.find('.ptr-content');
      self.getHistoryOperations();
    },
    fetchOperations(page) {
      var self = this;
      return new Promise((resolve, reject) => {
        self.$root.api('/app/profile/history-operations', {
          'page': page,
          'row': 10
        }, 'GET', function (data, status, xhr) {
          if (data.status == 'error') {
            reject();
          }
          if (data.status == 'ok') {
            self.page = self.page + 1;
            resolve(data);
          }
        }, true);
      });
    },
    getHistoryOperations() {
      var self = this;
      self.fetchOperations(self.page).then(response => {
        self.splitHistoryOperations(response.data);
        self.$setState({ history_operations: response.data });
        self.$el.find('.loader').css('display', 'block');
        var allowInfinite = true;
        self.lastItem = self.history_operations.length;
        self.count = response.count;
        if (self.lastItem >= self.count) {
          self.$app.infiniteScroll.destroy('.infinite-scroll-content');
          self.$el.find('.infinite-scroll-preloader').remove();
          return;
        }
        self.$el.find('.infinite-scroll-content').on('infinite', function () {
          if (!allowInfinite)
            return;
          allowInfinite = false;
          setTimeout(function () {
            allowInfinite = true;
            if (self.lastItem >= self.count) {
              self.$app.infiniteScroll.destroy('.infinite-scroll-content');
              self.$el.find('.infinite-scroll-preloader').remove();
              return;
            }
            self.fetchOperations(self.page).then(response => {
              let newArray = self.history_operations.concat(response.data);
              self.splitHistoryOperations(response.data);
              self.$setState({ history_operations: newArray });
              self.count = response.count;
              self.lastItem = self.history_operations.length;
              if (self.lastItem >= self.count) {
                self.$app.infiniteScroll.destroy('.infinite-scroll-content');
                self.$el.find('.infinite-scroll-preloader').remove();
                return;
              }
            });
          }, 1000);
        });
      }).catch(error => {
      });
    }
  },
  on: {
    pageBeforeOut: function () {
      this.updateNavbarAfterHeight('0px');
    },
    pageBeforeIn: function () {
      this.updateNavbarAfterHeight('1px');
    },
    pageInit: function () {
      var self = this;
      self.load();
      self.$el.find('.loader').css('display', 'none');
      var ptrContent = self.$el.find('.ptr-content');
      ptrContent.on('ptr:refresh', function (e) {
        self.page = 1;
        setTimeout(() => {
          self.getHistoryOperations();
          e.detail();
        }, 1400);
      });
    }
  },
  id: '1bee4a77ce',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                    История операций\n                </div>\n            </div>\n        </div>\n        <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="true">\n            <div class="ptr-preloader">\n                <div class="preloader"></div>\n                <div class="ptr-arrow"></div>\n            </div>\n            <div class="list">\n                <ul>\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.history_operations, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.history_operations, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.tournament_id, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                <li class="item-content pointer item-link" @click="openCommentary(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\')">\n                                    <div class="item-media">';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.amount.value>0 && this.coins===true', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<i class="icon icon-profile-push"></i>';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<i class="icon icon-profile-pull"></i>';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                    <div class="item-inner item-inner--not-after">\n                                        <div class="item-title">\n                                            <div>\n                                                <div class="item-header ellipsis">';
                  r += c(ctx_4.title, ctx_4);
                  r += '</div>\n                                                <div class="item-subtitle ellipsis">';
                  r += c(ctx_4.tournament_name, ctx_4);
                  r += '</div>\n                                            </div>\n                                            <div class="item-footer ellipsis">';
                  r += Template7Helpers.formatDate.call(ctx_4, ctx_4.created_at, {
                    hash: { 'type': 'full' },
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                        </div>\n                                        <div class="item-after">\n                                            <div class="item-amount ';
                  r += c(ctx_4.classColorAmount, ctx_4);
                  r += '">';
                  r += c(ctx_4.amount.value, ctx_4);
                  r += c(ctx_4.amount.currency, ctx_4);
                  r += '</div>\n                                            <div class=\'item-status ';
                  r += c(ctx_4.classColor, ctx_4);
                  r += '\'>';
                  r += c(ctx_4.status, ctx_4);
                  r += '</div>\n                                        </div>\n                                    </div>\n                                </li>\n                            ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                <li class="item-content pointer item-link" @click="openCommentary(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\')">\n                                    <div class="item-media">';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.amount.value>0 && this.coins===true', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<i class="icon icon-profile-push"></i>';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<i class="icon icon-profile-pull"></i>';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                    <div class="item-inner item-inner--not-after">\n                                        <div class="item-title">\n                                            <div class="ellipsis">\n                                                ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.scope == \'withdrawal\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    <div class="item-header ellipsis">Снятие с баланса</div>\n                                                    <div class="item-subtitle ellipsis">';
                      r += Template7Helpers.checkMessage.call(ctx_5, ctx_5.message, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</div>\n                                                ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    <div class="item-header ellipsis">';
                      r += Template7Helpers.checkMessage.call(ctx_5, ctx_5.title, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</div>\n                                                    <div class="item-subtitle ellipsis">';
                      r += c(ctx_5.message, ctx_5);
                      r += '</div>\n                                                ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                            </div>\n                                            <div class="item-footer">';
                  r += Template7Helpers.formatDate.call(ctx_4, ctx_4.created_at, {
                    hash: { 'type': 'full' },
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                        </div>\n                                        <div class="item-after">\n                                            <div class="item-amount ';
                  r += c(ctx_4.classColorAmount, ctx_4);
                  r += '">';
                  r += c(ctx_4.amount.value, ctx_4);
                  r += c(ctx_4.amount.currency, ctx_4);
                  r += '</div>\n                                            <div class=\'item-footer ';
                  r += c(ctx_4.classColor, ctx_4);
                  r += '\'>\n                                                ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.commentary', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    <svg style="\n                                                    vertical-align: middle;\n                                                    padding-bottom: 2px;\n                                                    " class="';
                      r += c(ctx_5.classColor, ctx_5);
                      r += '" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="15px" height="15px">\n                                                        <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"/>\n                                                    </svg>\n                                                ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                                ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.status', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    <div class="item-status">';
                      r += c(ctx_5.status, ctx_5);
                      r += '</div>\n                                                ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                            </div>\n                                        </div>\n                                    </div>\n                                </li>\n                            ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        <div class="block block-strong text-align-center">\n                            <div class="preloader"></div>\n                        </div>\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </ul>\n            </div>\n            <div class="preloader infinite-scroll-preloader loader"></div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-1bee4a77ce] .list .item-link .item-inner:before {
        content: none !important;
    }
[data-f7-1bee4a77ce] .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

[data-f7-1bee4a77ce] .pointer {
        cursor: pointer;
    }

[data-f7-1bee4a77ce] .block {
        margin-bottom: 0px;
    }

[data-f7-1bee4a77ce] .block + .block {
        margin-top: 0px;
        margin-bottom: 0px;
    }

[data-f7-1bee4a77ce] .item-inner--not-after::after {
        height: 0px !important;
    }

[data-f7-1bee4a77ce] .list .item-after {
        min-width: 19%;
    }

[data-f7-1bee4a77ce] .item-content::after {
        content: '';
        position: absolute;
        background-color: var(--f7-list-item-border-color);
        display: block;
        z-index: 15;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }


[data-f7-1bee4a77ce] .list .item-inner {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 15px;
    }
[data-f7-1bee4a77ce] .item-header {
        font-size: 17px;
        line-height: 23.8px;
        font-weight: 600;
        letter-spacing: normal;
        color: #000;
    }
[data-f7-1bee4a77ce] .item-subtitle {
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        letter-spacing: normal;
        color: #000;
    }
[data-f7-1bee4a77ce] .item-footer {
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        letter-spacing: normal;
        color: #00000073;
    }
[data-f7-1bee4a77ce] .item-amount {
        font-size: 17px;
        line-height: 22.8px;
        font-weight: 400;
        color: #000;
        display: inline-flex !important;
        align-items: center !important;
    }
[data-f7-1bee4a77ce] .item-status {
        display: inline-block;
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        letter-spacing: normal;
    }


[data-f7-1bee4a77ce] .item-header:first-letter {
        text-transform: uppercase;
    }

[data-f7-1bee4a77ce] .item-after {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }


[data-f7-1bee4a77ce] .item--warning {
        fill: #FAB030;
        color: #FAB030;
    }

[data-f7-1bee4a77ce] .item--success {
        fill:  #32BF61;
        color: #32BF61;
    }

[data-f7-1bee4a77ce] /*     .item--success .fa-star {
      height: 16px;
      width: 14px !important;
      background-image: url('/static/icons/2d-money-green.svg');
    } */

[data-f7-1bee4a77ce] .item--rejected {
        fill: #C1272D;
        color: #C1272D;
    }

`,
  styleScoped: true
};
    
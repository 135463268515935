export default {
  on: {
    pageBeforeIn: function (event, page) {
      this.$app.methods.setCookie('setNickname', false);
    }
  },
  methods: {
    setSubmitButtonLoading: function (loading) {
      if (loading) {
        this.$el.find('#button-submit').addClass('disabled').html('<div class="preloader color-white">' + this.$app.utils.iosPreloaderContent + '</div>');
      } else {
        this.$el.find('#button-submit').removeClass('disabled').html('Войти');
      }
    },
    close() {
      this.$app.popup.close();
    },
    cancel() {
      console.log(123);
    },
    changeNickname(name) {
      const _this = this;
      return new Promise((resolve, reject) => {
        this.$root.api('app/profile/name', { name }, 'POST', function (data, status, xhr) {
          if (data.status === 'error') {
            reject(data);
          }
          resolve(name);
        }, true);
      });
    },
    submit() {
      const _this = this;
      const nicknameInput = this.$el.find('#input-nickname')[0];
      if (nicknameInput.value.length === 0) {
        _this.$app.dialog.alert('Никнейм не может быть пустым');
        return;
      }
      _this.setSubmitButtonLoading(true);
      _this.changeNickname(nicknameInput.value).then(() => {
        _this.$app.data.events.emit('profile.update');
        _this.$app.popup.close();
      }).catch(data => {
        _this.$app.dialog.alert(data.message);
      }).finally(() => {
        _this.setSubmitButtonLoading(false);
      });
    }
  },
  id: '3621a67e30',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page change-nickname">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link icon-only" @click="close">\n                        <i class="icon icon-close"></i>\n                    </a>\n                </div>\n                <div class="title"></div>\n                <div class="right"></div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block no-margin-vertical">\n                <div class="row display-flex flex-direction-column">\n                    <span class="title">Как вас зовут?</span>\n                    <span class="subtitle">Ваше боевое имя, которое будет видно другим игрокам</span>\n                </div>\n                <div class="list no-hairline-top">\n                    <ul>\n                        <li class="item-content item-input">\n                            <div class="item-inner">\n                                <div class="item-title item-floating-label">Введите боевое имя</div>\n                                <div class="item-input-wrap">\n                                    <input type="text" id="input-nickname" name="nickname" />\n                                    <span class="input-clear-button"></span>\n                                </div>\n                            </div>\n                        </li>\n                    </ul>\n                    <span class="hint">Можно: английские буквы, цифры, символы (-, . , $), смайлы. Макс. 12 символов</span>\n                </div>\n                <button class="button button-fill" id="button-submit" @click="submit">\n                    Готово\n                </button>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    #view-change-nickname .navbar {
        background-color: #FFFFFF;
    }
    .change-nickname .hint {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.07px;
        color: #818E95;
        margin-top: 8px;
        display: block;
    }
    .change-nickname .page-content {
        background-color: #FFFFFF;
    }
    .change-nickname .row .title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 28px;
        color: #202122;
        margin-top: 24px;
    }
    .change-nickname .row .subtitle {
        margin-top: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: #445058;
    }
    .change-nickname .list {
        margin-top: 15px;
    }
    .change-nickname .list .item-input {
        padding-left: 0px;
    }
    #button-submit {
        height: 44px;
        font-size: 17px;
        font-weight: 500;
        letter-spacing: -0.41px;
        line-height: 22px;
        background-color: #007AFF;
        margin-top: 24px;
    }
`,
  styleScoped: false
};
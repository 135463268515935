
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      profileID: null,
      profile: {
        avatar: { url: '' },
        name: '',
        scores: 0,
        place: 0
      },
      refreshStatus: false,
      loading: false,
      games: null,
      quizPlayers: null,
      allowInfinite: true,
      countByRequest: 10,
      skeletonBlocks: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15
      ]
    };
  },
  methods: {
    updateNavbarAfterHeight: function (value) {
      setTimeout(() => {
        document.documentElement.style.setProperty('--navbar-after-height', value);
      }, 100);
    },
    refresh() {
      const self = this;
      this.$setState({
        games: null,
        refreshStatus: true
      });
      this.getMainDatas({}).then(() => {
        this.$app.ptr.done(this.$el.find('.page-content'));
        this.$setState({ refreshStatus: false });
      });
    },
    setMainProperties() {
      this.$setState({ profileID: this.$route.query.id });
    },
    getProfile() {
      const self = this;
      return new Promise((resolve, reject) => {
        this.$root.api('app/profile', {}, 'GET', function (data, status, xhr) {
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Ошибка');
            reject();
          } else {
            self.$setState({
              profile: {
                avatar: data.profile.avatar.url,
                points: data.profile.balance.points.value,
                name: data.profile.name
              }
            });
            resolve(data);
          }
        }, true);
      });
    },
    getMainDatas({initLoading = false, delay = 0}) {
      let self = this;
      if (initLoading) {
        self.$setState({ loading: true });
      }
      return Promise.all([
        self.getGamesHistory(),
        self.fetchProfile()
      ]).then(datas => {
        if (initLoading) {
          self.$setState({ loading: false });
        }
        if (datas[0].tournaments.concat(datas[0].duels).length < self.countByRequest) {
          self.destroyInfinite();
        }
        self.$el.find('.raiting-value').html(`${ self.formatInt(self.profile.total_exp) }`);
        self.$el.find('.place-value').html(`${ self.profile.total_percentage_of_wins }%`);
      }).catch(err => {
        console.error(err);
        self.$app.dialog.alert('Ошибка получения профиля', '', () => {
          self.$router.back();
        });
      }).finally(datas => {
      });
    },
    formatInt(price) {
      if (typeof price === 'function')
        price = price.call(this);
      var parts = price.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    },
    fetchProfile() {
      const self = this;
      return new Promise((resolve, reject) => {
        var url = `app/profile/${ self.profileID }`;
        self.$root.api(url, {}, 'GET', function (data, status, xhr) {
          if (data.status == 'error') {
            self.$app.dialog.alert('Ошибка получения профиля', '', () => {
              self.$router.back();
              reject();
            });
          } else {
            self.$setState({
              profile: {
                avatar: { url: data.profile.avatar.url },
                total_percentage_of_wins: data.profile.leaderboard.total_percentage_of_wins,
                total_exp: data.profile.leaderboard.total_exp,
                name: data.profile.name
              }
            });
            resolve(data);
          }
        }, true);
      });
    },
    getGamesHistory() {
      return new Promise((resolve, reject) => {
        var self = this;
        var skip = self.games === null ? 0 : self.games.length;
        var url = `app/rating/client/${ self.profileID }/history?live=false&item=${ self.countByRequest }&finished=true&skip=${ skip }`;
        self.$root.api(url, {}, 'GET', function (data, status, xhr) {
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Ошибка');
          }
          if (data.status == 'ok') {
            try {
              data.tournaments.map(t => {
                t.type = 'tournament';
              });
              data.duels.map(t => {
                t.type = 'duel';
              });
              data.quiz_players.map(t => {
                t.type = 'quiz_players';
              });
              data.quiz_players.forEach(e => {
                let playersExpected = e.players_expected;
                let playersPlaying = e.players_playing;
                playersExpected.forEach(p => {
                  let search = playersPlaying.find(f => f.id == p.id && f.position_code == p.position_code);
                  if (search)
                    p.guessed = true;
                  else
                    p.guessed = false;
                });
                let guessedCount = playersExpected.filter(p => p.guessed).length;
                e.guessedPlayers = guessedCount || '?';
              });
              self.$root.prepareDuels(data.duels);
              if (self.games === null)
                self.games = [];
              const finishedTournamens = self.games.concat(data.quiz_players).concat(data.tournaments).concat(data.duels);
              self.$setState({ games: finishedTournamens });
              self.allowInfinite = true;
              if (data.tournaments.concat(data.duels).length < self.countByRequest) {
                self.destroyInfinite();
              }
              resolve(data);
            } catch (err) {
            }
          }
        }, true);
      });
    },
    destroyInfinite: function () {
      this.$app.infiniteScroll.destroy(this.$el.find('.page-content'));
      this.$el.find('.page-content').find('.infinite-scroll-preloader').remove();
    }
  },
  on: {
    pageBeforeOut: function (event, page) {
      this.updateNavbarAfterHeight('1px');
    },
    pageBeforeIn: function (event, page) {
      this.updateNavbarAfterHeight('0px');
    },
    pageInit: function () {
      let self = this;
      self.setMainProperties();
      self.getMainDatas({ initLoading: true });
      self.$el.find('.page-content').on('infinite', function () {
        if (!self.allowInfinite)
          return;
        self.allowInfinite = false;
        self.getGamesHistory();
      });
      self.$el.find('.top-block')[0].style['margin-top'] = '44px';
      let last_known_scroll_position = 0;
      let ticking = false;
      let arrow = 'down';
      let controlPointer = 0;
      let currentScale = 0;
      let activeAnimate = true;
      let title = false;
      function doSomething(scrollPos, ticking) {
        if (scrollPos > controlPointer) {
          arrow = 'down';
        } else {
          arrow = 'up';
        }
        controlPointer = scrollPos;
        if (scrollPos <= 170) {
          if (arrow === 'down') {
            currentScale = -scrollPos;
          } else {
            currentScale = scrollPos;
          }
          if (ticking) {
            if (activeAnimate) {
              activeAnimate = false;
              if (scrollPos > 0) {
                if (currentScale < 0) {
                  if (self.$el.find('.top-block')[0].classList.contains('fade-in-top-profile-detail')) {
                    self.$el.find('.top-block')[0].classList.remove('fade-in-top-profile-detail');
                    self.$el.find('.page-content')[0].classList.remove('fade-in-top-profile-detail');
                  }
                  if (!self.$el.find('.top-block')[0].classList.contains('fade-out-top-profile-detail') || !self.$el.find('.top-block')[0].classList.contains('fade-in-top-profile-detail')) {
                    self.$el.find('.top-block')[0].classList.add('fade-out-top-profile-detail');
                  }
                  if (!self.$el.find('.page-content')[0].classList.contains('fade-out-top-profile-detail') || !self.$el.find('.page-content')[0].classList.contains('fade-in-top-profile-detail')) {
                    self.$el.find('.page-content')[0].classList.add('fade-out-top-profile-detail');
                  }
                  if (!title) {
                    const navbar = self.$app.navbar.getElByPage(self.$el);
                    const elem = navbar.querySelector('.title');
                    elem.classList.remove('fade');
                    title = true;
                  }
                } else {
                  if (self.$el.find('.top-block')[0].classList.contains('fade-out-top-profile-detail')) {
                    self.$el.find('.top-block')[0].classList.remove('fade-out-top-profile-detail');
                    self.$el.find('.page-content')[0].classList.remove('fade-out-top-profile-detail');
                  }
                  if (!self.$el.find('.top-block')[0].classList.contains('fade-out-top-profile-detail') || !self.$el.find('.top-block')[0].classList.contains('fade-in-top-profile-detail')) {
                    self.$el.find('.top-block')[0].classList.add('fade-in-top-profile-detail');
                  }
                  if (!self.$el.find('.page-content')[0].classList.contains('fade-out-top-profile-detail') || !self.$el.find('.page-content')[0].classList.contains('fade-in-top-profile-detail')) {
                    self.$el.find('.page-content')[0].classList.add('fade-in-top-profile-detail');
                  }
                  if (title) {
                    const navbar = self.$app.navbar.getElByPage(self.$el);
                    const elem = navbar.querySelector('.title');
                    elem.classList.add('fade');
                    elem.classList.remove('fade-in');
                  }
                  title = false;
                }
              }
            } else {
            }
          }
          setTimeout(() => {
            activeAnimate = true;
          }, 250);
        } else {
        }
      }
      self.$el.find('.page-content').on('scroll', function (e) {
        last_known_scroll_position = e.target.scrollTop;
        if (!ticking) {
          window.requestAnimationFrame(function () {
            if (!self.refreshStatus) {
              doSomething(last_known_scroll_position, ticking);
            }
            ticking = false;
          });
          ticking = true;
        }
      });
    },
    pageAfterOut: function () {
    }
  },
  id: '99bd2710d2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page profile-details" id="profile-details">\n      <div class="navbar navbar-transparent">\n          <div class="navbar-inner">\n              <div class="left">\n                  <a href="#" class="link back icon-only">\n                      <i class="icon icon-back"></i>\n                  </a>\n              </div>\n              ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n              ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.profile, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="title fade">';
              r += c(ctx_3.name, ctx_3);
              r += '</div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n          </div>\n      </div>\n      <div class="top-block">\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <div class="top-block-inner">\n              <div class="crow"></div>\n              <div class="crow">\n                <div class="raiting-wrap">\n                  <div class="skeleton-wrap">\n                    <div class="skeleton-block raiting-skeleton skeleton-effect-blink"></div>\n                  </div>\n                </div>\n                <div class="avatar-wrap">\n                  <div class="skeleton-block skeleton-effect-blink avatar" style="width: 95px; height: 95px; border-radius: 50%"></div>\n                </div>\n                <div class="place-wrap">\n                  <div class="skeleton-wrap">\n                    <div class="skeleton-block place-skeleton skeleton-effect-blink"></div>\n                  </div>\n                </div>\n              </div>\n              <div class="crow">\n                <div class="skeleton-wrap">\n                  <div class="skeleton-block login-skeleton skeleton-effect-blink"></div>\n                </div>\n              </div>\n            </div>\n        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n          ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.profile, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n              <div class="top-block-inner">\n                <div class="crow"></div>\n                <div class="crow">\n                  <div class="raiting-wrap fade-in">\n                    <span class="raiting-value"></span>\n                    <span class="raiting-text">Опыт</span>\n                  </div>\n                  <div class="avatar-wrap">\n                    <img class="avatar" src="';
              r += c(ctx_3.avatar.url, ctx_3);
              r += '" alt="" srcset="">\n                  </div>\n                  <div class="place-wrap">\n                    <span class="place-value"></span>\n                    <span class="place-text">Победы</span>\n                  </div>\n                </div>\n                <div class="crow">\n                  <span class="login">';
              r += c(ctx_3.name, ctx_3);
              r += '</span>\n                </div>\n              </div>\n          ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n        ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n      </div>\n      <div class="page-content infinite-scroll-content">\n        <div class="games-history">\n          ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.networkError, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n              ';
          r += Template7Helpers._partial.call(ctx_2, 'network-error', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n          ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.loading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.if.call(ctx_3, ctx_3.games, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n          ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.loading, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.skeletonBlocks, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                <div class="skeleton-card">\n                    <div class="header">\n                      <div class="title skeleton-effect-blink"></div>\n                    </div>\n                    <div class="body">\n                      <div class="content skeleton-effect-blink"></div>\n                    </div>\n                </div>\n            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n          ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.games, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n              ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.games, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                  ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.games.length', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                        ';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.games, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                            ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.type==\'tournament\'', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                ';
                              r += Template7Helpers._partial.call(ctx_7, 'profile-details-game-item', {
                                hash: {},
                                data: data_7 || {},
                                fn: function empty() {
                                  return '';
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.type==\'duel\'', {
                                hash: {},
                                data: data_7 || {},
                                fn: function empty() {
                                  return '';
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                              ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.type==\'duel\'', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                ';
                                  r += Template7Helpers._partial.call(ctx_8, 'duel-item', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                              ';
                                  return r;
                                },
                                inverse: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                ';
                                  r += Template7Helpers._partial.call(ctx_8, 'profile-details-quiz', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [
                                      ctx_7,
                                      ctx_6,
                                      ctx_5,
                                      ctx_4,
                                      ctx_3,
                                      ctx_2,
                                      ctx_1
                                    ]
                                  });
                                  r += '\n                              ';
                                  return r;
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                            ';
                              return r;
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                        ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                        <div class="preloader infinite-scroll-preloader"><span class="preloader-inner">\n                          <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span class="preloader-inner-line"></span>\n                        </span></div>\n                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                      ';
                      r += Template7Helpers._partial.call(ctx_5, 'no-finished-games', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n          ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n      ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n      </div>\n</div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-99bd2710d2] .active-game .active-game__header {
    background: #F0F3F8;
    color: #818E95;
    height: 42px;
    padding-top: 16px;
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
    padding-right: 16px;
    padding-left: 16px;
  }
[data-f7-99bd2710d2] .active-game .active-game__content {
    padding: 16px;
    padding-left: 0;
  }
[data-f7-99bd2710d2] .active-game .active-game__content-title {
    font-size: 15px;
    font-weight: 600;
    color: #007AFF;
    margin-bottom: 16px;
  }
[data-f7-99bd2710d2] .active-game .active-game__content-item:last-child .active-game__content-item-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
[data-f7-99bd2710d2] .active-game .active-game__content-item .active-game__content-item-title {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
  }
[data-f7-99bd2710d2] .active-game .active-game__content-item .active-game__content-item-subtitle {
    font-size: 10px;
    color: #818E95;
    text-transform: uppercase;
  }
[data-f7-99bd2710d2] .active-game .active-game__content-item {
    flex: 1 0 33%;
  }
[data-f7-99bd2710d2] .skeleton-card {
    background-color: white;
    height: 164px;
    width: 100%;
    margin-top: 26px;
  }
[data-f7-99bd2710d2] .skeleton-card .header {
    height: 30px;
    width: 40%;
    padding-left: 15px;
    display: flex;
    align-items: flex-end;
  }
[data-f7-99bd2710d2] .skeleton-card .header .title {
    background-color: #EFEFF4;
    width: 100%;
    height: 19px;
  }
[data-f7-99bd2710d2] .skeleton-card .body {
    height: calc(100% - 56px);
    background-color: white;
    padding: 15px;
  }
[data-f7-99bd2710d2] .skeleton-card .body .content {
    background-color: #EFEFF4;
    height: 100%;
    width: 100%;
  }
[data-f7-99bd2710d2] .page-content {
    margin-top: -44px;
  }
[data-f7-99bd2710d2] .top-block {
    z-index: 9999;
    position: relative;
    height: auto;
    background-color: var(--f7-theme-color);
    margin-top: 44px;
  }
[data-f7-99bd2710d2] .top-block-inner {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
[data-f7-99bd2710d2] .top-block-inner::after {
    content: '';
    position: absolute;
    background-color: var(--f7-bars-border-color);
    background-color: var(--f7-navbar-border-color, var(--f7-bars-border-color));
    display: block;
    z-index: 15;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform-origin: 50% 100%;
    transform: scaleY(calc(1 / 1));
    transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  }
[data-f7-99bd2710d2] .top-block-inner > .crow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
[data-f7-99bd2710d2] .top-block-inner .skeleton-block {
    background-color: var(--f7-skeleton-bg) !important;
  }
[data-f7-99bd2710d2] .top-block-inner > .crow:nth-child(1) {
  }
[data-f7-99bd2710d2] .top-block-inner > .crow:nth-child(2) {
  }
[data-f7-99bd2710d2] .top-block-inner > .crow:nth-child(3) {
    align-items: center;
    margin: 16px 0px;
  }
[data-f7-99bd2710d2] .avatar-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 50%;
  }
[data-f7-99bd2710d2] .avatar {
    height: 95px;
    width: 95px;
    border-radius: 50px;
  }
[data-f7-99bd2710d2] .login {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.35px;
    color: var(--f7-theme-text);
    width: 100%;
    text-align: center;
  }
[data-f7-99bd2710d2] .place-value, .raiting-value {
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.35px;
    color: var(--f7-theme-text);
    margin-bottom: 4px;
  }
[data-f7-99bd2710d2] .place-text, .raiting-text {
    font-weight: 300;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    color: var(--f7-theme-text-profile-details);
  }
[data-f7-99bd2710d2] .place-wrap, .raiting-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
[data-f7-99bd2710d2] .games-history {
    width:100%;
  }
[data-f7-99bd2710d2] .games-history .scores-item-block {
    align-items: center;
  }
[data-f7-99bd2710d2] .games-history .scores-item-block span {
    top: 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: #818E95;
  }
[data-f7-99bd2710d2] .games-history .scores-item-block {
    margin-top: 26px;
    margin-bottom: 10px;
    height: 19px;
  }
[data-f7-99bd2710d2] .games-history .scores-item-row1 {
    margin-bottom: 16px;
  }
[data-f7-99bd2710d2] .games-history .list-scores {
    margin-top: 7px;
  }
[data-f7-99bd2710d2] .games-history .item-inner {
    padding-top: 15px;
  }
[data-f7-99bd2710d2] .games-history .list-scores ul:before {
    background-color: #CFD6DB;
  }
[data-f7-99bd2710d2] .games-history .list-scores ul:after {
    background-color: #CFD6DB;
  }
[data-f7-99bd2710d2] .games-history .list-scores .scores-item-row1-title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #007AFF;
  }
[data-f7-99bd2710d2] .games-history .list-scores .game-item-col-top {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #445058;
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 2px;
    align-items: center;
  }

[data-f7-99bd2710d2] .games-history .list-scores .game-item-col-top .value {
    margin-left: 3px;
  }
[data-f7-99bd2710d2] .games-history .list-scores ul:after {
    content: none;
  }
[data-f7-99bd2710d2] .games-history .list-scores .game-item-col-bottom {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: #818E95;
  }
[data-f7-99bd2710d2] .games-history .list-scores .scores-item-row2 {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

[data-f7-99bd2710d2] .games-history .accordion-list ul:before {
    content: none;
  }
[data-f7-99bd2710d2] .games-history .accordion-list ul:after {
    background-color: #CFD6DB;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .item-inner {
    padding-top: 13px;
    padding-bottom: 13px;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .item-inner:before {
    color: #818E95;;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .item-title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #818E95;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .scores-item-row5 {
    padding-left: 0px;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .game-item-col-top {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #445058;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .game-item-col-bottom {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: #818E95;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .scores-item-row5-col3 {
    flex-grow: unset;
  }
[data-f7-99bd2710d2] .games-history .accordion-list .accordion-item-opened .accordion-item-content {
    padding-bottom: 2px;
  }
[data-f7-99bd2710d2] .skeleton-wrap {
    margin: 0 auto;
  }
[data-f7-99bd2710d2] .raiting-skeleton, .place-skeleton {
    height: 36px;
    width: 100px;
  }
[data-f7-99bd2710d2] .login-skeleton {
    height: 18px;
    width: 100px;
  }
`,
  styleScoped: true
};
    
export default {
  data: function () {
    return {};
  },
  methods: {},
  on: {
    popupOpen: function () {
      var self = this;
      let url = '/team/create-0/?id=' + self.$route.params.id + '&fromTour=' + self.$route.query.fromTour;
      if (this.$route.query.entry_id) {
        url += '&entry_id=' + this.$route.query.entry_id;
      }
      this.$app.views.create('#view-team-create', { url: url });
    },
    popupClosed: function () {
      this.$app.views.get('#view-team-create').destroy();
    }
  },
  id: '2e1235c47a',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="popup">\n        <div class="view" id="view-team-create"></div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

`,
  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      fieldImage: 'https://api.fanbattle.pro/img/football-field.png',
      loading: false,
      id: this.$route.query.id,
      entryId: this.$route.query.entry_id,
      clearDisable: this.$root.getTeam().length == 0,
      data: null,
      timer: {
        distance: null,
        interval: null
      },
      templateTeam: [
        {
          position: 'Нападающий',
          position_key: 'f1',
          position_type: 'A'
        },
        {
          position: 'Нападающий',
          position_key: 'f2',
          position_type: 'A'
        },
        {
          position: 'Полузащитник',
          position_key: 'm1',
          position_type: 'M'
        },
        {
          position: 'Полузащитник',
          position_key: 'm2',
          position_type: 'M'
        },
        {
          position: 'Полузащитник',
          position_key: 'm3',
          position_type: 'M'
        },
        {
          position: 'Защитник',
          position_key: 'd1',
          position_type: 'D'
        },
        {
          position: 'Защитник',
          position_key: 'd2',
          position_type: 'D'
        },
        {
          position: 'Вратарь',
          position_key: 'g1',
          position_type: 'G'
        }
      ]
    };
  },
  methods: {
    openPlayer(a, b, c, d, e, f) {
      console.log(a, b, c, d, e, f);
    },
    deletePlayer(id) {
      if (this.loading) {
        return false;
      }
      this.$setState({ loading: true });
      this.$app.preloader.show();
      let team = this.$root.getTeam();
      let player = team.find(el => el.player_id == id);
      let templateByPosition = this.templateTeam.find(el => el.position_key == player.position_key);
      let newTeam = team.filter(el => el.player_id != id);
      this.$root.setKey('team', JSON.stringify(newTeam));
      this.data.team.players = this.data.team.players.map(el => {
        if (el.id == id) {
          return templateByPosition;
        } else {
          return el;
        }
      });
      this.data.team.is_acceptable = false;
      this.load();
    },
    tabShow(e) {
      e.target.classList.add('fade-in');
      e.target.classList.remove('fade-out');
    },
    tabHide(e) {
      e.target.classList.remove('fade-in');
      e.target.classList.add('fade-out');
    },
    createRandomTeam() {
      var self = this;
      self.$app.dialog.confirm('Вы хотите собрать команду автоматически', 'Автозаполнение', function () {
        self.$app.dialog.preloader('Идет подбор игроков');
        setTimeout(function () {
          self.$root.api(self.entryId ? `app/client_tournaments/${ self.id }/entry/${ self.entryId }/random` : `app/tournaments/${ self.id }/team/random`, {}, 'GET', function (data, status, xhr) {
            if (data.status === 'error') {
              self.$app.dialog.close();
              self.$app.dialog.alert(data.message);
              return false;
            }
            data.team.players.map(el => {
              if (el.id) {
                const w = window.innerWidth;
                let lastName = el.lastname.slice(0, 11);
                if (w > 320 && w <= 375) {
                  lastName = el.lastname.slice(0, 13);
                }
                if (w > 375) {
                  lastName = el.lastname.slice(0, 15);
                }
                if (w >= 425) {
                  el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
                } else if (w >= 375 && w < 425) {
                  el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
                } else {
                  el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
                }
              }
            });
            self.$setState({ data });
            data.team.players.map(player => {
              self.$root.pushToTeam(player.id, player.position_key);
            });
            self.$app.dialog.close();
          }, true, {});
        }, 1000);
      });
    },
    importTeam() {
      var self = this;
      self.$app.dialog.confirm('Вы действительно хотите загрузить прошлый состав?', 'Повторить состав', function () {
        self.$root.clearTeam();
        self.$root.api(`app/tournaments/${ self.id }/team/clone`, {}, 'GET', function (data, status, xhr) {
          self.$app.dialog.preloader('Идет импорт игроков');
          setTimeout(() => {
            if (data.status == 'error') {
              self.$app.dialog.alert(data.message, 'Ошибка');
            } else {
              data.team.players.map(el => {
                if (el.id) {
                  const w = window.innerWidth;
                  let lastName = el.lastname.slice(0, 11);
                  if (w > 320 && w <= 375) {
                    lastName = el.lastname.slice(0, 13);
                  }
                  if (w > 375) {
                    lastName = el.lastname.slice(0, 15);
                  }
                  if (w >= 425) {
                    el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
                  } else if (w >= 375 && w < 425) {
                    el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
                  } else {
                    el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
                  }
                }
              });
              self.$setState({ data });
              data.team.players.map(player => {
                self.$root.pushToTeam(player.id, player.position_key);
              });
              self.$app.dialog.close();
            }
          }, 1000);
        }, true);
      });
    },
    toSelectCommandLead() {
      const data = JSON.stringify(this.data);
      this.$router.navigate({
        name: 'team-create-3',
        query: {
          'teamId': this.$route.query.id,
          'data': data
        }
      });
    },
    approve: function () {
      var self = this;
      if (this.entryId) {
        self.approveSend('coint', this.$root.getKey('fromTour') == '1');
        return;
      }
      if (this.$root.getKey('fromTour') == '1') {
        self.approveConfirm('coins', true);
        return;
      }
      var buttons = [];
      buttons.push({
        text: 'Выберите счет для списания',
        label: true
      });
      var oneAccount = null;
      for (let account in this.data.client.balance) {
        oneAccount = account;
        var data = this.data.client.balance[account];
        if (data.show) {
          if (self.$app.methods.getTypeApp() === 'olimp' && oneAccount == 'coins') {
            let currencyAmount = {
              value: parseInt(self.$root.params.profile.currency.rate * data.value),
              currency: self.$root.params.profile.currency.sign
            };
            let text = '';
            if (self.$root.params.profile.use_native_currency) {
              text = data.label + ': ' + currencyAmount.value + currencyAmount.currency + ' (' + data.value + data.currency + ')';
            } else {
              text = data.label + ': ' + data.value + data.currency + ' (' + currencyAmount.value + currencyAmount.currency + ')';
            }
            buttons.push({
              text: text,
              onClick: function (actions, e) {
                self.approveSend(account, false);
              }
            });
          } else {
            buttons.push({
              text: data.label + ': ' + data.value + data.currency,
              onClick: function (actions, e) {
                self.approveSend(account, false);
              }
            });
          }
          console.log(data.value);
        }
      }
      if (buttons.length == 2 || this.data.tournament.ingress.value == 0) {
        self.approveConfirm(oneAccount, false);
        return;
      }
      self.$app.actions.create({
        buttons: [
          buttons,
          [{
              text: 'Отмена',
              color: 'red'
            }]
        ]
      }).open();
    },
    approveConfirm: function (account, fromTour) {
      var self = this;
      console.log(1, account);
      self.$app.dialog.confirm('Создать команду?', 'Подтверждение', function () {
        self.approveSend(account, fromTour);
      });
    },
    approveSend: function (account, fromTour) {
      var self = this;
      self.$app.preloader.show();
      self.$root.api(self.entryId ? `app/client_tournaments/${ self.id }/entry/${ self.entryId }/confirm` : 'app/tournaments/' + self.$route.query.id + '/team/confirm', { account: account }, 'POST', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          clearInterval(self.timer.interval);
          if (self.$app.methods.getTypeApp() === 'olimp') {
            let can_entry_again = data.same_tournament.find(el => el.can_entry_again === true && el.sufficient_balance === true);
            if (typeof can_entry_again !== 'undefined') {
              self.$router.navigate({
                name: 'category_tournaments',
                query: {
                  'tournaments': JSON.stringify(data.same_tournament),
                  'team_hash': data.team_hash
                }
              });
            } else {
              self.closeAction();
              if (self.$app.views['games'].router.history.length == 1) {
                self.$app.views['games'].router.refreshPage();
              } else {
                self.$app.views['games'].router.navigate('/games/', { reloadAll: true });
              }
              self.$app.data.lastViewClick = 'games';
              self.$app.tab.show('#view-games');
              self.$app.dialog.alert('Вы успешно вошли в турнир со своей командой', 'Успешно!', function () {
                self.$root.events.emit('team-created');
              });
            }
          } else {
            self.closeAction();
            if (self.$app.views['games'].router.history.length == 1) {
              self.$app.views['games'].router.refreshPage();
            } else {
              self.$app.views['games'].router.navigate('/games/', { reloadAll: true });
            }
            self.$app.data.lastViewClick = 'games';
            self.$app.tab.show('#view-games');
            self.$app.dialog.alert('Вы успешно вошли в турнир со своей командой', 'Успешно!', function () {
              self.$root.events.emit('team-created');
            });
          }
        }
      }, true);
    },
    goTo2: function (url) {
      this.$router.navigate(url);
    },
    load: function () {
      var self = this;
      this.$root.api(self.entryId ? `app/client_tournaments/${ self.id }/entry/${ self.entryId }/team` : 'app/tournaments/' + self.$route.query.id + '/team', { team: this.$root.getTeam() }, 'POST', function (data, status, xhr) {
        if (data.status == 'error') {
          if (data.exception == 'teamBuild' && self.$root.getTeam().length) {
            self.$app.dialog.alert(data.message, 'Ошибка');
          } else {
            self.$app.dialog.alert(data.message, 'Ошибка');
          }
          self.closeAction();
          self.$setState({ loading: false });
          self.$app.preloader.hide();
        } else {
          data.team.players.map(el => {
            if (el.id) {
              const w = window.innerWidth;
              let lastName = el.lastname.slice(0, 11);
              if (w > 320 && w <= 375) {
                lastName = el.lastname.slice(0, 13);
              }
              if (w > 375) {
                lastName = el.lastname.slice(0, 15);
              }
              if (w >= 425) {
                el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
              } else if (w >= 375 && w < 425) {
                el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
              } else {
                el.custom_name = el.name.slice(0, 1).toUpperCase() + '. ' + lastName;
              }
            }
          });
          self.$setState({
            data: data,
            loading: false
          });
          self.updateData();
          if (self.$root.getKey('current.tour', 1) == 8) {
            self.$root.events.emit('team-create-0.load2');
          }
          self.$app.preloader.hide();
        }
      }, true);
    },
    updateData: function () {
      var self = this;
    },
    close: function () {
      var self = this;
      if (self.$app.views.current.router.currentRoute.name !== 'team_create_0') {
        return false;
      }
      if (this.$root.getTeam().length) {
        self.$app.dialog.confirm('Выйти из создания команды?', 'Подтверждение', function () {
          self.closeAction();
        });
      } else {
        self.closeAction();
      }
    },
    closeAction: function () {
      var self = this;
      self.$root.clearTeam();
      clearInterval(self.timer.interval);
      self.$root.events.emit('lobby.load');
      self.$app.popup.close();
    },
    updateTimer: function () {
      try {
        var hours = Math.floor(this.timer.distance / (1000 * 60 * 60));
        var minutes = Math.floor(this.timer.distance % (1000 * 60 * 60) / (1000 * 60));
        var seconds = Math.floor(this.timer.distance % (1000 * 60) / 1000);
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        var timerEl = this.$(this.$app.navbar.getElByPage(this.$router.currentPageEl)).find('#timer');
        timerEl.html(hours + ':' + minutes + ':' + seconds);
        this.timer.distance -= 1000;
        if (this.timer.distance < 0) {
          clearInterval(this.timer.interval);
        }
      } catch (e) {
        clearInterval(this.timer.interval);
      }
    }
  },
  on: {
    pageBeforeIn: function (e) {
      let self = this;
      let team = this.$root.getTeam();
      if (team.length > 0) {
        self.$app.preloader.show();
        self.load();
      }
    },
    pageInit: function () {
      let self = this;
      if (typeof this.$route.query.fromTour !== 'undefined') {
        this.$root.setKey('fromTour', this.$route.query.fromTour);
      }
      if (window.innerWidth == 320) {
        this.$setState({ fieldImage: 'https://api.fanbattle.pro/img/football-field__mini.png' });
      }
      var data = JSON.parse(this.$root.getKey('team.create'));
      if (data !== null) {
        this.$setState({ data: JSON.parse(this.$root.getKey('team.create')) });
        this.updateData();
        if (!!data.is_first_entry) {
          document.querySelector('.import-team').classList.add('disabled');
        }
        this.$root.setKey('team.create', null);
        if (this.$root.getKey('current.tour', 1) == 3) {
          this.$root.events.emit('team-create-0.load');
        }
      } else {
        this.load();
      }
    },
    pageBeforeRemove: function () {
      if (this.timer.interval !== null) {
        clearInterval(this.timer.interval);
      }
    }
  },
  id: '9a50e9c2a1',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-creation-team">\n        <div class="navbar">\n            <div class="navbar-inner navbar-popup">\n                <div class="left">\n                    <a href="#" class="link" @click="close">\n                        Закрыть\n                    </a>\n                </div>\n                <div class="right">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        <a href="/tournament/info/';
          r += c(ctx_2.id, ctx_2);
          r += '/?type=page" class="link icon-only"><i class="f7-icons tour-8">info_circle</i></a>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n                <div class="md subnavbar" id="subnavbar-create-0">\n                    <div class="subnavbar-inner">\n                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                            ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                <!--\n                                    <div class="row-top display-flex align-items-center justify-content-space-between width-100">\n                                        <span class="row-top-left"><i class="icon icon-sport-nba"></i>';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.tournament.started_at, {
                hash: { 'type': 'full' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>\n                                        <span class="row-top-right" id="timer"></span>\n                                    </div>\n                                -->\n                                <div class="row-bottom display-flex justify-content-space-between width-100">\n                                    <span class="row-bottom-left">\n                                        <span class="row-bottom-title">В игре</span> <span class="row-bottom-count">';
              r += c(ctx_3.tournament.inputs.current, ctx_3);
              r += '</span>\n                                    </span>\n                                    <span class="row-bottom-center">\n                                        <span class="row-bottom-title">Макс</span> <span class="row-bottom-count">';
              r += c(ctx_3.tournament.inputs.max, ctx_3);
              r += '</span>\n                                    </span>\n                                    <span class="row-bottom-right">\n                                        <span class="row-bottom-title">Вход</span> <span class="row-bottom-count">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.tournament.ingress.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += c(ctx_3.tournament.ingress.currency, ctx_3);
              r += '</span>\n                                    </span>\n                                </div>\n                                <div class="row-middle display-flex align-items-center justify-content-space-between width-100">\n                                    <span class="row-middle-left">\n                                        <div class="import-team color-link ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.is_first_entry, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'disabled';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '" @click="importTeam">\n                                          <div class="wrapper-icon square">\n                                            <i class="icon back-arrow"></i>\n                                          </div>\n                                        </div>\n                                    </span>\n                                    <span class="row-middle-center align-self-center">\n                                        <div class="row-middle-center-top text-align-center tour-3">Осталось ';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.team.balance.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.team.balance.currency, ctx_3);
              r += '</div>\n                                        <div class="row-middle-center-bottom text-align-center">\n                                            ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.team.open_position_count > 0, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                ';
                  r += Template7Helpers.js.call(ctx_4, 'parseInt(this.team.balance.value/this.team.open_position_count)', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += ' ';
                  r += c(ctx_4.team.average_salary.currency, ctx_4);
                  r += ' за открытую позицию (';
                  r += c(ctx_4.team.open_position_count, ctx_4);
                  r += ')\n                                            ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                Все позиции заняты\n                                            ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                        </div>\n                                    </span>\n                                    <span class="row-middle-right">\n                                        <div class="add-random-team color-link tour-5" @click="createRandomTeam">\n                                          <div class="wrapper-icon square">\n                                            <i class="icon thunder"></i>\n                                          </div>\n                                        </div>\n                                    </span>\n                                </div>\n                                <div id="tabbar-row">\n                                    <div class="toolbar tabbar">\n                                        <div class="toolbar-inner">\n                                            <div class="links-wrapper">\n                                                <a href="#create-tab-1" class="tab-link tab-link-active">На поле</a>\n                                                <a href="#create-tab-2" class="tab-link">Список</a>\n                                            </div>\n                                        </div>\n                                    </div>\n                                </div>\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </div>\n                </div>\n            </div>\n        </div>\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <div class="toolbar toolbar-bottom no-hairline" id="toolbar-team">\n                <div class="toolbar-inner">\n                    <button id="button-create" @click="toSelectCommandLead" class="button button-fill ';
              r += Template7Helpers.js_if.call(ctx_3, '!this.team.is_acceptable', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'disabled';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">Выбрать капитана</button>\n                </div>\n            </div>\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        <div class="page-content" id="page-content-create-0">\n            <div class="tabs">\n\n                <div class="tab tab-active create-tab-1 fade-in" @tab:show="tabShow" @tab:hide="tabHide" id="create-tab-1">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="field">\n                        <img src="';
          r += c(ctx_2.fieldImage, ctx_2);
          r += '" alt="">\n                            ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data.team.players, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'g1\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                      <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box one fade-in">\n                                                  <div class="player-bg--icon-svg"></div>\n                                                  <span class="position">ВР</span>\n                                      </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box one fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'d1\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                      <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box two fade-in" class="player-box two">\n                                              <div class="player-bg--icon-svg"></div>\n                                              <span class="position">ЗАЩ</span>\n                                      </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box two fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'d2\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                      <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box three fade-in" class="player-box three">\n                                                  <div class="player-bg--icon-svg"></div>\n                                                  <span class="position">ЗАЩ</span>\n                                      </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box three fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'m1\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                      <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box four fade-in">\n                                              <div class="player-bg--icon-svg"></div>\n                                              <span class="position">ПЗ</span>\n                                      </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box four fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'m2\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                      <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box five fade-in">\n                                              <div class="player-bg--icon-svg"></div>\n                                              <span class="position">ПЗ</span>\n                                      </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box five fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'m3\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                      <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box six fade-in">\n                                              <div class="player-bg--icon-svg"></div>\n                                              <span class="position">ПЗ</span>\n                                      </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box six fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'f1\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box seven fade-in">\n                                            <div class="player-bg--icon-svg"></div>\n                                            <span class="position">НАП</span>\n                                        </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box seven fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.position_key == \'f2\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.id === \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div @click="goTo2(\'/team/create-1/?id=';
                      r += c(ctx_2.id, ctx_5);
                      r += '&position_key=';
                      r += c(ctx_5.position_key, ctx_5);
                      r += '&position_type=';
                      r += c(ctx_5.position_type, ctx_5);
                      r += '&position=';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.position, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '&entry_id=';
                      r += c(root.entryId, ctx_5);
                      r += ')\'" class="player-box eight fade-in">\n                                            <div class="player-bg--icon-svg"></div>\n                                            <span class="position">НАП</span>\n                                        </div>\n                                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                        <div class="player-box eight fade-in">\n                                            <img src="';
                      r += c(ctx_5.image, ctx_5);
                      r += '" alt="">\n                                            <div class="player-info">\n                                                <span class="custom-name">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                <span class="team-abbr">';
                      r += c(ctx_5.team_abbr, ctx_5);
                      r += '<span class="salary"> ';
                      r += c(ctx_5.salary.value, ctx_5);
                      r += ' ';
                      r += c(ctx_5.salary.currency, ctx_5);
                      r += '</span></span>\n                                            </div>\n                                            <i @click="deletePlayer(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="icon minus-round--icon-svg "></i>\n                                        </div>\n                                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    </div>\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="text-align-center">\n                        <div class="preloader"></div>\n                    </div>\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n                <div class="tab create-tab-2" id="create-tab-2" @tab:show="tabShow" @tab:hide="tabHide">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        <div class="list list-create-0 no-chevron">\n                            <ul>\n                                ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data.team.players, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                    ';
              r += Template7Helpers.js_if.call(ctx_3, 'typeof this.id === \'undefined\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <li>\n                                            <a href="/team/create-1/?id=';
                  r += c(ctx_1.id, ctx_4);
                  r += '&position_key=';
                  r += c(ctx_4.position_key, ctx_4);
                  r += '&position_type=';
                  r += c(ctx_4.position_type, ctx_4);
                  r += '&position=';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.position, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '&entry_id=';
                  r += c(root.entryId, ctx_4);
                  r += '" class="item-link item-content">\n                                                <div class="item-inner">\n                                                    <div class="item-title">Позиция <span class="color-link">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.position, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</span></div>\n                                                    <div class="item-after"><i class="icon-plus tour-4"></i></div>\n                                                </div>\n                                            </a>\n                                        </li>\n                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.pgp != \'0.00\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, 'this.score_average != \'0.00\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <li class="bg-color-white">\n                                            <div class="item-content list-full-content">\n                                                <div class="item-media"><img src="';
                  r += c(ctx_4.image, ctx_4);
                  r += '" class="game-avatar"></div>\n                                                <div class="item-inner">\n                                                    <div class="width-100 min-width-0">\n                                                        <div class="list-full-top display-flex justify-content-space-between align-content-center">\n                                                            <div class="list-full-top-left">\n                                                                <a href="#">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</a>\n                                                            </div>\n                                                            <div class="list-full-top-right">\n                                                                ';
                  r += Template7Helpers.formatPrice.call(ctx_4, ctx_4.salary.value, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += ' ';
                  r += c(ctx_4.salary.currency, ctx_4);
                  r += '\n                                                            </div>\n                                                        </div>\n\n                                                        <div class="list-full-bottom display-flex justify-content-space-between">\n\n                                                            <div class="list-full-bottom-left min-width-0">\n                                                                <div class="list-full-bottom-left-top">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.team, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                                                <div class="list-full-bottom-left-bottom">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.position, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                                            </div>\n                                                            ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.pgp != \'0.00\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                                <div class="list-full-bottom-center text-align-right" style="margin-left: auto;">\n                                                                    <div class="list-full-bottom-center-top">';
                      r += c(ctx_5.pgp, ctx_5);
                      r += '</div>\n                                                                    <div class="list-full-bottom-center-bottom">PGP</div>\n                                                                </div>\n                                                            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\n                                                            ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.score_average != \'0.00\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                                <div class="list-full-bottom-center text-align-right">\n                                                                    <div class="list-full-bottom-center-top tour-6">';
                      r += c(ctx_5.score_average, ctx_5);
                      r += '</div>\n                                                                    <div class="list-full-bottom-center-bottom">PTS</div>\n                                                                </div>\n                                                            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\n                                                        </div>\n                                                    </div>\n\n                                                    <div class="item-after"><i @click="goTo2(\'/team/create-1/?replaceId=';
                  r += c(ctx_4.id, ctx_4);
                  r += '&id=';
                  r += c(ctx_1.id, ctx_4);
                  r += '&position_key=';
                  r += c(ctx_4.position_key, ctx_4);
                  r += '&position_type=';
                  r += c(ctx_4.position_type, ctx_4);
                  r += '&position=';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.position, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '&entry_id=';
                  r += c(root.entryId, ctx_4);
                  r += '&playerSalary=';
                  r += c(ctx_4.salary.value, ctx_4);
                  r += '\');" class="icon-change-player"></i></a></div>\n                                                </div>\n                                            </div>\n                                        </li>\n                                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                            </ul>\n                        </div>\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        <div class="block block-strong text-align-center">\n                            <div class="preloader"></div>\n                        </div>\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    :root{
        --f7-toolbar-height : 78px;
    }
    .icon-change-player{
        position: relative;
    }

    .icon-change-player::after {
        content: '';
        position: absolute;
        top: -300%;
        left: -300%;
        width: 300%;
        height: 300%;
    }

    .list-full-bottom-left-top{
        font-size: 14px;
        color: #445058;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }
    .list-full-bottom-left-bottom{
        font-size: 11px;
        color: #818E95;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .list-full-bottom-center {
        min-width: 46px;
    }

    .list-full-bottom-center-top{
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        text-transform:uppercase;
    }
    .list-full-bottom-center-bottom{
        font-size: 11px;
        color: #818E95;
        text-transform:uppercase;
    }

    .list-full-bottom-right-top{
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        text-transform:uppercase;
    }
    .list-full-bottom-right-bottom{
        font-size: 11px;
        color: #818E95;
        text-transform:uppercase;
    }

    .list-full-content{
        height: 100px;
    }

    .list-full-top{
        height: 29px;
        flex-direction: row;
        padding-right: 10px;

    }
    .list-full-bottom{
        height: 33px;
        padding-right: 10px;
    }

    .list-full-top-left-badge{
        position: relative;
        top: 0;
        font-size: 13px;
        padding: 4px;
        text-transform: capitalize;
        color:#818E95;
        background-color: #F0F3F8;
    }

    .list-full-top-left{
        font-size: 15px;
        color: #007AFF;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }
    .list-full-top-left a {

    }
    .list-full-top-right{
        position: relative;
        top: 0;
        text-align: right;
        flex-shrink: 0;
    }

    .list-full-top-right{
        font-weight: bold;
        color: #445058;
    }


    #toolbar-team{
        --f7-toolbar-bg-color: #F0F3F8;
        --f7-toolbar-height: 78px;
    }

    #button-create{
        height: 46px;
        background-color: #007AFF;
        font-size: 17px;
    }

    .row-top{
        height: 38px;
    }

    .row-top:after {
        content: '';
        position: absolute;
        background-color: #CACACA;
        display: block;
        z-index: 15;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        height: 1px;
        top: 38px;
        width: 100%;

        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

    .row-top-left{
        font-size: 14px;
        padding-left: 18px;
        color: #445058;

    }
    .row-top-left .icon{
        margin-right: 9px;
    }
    .row-top-right{
        font-weight: 800;
        font-size: 14px;
        color: #445058;
        padding-right: 18px;
    }

    .row-middle{
        font-size: calc(0.5em + 1vw);
        height: 52px;
        background-color: #FFF;
    }
    .row-middle-center-top {
        font-size: 14px;
        line-height: 20px;
        font-weight: 800;
        color: #445058;
        letter-spacing: -0.41px;
    }
    .row-middle-center-bottom{
        font-size: 12px;
        line-height: 16px;
        color: #445058;
        letter-spacing: -0.41px;
    }
    .row-middle-left{
        padding-left: 16px;
        width: 80px;
        text-align: left;
    }
    .row-middle-right{
        padding-right: 16px;
        width: 80px;
        text-align: right;
    }
    .row-middle-right a.disabled{
        color: rgba(210, 213, 219, 0.94);
    }
    .row-bottom {
        height: 27px;
        background-color: #F5F8FC;
    }
    .row-bottom-left{
        padding-left: 18px;
        padding-right: 18px;
    }
    .row-bottom-right{
        padding-left: 18px;
        padding-right: 18px;
        display: flex;
        align-items: center;
        gap: 6px;
    }
    .row-bottom-title{
        font-size: 12px;
        font-weight: bold;
        color: #99A8B1;
    }
    .row-bottom-count{
        font-size: 12px;
        font-weight: bold;
        color:#445058;
        display: inline-flex;
        align-items: center;
    }


    #subnavbar-create-0{
        --f7-subnavbar-height: auto;
        --f7-subnavbar-inner-padding-left: 0;
        --f7-subnavbar-inner-padding-right: 0;
        background-color: white;
    }
    #subnavbar-create-0 .subnavbar-inner{
        padding-bottom: 0;
    }

    #page-content-create-0 {
        padding-top: 172px;
    }
    #subnavbar-create-0 .subnavbar-inner{
        flex-direction: column;
    }
    .list-create-0 {
        margin-top: 0px;
    }
    .list-create-0.list{
        --f7-list-bg-color: F0F3F8;
        --f7-list-item-title-line-height: 100px;
        --f7-list-item-padding-vertical: 0;
    }
    .list-create-0.list .item-title{
        color: #445058;
    }
    .custom-preloader {
        width: 24px;
        height: 24px;
        display: none;
    }
    #tabbar-row {
        position: relative;
        background: #FFFF;
        width: 100%;
        /* padding: 8px 16px;
        display: flex;
        justify-content: center; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #tabbar-row .tabbar {
    }
    #tabbar-row .tabbar .toolbar-inner {
        background: #FFF;
        padding: 9px 16px;
    }
    #tabbar-row::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: var(--f7-block-strong-border-color);
        z-index: 999999;
    }
    #tabbar-row .tab-link {
        color: #000000;
        letter-spacing: -0.24px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-transform: unset;

        transition: all 0.1s linear;
    }
    #tabbar-row .links-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        background: #F0F3F8;
        border-radius: 8.91px;
        padding: 2px;
    }
    #tabbar-row .tab-link.tab-link-active {
        background: #FFFFFF;
        /* border: 0.5px solid rgba(0, 0, 0, 0.04); */
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
        border-radius: 6.93px;
    }
    #create-tab-1 {
        background-color:  #1D4323;
        min-height: calc(100vh - 78px - 44px - 128px - var(--f7-safe-area-top) - var(--f7-safe-area-bottom));
    }
    #create-tab-1.tab-active {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #page-content-create-0 #create-tab-1 .field {
        position: relative;
    }

    #page-content-create-0 #create-tab-1 .field img {
        width: 100%;
    }
    #page-content-create-0 .player-box .position {
        color: #FFF;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: -0.6px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 4px);
    }
    #page-content-create-0 .player-box .player-info {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 32px);
        width: 100px;
        height: 28px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    #page-content-create-0 .player-box .team-abbr {
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        text-align: center;
        letter-spacing: -0.6px;
        color: #FFF;
    }
    #page-content-create-0 .player-box .player-info .custom-name {
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #FFF;
        width: 100%;
        letter-spacing: -0.6px;
    }
    #page-content-create-0 .player-box img {
        border-radius: 5px;
    }
    #page-content-create-0 .player-box .player-info .salary {
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #FFF;
        width: 100%;
        letter-spacing: -0.6px;
    }
    #page-content-create-0 .player-box {
        width: 40px;
        height: 40px;
        background-color: #FFF;
        border-radius: 5px;
        position: absolute;
        top: 0;
        text-align: center;
    }
    #page-content-create-0 #create-tab-1 .field {
      padding: 0px 4px;
    }
    #page-content-create-0 .player-box.one {
      top: 273px;
      left: 50%;
      transform: translate(-50%);
    }
    #page-content-create-0 .player-box.two {
      top: 192px;
      left: 30%;
      transform: translate(-30%);
    }
    #page-content-create-0 .player-box.three {
      top: 192px;
      left: 70%;
      transform: translate(-70%);
    }
    #page-content-create-0 .player-box.four {
      top: 108px;
      left: 17%;
      transform: translate(-17%);
    }
    #page-content-create-0 .player-box.five {
      top: 108px;
      left: 50%;
      transform: translate(-50%);
    }
    #page-content-create-0 .player-box.six {
      top: 108px;
      left: 83%;
      transform: translate(-83%);
    }
    #page-content-create-0 .player-box.seven {
      top: 27px;
      left: 30%;
      transform: translate(-30%);
    }
    #page-content-create-0 .player-box.eight {
      top: 27px;
      left: 70%;
      transform: translate(-70%);
    }

    @media (min-width: 321px) and (max-width: 375px) {
        #page-content-create-0 #create-tab-1 .field {
          padding: 0px 4px;
        }
        #page-content-create-0 .player-box {
            width: 44px;
            height: 44px;
        }
        #page-content-create-0 .player-box .player-info {
            transform: translate(-50%, 34px);
        }
        #page-content-create-0 .player-box.one {
          top: 79%;
          left: 50%;
          transform: translate(-50%);
        }
        #page-content-create-0 .player-box.two {
          top: 58%;
          left: 31%;
          transform: translate(-31%);
        }
        #page-content-create-0 .player-box.three {
          top: 58%;
          left: 69%;
          transform: translate(-69%);
        }
        #page-content-create-0 .player-box.four {
          top: 36%;
          left: 17%;
          transform: translate(-17%);
        }
        #page-content-create-0 .player-box.five {
          top: 36%;
          left: 50%;
          transform: translate(-50%);
        }
        #page-content-create-0 .player-box.six {
          top: 36%;
          left: 83%;
          transform: translate(-83%);
        }
        #page-content-create-0 .player-box.seven {
          top: 14%;
          left: 31%;
          transform: translate(-31%);
        }
        #page-content-create-0 .player-box.eight {
          top: 14%;
          left: 69%;
          transform: translate(-69%);
        }
    }
    @media (min-width: 376px) {
        #page-content-create-0 .player-box .player-info {
            transform: translate(-50%, 32px);
        }
        #page-content-create-0 .player-box .position {
            transform: translate(-50%, 5px);
        }
        #page-content-create-0 .player-box {
            width: 48px;
            height: 48px;
        }
        #page-content-create-0 .player-box.one {
            top: 371px;
            left: 50%;
            transform: translate(-50%);
        }
        #page-content-create-0 .player-box.two {
          top: 275px;
          left: 32%;
          transform: translate(-32%);
        }
        #page-content-create-0 .player-box.three {
          top: 275px;
          left: 67%;
          transform: translate(-67%);
        }
        #page-content-create-0 .player-box.four {
          top: 183px;
          left: 18%;
          transform: translate(-18%);
        }
        #page-content-create-0 .player-box.five {
          top: 183px;
          left: 50%;
          transform: translate(-50%);
        }
        #page-content-create-0 .player-box.six {
          top: 183px;
          left: 82%;
          transform: translate(-82%);
        }
        #page-content-create-0 .player-box.seven {
          top: 91px;
          left: 32%;
          transform: translate(-32%);
        }
        #page-content-create-0 .player-box.eight {
          top: 91px;
          left: 67%;
          transform: translate(-67%);
        }
    }

    .minus-round--icon-svg {
        position: absolute !important;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`,
  styleScoped: false
};
    
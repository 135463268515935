
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      id: null,
      isLive: null,
      scores: {},
      totals: {},
      client: null,
      timer: null,
      loadTimer: null,
      tournamentStatus: null
    };
  },
  methods: {
    refresh: function () {
      var self = this;
      setTimeout(function () {
        self.$app.ptr.done(self.$el.find('.ptr-content'));
        self.load(true);
      }, 1400);
    },
    getScoresCount: function (teamId) {
      var self = this;
      var count = 0;
      if (self.scores !== null) {
        for (var scoreType in self.scores[teamId]) {
          var score = self.scores[teamId][scoreType];
          if (score.total != '0.0') {
            count++;
          }
        }
      }
      return count;
    },
    animateTotals: function (data) {
      var self = this;
      self.$el.find('.game-player-points-box').each(function () {
        var elPart1 = self.$(this).find('.game-player-points-main');
        var elPart2 = self.$(this).find('.game-player-points-add');
        var newScore = self.$(this).data('new');
        if (typeof newScore === 'undefined' || newScore == '')
          return;
        newScore = parseFloat(newScore);
        var oldScore = parseFloat(self.$(this).data('current'));
        var currentStep = oldScore > newScore ? -0.1 : 0.1;
        var direction = oldScore > newScore ? 'down' : 'up';
        var timerStep = 1200 / (Math.abs(oldScore - newScore) * 10);
        var current = oldScore;
        var timer = setInterval(function () {
          current = Math.round((current + currentStep) * 10) / 10;
          if (direction == 'up' && current >= newScore || direction == 'down' && current <= newScore) {
            var currentScore = self.$root.scoreSplit(newScore);
            elPart1.html(currentScore.main);
            elPart2.html('.' + currentScore.add);
            clearInterval(timer);
          } else {
            var currentScore = self.$root.scoreSplit(current);
            elPart1.html(currentScore.main);
            elPart2.html('.' + currentScore.add);
          }
        }, timerStep);
      });
    },
    animateScores: function () {
      var self = this;
      self.$el.find('.accordion-item-opened .gamer-full-right-row-right').each(function () {
        var newScore = self.$(this).data('new');
        if (typeof newScore === 'undefined' || newScore == '')
          return;
        newScore = parseFloat(newScore);
        var oldScore = parseFloat(self.$(this).html());
        var el = self.$(this);
        var currentStep = oldScore > newScore ? -0.1 : 0.1;
        var direction = oldScore > newScore ? 'down' : 'up';
        var timerStep = 1200 / (Math.abs(oldScore - newScore) * 10);
        var current = oldScore;
        var timer = setInterval(function () {
          current = Math.round((current + currentStep) * 10) / 10;
          if (direction == 'up' && current >= newScore || direction == 'down' && current <= newScore) {
            var currentScore = self.$root.scoreSplit(newScore);
            el.html(currentScore.main + '.' + currentScore.add);
            clearInterval(timer);
            el.data('old', '');
          } else {
            var currentScore = self.$root.scoreSplit(current);
            el.html(currentScore.main + '.' + currentScore.add);
          }
        }, timerStep);
      });
    },
    load: function () {
      var self = this;
      this.$root.api('app/client_tournaments/' + self.$route.query.id + '/client/' + self.$route.query.client_id + '?entry=' + self.$route.query.entry, {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          self.$root.updateLiveCount();
          data.tournament.client.can_get_details = false;
          self.isLive = data.tournament.status == 'live';
          for (var j = 0; j < data.tournament.client.team.length; j++) {
            var team = data.tournament.client.team[j];
            var id = team.id;
            data.tournament.client.team[j].scores = [];
            if (typeof team.stat.total === 'undefined') {
              team.stat.total = '0.0';
            }
            if (typeof self.totals[id] !== 'undefined' && self.totals[id] != team.stat.total) {
              team.stat.newTotal = team.stat.total;
              team.stat.total = self.totals[id];
              self.totals[id] = team.stat.newTotal;
            } else {
              team.stat.newTotal = '';
              self.totals[id] = team.stat.total;
            }
            if (typeof team.stat.scores !== 'undefined') {
              for (var i = 0; i < team.stat.scores.length; i++) {
                var score = team.stat.scores[i];
                var type = team.stat.scores[i].type;
                if (typeof self.scores[id] !== 'undefined' && typeof self.scores[id][score.type] !== 'undefined' && self.scores[id][score.type].total != score.total) {
                  var totalOld = self.scores[id][score.type].total;
                  var totalNew = score.total;
                } else {
                  var totalOld = score.total;
                  var totalNew = '';
                }
                score.total = totalOld;
                score.new = totalNew;
                if (typeof self.scores[id] === 'undefined') {
                  self.scores[id] = {};
                }
                self.scores[id][score.type] = self.$app.utils.extend({}, score);
                if (score.count != 0) {
                  data.tournament.client.team[j].scores.push(self.$app.utils.extend({}, score));
                }
              }
            }
          }
          self.$setState({
            id: data.tournament.id,
            client: data.tournament.client,
            tournamentStatus: data.tournament.status,
            scores: self.scores
          });
          self.animateScores();
          self.animateTotals();
          self.loadTimer = setTimeout(function () {
            self.load();
          }, 15000);
        }
      }, true);
    }
  },
  on: {
    pageBeforeOut: function (event, page) {
      setTimeout(() => {
        let root = document.documentElement;
        root.style.setProperty('--popup-navbar-after-height', '3px');
        root.style.setProperty('--f7-navbar-after-height', '1px');
      }, 100);
    },
    pageBeforeIn: function (event, page) {
      console.log(1);
      setTimeout(() => {
        let root = document.documentElement;
        root.style.setProperty('--popup-navbar-after-height', '0px');
        root.style.setProperty('--f7-navbar-after-height', '0px');
      }, 100);
    },
    pageInit: function () {
      this.load();
      this.timer = setTimeout(() => this.$('.action-icon').addClass('action-icon-rotate'), 2000);
    },
    pageBeforeRemove: function () {
      clearTimeout(this.timer);
      clearTimeout(this.loadTimer);
    }
  },
  id: 'a8a8b84a19',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-with-subnavbar">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Очки в составе</div>\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.tournamentStatus, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.tournamentStatus != \'nomorebets\' && this.tournamentStatus != \'live\' && this.tournamentStatus != \'finished\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div class="right">\n<!--                            <a href="" data-tournament-id="';
              r += c(ctx_3.$route.query.id, ctx_3);
              r += '" data-entry-id="';
              r += c(ctx_3.$route.query.entry, ctx_3);
              r += '" class="update-entry">-->\n<!--                              <i class="f7-icons">square_pencil</i>-->\n<!--                            </a>-->\n                        </div>\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n                <div class="subnavbar md" style="">\n\n                    <div class="subnavbar-inner ios subnavbar-inner-live-profile" style="padding: 0;">\n                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.client, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                            <div class="list no-chevron width-100">\n                                <ul>\n                                    ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.client, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        ';
              r += Template7Helpers._partial.call(ctx_3, 'game-view-player', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                </ul>\n                            </div>\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="page-content ptr-content" @ptr:refresh="refresh">\n            <div class="ptr-preloader">\n                <div class="preloader"></div>\n                <div class="ptr-arrow"></div>\n            </div>\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.client, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="list no-chevron accordion-list a-list">\n                    <ul>\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.client.team, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <li class="accordion-item">\n                                <a href="#" class="item-content item-link">\n                                    <div class="item-media">\n                                        <div class="img">\n                                            <img src="';
              r += c(ctx_3.image, ctx_3);
              r += '" class="game-avatar">\n                                            ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.is_captain, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                <i class="icon star"></i>\n                                            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                        </div>\n                                        ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.in_team, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                            <i class="icon icon-player-is-play" style="position: absolute; top: 61px; left: 69px;"></i>\n                                        ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                    </div>\n                                    <div class="item-inner" style="flex-direction: column;">\n                                        <div class="" style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; align-items: center">\n                                            <div class="min-width-0" style="display: flex; align-items: center;">\n                                                <span class="gamer-name">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>\n                                            </div>\n\n                                            <div style="padding-bottom: 4px;" class="game-player-points-box ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.is_captain === true', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'is-captain';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '" data-id="';
              r += c(ctx_3.id, ctx_3);
              r += '" data-new="';
              r += c(ctx_3.stat.newTotal, ctx_3);
              r += '" data-current="';
              r += c(ctx_3.stat.total, ctx_3);
              r += '">\n                                                ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.stat.total, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                <span class="game-player-points-main" style="margin-right: 2px;">';
                  r += Template7Helpers.js.call(ctx_4, 'this.stat.total.toString().split(\'.\')[0]', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</span>\n                                                ';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.stat.total.toString().split(\'.\')[1] !== \'undefined\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                <span class=\'game-player-points-add profile-points-add\'>.';
                      r += Template7Helpers.js.call(ctx_5, 'this.stat.total.toString().split(\'.\')[1]', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '</span>\n                                                ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                            </div>\n\n                                        </div>\n                                        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">\n                                            <div>\n                                                <div class="game-player-command">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.team, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                            </div>\n                                        </div>\n\n                                        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">\n                                            <div>\n                                                <div class="game-player-type">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.position, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                            </div>\n\n                                            <div class="gamer-full-col-bottom" style="display: flex; justify-content: flex-end;" >действия (';
              r += Template7Helpers.js.call(ctx_3, 'this.scores.length', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ') <i class="f7-icons icon action-icon">chevron_down</i></div>\n\n                                        </div>\n\n\n\n                                    </div>\n                                </a>\n                                <div class="accordion-item-content gamer-full" style="">\n                                    <div class="gamer-full-col">\n                                        <span class="gamer-full-col-top">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.salary.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += c(ctx_3.salary.currency, ctx_3);
              r += '</span>\n                                        <span class="gamer-full-col-bottom">зарплата</span>\n                                    </div>\n                                    ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.notExist, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="gamer-full-col">\n                                            <span class="gamer-full-col-top">';
                  r += c(ctx_4.rating, ctx_4);
                  r += '</span>\n                                            <span class="gamer-full-col-bottom">рейтинг</span>\n                                        </div>\n                                        ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.score_average != \'0.00\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                            <div class="gamer-full-col">\n                                                <span class="gamer-full-col-top">';
                      r += c(ctx_5.score_average, ctx_5);
                      r += '</span>\n                                                <span class="gamer-full-col-bottom">ОЧК. СР.</span>\n                                            </div>\n                                        ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                    ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.scores.length', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="gamer-full-col" style="height:';
                  r += Template7Helpers.js.call(ctx_4, '(this.scores.length + 1) * 16 ', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += 'px;" key="scores-';
                  r += c(ctx_4.id, ctx_4);
                  r += '">\n                                            ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.scores, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                            <div class="gamer-full-right-row" key="scores-';
                      r += c(ctx_4.id, ctx_5);
                      r += '-';
                      r += c(ctx_5.type, ctx_5);
                      r += '">\n                                                <span class="gamer-full-right-row-left">';
                      r += c(ctx_5.count, ctx_5);
                      r += ' ';
                      r += c(ctx_5.title, ctx_5);
                      r += '</span>\n                                                <span class="gamer-full-right-row-right" data-new="';
                      r += c(ctx_5.new, ctx_5);
                      r += '">';
                      r += c(ctx_5.total, ctx_5);
                      r += '</span>\n                                            </div>\n                                            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                        </div>\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </div>\n                            </li>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    </ul>\n                </div>\n\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block block-strong text-align-center">\n                    <div class="preloader"></div>\n                </div>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .a-list .item-media .img {
        position: relative;
    }
    .a-list .item-media .img .star {
        position: absolute;
        top: -2px;
        right: -8px;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9806 6.30158L14.9805 6.3011C14.8049 5.76118 14.3251 5.37751 13.7591 5.3262C13.7591 5.3262 13.759 5.32619 13.7589 5.32618L10.5679 5.03648L9.30633 2.08293L9.3058 2.08171C9.08181 1.55986 8.56991 1.2207 8.00021 1.2207C7.43109 1.2207 6.91811 1.55946 6.69467 2.08293C6.69464 2.08302 6.6946 2.08311 6.69456 2.08319C6.69455 2.08323 6.69453 2.08326 6.69452 2.08329L5.43312 5.03647L2.2413 5.3262L2.24018 5.3263C1.6746 5.3785 1.19663 5.76184 1.02046 6.29949L1.01994 6.3011C0.844466 6.84073 1.00638 7.43372 1.43399 7.80875L1.43405 7.8088L3.84772 9.92524L3.13592 13.0602L3.13574 13.061C3.01066 13.6147 3.2256 14.1909 3.68808 14.5254C3.93629 14.7054 4.22852 14.7953 4.52043 14.7953C4.77219 14.7953 5.02437 14.7283 5.24891 14.594L8.00018 12.9489L10.7511 14.594L10.7513 14.5942C11.2384 14.8853 11.8518 14.8587 12.3121 14.5256C12.7747 14.1912 12.9898 13.6148 12.8647 13.061L12.8645 13.0602L12.1527 9.92524L14.5664 7.8088L14.5666 7.80863C14.9938 7.43374 15.1559 6.84164 14.9806 6.30158Z' fill='%23760AFF' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }
    :root, .md{
        --f7-subnavbar-height: 64px;
    }

    .action-icon {
        color: #007AFF;
        font-size: 15px;
        color: #818E95;
        transition: all 0.2s linear;
    }
    .accordion-item-opened .action-icon{
        transform: rotate(180deg);
    }

    .subnavbar-inner-live-profile{
        background-color: white;
    }
    .game-player-command{
        font-size: 14px;
        color: #445058;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
        font-weight: 600;
    }
    .game-player-type{
        font-size: 11px;
        color: #818E95;
        text-transform: uppercase;
    }

    .game-player-points-box{
        display: flex;
        align-items: baseline;
        height: 23px;
    }

    .gamer-full{
        padding:0 12px;
        display:flex;
        justify-content: space-between;
    }
    .gamer-full-col{
        display: flex;
        flex-direction: column;
    }
    .gamer-full-col-top{
        font-size: 14px;
        color: #445058;
        font-weight: 500;
    }
    .gamer-full-col-bottom{
        font-size: 10px;
        color: #818E95;
        text-transform: uppercase;
    }
    .gamer-full-right-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .gamer-full-right-row-left{
        font-size: 12px;
        color: #818E95;
    }
    .gamer-full-right-row-right{
        font-size: 12px;
        color: #818E95;
        color: #445058;
        font-weight: 500;
        padding-left: 30px;
    }

    .gamer-type{
        padding: 3px;
        border-radius: 3px;
        background-color:#F0F3F8;
        color:#818E95;
        font-size: 13px;
        text-transform: uppercase;
    }
    .gamer-name{
        color: #007AFF;
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }
    .gamer-commands{

    }
    .gamer-commands-text{
        font-size: 11px;
        text-transform: uppercase;
        color: #818E95;
    }
    .profile-points-add{

    }

`,
  styleScoped: false
};
    
export default {
  id: 'f1445e5f9a',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page change-currency">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Отображение валюты</div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="list">\n                <select name="fruits">\n                    <option value="apple" selected>Apple</option>\n                    <option value="pineapple">Pineapple</option>\n                </select>\n            </div>\n\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

`,
  styleScoped: false
};
export default {
  data: function () {
    return { email: null };
  },
  methods: {
    confirmCode() {
      let self = this;
      self.$app.dialog.preloader('Ожидайте...');
      let code = self.$el.find('#code').val();
      if (code.length == 0) {
        self.$app.dialog.close();
        self.$app.dialog.alert('', 'Введите код');
        return 0;
      }
      self.confirmEmail(code).then(response => {
        self.$app.dialog.close();
        if (response.status == 'error') {
          self.$app.dialog.alert('', response.message);
          return 0;
        }
        self.$app.dialog.alert(response.message, 'Успешно', () => {
          self.$router.navigate('/profile/');
        });
      });
    },
    confirmEmail(code) {
      let self = this;
      return new Promise((resolve, reject) => {
        self.$root.api('/app/profile/verified/confirm-email?code=' + code + '&email=' + self.email, {}, 'GET', function (data, status, xhr) {
          resolve(data);
        }, true);
      });
    },
    checkQuery(data) {
      var self = this;
      self.$setState({ 'email': data.email });
    },
    back() {
      this.$router.back();
    }
  },
  on: {
    pageBeforeIn: function (event, page) {
    },
    pageInit: function () {
      this.checkQuery(this.$router.currentRoute.query);
    }
  },
  id: 'de159fee49',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left"></div>\n                <div class="title">\n                    Подтверждение email\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block-title">Код отправлен на ';
      r += c(ctx_1.email, ctx_1);
      r += '</div>\n            <div class="list inline-labels no-hairlines-md">\n                <ul>\n                    <li class="item-content item-input">\n                        <div class="item-inner">\n                            <div class="item-input-wrap">\n                                <input id="code" type="text" value="" pattern="[0-9]*" placeholder="Код подтверждения" novalidate>\n                            </div>\n                        </div>\n                        \n                    </li>\n                </ul>\n            </div>\n            <div class="button_send">\n                <button id="confirmCode" @click="confirmCode" class="button button-fill button-large button--success button button-fill button-large">\n                    Подтвердить\n                </button>\n            </div>\n            <div class="block text-align-center">\n                <a href="#" class="link" @click="back">Не пришел код?</a>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `

[data-f7-de159fee49] .pointer {
        cursor: pointer;
    }

[data-f7-de159fee49] .list+.list {
        margin-top: 27px;
    }

[data-f7-de159fee49] .fa-star--grey {
        width: 15px;
        height: 14.7px;
        background: #818E95;
    }

[data-f7-de159fee49] .item--success {
        color: #32BF61 !important;
    }

    
[data-f7-de159fee49] .black {
        color: black !important;
    }
[data-f7-de159fee49] body {
        display:none !important;
    }
[data-f7-de159fee49] .ml-mini {
        margin-left: 0.2em;
    }

[data-f7-de159fee49] .button--success {
        height: 46px;
        background-color: #32BF61;
        font-size: 17px;
        border-radius: 4px;
    }

[data-f7-de159fee49] .button--success:hover {
        background-color: #3fd06f;
    }


[data-f7-de159fee49] .list+.button_send {
        margin-top: 35px;
    }

[data-f7-de159fee49] .button_send {
        padding: 0 8px;
    }

[data-f7-de159fee49] .block-title {
        margin-top: 35px;
    }

[data-f7-de159fee49] .preloader {
        width: 24px;
        height: 20px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
        right: calc(var(--f7-safe-area-right) + 10px);
    }

[data-f7-de159fee49] .item-inner--other_amount {
        justify-content: center;
    }

[data-f7-de159fee49] .item-inner--other_amount>.item-title {
        color: #007AFF;
    }

[data-f7-de159fee49] .page-content {
        background-color: unset;
    }

[data-f7-de159fee49] .grecaptcha-badge {
        display: none !important;
    }


[data-f7-de159fee49] #code-space + .button_send {
        margin-top: 35px;
    }

[data-f7-de159fee49] .button + div {
        margin-top: 5px;
    }
[data-f7-de159fee49] .grey-collor {
        background-color: grey;
    }

[data-f7-de159fee49] .preloader {
        right: unset !important;
    }
`,
  styleScoped: true
};
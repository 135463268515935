
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      isPage: typeof this.$route.query.type !== 'undefined' && this.$route.query.type == 'page',
      quiz: null,
      myPlayers: null,
      realPlayers: null,
      guessedPlayers: null,
      tournament: null,
      timer: {
        distance: null,
        interval: null
      },
      prizes: null,
      prizes_details: null,
      viewPrizesDetails: false
    };
  },
  methods: {
    toProfileDetails(id) {
      console.log(id);
      this.$router.navigate({
        name: 'profile-details',
        query: { 'id': id }
      });
    },
    handlerPrizes(tournament) {
      let result = [];
      if (!!tournament.for_points) {
        let prizes = tournament.prizesV2;
        for (let i = 0; i < prizes.length; i++) {
          let {
            place_from,
            place_to,
            amount: {value, percent}
          } = prizes[i];
          if (typeof value === 'number') {
            if (typeof percent === 'object' && !!percent === false) {
              result.push({
                'amount': {
                  'value': value,
                  'currency': '<i class="fas fa-star"></i>'
                },
                'place_from': place_from,
                'place_to': place_to
              });
            } else {
              result.push({
                'amount': {
                  'value': percent + ' % (' + value,
                  'currency': '<i class="fas fa-star"></i>)'
                },
                'place_from': place_from,
                'place_to': place_to
              });
            }
          } else {
            if (typeof percent === 'number' && !!percent === true) {
              result.push({
                'amount': {
                  'value': percent,
                  'currency': '%'
                },
                'place_from': place_from,
                'place_to': place_to
              });
            }
          }
        }
      } else {
        let prizes = tournament.prizesV2;
        for (let i = 0; i < prizes.length; i++) {
          let {
            place_from,
            place_to,
            amount: {value, percent}
          } = prizes[i];
          if (typeof value === 'number') {
            if (typeof percent === 'object' && !!percent === false) {
              result.push({
                'amount': {
                  'value': value,
                  'currency': '\u20BD'
                },
                'place_from': place_from,
                'place_to': place_to
              });
            } else {
              result.push({
                'amount': {
                  'value': percent + ' % (' + value,
                  'currency': '\u20BD)'
                },
                'place_from': place_from,
                'place_to': place_to
              });
            }
          } else {
            if (typeof percent === 'number' && !!percent === true) {
              result.push({
                'amount': {
                  'value': percent,
                  'currency': '%'
                },
                'place_from': place_from,
                'place_to': place_to
              });
            }
          }
        }
      }
      return result;
    },
    handlerPrizesDetails(tournament) {
      let result = [];
      if (!!tournament.for_points) {
        let prizes = tournament.prizesV2;
        for (let i = 0; i < prizes.length; i++) {
          let {
            place_from,
            place_to,
            amount: {value, percent}
          } = prizes[i];
          if (typeof value === 'number') {
            if (typeof percent === 'object' && !!percent === false) {
              if (place_from == place_to) {
                result.push({
                  'amount': {
                    'value': value,
                    'currency': '<i class="fas fa-star"></i>'
                  },
                  'place_from': place_from,
                  'place_to': place_to
                });
              }
              if (place_from < place_to) {
                let count = place_to - place_from + 1;
                let summ = Math.round(value / count);
                let place = place_from;
                while (place <= place_to) {
                  result.push({
                    'amount': {
                      'value': summ,
                      'currency': '<i class="fas fa-star"></i>'
                    },
                    'place_from': place,
                    'place_to': ''
                  });
                  place++;
                }
              }
            } else {
              if (place_from === place_to) {
                if (this.viewPrizesDetails === false) {
                  result.push({
                    'amount': {
                      'value': value,
                      'currency': '<i class="fas fa-star"></i>'
                    },
                    'place_from': place_from,
                    'place_to': place_to
                  });
                } else {
                  result.push({
                    'amount': {
                      'value': value,
                      'currency': '<i class="fas fa-star"></i>'
                    },
                    'place_from': place_from,
                    'place_to': place_to
                  });
                }
              }
              if (place_from < place_to) {
                let count = place_to - place_from + 1;
                let summ = Math.round(value / count);
                let place = place_from;
                while (place <= place_to) {
                  result.push({
                    'amount': {
                      'value': summ,
                      'currency': '<i class="fas fa-star"></i>'
                    },
                    'place_from': place,
                    'place_to': ''
                  });
                  place++;
                }
              }
            }
          } else {
            if (typeof percent === 'number' && !!percent === true) {
              result.push({
                'amount': {
                  'value': percent,
                  'currency': '%'
                },
                'place_from': place_from,
                'place_to': place_to
              });
            }
          }
        }
      } else {
        let prizes = tournament.prizesV2;
        for (let i = 0; i < prizes.length; i++) {
          let {
            place_from,
            place_to,
            amount: {value, percent}
          } = prizes[i];
          if (typeof value === 'number') {
            if (typeof percent === 'object' && !!percent === false) {
              if (place_from === place_to) {
                result.push({
                  'amount': {
                    'value': value,
                    'currency': '\u20BD'
                  },
                  'place_from': place_from,
                  'place_to': ''
                });
              }
              if (place_from < place_to) {
                let count = place_to - place_from + 1;
                let summ = Math.round(value / count);
                let place = place_from;
                while (place <= place_to) {
                  result.push({
                    'amount': {
                      'value': summ,
                      'currency': '\u20BD'
                    },
                    'place_from': place,
                    'place_to': ''
                  });
                  place++;
                }
              }
            } else {
              if (place_from === place_to) {
                if (this.viewPrizesDetails === false) {
                  result.push({
                    'amount': {
                      'value': percent + ' % (' + value,
                      'currency': '\u20BD)'
                    },
                    'place_from': place_from,
                    'place_to': ''
                  });
                } else {
                  result.push({
                    'amount': {
                      'value': value,
                      'currency': '\u20BD'
                    },
                    'place_from': place_from,
                    'place_to': ''
                  });
                }
              }
              if (place_from < place_to) {
                let count = place_to - place_from + 1;
                let summ = Math.round(value / count);
                let place = place_from;
                while (place <= place_to) {
                  result.push({
                    'amount': {
                      'value': summ,
                      'currency': '\u20BD'
                    },
                    'place_from': place,
                    'place_to': ''
                  });
                  place++;
                }
              }
            }
          } else {
            if (typeof percent === 'number' && !!percent === true) {
              if (place_from === place_to) {
                result.push({
                  'amount': {
                    'value': percent,
                    'currency': '%'
                  },
                  'place_from': place_from,
                  'place_to': place_to
                });
              }
            }
          }
        }
      }
      console.log(result);
      return result;
    },
    checkViewPrizesDetails(tournament) {
      let view = false;
      let prizes = tournament.prizes;
      for (var i = 0; i < prizes.length; i++) {
        let {place_from, place_to} = prizes[i];
        if (place_from < place_to) {
          view = true;
        }
      }
      return view;
    },
    ruleInfo: function (title, description) {
      this.$app.dialog.alert(description, title);
    },
    close: function () {
      this.$app.popup.close();
    },
    updateTimer: function () {
      var hours = Math.floor(this.timer.distance / (1000 * 60 * 60));
      var minutes = Math.floor(this.timer.distance % (1000 * 60 * 60) / (1000 * 60));
      var seconds = Math.floor(this.timer.distance % (1000 * 60) / 1000);
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.$el.find('#timer').html(hours + ':' + minutes + ':' + seconds);
      this.timer.distance -= 1000;
      if (this.timer.distance < 0) {
        clearInterval(this.timer.interval);
      }
    }
  },
  on: {
    pageInit: function () {
      var self = this;
      this.$root.api('app/quiz/' + self.$route.params.id, {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          let playersExpected = data.quiz.players_expected;
          let playersPlaying = data.quiz.players_playing;
          playersExpected.forEach(p => {
            let search = playersPlaying.find(f => f.id == p.id && f.position_code == p.position_code);
            if (search)
              p.guessed = true;
            else
              p.guessed = false;
          });
          playersExpected.sort(function (a, b) {
            if (a.position_code == 'A')
              return -1;
            if (b.position_code == 'A')
              return 1;
            if (a.position_code == 'G')
              return 1;
            if (b.position_code == 'G')
              return -1;
            if (a.position_code == 'M' && b.position_code == 'D')
              return -1;
            if (a.position_code == 'D' && b.position_code == 'M')
              return 1;
            return 0;
          });
          playersPlaying.forEach(p => {
            let search = playersExpected.find(f => f.id == p.id && f.position_code == p.position_code);
            if (search)
              p.guessed = true;
            else
              p.guessed = false;
          });
          playersPlaying.sort(function (a, b) {
            if (a.position_code == 'A')
              return -1;
            if (b.position_code == 'A')
              return 1;
            if (a.position_code == 'G')
              return 1;
            if (b.position_code == 'G')
              return -1;
            if (a.position_code == 'M' && b.position_code == 'D')
              return -1;
            if (a.position_code == 'D' && b.position_code == 'M')
              return 1;
            return 0;
          });
          let guessedCount = playersExpected.filter(p => p.guessed).length;
          self.$setState({
            quiz: data.quiz,
            myPlayers: playersExpected,
            realPlayers: playersPlaying,
            guessedPlayers: guessedCount || '?'
          });
        }
        console.log(data);
        ;
      }, true);
    },
    pageBeforeRemove: function () {
      if (this.timer.interval !== null) {
        clearInterval(this.timer.interval);
      }
    }
  },
  id: 'ac06eaf648',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner navbar-popup">\n                <div class="left">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.isPage, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        <a href="#" class="link back icon-only">\n                            <i class="icon icon-back"></i>\n                        </a>\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <a href="#" class="link" @click="close">Закрыть</a>\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n                <div class="title">Угадай состав</div>\n                <div class="subnavbar md subnavbar-menu quiz-info-subnavbar">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.quiz, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.quiz, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <div class="quiz-header">\n                                <div class="quiz-header__top display-flex">\n                                    <div class="quiz-header__top-item display-flex justify-content-center align-items-center text-align-center ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.localteam_highlight, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'quiz-header__top-item--highlight';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">\n                                        ';
              r += c(ctx_3.match.localteam, ctx_3);
              r += '\n                                    </div>\n                                    <div class="quiz-header__top-item quiz-header__top-item--middle display-flex justify-content-center align-items-center text-align-center">\n                                        <span>\n                                            <div class="quiz-header__top-date">';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.match.started_at, {
                hash: { 'type': 'date' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                            ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.localteam_highlight, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                <i class="icon icon-long-arrow-alt-left"></i>\n                                            ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                <i class="icon icon-long-arrow-alt-right"></i>\n                                            ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                        </span>\n                                    </div>\n                                    <div class=\'quiz-header__top-item display-flex justify-content-center align-items-center text-align-center ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.localteam_highlight == false', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'quiz-header__top-item--highlight';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\'>\n                                        ';
              r += c(ctx_3.match.visitorteam, ctx_3);
              r += '\n                                    </div>\n                                </div>\n                                <div class="quiz-header__bottom display-flex justify-content-space-between">\n                                    <div class="quiz-header__bottom-item text-align-left">\n                                        <div class="quiz-header__bottom-item-title">';
              r += c(ctx_2.guessedPlayers, ctx_3);
              r += ' из 11</div>\n                                        <div class="quiz-header__bottom-item-subtitle">Угадано</div>\n                                    </div>\n                                    <div class="quiz-header__bottom-item text-align-center">\n                                        <div class="quiz-header__bottom-item-title">';
              r += c(ctx_3.match.current_state, ctx_3);
              r += '</div>\n                                        <div class="quiz-header__bottom-item-subtitle">Статус</div>\n                                    </div>\n                                    <div class="quiz-header__bottom-item text-align-right">\n                                        <div class="quiz-header__bottom-item-title">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.prize.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '<i class="icon fa-star" style="font-size:13px;"></i></div>\n                                        <div class="quiz-header__bottom-item-subtitle">\n                                            ';
              r += Template7Helpers.if.call(ctx_3, 'status == \'finished\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                <span>Выигрыш</span>\n                                            ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                                <span>Призовой фонд</span>\n                                            ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="subnavbar-inner ios" style="height: 60px;">\n                                <div class="segmented">\n                                    <a class="button tab-link tab-link-active" href="#tab-my-choose">Мой выбор</a>\n                                    <a class="button tab-link" href="#tab-team">Стартовый состав</a>\n                                </div>\n                            </div>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n            </div>\n        </div>\n        <div class="page-content" style="padding-top: 212px;">\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.quiz, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="tabs">\n                    <div class="tab tab-active" id="tab-my-choose">\n                        <div id="all-entries" class="list no-chevron virtual-list">\n                            <div class="quiz-info-players-list">\n                                ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.myPlayers, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                    <div class="quiz-info-players-list__player display-flex align-items-center">\n                                        <div class="quiz-info-players-list__player-img" style="background-image: url(\'';
              r += c(ctx_3.image, ctx_3);
              r += '\');"></div>\n                                        <div class="quiz-info-players-list__player-main-info">\n                                            <div class="quiz-info-players-list__player-name">';
              r += c(ctx_3.name, ctx_3);
              r += '</div>\n                                            <div class="quiz-info-players-list__player-position">';
              r += c(ctx_3.position, ctx_3);
              r += '</div>\n                                        </div>\n                                        <div class="quiz-info-players-list__player-status ';
              r += Template7Helpers.if.call(ctx_3, ctx_2.realPlayers.length, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.guessed, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'quiz-info-players-list__player-status--green';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += 'quiz-info-players-list__player-status--red';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '"></div>\n                                    </div>\n                                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                            </div>\n                        </div>\n                    </div>\n                    <div class="tab" id="tab-team" style="height:100%;">\n                        <div id="all-entries" class="list no-chevron virtual-list">\n                            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.realPlayers.length, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                <div class="quiz-info-players-list">\n                                    ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.realPlayers, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <div class="quiz-info-players-list__player display-flex align-items-center">\n                                            <div class="quiz-info-players-list__player-img" style="background-image: url(\'';
                  r += c(ctx_4.image, ctx_4);
                  r += '\');"></div>\n                                            <div class="quiz-info-players-list__player-main-info">\n                                                <div class="quiz-info-players-list__player-name">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</div>\n                                                <div class="quiz-info-players-list__player-position">';
                  r += c(ctx_4.position, ctx_4);
                  r += '</div>\n                                            </div>\n                                            <div class="quiz-info-players-list__player-status ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.guessed, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += 'quiz-info-players-list__player-status--green';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '"></div>\n                                        </div>\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </div>\n                            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                <div class="vh-centered quiz-info-empty-players-list display-flex align-items-center justify-content-center">\n                                    <div class="text-align-center">\n                                        <i class="icon icon-empty-gamelist"></i>\n                                        <div class="quiz-info-empty-players-list__title">Пока нет данных</div>\n                                        <div class="quiz-info-empty-players-list__subtitle">Стартовый состав ещё не объявлен</div>\n                                    </div>\n                                </div>\n                            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        </div>\n                    </div>\n                </div>\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block block-strong text-align-center">\n                    <div class="preloader"></div>\n                </div>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .vh-centered {
      height: calc(100vh - 168px - 44px - 20px - var(--f7-safe-area-top) - var(--f7-safe-area-bottom)) !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .quiz-header {
        width: calc(100% - 26px);
    }
    .quiz-header .quiz-header__top .quiz-header__top-item {
        flex: 1 0 33%;
        min-height: 64px;
        font-size: 16px;
    }
    .quiz-header .quiz-header__top .quiz-header__top-item.quiz-header__top-item--highlight {
        font-weight: 600;
    }
    .quiz-header .quiz-header__top .quiz-header__top-item.quiz-header__top-item--middle span{
        position: relative;
        font-size: 18px;
    }
    .quiz-header .quiz-header__top .quiz-header__top-item.quiz-header__top-item--middle span .quiz-header__top-date {
        position: absolute;
        top: -12px;
        left: -30px;
        font-size: 11px;
        white-space: nowrap;
        color: #99A8B1;
    }
    .quiz-header .quiz-header__bottom {
        padding-top: 12px;
        border-top: 1px solid #F5F5F5;
    }
    .quiz-header .quiz-header__bottom-item:last-child .quiz-header__bottom-item-title {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .quiz-header .quiz-header__bottom-item .quiz-header__bottom-item-title {
        font-size: 15px;
        font-weight: 600;
        color: #000000;
    }
    .quiz-header .quiz-header__bottom-item .quiz-header__bottom-item-subtitle {
        font-size: 10px;
        color: #818E95;
        text-transform: uppercase;
    }
    .quiz-header .quiz-header__bottom-item {
        flex: 1 0 33%;
    }
    .quiz-info-subnavbar{
        height: 168px !important;
        flex-direction: column;
    }
    .quiz-info-players-list {
        background: #ffffff;
        padding-left: 16px;
    }
    .quiz-info-players-list .quiz-info-players-list__player {
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #D1D1D6;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-img {
        height: 38px;
        width: 38px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-main-info {
        flex-grow: 1;
        padding: 0 16px;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-name {
        font-size: 16px;
        font-weight: 500;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-position {
        font-size: 12px;
        color: #787E8A;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-status {
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-status.quiz-info-players-list__player-status--red {
        background: #EB4036;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-status.quiz-info-players-list__player-status--green {
        background: #32BF61;
    }
    .quiz-info-players-list .quiz-info-players-list__player .quiz-info-players-list__player-status.quiz-info-players-list__player-status--gray {
        background: #A9A9A9;
    }
    .quiz-info-empty-players-list {
        height: 100%;
    }
    .quiz-info-empty-players-list .quiz-info-empty-players-list__title {
        font-size: 24px;
        color: #818E95;
    }
    .quiz-info-empty-players-list .quiz-info-empty-players-list__subtitle {
        font-size: 16px;
        color: #ACB4BE;
    }


    .tour-info-list-cup + .tour-info-list-cup-details {
        margin-top: 35px !important;
    }
    .points-negative{
        color: #BE2D33 !important;
    }
    .rule-full {
        color: #AAADB3;
    }
    .game-games-center{
        margin-left: 10px;
        margin-right: 10px;
        width:36%;
    }
    .game-games-center-top{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .list-games .item-content{
        padding-left:  calc(10px + var(--f7-safe-area-left));
        padding-right: calc(10px + var(--f7-safe-area-left));
    }

    .game-games-center-top{
        position: relative;
        /*top: 5px;*/
        font-size: 11px;
        text-align: center;
        color: #99A8B1;
    }

    .game-games-center-bottom{
        position: relative;
        /*top: -5px;*/
        font-size: 11px;
        text-align: center;
        color: #99A8B1;
    }

    .game-games-scores{
        width: 28px;
        font-size: 26px;
        font-weight: bold;
        text-transform: uppercase;
        color: #445058;
    }
    .game-games-left{
        width:33%;
        font-size: 16px;
        text-align: center;
        color: #445058;
        line-height: 18px;
    }
    .game-games-right{
        width:33%;
        font-size: 16px;
        text-align: center;
        color: #445058;
        line-height: 18px;
    }

    .tour-info-ended-title{
        font-size: 14px;
        text-transform: uppercase;
        color: #BE2D33;
    }
    .tour-info-cup-li-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .tour-info-cup-li-item-top{
        font-weight:500;
        font-size: 17px;
        color: #445058;
        display: inline-flex;
        align-items: center;
    }
    .tour-info-cup-li-item-top{
        font-size: 17px;
        color: #445058;
    }

    .tour-info-games-block-command-div>span{
        position: relative;
        top: 3px;
    }



    .player-with-place{
        padding-left: var(--f7-safe-area-left) !important;
    }
    .tour-info-block{
        font-size: 12px;
        font-weight: 500;
        color: #445058;

    }
    .tour-info-list-rules .item-after{
       align-items: baseline;;
    }
    .tour-info-list-rules .item-title{
        font-size: 16px;
        font-weight: 500;
        color: #445058;
    }
    .tour-info-list-rules .item-footer{
        font-size: 12px;
        font-weight: 500;
        color: #AAADB3;
    }
    .tour-info-list-rules .item-after .points-main{
        font-size: 24px;
        color: #32BF61;
    }
    .tour-info-list-rules .item-after .points-add{
        font-size: 19px;
        color: #32BF61;
    }

    .tour-info-top{
        width: calc(100% - 26px);
        height: 108px;
        flex-direction: column;
        display: flex;
    }
    .tour-info-top-first{
        margin-top: 4px;
        font-weight:bold;
        color: #007AFF;
        font-size: 15px;
        white-space: nowrap;
        height: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .tour-info-top-second{
        margin-top: 4px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }
    .tour-info-top-second-col{
        flex-direction: column;
        display: flex;
    }
    .tour-info-top-third{
        border-top: 1px solid #c8c7cc;
        padding-top: 6px;
        margin-top: 4px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }

    .tour-info-top-bottom-left{
        flex-direction: row;
        display: flex;
        font-size: 14px;
        color: #445058;
    }
    .tour-info-top-bottom-left i{
        margin-top: 1px;
        padding-left: 0;
        margin-right: 6px
    }

    .tour-info-top-bottom-right{
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        font-weight: bold;
        color: #445058;
    }

    .tour-info-top-col-top{
        font-size: 14px;
        line-height: 16px;
        color: #445058;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
    }
    /* .tour-info-top-col-top .icon{
        position: relative;
        top: -1px;
        margin-right: 5px;
        margin-bottom: 0px;
    } */
    .tour-info-top-col-bottom{
        font-size: 10px;
        line-height: 16px;
        text-transform: uppercase;
        color: #818E95;
    }

    #tab-tour-rules .main-svg {
        text-align: center;
    }

    #tab-tour-rules .main-svg img {
        display: block;
        max-width: 420px;
        margin: 0 auto;
    }

    #tab-tour-rules .block-header {
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.35px;
        text-transform: uppercase;
        margin-bottom: 2px;
        margin-top: 12px;
    }

    .list-tour-rules > ul {
        padding-left: 0;
        margin: 0;
    }

    .list-tour-rules > ul > li {
        list-style-type: none;
    }

    .list-tour-rules > ul > li > .item-inner {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
    }

    .list-tour-rules > ul > li > .item-inner > a {
        pointer-events: none;
        cursor: default;
        color: #445058;
        font-size: 14px;
        line-height: 17px;
        padding-left: 12px;
    }

    .list-tour-rules > ul > li > .item-inner > .item-media {
        display: flex;
        flex-direction: column;
        margin-top: .3em;
        justify-content: start;
    }

`,
  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return { tournaments: [] };
  },
  methods: {
    close() {
      var self = this;
      self.$root.clearTeam();
      self.$root.events.emit('lobby.load');
      self.$app.popup.close();
    },
    openTournament(id) {
      this.$router.navigate(`/team/create-0/?id=${ id }`);
    }
  },
  on: {
    pageBeforeIn() {
      let tournaments = JSON.parse(this.$route.query.tournaments);
      const teamHash = this.$route.query.team_hash;
      tournaments.map(el => {
        if (el.can_entry_again && !el.teams_hash.includes(teamHash) && el.sufficient_balance) {
          el.isAvailable = true;
        } else {
          el.isAvailable = false;
        }
      });
      console.log(tournaments, teamHash);
      this.$setState({ tournaments });
    },
    pageInit() {
    }
  },
  id: '1994e4982e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-category-tournaments">\n\n        <div class="page-content">\n            <div class="row-header">\n                <div class="success-svg"></div>\n                <span class="title">Готово!</span>\n                <span class="subtitle">Вы успешно вошли в турнир со своей командой</span>\n            </div>\n            <div class="row-content">\n                <divc class="title-block">\n                    <span class="title">Войти этим же составом?</span>\n                    <span class="subtitle">Вы можете войти с этой командой в такой же турнир другой категории. Выбор можно отредактировать</span>\n                </divc>\n                <div class="tournaments-block">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.tournaments, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.tournaments, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="tournament">\n                        <div class="data-inner">\n                            <div\n                                class="prize display-flex flex-direction-column justify-content-center align-items-flex-start">\n                                <span class="value">';
              r += c(ctx_3.prize.value, ctx_3);
              r += ' ';
              r += c(ctx_3.prize.currency, ctx_3);
              r += '</span>\n                                <span class="text">Приз. фонд</span>\n                            </div>\n                            <div\n                                class="count-players display-flex flex-direction-column justify-content-center align-items-center">\n                                <span class="value">';
              r += c(ctx_3.participants.current, ctx_3);
              r += '</span>\n                                <span class="text">Участники</span>\n                            </div>\n                            <div\n                                class="count-entered display-flex flex-direction-column justify-content-center align-items-flex-end">\n                                <span class="value">';
              r += c(ctx_3.ingress.value, ctx_3);
              r += ' ';
              r += c(ctx_3.ingress.currency, ctx_3);
              r += '</span>\n                                <span class="text">Вход</span>\n                            </div>\n                        </div>\n                        <div class="me-entered">\n                            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.isAvailable === false', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            <div class="content display-flex justify-content-center align-items-center">\n                                <div class="ok-svg"></div>\n                            </div>\n                            ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                            <div class="content fill display-flex justify-content-center align-items-center" @click="openTournament(';
                  r += c(ctx_4.id, ctx_4);
                  r += ')">\n                                <div class="plus-svg"></div>\n                            </div>\n                            ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        </div>\n                    </div>\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n            </div>\n            <div class="row-actions">\n                <div class="btn-wrap">\n                    <button @click="close">Закрыть</button>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .page-category-tournaments .page-content {
        background-color: #FFFFFF;
    }

    .page-category-tournaments .row-header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
    }

    .page-category-tournaments .row-header .success-svg {
        width: 56px;
        height: 56px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28' cy='28' r='28' fill='%2332BF61'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.6254 33.2318L39.6785 18.1786C40.5834 17.2738 42.0504 17.2738 42.9552 18.1786C43.8601 19.0835 43.8601 20.5505 42.9552 21.4554L24.6254 39.7852L13.9792 29.139C13.0743 28.2342 13.0743 26.7671 13.9792 25.8623C14.884 24.9574 16.3511 24.9574 17.2559 25.8623L24.6254 33.2318Z' fill='white'/%3E%3C/svg%3E%0A");
    }

    .page-category-tournaments .row-header .title {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0.337647px;
        color: #445058;
        margin-top: 16px;
    }

    .page-category-tournaments .row-header .subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.35px;
        color: #818E95;
        margin-top: 7px;
    }

    .page-category-tournaments .row-content .title-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 40px 16px 0px;
    }

    .page-category-tournaments .row-content .title-block .title {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: #445058;
    }

    .page-category-tournaments .row-content .title-block .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.35px;
        color: #818E95;
        margin-top: 7px;
        text-align: center;
    }

    .page-category-tournaments .row-content .tournaments-block {
        margin: 16px 16px 0px;
        gap: 8px;
        display: flex;
        flex-direction: column;
    }

    .page-category-tournaments .row-content .tournaments-block .tournament {
        height: 54px;
        background: #FFFFFF;
        border: 1px solid #CFD6DB;
        border-radius: 8px;
        display: flex;
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .data-inner {
        display: flex;
        justify-content: space-between;
        flex: 1;
        gap: 2px;
        padding: 0 16px;
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .data-inner .value {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.24px;
        color: #445058;
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .data-inner .text {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.07px;
        text-transform: uppercase;
        color: #818E95;
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .me-entered {
        width: 54px;
        height: 54px;
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .me-entered .ok-svg {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5535 14.2422L17.0049 7.79084C17.3926 7.40305 18.0214 7.40305 18.4092 7.79084C18.797 8.17863 18.797 8.80736 18.4092 9.19515L10.5535 17.0508L5.99085 12.4881C5.60306 12.1004 5.60306 11.4716 5.99085 11.0838C6.37864 10.696 7.00738 10.696 7.39517 11.0838L10.5535 14.2422Z' fill='%2332BF61'/%3E%3C/svg%3E%0A");
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .me-entered .plus-svg {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C6.4858 22 2 17.5137 2 11.9997C2 6.48566 6.4858 2 12 2C17.5142 2 22 6.48566 22 11.9997C22.0006 17.5137 17.5142 22 12 22ZM12 3.14234C7.11559 3.14234 3.14238 7.11607 3.14238 11.9997C3.14238 16.8833 7.11559 20.8564 12 20.8564C16.8838 20.8564 20.857 16.8833 20.857 11.9997C20.857 7.11607 16.8838 3.14234 12 3.14234Z' fill='white'/%3E%3Cpath d='M15.9427 12.5708H8.05647C7.74092 12.5708 7.48462 12.3158 7.48462 11.9996C7.48462 11.684 7.74028 11.4277 8.05647 11.4277H15.9427C16.2582 11.4277 16.5139 11.684 16.5139 11.9996C16.5139 12.3158 16.2589 12.5708 15.9427 12.5708Z' fill='white'/%3E%3Cpath d='M11.9996 16.514C11.684 16.514 11.4277 16.2583 11.4277 15.9428V8.0566C11.4277 7.74105 11.684 7.48474 11.9996 7.48474C12.3151 7.48474 12.5708 7.74105 12.5708 8.0566V15.9428C12.5708 16.259 12.3158 16.514 11.9996 16.514Z' fill='white'/%3E%3C/svg%3E%0A");
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .me-entered .content {
        height: inherit;
        border-left: 1px solid #CFD6DB;
    }

    .page-category-tournaments .row-content .tournaments-block .tournament .me-entered .content.fill {
        background-color: #007AFF;
        border-left: unset;
        height: 56px;
        position: relative;
        top: -1px;
        width: 55px;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
    }

    .page-category-tournaments .row-actions {
        position: absolute;
        bottom: calc(16px + var(--f7-safe-area-bottom));
        left: 0px;
        right: 0px;
    }

    .page-category-tournaments .row-actions:before {
        content: '';
        position: absolute;
        background-color: var(--f7-block-strong-border-color);
        display: block;
        z-index: 15;
        top: -16px;
        right: auto;
        bottom: auto;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: 50% 0%;
        transform: scaleY(calc(1 / 1));
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

    .page-category-tournaments .row-actions .btn-wrap {
        margin: 0px 16px;
    }

    .page-category-tournaments .row-actions button {
        height: 46px;
        background: #32BF61;
        border-radius: 7px;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #FFFFFF;
    }
`,
  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      categoryGroup: null,
      type: null
    };
  },
  methods: {
    openTournament(id) {
      if (this.$root.isNosession()) {
        this.$root.alertNoSession();
        return false;
      }
      this.$root.openTournament(this, id, 'unknown');
    },
    refresh: function () {
      var self = this;
      setTimeout(function () {
        self.load(function () {
          self.$app.ptr.done(self.$el.find('.ptr-content'));
        });
      }, 1400);
    },
    load: function (success) {
      var categoryGroup = {
        title: this.category.title,
        groups: {}
      };
      if (this.category.friendsMode)
        categoryGroup.friendsMode = true;
      else
        categoryGroup.friendsMode = false;
      this.category.tournaments.forEach(function (e) {
        var date = new Date(e.started_at);
        var groupByKey = 'group.' + date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear() + '.' + date.getHours() + '.' + date.getMinutes();
        if (typeof categoryGroup['groups'][groupByKey] === 'undefined') {
          categoryGroup['groups'][groupByKey] = {
            tournaments: [],
            create_at: e.started_at
          };
        }
        categoryGroup['groups'][groupByKey]['tournaments'].push(e);
      });
      this.$setState({ categoryGroup: categoryGroup });
    }
  },
  on: {
    pageInit: function () {
      this.load();
    },
    pageAfterOut: function () {
    }
  },
  id: '3413307ed2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.categoryGroup, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.categoryGroup.title, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '...';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n            </div>\n        </div>\n\n        <div class="page-content ptr-content" @ptr:refresh="refresh">\n            <div class="ptr-preloader">\n                <div class="preloader"></div>\n                <div class="ptr-arrow"></div>\n            </div>\n\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.categoryGroup, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.categoryGroup.friendsMode == true', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.categoryGroup.groups, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                <div class="block list-title display-flex align-content-center align-items-center">\n                    <i class="icon icon-sport-ucl"></i> ';
                  r += Template7Helpers.formatDate.call(ctx_4, ctx_4.create_at, {
                    hash: { 'type': 'full' },
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                </div>\n                <div class="list list-blocks no-chevron">\n                    <ul>\n                        ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.tournaments, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                        <li>\n                          <a href="#" @click="openTournament(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="item-link item-content">\n                              <div class="item-inner">\n                                  <div class="item-title width-100 ">\n                                      <div class="tournament-title">\n                                          ';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.title, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                      </div>\n                                      <div class="item-footer">\n                                          <div class="display-flex justify-content-space-between">\n                                            <div style="flex: 1;">\n                                                <div class="list-block-col-top">\n                                                    ';
                      r += Template7Helpers.with.call(ctx_5, ctx_5.participants, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                                        ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.min == 0 && this.max != 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current < this.max', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.max, ctx_8);
                                  r += ' max\n                                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                        ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n\n                                                        ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.min != 0 && this.max != 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current < this.min', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.min, ctx_8);
                                  r += ' min\n                                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current >= this.min && this.current < this.max', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.max, ctx_8);
                                  r += ' max\n                                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                        ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                                        ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.min != 0 && this.max == 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current < this.min', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.min, ctx_8);
                                  r += ' min\n                                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                            ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current >= this.min', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                <i class="icon icon-progress-complete"></i>\n                                                            ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                        ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                                    ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n\n                                                </div>\n                                                <div class="list-block-col-bottom">участники</div>\n                                            </div>\n                                            <div class="text-align-center" style="flex: 1;">\n                                                <div class="list-block-col-top svg-position">';
                      r += Template7Helpers.formatPrice.call(ctx_5, ctx_5.ingress.value, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' ';
                      r += c(ctx_5.ingress.currency, ctx_5);
                      r += '</div>\n                                                <div class="list-block-col-bottom">вход</div>\n                                            </div>\n                                            <div class="text-align-right" style="flex: 1;">\n                                                <div class="list-block-col-top svg-position">\n                                                    ';
                      r += Template7Helpers.formatPrice.call(ctx_5, ctx_5.prize.value, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' ';
                      r += c(ctx_5.prize.currency, ctx_5);
                      r += '\n                                                </div>\n                                                <div class="list-block-col-bottom">приз</div>\n                                            </div>\n                                          </div>\n                                      </div>\n                                  </div>\n                              </div>\n                          </a>\n                      </li>\n                        ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                    </ul>\n                </div>\n              ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.min == 0 && this.max != 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.current < this.max', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.min != 0 && this.max != 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.current < this.min', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, 'this.current >= this.min && this.current < this.max', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.min != 0 && this.max == 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.current < this.min', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, 'this.current >= this.min', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                  ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.categoryGroup.groups, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                    <div class="block list-title display-flex align-content-center align-items-center">\n                        <i class="icon icon-sport-ucl"></i> ';
                  r += Template7Helpers.formatDate.call(ctx_4, ctx_4.create_at, {
                    hash: { 'type': 'full' },
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                    </div>\n                    <div class="list list-blocks no-chevron">\n                        <ul>\n                            ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.tournaments, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                            <li>\n                                <a href="#" @click="openTournament(';
                      r += c(ctx_5.id, ctx_5);
                      r += ')" class="item-link item-content">\n                                    <div class="item-inner">\n                                        <div class="item-title width-100 ">\n                                            <div class="tournament-title">\n                                                ';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.title, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                            </div>\n                                            <div class="item-footer">\n                                                <div class="display-flex justify-content-space-between">\n                                                  <div style="flex: 1;">\n                                                      <div class="list-block-col-top">\n                                                          ';
                      r += Template7Helpers.with.call(ctx_5, ctx_5.participants, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                                              ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.min == 0 && this.max != 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                                  ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current < this.max', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                      <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.max, ctx_8);
                                  r += ' max\n                                                                  ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                              ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n\n                                                              ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.min != 0 && this.max != 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                                  ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current < this.min', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                      <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.min, ctx_8);
                                  r += ' min\n                                                                  ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                                  ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current >= this.min && this.current < this.max', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                      <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.max, ctx_8);
                                  r += ' max\n                                                                  ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                              ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                                              ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.min != 0 && this.max == 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n                                                                  ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current < this.min', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                      <b>';
                                  r += c(ctx_8.current, ctx_8);
                                  r += '</b>&nbsp;из ';
                                  r += c(ctx_8.min, ctx_8);
                                  r += ' min\n                                                                  ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                                  ';
                              r += Template7Helpers.js_if.call(ctx_7, 'this.current >= this.min', {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '\n                                                                      <i class="icon icon-progress-complete"></i>\n                                                                  ';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [
                                  ctx_6,
                                  ctx_5,
                                  ctx_4,
                                  ctx_3,
                                  ctx_2,
                                  ctx_1
                                ]
                              });
                              r += '\n                                                              ';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += '\n                                                          ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n\n                                                      </div>\n                                                      <div class="list-block-col-bottom">участники</div>\n                                                  </div>\n                                                  <div class="text-align-center" style="flex: 1;">\n                                                      <div class="list-block-col-top svg-position">';
                      r += Template7Helpers.formatPrice.call(ctx_5, ctx_5.ingress.value, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' ';
                      r += c(ctx_5.ingress.currency, ctx_5);
                      r += '</div>\n                                                      <div class="list-block-col-bottom">вход</div>\n                                                  </div>\n                                                  <div class="text-align-right" style="flex: 1;">\n                                                      <div class="list-block-col-top svg-position">\n                                                          ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.prize.message, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                                              TBD\n                                                          ';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                                              ';
                          r += Template7Helpers.formatPrice.call(ctx_6, ctx_6.prize.value, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [
                              ctx_5,
                              ctx_4,
                              ctx_3,
                              ctx_2,
                              ctx_1
                            ]
                          });
                          r += ' ';
                          r += c(ctx_6.prize.currency, ctx_6);
                          r += '\n                                                          ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                                      </div>\n                                                      <div class="list-block-col-bottom">приз</div>\n                                                  </div>\n                                                </div>\n                                            </div>\n                                        </div>\n                                    </div>\n                                </a>\n                            </li>\n                            ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                        </ul>\n                    </div>\n                  ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block block-strong text-align-center">\n                    <div class="preloader"></div>\n                </div>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n        </div>\n\n    </div>\n\n';
      return r;
    }(this);
  },
  style: `
    .tournament-title{
        white-space: normal;
        height: 42px;
        overflow: hidden;
    }
    .list-title {
        font-size: 12px;
        color: #445058;
        font-weight: 800;
        height: 26px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .list-title .icon{
        margin-right: 5px;
        margin-bottom: 0px;
    }
    .list-blocks{
        margin-top: 0;
        margin-bottom: 0;
    }
    .list-blocks .item-title{
        font-weight: bold !important;
        font-size: 15px;
        color: #007AFF;
    }
    .list-block-col-top{
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
    }

    .list-block-col-bottom{
        text-transform: uppercase;
        font-size: 10px;
        color: #818E95;
    }
`,
  styleScoped: false
};
    
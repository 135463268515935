
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      ratePercent: 0,
      rate: null,
      amount: null,
      currency: null,
      final: null,
      full: null,
      balances: {
        olimp: {
          value: 0,
          currency: ''
        },
        fanbattle: {
          value: 0,
          currency: ''
        }
      }
    };
  },
  methods: {
    submit() {
      const _this = this;
      const url = `app/balance/olimp/fanbattle-olimp`;
      const amount = _this.full.value;
      _this.$app.preloader.show();
      _this.$root.api(url, { amount }, 'POST', function (data, status, xhr) {
        _this.$app.preloader.hide();
        if (data.status == 'error') {
          _this.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          _this.$app.dialog.alert('Ваш баланс пополнен', 'Успешно', () => {
            _this.$app.data.events.emit('profile.update');
            _this.$app.popup.close();
          });
        }
      }, true);
    },
    fetchDetails() {
      const _this = this;
      console.log(1);
      _this.$root.api('/app/balance/olimp/fanbattle-olimp/calculate', { amount: parseFloat(_this.$route.query.amount).toFixed(2) }, 'GET', function (data, status, xhr) {
        _this.$app.preloader.hide();
        if (data.status == 'error') {
          _this.$app.dialog.alert(data.message, 'Ошибка');
        } else {
          setTimeout(() => {
            console.log(data.origin.commission.percent);
            _this.$setState({
              ratePercent: data.origin.commission.percent,
              amount: {
                value: data.origin.value_minus_commission,
                currency: _this.$root.params.profile.balance.coins.currency
              },
              currency: {
                value: parseInt(_this.$root.params.profile.currency.rate),
                currency: _this.$root.params.profile.currency.sign
              },
              rate: {
                value: data.origin.commission.value,
                currency: _this.$root.params.profile.balance.coins.currency
              },
              final: {
                value: data.currency.value,
                currency: _this.$root.params.profile.balance.olimp.currency
              },
              full: {
                value: data.origin.value,
                currency: _this.$root.params.profile.balance.coins.currency
              }
            });
          }, 314);
        }
      }, true);
    },
    initBalances() {
      this.$setState({
        balances: {
          olimp: {
            value: this.$root.params.profile.balance.olimp.value,
            currency: this.$root.params.profile.balance.olimp.currency
          },
          fanbattle: {
            value: this.$root.params.profile.balance.coins.value,
            currency: this.$root.params.profile.balance.coins.currency
          }
        }
      });
    }
  },
  on: {
    pageInit: function () {
      this.initBalances();
      console.log(this.final);
    },
    pageBeforeOut: function () {
    },
    pageBeforeIn: function () {
      this.fetchDetails();
    }
  },
  id: 'b29179d5dd',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Перевод между счетами</div>\n            </div>\n        </div>\n        <div class="page-content increase-balance">\n            <div class="block no-margin-vertical">\n                <div class="balances__wrapper">\n                    <div class="balance-row">\n                        <div class="app-icon fanbattle"></div>\n                        <div class="app-name">\n                            <span>Счет списания</span>\n                            <span>Fanbattle</span>\n                        </div>\n                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.balances, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.balances, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div class="app-balance">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.fanbattle.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.fanbattle.currency, ctx_3);
              r += '</div>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </div>\n                    <div class="separator">\n                        <hr>\n                    </div>\n                    <div class="balance-row">\n                        <div class="app-icon olimp"></div>\n                        <div class="app-name">\n                            <span>Счет зачисления</span>\n                            <span>Olimp</span>\n                        </div>\n                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.balances, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.balances, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div class="app-balance">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.olimp.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.olimp.currency, ctx_3);
              r += '</div>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </div>\n                </div>\n            </div>\n            <div class="list list-details">\n                <ul>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Сумма списания</div>\n                                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.amount, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.amount, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <div class="item-after">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.currency, ctx_3);
              r += '</div>\n                                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                <div class="preloader color-gray">';
          r += c(ctx_2.$app.utils.iosPreloaderContent, ctx_2);
          r += '</div>\n                                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Комисия сервиса (';
      r += c(ctx_1.$root.params.profile.params.fb_to_olimp_commission_percent, ctx_1);
      r += '%)</div>\n                                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.rate, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.rate, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <div class="item-after">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.currency, ctx_3);
              r += '</div>\n                                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                <div class="preloader color-gray">';
          r += c(ctx_2.$app.utils.iosPreloaderContent, ctx_2);
          r += '</div>\n                                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                            </div>\n                        </div>\n                    </li>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Курс валюты (USD) </div>\n                                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.currency, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.currency, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <div class="item-after">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.currency, ctx_3);
              r += '</div>\n                                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    <div class="preloader color-gray">';
          r += c(ctx_2.$app.utils.iosPreloaderContent, ctx_2);
          r += '</div>\n                                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                            </div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n            <div class="list list-details">\n                <ul>\n                    <li>\n                        <div class="item-content">\n                            <div class="item-inner">\n                                <div class="item-title">Сумма зачисления</div>\n                                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.final, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.final, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <div class="item-after">';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.currency, ctx_3);
              r += '</div>\n                                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    <div class="preloader color-gray">';
          r += c(ctx_2.$app.utils.iosPreloaderContent, ctx_2);
          r += '</div>\n                                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                            </div>\n                        </div>\n                    </li>\n                </ul>\n            </div>\n            <div class="block no-margin-vertical">\n                <div class="action_wrapper">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.full, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.full, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <button @click="submit">Перевести ';
              r += Template7Helpers.formatPrice.call(ctx_3, ctx_3.value, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.currency, ctx_3);
              r += '</button>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                            <button class="disabled"><div class="preloader color-white">';
          r += c(ctx_2.$app.utils.iosPreloaderContent, ctx_2);
          r += '</div></button>\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    .increase-balance .list-details:nth-child(2) {
        margin-top: 24px;
    }
    .increase-balance .list-details:nth-child(3) {
        margin-top: 16px;
    }
`,
  styleScoped: false
};
    
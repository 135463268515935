
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: () => ({
    matchInfo: null,
    selectedTeam: null,
    selectedPlayer: [],
    submitSheet: null,
    countSelectedPlayer: 0
  }),
  methods: {
    SUBMIT() {
      try {
        let self = this;
        if (self.countSelectedPlayer < 11) {
          console.log('1: ', self.countSelectedPlayer);
          return false;
        }
        if (self.countSelectedPlayer > 11) {
          console.log('2: ', self.countSelectedPlayer);
          self.$app.dialog.alert('Игроков должно быть 11', '');
          return false;
        }
        self.$app.preloader.show();
        let payload = {
          players_ids: self.selectedPlayer,
          match_id: self.matchInfo.match_id
        };
        self.$root.api(`app/quiz/players`, payload, 'POST', function (data, status, xhr) {
          self.$app.preloader.hide();
          if (data.status == 'error') {
            self.$app.dialog.alert(data.message, 'Ошибка');
          }
          if (data.status == 'ok') {
            self.$app.dialog.alert('Заявка принята. Следите за результатом после того как событие начнется', 'Успешно');
            self.$root.events.emit('lobby.updateMiniGames');
            self.$router.back();
          }
        }, true);
      } catch (e) {
        self.$app.dialog.alert('Попробуйте позже', 'Что-то пошло не так');
      }
    },
    preventEvent(e) {
      e.preventDefault();
    },
    pickPlayer(id) {
      const elem = event.target.closest('.player');
      const input = elem.querySelector('input[type="checkbox"]');
      let btn = this.submitSheet.querySelector('.button');
      if (input.getAttribute('checked')) {
        input.removeAttribute('checked');
        const idx = this.selectedPlayer.findIndex(el => el == input.value);
        this.selectedPlayer = [
          ...this.selectedPlayer.slice(0, idx),
          ...this.selectedPlayer.slice(idx + 1, this.selectedPlayer.length)
        ];
        this.$setState({
          selectedPlayer: this.selectedPlayer,
          countSelectedPlayer: this.countSelectedPlayer - 1
        });
        if (!btn.classList.contains('disabled')) {
          btn.classList.add('disabled');
        }
        if (this.countSelectedPlayer == 11) {
          if (btn.classList.contains('disabled')) {
            btn.classList.remove('disabled');
          }
          if (btn.classList.contains('overflow')) {
            btn.classList.remove('overflow');
          }
        }
      } else {
        input.setAttribute('checked', true);
        this.selectedPlayer.push(input.value);
        this.$setState({
          selectedPlayer: this.selectedPlayer,
          countSelectedPlayer: this.countSelectedPlayer + 1
        });
        if (this.countSelectedPlayer > 11) {
          btn.classList.add('overflow');
        }
        if (this.countSelectedPlayer == 11) {
          if (btn.classList.contains('disabled')) {
            btn.classList.remove('disabled');
          }
          if (btn.classList.contains('overflow')) {
            btn.classList.remove('overflow');
          }
        }
      }
    },
    fetchGameInfo(id, team) {
      var self = this;
      self.$app.preloader.show();
      self.$root.api(`/app/quiz/match/${ id }?team=${ team }`, {}, 'GET', function (data, status, xhr) {
        self.$setState({
          matchInfo: prepareMatch(data.match),
          selectedTeam: team
        });
        self.$app.preloader.hide();
      }, true);
      function prepareMatch(match) {
        let players = {
          'A': [],
          'M': [],
          'D': [],
          'G': []
        };
        match.players.map(player => {
          switch (player.position_code) {
          case 'A':
            players['A'].push(player);
            break;
          case 'M':
            players['M'].push(player);
            break;
          case 'D':
            players['D'].push(player);
            break;
          case 'G':
            players['G'].push(player);
            break;
          }
        });
        match.players = players;
        match.started_at = handlerDate(match.started_at);
        return match;
      }
      function handlerDate(startedAt) {
        let date = new Date(startedAt);
        let day = date.getDate();
        if (day < 10) {
          day = '0' + day;
        }
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = '0' + month;
        }
        let year = date.getFullYear();
        year = String(year).slice(-2);
        let hours = date.getHours();
        if (hours < 10) {
          hours = '0' + hours;
        }
        let minutes = date.getMinutes();
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        return `${ day }.${ month }.${ year } ${ hours }:${ minutes }`;
      }
    }
  },
  on: {
    pageInit: function () {
      let matchID = this.$f7route.query.matchId;
      let team = this.$f7route.query.team;
      this.fetchGameInfo(matchID, team);
      this.submitSheet = this.$el.find('.submit-sheet')[0];
      if (document.querySelector('.toolbar')) {
        document.querySelector('.toolbar').classList.add('fade-out');
      }
      this.$app.sheet.open(this.submitSheet);
    },
    pageBeforeOut: function () {
      if (this.submitSheet)
        this.$app.sheet.close(this.submitSheet);
      document.querySelector('.toolbar').classList.remove('fade-out');
      document.querySelector('.toolbar').classList.add('fade-in');
    }
  },
  id: '2f686c894c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page who-will-play-page" data-name="who-will-play-page">\n        <div class="navbar">\n            <div class="navbar-bg"></div>\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Угадай состав</div>\n            </div>\n        </div>\n        <div class="page-content">\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.matchInfo, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.with.call(ctx_2, ctx_2.matchInfo, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="header-block fade-in">\n                        <div class="teams">\n                            <div class="localTeam ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.team_name == this.localteam_name', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'selected';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">\n                                <span>';
              r += c(ctx_3.localteam_name, ctx_3);
              r += '</span>\n                            </div>\n                            <div class="score">\n                                <span class="date">';
              r += c(ctx_3.started_at, ctx_3);
              r += '</span>\n                                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.team_name == this.localteam_name', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    <i class="icon icon-long-arrow-alt-left"></i>\n                                ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                    <i class="icon icon-long-arrow-alt-right"></i>\n                                ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                            </div>\n                            <div class="visitorTeam ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.team_name == this.visitorteam_name', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'selected';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">\n                                <span>';
              r += c(ctx_3.visitorteam_name, ctx_3);
              r += '</span>\n                            </div>\n                        </div>\n                        <div class="league">\n                            <span>';
              r += c(ctx_3.league_name_ru, ctx_3);
              r += '</span>\n                        </div>\n                        <div class="positions tabbar">\n                            <a href="#tab-A" class="tab-link tab-link-active">НАП</a>\n                            <a href="#tab-M" class="tab-link">ПЗ</a>\n                            <a href="#tab-D" class="tab-link">ЗАЩ</a>\n                            <a href="#tab-G" class="tab-link">ВР</a>\n                        </div>\n                    </div>\n                    <div class="tabs">\n                        <div id="tab-A" class="page-content fade-in-x2 tab tab-active">\n                            <div class="block">\n                                <ul>\n                                    ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.players['A'], {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <li class="player" @click="pickPlayer(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\')">\n                                            <div class="left-side">\n                                                <img src="';
                  r += c(ctx_4.image, ctx_4);
                  r += '" alt="">\n                                                <div class="description">\n                                                    <span class="name">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</span>\n                                                    <span class="position">';
                  r += c(ctx_4.position, ctx_4);
                  r += '</span>\n                                                </div>\n                                            </div>\n                                            <label class="checkbox item-checkbox" @click="preventEvent">\n                                                <input type="checkbox" name="player" value="';
                  r += c(ctx_4.id, ctx_4);
                  r += '">\n                                                <i class="icon-checkbox"></i>\n                                            </label>\n                                        </li>\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </ul>\n                            </div>\n                        </div>\n                        <div id="tab-M" class="page-content fade-in-x2 tab">\n                            <div class="block">\n                                <ul>\n                                    ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.players['M'], {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <li class="player" @click="pickPlayer(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\')">\n                                            <div class="left-side">\n                                                <img src="';
                  r += c(ctx_4.image, ctx_4);
                  r += '" alt="">\n                                                <div class="description">\n                                                    <span class="name">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</span>\n                                                    <span class="position">';
                  r += c(ctx_4.position, ctx_4);
                  r += '</span>\n                                                </div>\n                                            </div>\n                                            <label class="checkbox item-checkbox" @click="preventEvent">\n                                                <input type="checkbox" name="player" value="';
                  r += c(ctx_4.id, ctx_4);
                  r += '">\n                                                <i class="icon-checkbox"></i>\n                                            </label>\n                                        </li>\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </ul>\n                            </div>\n                        </div>\n                        <div id="tab-D" class="page-content fade-in-x2 tab">\n                            <div class="block">\n                                <ul>\n                                    ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.players['D'], {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <li class="player" @click="pickPlayer(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\')">\n                                            <div class="left-side">\n                                                <img src="';
                  r += c(ctx_4.image, ctx_4);
                  r += '" alt="">\n                                                <div class="description">\n                                                    <span class="name">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</span>\n                                                    <span class="position">';
                  r += c(ctx_4.position, ctx_4);
                  r += '</span>\n                                                </div>\n                                            </div>\n                                            <label class="checkbox item-checkbox" @click="preventEvent">\n                                                <input type="checkbox" name="player" value="';
                  r += c(ctx_4.id, ctx_4);
                  r += '">\n                                                <i class="icon-checkbox"></i>\n                                            </label>\n                                        </li>\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </ul>\n                            </div>\n                        </div>\n                        <div id="tab-G" class="page-content fade-in-x2 tab">\n                            <div class="block">\n                                <ul>\n                                    ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.players['G'], {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                        <li class="player" @click="pickPlayer(\'';
                  r += c(ctx_4.id, ctx_4);
                  r += '\')">\n                                            <div class="left-side">\n                                                <img src="';
                  r += c(ctx_4.image, ctx_4);
                  r += '" alt="">\n                                                <div class="description">\n                                                    <span class="name">';
                  r += c(ctx_4.name, ctx_4);
                  r += '</span>\n                                                    <span class="position">';
                  r += c(ctx_4.position, ctx_4);
                  r += '</span>\n                                                </div>\n                                            </div>\n                                            <label class="checkbox item-checkbox" @click="preventEvent">\n                                                <input type="checkbox" name="player" value="';
                  r += c(ctx_4.id, ctx_4);
                  r += '">\n                                                <i class="icon-checkbox"></i>\n                                            </label>\n                                        </li>\n                                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                </ul>\n                            </div>\n                        </div>\n                    </div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </div>\n        <!-- Навигационное модальное окно -->\n        <div class="sheet-modal submit-sheet">\n            <div class="sheet-modal-inner">\n            <div class="page-content">\n                <div class="block">\n                    <button class="button success disabled" @click="SUBMIT">Продолжить (';
      r += c(ctx_1.countSelectedPlayer, ctx_1);
      r += ' из 11)</button>\n                </div>\n            </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
.who-will-play-page {

}
.who-will-play-page .page-content {
    padding-bottom: 0px;
    padding-top: 44px !important;
}
.who-will-play-page .page-content .header-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    background-color: #FFFFFF;
    z-index: 9;
}
.who-will-play-page .page-content .header-block:after {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    top: 100%;
    bottom: auto;
    height: 8px;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.08) 40%, rgba(0, 0, 0, 0.04) 50%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    z-index: 99999;
}
.who-will-play-page .page-content .header-block .teams {
    display: flex;
    height: 64px;
    width: 100%;
    position: relative;
}
.who-will-play-page .page-content .header-block .league {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.41px;
    color: #445058;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}
.who-will-play-page .page-content .header-block .league span {
    padding: 0px 16px 8px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.who-will-play-page .page-content .header-block .league:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #000000;
    position: absolute;
    bottom: 0;
    opacity: 0.1;
}
.who-will-play-page .page-content .header-block .teams .team-highlight span{
    font-weight: 600;
}
.who-will-play-page .page-content .header-block .teams .localTeam {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 16px;
}
.who-will-play-page .page-content .header-block .teams .localTeam span {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.41px;
    color: #445058;
}
.who-will-play-page .page-content .header-block .teams .localTeam.selected span {
    font-weight: 600;
}
.who-will-play-page .page-content .header-block .teams .score {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}
.who-will-play-page .page-content .header-block .teams .score span.date {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #99A8B1;
    padding-bottom: 6px;
    margin-top: -12px;
}
.who-will-play-page .page-content .header-block .teams .score span.count {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    color: #445058;
}
.who-will-play-page .page-content .header-block .teams .visitorTeam {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 16px;
}
.who-will-play-page .page-content .header-block .teams .visitorTeam.selected span {
    font-weight: 600;
}
.who-will-play-page .page-content .header-block .teams .visitorTeam span {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.41px;
    color: #445058;
}
.who-will-play-page .page-content .header-block .positions {
    display: flex;
    height: 50px;
    width: 100%;
}
.who-will-play-page .page-content .header-block .positions .tab-link {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.41px;
    color: #007AFF;
    position: relative;
}
.who-will-play-page .page-content .header-block .positions .tab-link.tab-link-active {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.41px;
    color: #007AFF;
}
.who-will-play-page .page-content .header-block .positions .tab-link.tab-link-active:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #007AFF;
    position: absolute;
    bottom: 0;
}
.who-will-play-page .page-content .tabs {
    height: calc(100% - 76px - 144px - var(--f7-safe-area-bottom));
    overflow-y: auto;
    /* margin-top: 16px; */
}
.who-will-play-page .page-content .tabs .page-content {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 0px !important;
}
.who-will-play-page .page-content .tabs .page-content {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #FFFFFF;
    position: relative;
    height: auto;
}
.who-will-play-page .page-content .tabs .page-content:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #D1D1D6;
    position: absolute;
    bottom: 0;
    opacity: 0.7;
}
.who-will-play-page .page-content .tabs .page-content:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #D1D1D6;
    position: absolute;
    top: 0;
    opacity: 0.7;
}
.who-will-play-page .page-content .tabs .page-content .block {
    padding: 0px;
    margin: 0px;
}
.who-will-play-page .page-content .tabs .page-content ul {
    padding: 0px;
    margin: 0px;
}
.who-will-play-page .page-content .tabs .page-content ul li {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.who-will-play-page .page-content .tabs .page-content .player {
    padding: 8px 16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.who-will-play-page .page-content .tabs .page-content .player:active {
    /* background-color: var(--f7-list-link-pressed-bg-color); */
}
/* .who-will-play-page .page-content .tabs .page-content .player:active .icon-checkbox {
    border: 1px solid #FFFFFF;
} */
.who-will-play-page .page-content .tabs .page-content .player img {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.who-will-play-page .page-content .tabs .page-content .player:not(:last-child):after {
    content: "";
    width: calc(100% - 16px);
    height: 1px;
    background-color: #D1D1D6;
    position: absolute;
    bottom: 0;
    opacity: 0.7;
}
.who-will-play-page .page-content .tabs .page-content .player .left-side {
    display: flex;
}
.who-will-play-page .page-content .tabs .page-content .player .left-side .description {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}
.who-will-play-page .page-content .tabs .page-content .player .left-side .name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.35px;
    color: #000000;
}
.who-will-play-page .page-content .tabs .page-content .player .left-side .name + .position {
    margin-top: 3px;
}
.who-will-play-page .page-content .tabs .page-content .player .left-side .position {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #787E8A;
}
.who-will-play-page .page-content .tabs .page-content .player .item-checkbox {
    display: flex;
    align-items: center;
    background-color: transparent !important;
}
.who-will-play-page .page-content .tabs .page-content .player .item-checkbox .icon-checkbox {
    margin-right: 0px;
}
 /* Навигационное модальное окно */
.submit-sheet {
    height: calc(79px + var(--f7-safe-area-bottom));
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
}
.submit-sheet .page-content {
    background-color: #ffffff;
}
.submit-sheet .page-content .block {
    margin-top: 15.5px;
    margin-bottom: calc(15.5px + var(--f7-safe-area-bottom));
}
.submit-sheet .page-content .block .button {
    height: 46px;
}
.submit-sheet .page-content .block .button.disabled {
    background-color: #CFD6DB;
    opacity: 1 !important;
}
.submit-sheet .page-content .block .button.overflow {
    background-color: #EB4036;
}
`,
  styleScoped: false
};
    
export default {
  id: '717fee0b5a',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                    <div class="left">\n                            <a href="#" class="link back icon-only">\n                                <i class="icon icon-back"></i>\n                            </a>\n                        </div>\n                <div class="title">\n                    Информация\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content">\n\n                <div class="list list-info links-list">\n                    <ul>\n                        <li>\n                            <a href="/info/rules/" class="item-content">\n                                <div class="item-inner">\n                                    <div class="item-title">Правила сервиса</div>\n                                </div>\n                            </a>\n                        </li>\n                        <li>\n                            <a href="/info/terms/" class="item-content">\n                                <div class="item-inner">\n                                    <div class="item-title">Политика конфиденциальности</div>\n                                </div>\n                            </a>\n                        </li>\n                    </ul>\n                </div>\n\n\n        </div>\n\n    </div>\n\n';
      return r;
    }(this);
  },
  styleScoped: false
};
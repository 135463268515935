//Please define App version at ROW 237
import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import 'framework7/css/framework7.bundle.css';

import Template7 from 'template7';

import '../css/icons.css';
import '../css/app.css';
import '../css/animations.css';

import cordovaApp from './cordova-app.js';
import routes from './routes.js';

import GooglePay from './google-pay/google-pay';

Template7.registerHelper('checkMessage', function (message, options) {
    var NewMessage = '';
    switch (message) {
        case 'Изменение баланса администратором':
            NewMessage = 'Изменение баланса' + '<br>' + 'администратором';
            break;
        default:
            NewMessage = message;
    }
    return NewMessage;
});

Template7.registerHelper('formatPrice', function (price, options) {
    if (typeof price === 'function') price = price.call(this);
    var parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
});

Template7.registerHelper('formatPriceInteger', function (price, options) {
    if (typeof price === 'function') price = price.call(this);
    var parts = price.toString().split(".");
    if (Math.round(+parts[1] / 100) == 1) {
        parts[0] = '' + (+parts[0] + 1);
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts[0];
});

Template7.registerHelper('formatDate', function (value, options) {
    if (typeof value === 'function') value = value.call(this);
    var d = new Date(value);

    var hours = d.getHours();
    var minutes = d.getMinutes();
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear().toString().substr(2, 2);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    var dateToday = new Date();
    var dateTomorrow = new Date();
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);
    var dateComment = null;

    if (d.setHours(0, 0, 0, 0) == dateToday.setHours(0, 0, 0, 0)) {
        dateComment = 'Сегодня';
    }
    if (dateTomorrow.getFullYear() == d.getFullYear() && dateTomorrow.getMonth() == d.getMonth() && dateTomorrow.getDate() == d.getDate()) {
        dateComment = 'Завтра';
    }

    if (options.hash.type == 'date') {
        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }
    if (options.hash.type == 'time') {
        return hours + ':' + minutes;
    }

    if (options.hash.type == 'full') {
        if (dateComment !== null) {
            return dateComment + ' ' + hours + ':' + minutes;
        } else {
            return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
        }
    }
});

Template7.registerPartial('network-error', `
    <div>
        <div class="text-align-center" style="margin-top: 120px;"><img src="./static/warning-large.svg" style="width:100px; height: 100px;"></div>
        <div style="margin-top:10px;text-align:center;color: #818E95;font-size: 24px;">Ошибка подключения</div>
        <div style="margin-top: 10px;text-align:center;color: #ACB4BE;font-size: 16px;">Произошла неудачная загрузка данных, попробуйте еще раз</div>
        <div class="text-align-center" style="margin-top: 20px;"><a href="#" class="network-error-refresh">Обновить</a></div>
    </div>
`);

Template7.registerPartial('duel-item',
    `<div key="duel-{{../id}}">
<div class="block scores-item-block">
    <i class="icon-duel-list icon"></i>
    <span>{{formatDate duel.match_1.started_at type="date"}}</span>
</div>
<a href="/duels/view/?id={{../id}}">
    <div class="duel-item no-padding-top" style="margin-bottom: 0px">
        <div class="row no-gap">
            <div class="col-50 duel-item-left">
                <div class="duel-item-player">
                    <div class="duel-item-player-avatar">
                        <img src="{{duel.player_1.image}}">
                    </div>
                    <div class="duel-item-player-info">
                        <div style="color: #007AFF;font-size: 15px;font-weight: 600;line-height: 95%; height: 30px; overflow:hidden;">{{escape duel.player_1.name}}</div>
                        <div class="duel-item-player-team"><span class="{{#js_if "this.duel.match_1.localteam == this.duel.player_1.team"}}text-font-weight-bold{{/js_if}}">{{escape duel.match_1.localteam}}</span>@<span class="{{#js_if "this.duel.match_1.visitorteam == this.duel.player_1.team"}}text-font-weight-bold{{/js_if}}">{{escape duel.match_1.visitorteam}}</span></div>
                        <div class="duel-item-player-time">{{formatDate duel.match_1.started_at type="full"}}</div>
                    </div>
                </div>

            </div>
            <div class="col-50 duel-item-right">
                <div class="duel-item-player">

                    <div class="duel-item-player-info text-align-right">
                        <div style="color: #007AFF;font-size: 15px;font-weight: 600;line-height: 95%; height: 30px; overflow:hidden;">{{escape duel.player_2.name}}</div>
                        <div class="duel-item-player-team"><span class="{{#js_if "this.duel.match_2.localteam == this.duel.player_2.team"}}text-font-weight-bold{{/js_if}}">{{escape duel.match_2.localteam}}</span>@<span class="{{#js_if "this.duel.match_2.visitorteam == this.duel.player_2.team"}}text-font-weight-bold{{/js_if}}">{{escape duel.match_2.visitorteam}}</span></div>
                        <div class="duel-item-player-time">{{formatDate duel.match_2.started_at type="full"}}</div>
                    </div>
                    <div class="duel-item-player-avatar">
                        <img src="{{duel.player_2.image}}">
                    </div>
                </div>
            </div>
        </div>
        <div class="duel-item-bottom" style="height: 51px; padding: 0 16px;">
            <div class="row" style="width: 100%;">
                <div class="col-40 duel-item-bottom-left" style="height: 100%;display: flex;align-items: center;">
                    {{#if left}}

                        <div class="duel-item-user" style="height: 100%; display: flex;align-items: center;min-width:0;">
                            <div class="duel-item-user-avatar" style="margin-right: 16px;"><img src="{{left.client.avatar}}"></div>
                            <span class="{{#if left.is_winner}}icon-cup{{/if}}" style="background-repeat: no-repeat;width: 20px;height: 20px;position: relative; top: 9px; left: -26px;"></span>
                            <div class="duel-user-info" style="margin-left: -22px;min-width:0;">
                                <div class="duel-item-user-name">{{escape left.client.name}}</div>
                                <div class="duel-item-user-amount" style="{{#if left.is_winner}}color: #32BF61;{{/if}}">{{left.amount.value}}{{left.amount.currency}}</div>
                            </div>
                         </div>
                     {{else}}
                        <div class="duel-item-search" style="color: #445058;">Поиск соперника....</div>
                    {{/if}}
                </div>
                <div class="col-20 duel-item-prize-value" style="height: 100%;display: flex;justify-content: center; align-content: center; flex-direction: column;">
                       <div class="text-align-center" style="font-weight: 500;display:flex;align-items: center;justify-content: center;color:#445058;">{{js "this.self.prize.value"}}{{self.amount.currency}}</div>
                       <div class="text-align-center duel-item-prize-text" style="color: #818E95;font-size: 10px;">ПРИЗ</div>
                </div>
                <div class="col-40 duel-item-bottom-right" style="height: 100%; display: flex; align-items: center; justify-content: flex-end;">
                     {{#if right}}
                         <div class="duel-item-user" style="height: 100%; display: flex;align-items: center;justify-content: right; min-width:0;">
                            <div class="duel-user-info" style="min-width:0;margin-right: -22px;">
                                <div class="duel-item-user-name" style="text-align: right">{{escape right.client.name}}</div>
                                <div class="duel-item-user-amount" style="{{#if right.is_winner}}color: #32BF61;{{/if}}    justify-content: flex-end;">{{right.amount.value}}{{right.amount.currency}}</div>
                            </div>
                            <span class="{{#if right.is_winner}}icon-cup{{/if}}" style="background-repeat:no-repeat;width: 20px;height: 20px;position: relative; top: 9px; left: 26px;"></span>
                            <div class="duel-item-user-avatar" style="margin-left: 16px;"><img src="{{right.client.avatar}}"></div>
                        </div>
                    {{else}}
                         <div class="duel-item-search" style="color: #445058;">Поиск соперника....</div>
                    {{/if}}
                </div>
            </div>
        </div>
    </div>
</a></div>`);

Template7.registerPartial('game-item',
    '<div key="game-{{id}}"><div class="block scores-item-block"> ' +
    ' <i class="icon-sport-ucl icon"></i> ' +
    ' <span>{{formatDate started_at type="date"}}</span> ' +
    '</div> ' +
    '<div class="list list-scores no-chevron" style="margin-bottom: 0;"> ' +
    ' <ul> ' +
    '   <li> ' +
    '    <a href="/games/scores/?id={{id}}" class="item-link item-content"> ' +
    '     <div class="item-inner" style="flex-direction: column; align-items: normal;"> ' +
    '      <div class="scores-item-row1"> ' +
    '       <div class="scores-item-row1-title"> ' +
    '        {{escape title}} ' +
    '       </div> ' +
    '       <div class="scores-item-row1-icon"> ' +
    '       </div> ' +
    '      </div> ' +
    ' ' +
    '      <div class="scores-item-row2{{#if is_future}}-future{{/if}}" style="border: 0;"> ' +
    '       <div class="scores-item-row2-col1"> ' +
    '        <div class="game-item-col-top">{{participants.current}}</div> ' +
    '        <div class="game-item-col-bottom">участники</div> ' +
    '       </div> ' +
    '       <div class="scores-item-row2-col2"> ' +
    '        <div class="game-item-col-top">{{formatPrice ingress.value}}{{ingress.currency}}</div> ' +
    '        <div class="game-item-col-bottom">взнос</div> ' +
    '       </div> ' +
    '       <div class="scores-item-row2-col3"> ' +
    '        <div class="game-item-col-top">{{#if prize.message}}TBD{{else}}{{formatPrice prize.value}}{{prize.currency}}{{/if}}</div> ' +
    '        <div class="game-item-col-bottom text-align-right">приз</div> ' +
    '       </div> ' +
    '      </div> ' +
    '      {{#js_if "this.client.score !== null"}} ' +
    '       <div class="scores-item-row3"> ' +
    '        <div class="game-top-progress-marker"> ' +
    '         <div class="game-top-margin"> ' +
    '          <i class="icon-marker" id="top-pb-marker" style="left:calc({{client.position_progress.current}}% - 7px)"></i> ' +
    '         </div> ' +
    '        </div> ' +
    '        <div class="game-top-progress"> ' +
    '         <div class="game-top-progressbar"><div id="top-pb-value" style="width:{{client.position_progress.prize}}%;"></div></div> ' +
    '        </div> ' +
    '        <div class="game-top-progress-text"> ' +
    '         <div class="game-top-margin"> ' +
    '          <div id="top-pb-text" style="width: 30px;text-align:center;left:calc({{client.position_progress.current}}% - 15px)">{{client.score}}</div> ' +
    '         </div> ' +
    '        </div> ' +
    '       </div> ' +
    '       {{/js_if}}' +
    '     </div> ' +
    '    </a> ' +
    '   </li> ' +
    ' </ul> ' +
    '</div>' +

    '<div class="list accordion-list" style="margin-top: 0; margin-bottom: 0px;">' +
    '  <ul>' +
    '    <li class="accordion-item"><a href="#" class="item-content item-link">' +
    '        <div class="item-inner">' +
    '          <div class="item-title">Мои входы ({{entries.length}})</div>' +
    '        </div></a>' +
    '      <div class="accordion-item-content" style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));padding-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));">' +
    '      {{#each entries}}' +
    '      <div class="game-item-enter">' +
    '          <div class="scores-item-row4" style="margin-bottom: 8px;;"> ' +
    '              {{#each players}} ' +
    '                  <div class="box">' +
    '                      <img src="{{image}}"> ' +
    '                      {{#if is_captain}}' +
    '                      <span class="star"></span>' +
    '                      {{/if}}' +
    '                  </div>' +
    '              {{/each}} ' +
    '              {{#if showUpdate}}          ' +
    '                   <a href="" data-tournament-id="{{../../id}}" data-entry-id="{{entry_num}}" class="update-entry"><i class="icon icon-edit"></i></a>           ' +
    '              {{/if}}            ' +
    '          </div> ' +
    '          {{#if place}}' +
    '              <div class="scores-item-row5" style="padding: 8px 0px;padding-top:0"> ' +
    '                  <div class="scores-item-row5-col1 justify-content-center"> ' +
    '                       <div class="game-item-col-top">{{place}}</div> ' +
    '                       <div class="game-item-col-bottom">место</div> ' +
    '                  </div> ' +
    '                  <div class="scores-item-row5-col2 justify-content-center"> ' +
    '                      <div class="game-item-col-top{{#js_if "this.prize.value > 0"}} game-item-col-top-win{{/js_if}}">{{#if prize.message}}TBD{{else}}{{formatPrice prize.value}} {{prize.currency}}{{/if}}</div> ' +
    '                      <div class="game-item-col-bottom">выигрыш</div> ' +
    '                  </div> ' +
    '                  <div class="scores-item-row5-col3 justify-content-center"> ' +
    '                      <div class="game-player-points text-align-right"> ' +
    '                          <span class="game-player-points-main">{{js "this.score.toString().split(\'.\')[0]"}}</span> ' +
    '                          {{#js_if "typeof this.score.toString().split(\'.\')[1] !== \'undefined\'"}}' +
    '                              .<span class="game-player-points-add">{{js "this.score.toString().split(\'.\')[1]"}}</span> ' +
    '                          {{/js_if}}' +
    '                      </div> ' +
    '                     <div class="game-player-comment-text text-align-right">очки</div> ' +
    '                  </div>' +
    '              </div> ' +
    '          {{/if}}' +
    '       </div>' +
    '       {{/each}}' +
    '      </div>' +
    '    </li>' +
    '    </ul>' +
    '</div></div>'
);

Template7.registerPartial('profile-details-game-item',
    '<div key="game-{{id}}"><div class="block scores-item-block"> ' +
    ' <i class="icon-sport-ucl-grey icon"></i> ' +
    ' <span>{{formatDate started_at type="date"}}</span> ' +
    '</div> ' +
    '<div class="list list-scores no-chevron" style="margin-bottom: 0;"> ' +
    ' <ul> ' +
    '   <li> ' +
    '    <a href="/games/scores/?id={{id}}" class="item-link item-content"> ' +
    '     <div class="item-inner" style="flex-direction: column; align-items: normal;"> ' +
    '      <div class="scores-item-row1"> ' +
    '       <div class="scores-item-row1-title"> ' +
    '        {{escape title}} ' +
    '       </div> ' +
    '       <div class="scores-item-row1-icon"> ' +
    '       </div> ' +
    '      </div> ' +
    ' ' +
    '      <div class="scores-item-row2{{#if is_future}}-future{{/if}}" style="border: 0;"> ' +
    '       <div class="scores-item-row2-col1"> ' +
    '        <div class="game-item-col-top">{{participants.current}}</div> ' +
    '        <div class="game-item-col-bottom">участники</div> ' +
    '       </div> ' +
    '       <div class="scores-item-row2-col2"> ' +
    '        <div class="game-item-col-top">{{prize.currency}} <span class="value">{{#if prize.message}}TBD{{else}}{{formatPrice prize.value}}{{/if}}</span></div> ' +
    '        <div class="game-item-col-bottom">приз</div> ' +
    '       </div> ' +
    '       <div class="scores-item-row2-col3"> ' +
    '        <div class="game-item-col-top">{{ingress.currency}} <span class="value">{{formatPrice ingress.value}}</span></div> ' +
    '        <div class="game-item-col-bottom text-align-right">взнос</div> ' +
    '       </div> ' +
    '      </div> ' +
    '     </div> ' +
    '    </a> ' +
    '   </li> ' +
    ' </ul> ' +
    '</div>' +

    '<div class="list accordion-list" style="margin-top: 0; margin-bottom: 0px;">' +
    '  <ul>' +
    '    <li class="accordion-item"><a href="#" class="item-content item-link">' +
    '        <div class="item-inner">' +
    '          <div class="item-title">Мои входы ({{entries.length}})</div>' +
    '        </div></a>' +
    '      <div class="accordion-item-content" style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));padding-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));">' +
    '      {{#each entries}}' +
    '      <div class="game-item-enter">' +
    '          <div class="scores-item-row4" style="margin-bottom: 0px;"> ' +
    '              {{#each players}} ' +
    '                  <img src="{{image}}"> ' +
    '              {{/each}} ' +
    '              {{#if showUpdate}}          ' +
    '                   <a href="" data-tournament-id="{{../../id}}" data-entry-id="{{entry_num}}" class="update-entry"><i class="icon icon-edit"></i></a>           ' +
    '              {{/if}}            ' +
    '          </div> ' +
    '          {{#if place}}' +
    '              <div class="scores-item-row5" style="padding: 0px 0px 8px"> ' +
    '                  <div class="scores-item-row5-col1 justify-content-start"> ' +
    '                       <div class="game-item-col-top">{{place}}</div> ' +
    '                       <div class="game-item-col-bottom">место</div> ' +
    '                  </div> ' +
    '                  <div class="scores-item-row5-col2 justify-content-start"> ' +
    '                      <div class="game-item-col-top{{#js_if "this.prize.value > 0"}} game-item-col-top-win green{{/js_if}}">{{#if prize.message}}TBD{{else}}{{formatPrice prize.value}} {{prize.currency}}{{/if}}</div> ' +
    '                      <div class="game-item-col-bottom">выигрыш</div> ' +
    '                  </div> ' +
    '                  <div class="scores-item-row5-col3 justify-content-start"> ' +
    '                       <div class="game-item-col-top">{{score}}</div> ' +
    '                       <div class="game-item-col-bottom">очки</div> ' +
    '                  </div>' +
    '              </div> ' +
    '          {{/if}}' +
    '       </div>' +
    '       {{/each}}' +
    '      </div>' +
    '    </li>' +
    '    </ul>' +
    '</div></div>'
);
Template7.registerPartial('profile-details-quiz',
    '<div key="quiz-{{id}}" class="active-game">' +
    '    <div class="active-game__header display-flex justify-content-space-between align-items-center">' +
    '        <div class="display-flex align-items-center">' +
    '            <i class="icon icon-t-shirt" style="font-size:13px;margin-right:6px;"></i>' +
    '            Угадай состав #{{id}}' +
    '        </div>' +
    '        <div>' +
    '            {{formatDate match.started_at type="date"}}' +
    '        </div>' +
    '    </div>' +
    '    <div class="list list-scores no-chevron" style="margin-top:0;margin-bottom:0;">' +
    '        <ul><li>' +
    '           <a href="/quiz-popup/info/{{id}}/" class="item-link item-content slide-info">' +
    '               <div class="item-inner active-game__content">' +
    '                   <div class="active-game__content-title">{{match.localteam}} – {{match.visitorteam}}</div>' +
    '                   <div class="display-flex justify-content-space-between">' +
    '                       <div class="active-game__content-item text-align-left">' +
    '                           <div class="active-game__content-item-title">{{guessedPlayers}} из {{players_expected.length}}</div>' +
    '                           <div class="active-game__content-item-subtitle">Угадано</div>' +
    '                       </div>' +
    '                       <div class="active-game__content-item text-align-center">' +
    '                           <div class="active-game__content-item-title">{{match.current_state}}</div>' +
    '                           <div class="active-game__content-item-subtitle">Статус</div>' +
    '                       </div>' +
    '                       <div class="active-game__content-item text-align-right">' +
    '                           <div class="active-game__content-item-title"><i class="icon fa-star" style="font-size:13px;margin-right:3px;"></i>{{formatPrice prize.value}}</div>' +
    '                           <div class="active-game__content-item-subtitle">Призовой фонд</div>' +
    '                       </div>' +
    '                   </div>' +
    '               </div>' +
    '           </a>' +
    '        </li></ul>' +
    '    </div>' +
    '</div>'
);

Template7.registerPartial('game-view-player',
    '   <li class="game-player" id="li-{{id}}" {{#if noOpacity}}style="opacity:0;"{{/if}}>' +
    '   {{#if can_get_details}}' +
    '      <a href="/games/profile/?id={{t_id}}&client_id={{id}}&entry={{entry}}" class="item-link item-content {{#unless place}}no-place{{/unless}}">' +
    '{{else}}' +
    '<div class="item-content" style="{{#if place}}padding-left:0;{{/if}}">' +
    '{{/if}}' +
    '     {{#if place}}' +
    '      <div class="item-media text-align-center justify-content-center">' +
    '      {{#if is_prize}}' +
    '       <span class="game-player-place-cup{{#if is_self}}-i{{/if}}"><span>{{place}}</span></span>' +
    '      {{else}}' +
    '       <span class="game-player-place{{#if is_self}}-i{{/if}}"><span>{{place}}</span></span>' +
    '      {{/if}}' +
    '     </div>' +
    '      {{/if}}' +
    '     <div class="item-inner">' +
    '      <img class="game-player-avatar" src="{{avatar}}">' +
    '      {{#if is_prize}}' +
    '       <span class="game-player-icon-cup"><i class="icon-cup-bg"></i></span>' +
    '      {{/if}}' +
    '      <div class="item-title">' +
    '        {{#if can_get_details}}' +
    '           <span class="game-player-name{{#if is_self}}-i{{/if}}" data-id="{{id}}">{{escape name}}</span>' +
    '       {{else}}' +
    '           <a href="/profile/details/?id={{id}}" class="item-link game-player-name{{#if is_self}}-i{{/if}}" data-id="{{id}}" style="color:#445058 !important;">{{escape name}}</a>' +
    '       {{/if}}' +
    '       <div class="item-footer"><span class="game-player-cost game-player-cost{{#js_if "this.prize.value == 0"}}-0{{/js_if}} {{#if is_prize}}green{{/if}}">{{formatPrice prize.value}}{{prize.currency}}</span></div>' +
    '      </div>' +
    '      <div class="item-after align-items-flex-end justify-content-center" style="flex-direction: column;">' +
    '       <div class="game-player-points">' +
    '        <span class="game-player-points-main{{#if is_self}}-i{{/if}} point-part-1">{{js "this.score.toString().split(\'.\')[0]"}}</span>' +
    '        {{#js_if "typeof this.score.toString().split(\'.\')[1] !== \'undefined\'"}}' +
    '          .<span class="game-player-points-add{{#if is_self}}-i{{/if}} point-part-2">{{js "this.score.toString().split(\'.\')[1]"}}</span>' +
    '        {{/js_if}}' +
    '       </div>' +
    '       <div class="game-player-period display-flex align-items-center" style="flex-direction: row;">' +
    '        <span class="game-player-period-value">{{js "this.periods.total - this.periods.current"}}</span>' +
    '        <div class="game-player-period-progressbar"><div style="width: {{periods.percent}}%;"></div></div>' +
    '       </div>' +
    '       <div class="game-player-comment-text">осталось периодов</div>' +
    '      </div>' +
    '     </div>' +
    '   {{#if can_get_details}}' +
    '    </a>' +
    '    {{else}}' +
    '      </div>' +
    '    {{/if}}' +
    '    </a>' +
    '   </li>');


Template7.registerPartial('no-games',
    '<div class="block">' +
    '<div class="no-games-block">' +
    '<div class="image-block"><span class="image-no-games"></span></div>' +
    '<div class="no-games-block-title">Турниров нет</div>' +
    '<div class="no-games-block-description">Вы еще не приняли участие <br>ни в одном турнире</div>' +
    '</div>' +
    '</div>'
);
Template7.registerPartial('no-finished-games',
    '<div class="block">' +
    '<div class="no-finished-games-block">' +
    '<div class="image-block"><span class="image-no-games"></span></div>' +
    '<div class="no-games-block-title">История игр пуста</div>' +
    '<div class="no-games-block-description">Вы еще не приняли участие <br>ни в одном турнире</div>' +
    '</div>' +
    '</div>'
);

var app = new Framework7({
    root: '#app',
    name: 'Fan Battle',
    theme: 'ios',
    id: 'com.fanbattle.app',
    version: "2.9.2",
    view: {
        routesBeforeEnter: function (to, from, resolve, reject) {
            if (to.url.indexOf('/auth/') === 0 || this.app.methods.isAuth()) {
                resolve();
            } else {
                if (to.hash != undefined) {
                    var hashName = to.hash.split('?')[0];

                    if (this.app.views.length == 1) {
                        if (hashName == 'support') {
                            this.navigate('/auth/support/', {animate: false});
                        } else if (hashName == 'verificated') {
                            this.navigate('/auth/profile/verificated/phone/step-1', {animate: false});
                        } else {
                            this.navigate('/auth/sign-in/', {animate: false});
                        }

                    }
                } else {
                    if (this.app.views.length == 1) {
                        if (this.app.methods.getTypeApp() == "olimp") {
                            this.navigate('/auth/something-wrong', {animate: false});
                        } else {
                            this.navigate('/auth/sign-in/', {animate: false});
                        }
                    } else {
                        this.navigate('/auth/sign-in/', {animate: false});
                    }
                }

            }
        }
    },
    routes: routes,
    input: {
        scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
        scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
    },
    statusbar: {
        overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
        androidTextColor: 'white',
    },
    smartSelect: {
        pageBackLinkText: 'Назад',
    },
    dialog: {
        buttonCancel: 'Отмена'
    },
    init: !Framework7.device.cordova,
    on: {
        init: function () {
            var self = this;

            self.methods.showApp();

            self.methods.checkTokenUrl();
            self.methods.setTypeApp();

            // console.log(this.methods.getTypeApp());
            self.methods.updateStyleFromAppType();

            localStorage.removeItem('email')

            try {
                if (universalLinks) {
                    universalLinks.subscribe('openApp', (eventData) => {
                        localStorage.setItem('personal_tournament_code', eventData.params.code);
                    });
                }
            } catch (e) {
            }

            if (self.device.cordova) {
                cordovaApp.init(self);

                // Инициализируем товары в магазине Google Pay
                GooglePay.init(this);
            } else {
                //Для всего что не андройд
                // if (self.methods.getKey('selectedApi') == null) {
                //     // self.data.apiBaseUrl = 'https://api.fanbattle.pro/';
                // } else {
                //     self.data.apiBaseUrl = self.methods.getKey('selectedApi');
                // }

                //Хак для WebView инсты и fb
                const ua = navigator.userAgent;
                if (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1 || ua.indexOf("Instagram") > -1) {
                    this.$('#main-toolbar').css({bottom: '88px'});
                    var sheet = window.document.styleSheets[0];
                    sheet.insertRule('.page-content{ padding-bottom: 138px !important;}', sheet.cssRules.length);
                }

            }

            /* Установка параметра firebase для доступа в любом месте приложения */

            this.$(document).on('click', '.network-error-refresh', function (e) {
                self.views.current.router.refreshPage();
            });

            this.$(document).on('click', '.update-entry', function (e) {
                let tournamentId = self.$(e.target).parent().data('tournament-id');
                let entryId = self.$(e.target).parent().data('entry-id');
                if (typeof tournamentId == 'undefined') {
                    tournamentId = self.$(e.target).data('tournament-id');
                    entryId = self.$(e.target).data('entry-id');
                }
                self.methods.updateEntry(tournamentId, entryId);
            });

            this.$(document).on('click', '.tabbar .tab-link', function (e) {
                // var viewName = self.$(e.target).attr('href') !== null ? self.$(e.target).attr('href').replace("#view-", "") :  self.$(e.target).parent().attr('href').replace("#view-", "");
                const viewName = self.$(e.target).closest('.tab-link')[0].getAttribute('href').replace("#view-", "");
                if (self.data.lastViewClick != viewName) {
                    self.data.lastViewClick = viewName;
                    switch (viewName) {
                        case 'lobby' : {
                            // self.views[viewName].router.currentPageEl.f7Component.load();
                            break;
                        }
                        case 'games' : {
                            self.views[viewName].router.currentPageEl.f7Component.load('coming', true);
                            break;
                        }
                        case 'with-friends' : {
                            self.views[viewName].router.currentPageEl.f7Component.load(true);
                            break;
                        }
                        case 'profile' : {
                            document.documentElement.style.setProperty('--navbar-after-height', '0px');
                            self.views[viewName].router.currentPageEl.f7Component.load(true);
                            break;
                        }
                        case 'leaderboard' : {
                            document.documentElement.style.setProperty('--navbar-after-height', '1px');
                            self.views[viewName].router.currentPageEl.f7Component.load(true);
                            break;
                        }
                    }
                } else {
                    if (typeof self.views[viewName] != "undefined") {
                        if (self.views[viewName].router.history.length > 1) {
                            var homeUrl = self.views[viewName].router.history[0];
                            self.views[viewName].router.back(homeUrl, {force: true});
                        }
                    }
                }
            });

            this.$(document).on('page:afterin', (e, page) => {
                page.$el.css('overflow', 'hidden');
                setTimeout(() => {
                    page.$el.css('overflow', '');
                }, 0);
            });

            this.$(document).on('page:init', function (e, page) {
                page.app.methods.checkDomen('fanbattle.ru', 'fanbattle.app', 'fanbattle.mobi');
                if (!page.app.methods.isAuth()) {
                    page.$el.addClass('no-toolbar');
                } else {
                    page.$el.removeClass('no-toolbar');
                }
            })

            this.methods.checkHashLinkTab('profile');

            /** ТУР */

            //this.methods.showLiderboard();
            this.tab.show(`#view-lobby`);

            if (this.methods.isAuth()) {
                // this.methods.updateLiveCount();
            }
        }
    },
    data: {
        apiXHR: [],
        events: new Framework7.Events(),
        apiBaseUrl: 'https://api.fanbattle.pro/', //document.api,
        version: "origin",
        domain: "fanbattle.app", //Для веб берется так, а для приложения - из FireBase
        isNetworkErrorToast: false,
        production: false,
        type: null,
        params: {
            visibleStarIncreaseBalance: true,
            statusGuide: true
        },
        isLive: false,
        is_guest: false,
        lastViewClick: 'lobby',
        registrationTourSeconds: 3600 * 24, //Количество секунд, в течении которых доступен баннер тура,
        //Для алерта с инормацией о новой версии
        alert_btn_action: '() alert_btn_action',
        alert_btn_cancel: '() alert_btn_cancel',
        alert_btn_message: '() alert_btn_message',
        alert_btn_title: '() alert_btn_title'
    },
    methods: {
        showApp() {
            document.querySelector('#app').style.display = 'block';
            document.querySelector('#welcome-screen').classList.add('fade-out');
            document.querySelector('#welcome-screen').style.display = 'none';
        },
        checkTokenUrl() {
            const url_string = window.location.href;
            const url = new URL(url_string);
            const token = url.searchParams.get("token");
            const newClient = url.searchParams.get("is_new_client");
            const error = url.searchParams.get("error");

            this.methods.checkNoSessionParam();

            if (token) {
                this.methods.setToken(token);
                localStorage.removeItem('nosession');
            }
            if (newClient && newClient === "true") {
                this.methods.setCookie('setNickname', true);
                localStorage.removeItem('nosession');
            }

            window.history.pushState({}, document.title, "/");
        },
        alertNoSession() {
            this.dialog.alert('Необходимо зарегистрироваться', '');
        },
        checkNoSessionParam() {
            const url = new URL(window.location.href);
            const nosession = url.searchParams.get("nosession");
            if (nosession) {
                if (nosession === 'true') {
                    localStorage.setItem('nosession', nosession);
                }
            }
        },
        isNosession() {
            return !!localStorage.getItem('nosession');
        },
        setTypeApp() {
            switch (window.location.origin) {
                case "http://localhost:8081":
                    this.data.type = this.data.version;
                    break;
                case "https://olimp.fanbattle.app":
                    this.data.type = "olimp";
                    break;
                default:
                    this.data.type = "origin";
            }
        },
        getTypeApp() {
            return this.data.type;
        },
        updateStyleFromAppType() {
            let root = document.documentElement;
            if (this.methods.getTypeApp() === "olimp") {
                // root.style.setProperty('--f7-navbar-bg-color', "#FFFFFF");
                // root.style.setProperty('--f7-navbar-text-color', "#000000");
                // root.style.setProperty('--f7-navbar-link-color', "#007AFF");
                // root.style.setProperty('--f7-theme-color', "#FFFFFF");
                // root.style.setProperty('--f7-theme-text', "#007AFF");
                // root.style.setProperty('--f7-theme-icon-color', "#007AFF");
                // root.style.setProperty('--f7-theme-text-profile-details', "#000000");
                // root.style.setProperty('--f7-skeleton-color', "#EFEFF4");
                // root.style.setProperty('--f7-skeleton-bg', "#EFEFF4");
                // root.style.setProperty('--tab-lobby-duels-padding-top', '0px');
                // root.style.setProperty('--popup-navbar-after-height', '3px');
                // root.style.setProperty('--navbar-steeper-li-bg', '#000000');
                // root.style.setProperty('--navbar-steeper-li-flag', 'url("../static/icons/flag-white.svg")');
                // root.style.setProperty('--f7-checkbox-active-color', '#007AFF');
                // root.style.setProperty('--f7-actions-button-text-color', '#007AFF');
                // root.style.setProperty('--f7-progressbar-progress-color', '#007AFF');
                // root.style.setProperty('--f7-dialog-button-text-color', '#007AFF');

                // root.style.setProperty('--tab-lobby-duels-padding-top', '100px');
                root.style.setProperty('--f7-theme-text-profile-details', "#FFFFFF");
                root.style.setProperty('--f7-skeleton-bg', "#2b8ffc");
                root.style.setProperty('--f7-theme-text', "#FFFFFF");
                // root.style.setProperty('--f7-theme-icon-color', "#007AFF");
                root.style.setProperty('--f7-navbar-content', 'none');
                root.style.setProperty('--popup-navbar-after-height', '0px');
                root.style.setProperty('--navbar-steeper-li-bg', '#FFFFFF');
                root.style.setProperty('--navbar-steeper-li-flag', 'url("../static/icons/flag.svg")');
                root.style.setProperty('--f7-dialog-button-text-color', '#007AFF');
                root.style.setProperty('--view-lobby-padding-top', '44px');
            } else {
                root.style.setProperty('--tab-lobby-duels-padding-top', '100px');
                root.style.setProperty('--f7-theme-text-profile-details', "#FFFFFF");
                root.style.setProperty('--f7-skeleton-bg', "#2b8ffc");
                root.style.setProperty('--f7-theme-text', "#FFFFFF");
                root.style.setProperty('--f7-theme-icon-color', "#007AFF");
                root.style.setProperty('--f7-navbar-content', 'none');
                root.style.setProperty('--popup-navbar-after-height', '0px');
                root.style.setProperty('--navbar-steeper-li-bg', '#FFFFFF');
                root.style.setProperty('--navbar-steeper-li-flag', 'url("../static/icons/flag.svg")');
                root.style.setProperty('--f7-dialog-button-text-color', '#007AFF');
                root.style.setProperty('--view-lobby-padding-top', '98px');
            }
        },
        generateDinamicLinkForFriendTournament(pincode) {
            const self = this;

            return new Promise(function (resolve, reject) {
                let data = {
                    "dynamicLinkInfo": {
                        "domainUriPrefix": "https://fanbattle.page.link",
                        "link": `https://fanbattle.app/invite?code=${pincode}`,
                        "androidInfo": {
                            "androidPackageName": "com.fanbattle"
                        },
                        "iosInfo": {
                            "iosBundleId": "com.fanbattle",
                            "iosAppStoreId": "1473117479",
                        },
                        "navigationInfo": {
                            "enableForcedRedirect": true,
                        },
                    }
                };

                fetch("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB6bAnJie8kByffELSIGOu7ntxLrYTVIxg", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((responseData) => {
                        resolve(responseData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        },
        sortTournaments: function (tournaments) {
            tournaments.sort((a, b) => {
                const value1 = a.type == 'duel' ? a.duel.match_1.started_at : a.started_at;
                const value2 = b.type == 'duel' ? b.duel.match_1.started_at : b.started_at;
                return value1 < value2 ? 1 : -1;
            });
        },
        prepareDuels: function (duels) {
            duels.map(d => this.methods.prepareDuel(d));
        },
        prepareDuel: function (d) {
            if (d.duel.status.value != 'finished') {
                if (d.self.length != 0) d.self.is_winner = false;
                if (d.partner.length != 0) d.partner.is_winner = false;
            } else {
                if (d.is_winner) {
                    d.self.is_winner = true;
                    if (d.partner.length != 0) d.partner.is_winner = false;
                } else {
                    d.self.is_winner = false;
                    if (d.partner.length != 0) d.partner.is_winner = true;
                }
            }

            if (d.self.player.id == d.duel.player_1.id) {
                d.left = d.self.length === 0 ? null : d.self;
                d.right = d.partner.length === 0 ? null : d.partner;

            } else {
                d.right = d.self.length === 0 ? null : d.self;
                d.left = d.partner.length === 0 ? null : d.partner;
            }
            return d;
        },
        updateEntry: function (tournamentId, entryId) {
            const self = this;
            const clientId = 1;
            this.preloader.show();

            this.methods.api(`app/client_tournaments/${tournamentId}/self?entry=${entryId}`, {}, 'GET', function (data, status, xhr) {
                if (data.status == 'ok') {
                    self.methods.clearTeam();
                    const team = data.tournament.client.team;
                    for (let i = 0; i < team.length; i++) {
                        self.methods.pushToTeam(team[i].id, team[i].position_key);
                    }
                    self.views.current.router.navigate({
                        name: 'team.create',
                        params: {id: tournamentId, type: 'unknown'},
                        query: {entry_id: entryId},
                    });
                }
            }, true);
        },
        updateLiveCount: function () {
            var self = this;
            self.methods.api('app/client_tournaments/has_live', {}, 'GET', function (data, status, xhr) {
                if (data.status == 'ok') {
                    self.methods.setLiveBadge(data.count_of_live);
                }
            }, true);
        },

        //Метод используется для того, чтобы не грузить значения при старте
        removeNoLoadFromUrl: function (router) {
            router.updateCurrentUrl(router.currentRoute.url.replace('noload=1', ''));
        },
        //Метод генерирует значение таймера в виде ЧЧ:ММ:СС
        renderTimerValue: function (timeStart, seconds) {
            if (timeStart === null) return false;
            //seconds = 7470;
            var diffSeconds = seconds - (((new Date()).getTime() - timeStart) / 1000);
            if (diffSeconds <= 0) return false;
            var diffHours = Math.floor(diffSeconds / 3600);
            var diffMinutes = Math.floor((diffSeconds - 3600 * diffHours) / 60);
            var diffSeconds = Math.floor(diffSeconds - (diffHours * 3600 + diffMinutes * 60));
            return (diffHours < 10 ? '0' : '') + diffHours + ':' + (diffMinutes < 10 ? '0' : '') + diffMinutes + ':' + (diffSeconds < 10 ? '0' : '') + diffSeconds;
        },
        test() {
            console.log('test');
        },
        openTournament(component, id, type, fromTour) {
            var self = this;



            var error = type == 'for_coins' ? true : false;
            var query = {};
            fromTour = typeof fromTour === 'undefined' ? 0 : (fromTour ? 1 : 0);
            query['fromTour'] = fromTour;

            if (self.data.is_guest) {
                if (error === true) {
                    self.dialog.create({
                        text: 'Чтобы участвовать в этих турнирах, необходимо зарегистрироваться',
                        title: 'Игра на деньги',
                        destroyOnClose: true,
                        buttons: [
                            {
                                text: 'Отмена',
                                cssClass: 'color--black',
                            },
                            {
                                text: 'Регистрация',
                                bold: true,
                                cssClass: 'color--green',
                                onClick: function (dialog, e) {
                                    self.methods.api('app/logout', {}, 'POST', function (data, status, xhr) {
                                        if (data.status == 'error') {
                                            if (data.exception == 'AuthenticationException') {
                                                self.methods.logout();
                                            } else {
                                                self.dialog.alert(data.message, 'Ошибка выхода');
                                            }
                                        }
                                        if (data.status == 'ok') {
                                            self.methods.logout();
                                        }
                                    }, true);
                                }
                            }
                        ]
                    }).open();
                } else {
                    component.$router.navigate({
                        name: 'team.create',
                        params: {id, type},
                        query: query,
                    });
                }
            } else {
                component.$router.navigate({
                    name: 'team.create',
                    params: {id, type},
                    query: query,
                });
            }
        },
        canChangeDomain() {
            return false;
            // return this.data.domain ==  'dev.fanbattle.ru' || this.data.domain == 'localhost';
        },
        canChangeApi() {
            return false;
            // return this.data.domain ==  'dev.fanbattle.ru' || this.data.domain == 'localhost';
        },
        getDomain() { //Код работает только с этим методом получения домена
            if (this.device.android) {
                return "fanbattle.app";
            } else {
                return this.methods.canChangeDomain() ? this.methods.getKey('selectedDomain', this.data.domain) : this.data.domain;
            }
        },
        setSelectedDomain(value) { //Установка "выбранного домена"
            this.methods.setKey('selectedDomain', value);
        },
        setSelectedApi(value) { //Установка "выбранного api"
            this.data.apiBaseUrl = value;
            this.methods.setKey('selectedApi', value);
        },
        getSelectedDomain() { //Получение "выбранного домена"
            return this.methods.getKey('selectedDomain');
        },

        getStatusGuide() {
            var self = this;

            self.methods.api('app/profile/guide', {}, 'GET', function (data, status, xhr) {
                self.data.params.statusGuide = data.guide;
                //console.log(self.data.params.statusGuide)
                if (self.data.params.statusGuide === false) {
                    self.data.events.emit('guide.start');
                    //console.log('guide.start');
                }
            }, true);
        },
        setStatusGuide(statusGuide) {
            var self = this;

            self.methods.api('app/profile/guide', {guide: statusGuide}, 'POST', function (data, status, xhr) {
                //console.log(data)
            }, true, {});
        },
        isValidEmail: function (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        sendPushToken: function () {
            var pushToken = this.methods.getKey('push.token');
            if (pushToken === null || !this.methods.isAuth()) return;

            this.methods.api('app/firebase/token', {'fcm_token': pushToken}, 'POST', function (data, status, xhr) {

            }, true, {});
        },
        checkDomen(...hrefs) {
            var self = this;
            hrefs.map(href => {
                if (href == self.methods.getDomain()) {
                    this.data.production = true;
                }
            });
        },
        setLiveBadge: function (value) {
            value !== 0 ? this.data.isLive = true : this.data.isLive = false;
            this.$('.tab-link[href="#view-games"]').find('i').html(value === 0 ? '' : `<span class="badge color-red">${value}</span>`);
        },
        checkHashLinkTab(hash) {
            if (window.location.hash === `#${hash}`) {
                this.tab.show(`#view-${hash}`)
            }
        },
        scoreSplit: function (value) {
            return {
                main: value.toString().split('.')[0],
                add: typeof value.toString().split('.')[1] !== 'undefined' ? value.toString().split('.')[1] : 0,
            }
        },
        copyArray: function (from, to) {
            var matches = [];
            for (var i = 0; i < data.tournament.matches.length; i++) {
                matches.push(self.$app.utils.extend({}, data.tournament.matches[i]));
            }
        },
        hash: function (data) {
            var s = Array.isArray(data) ? data.join(':') : data;
            var hash = 0, chr;
            if (s.length === 0) return hash;
            for (var i = 0; i < s.length; i++) {
                chr = s.charCodeAt(i);
                hash = ((hash << 5) - hash) + chr;
                hash |= 0;
            }
            return hash;
        },

        pushToTeam: function (id, key) {
            var team = this.methods.getTeam().filter(function (v) {
                return v.position_key != key;
            });
            team.push({player_id: id, position_key: key});
            this.methods.setKey('team', JSON.stringify(team));
        },
        getTeam: function () {
            var team = this.methods.getKey('team');
            return team === null ? [] : JSON.parse(team);
        },
        clearTeam: function () {
            this.methods.setKey('team', null);
        },
        showTooltip: function (el, text) {
            var tooltip = this.tooltip.create({
                targetEl: el,
                text: text
            }).show();

            setTimeout(function () {
                tooltip.destroy();
            }, 2000);
        },

        login: function (token, loginParameter) {
            this.methods.setToken(token);
            this.methods.setLoginParameter(loginParameter);

            this.methods.getStatusGuide();

            this.methods.sendPushToken();

            this.views.games.router.navigate('/games/?noload=1', {reloadAll: true});
            this.views["with-friends"].router.navigate('/with-friends/?noload=1', {reloadAll: true});
            this.views.profile.router.navigate('/profile/?noload=1', {reloadAll: true});
            this.views.leaderboard.router.navigate('/leaderboard/?noload=1', {reloadAll: true});

            if (this.methods.getDomain() == 'fanbattle.app' && !this.device.cordova &&
                (this.device.android || this.device.ios) //Нужно убрать отсюда условие для iOS
                && this.methods.getSessionKey('app.offer.show') === null) {
                setTimeout(() => {
                    if (this.methods.getKey('guest_id') == null) {
                        this.views.lobby.router.navigate('/app/');
                    }
                }, 1400);

            }

            this.views.lobby.router.navigate('/lobby/', {reloadAll: true});

            this.tab.show(`#view-lobby`);

            this.methods.updateLiveCount();

            // Устанавливаем для Google Pay
            try {
                if (window.store) {
                    GooglePay.setValidatorHeaders({token});
                }
            } catch (e) {
            }
            ;
        },
        logout: function () {
            this.methods.setToken(null);
            this.methods.setLoginParameter(null);
            this.methods.setSessionKey('app.offer.show', null);
            this.data.lastViewClick = 'lobby';

            for (var i in ['lobby', 'games', 'live', 'profile', 'board']) {

                this.views[i].router.navigate('/auth/sign-in/', {reloadAll: true});
            }

            if (window.webkit) {
                window.webkit.messageHandlers.iosListener.postMessage("logout");
            }

            if (this.data.is_guest) {
                this.data.is_guest = false;
            }
        },

        pushXHR: function (key, XHR) {
            this.data.apiXHR[key] = XHR;
        },
        abortXHR: function (key) {
            if (typeof this.data.apiXHR[key] !== 'undefined') {
                this.data.apiXHR[key].abort();
            }
        },
        showNetworkErrorToast: function () {
            if (this.data.isNetworkErrorToast) return;
            this.data.isNetworkErrorToast = true;
            this.toast.create({
                cssClass: 'toast-network-error',
                text: `
                    <div style="width: 100%;background-color: #BA3C3C;padding: 5px 15px;display: flex; justify-content: left; align-items: center;">
                        <div><img style="display:block;" src="./static/warning.svg" style="width: 25px; height: 25px;"></div>
                        <div style="padding-left: 10px;">
                            <div style="font-size: 14px; color:white;">Ошибка подключения</div>
                            <div style="font-size: 12px;color:#FFFFFF;">Что-то пошло не так. Попробуйте еще раз.</div>
                        </div>
                    </div>
                `,
                position: 'top',
                closeTimeout: 3000,
                destroyOnClose: true,
            }).open();
            setTimeout(() => {
                this.data.isNetworkErrorToast = false
            }, 3000);
        },
        checkApiKey: function () {
            if (location.hash.length > 1) {
                if (location.hash.indexOf('?') != -1) {
                    let key = location.hash.split('?');
                    if (key != undefined) {
                        key = key[1].split('=')[1];
                        return key;
                    }
                }
            }
            return false;
        },
        api: function (url, data, method, successCallback, auth, errorOptions, successOptions) {
            var self = this;
            //Уникальный ключ чтобы различать запросы (мы считаем повторения)
            var requestKey = ('request:' + url + JSON.stringify(data) + method).substring(0, 255);
            var headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
            if (auth) { //Если требуется аутентификация, то добавляем заговок
                headers['Authorization'] = 'Bearer ' + this.methods.getToken();
            }
            if (self.methods.checkApiKey() !== false) {
                headers['Authorization'] = 'Bearer ' + self.methods.checkApiKey();
            }
            data.domain = this.data.domain;

            self.methods.setSessionKey(this.requestKey, 1);//Отмечаем, что это первый запрос
            return this.request({
                requestKey: requestKey, //Нам нужно сохранить
                url: self.data.apiBaseUrl + url,
                method: method,
                data: method == 'POST' ? JSON.stringify(data) : data,
                processData: false,
                dataType: 'json',
                headers: headers,
                timeout: 8000,
                successOptions: typeof successOptions === 'undefined' ? {} : successOptions, //Настройки для успешного ответа (200)
                errorOptions: typeof errorOptions === 'undefined' ? {} : errorOptions, //Настройки для ошибок (любой тип ошибок)
                beforeSend: function (xhr) {

                },
                success: function (data, status, xhr) {
                    self.methods.setSessionKey(this.requestKey, 0);// Очищаем счетчик этого запроса
                    var successOptions = Framework7.utils.extend({ //Мержем настройки для "успеха".
                        hidePreloader: true, //Скрыть прелоадер - поведение по умолчанию
                    }, this.successOptions);

                    if (successOptions.hidePreloader) {
                        self.preloader.hide(); //Скрываем прелоадер, если это нужно согласно настройкам
                    }

                    if (data.status == 'error') { //Запрос прошел, но мы получили статус ошибки от сервера
                        if (data.exception == 'AuthenticationException') { //Обработка определенного типа ошибки: протух токен
                            if (self.methods.getTypeApp() === "olimp") {
                                self.dialog.alert("Необходимо обновить сессию", '', function () {
                                    if (window.webkit) {
                                        window.webkit.messageHandlers.iosListener.postMessage("refresh");
                                        self.$router.navigate({
                                            name: 'something-wrong',
                                        });
                                    }
                                    return false;
                                });
                            } else {
                                return self.methods.logout();
                            }
                        }
                    }
                    successCallback(data, status, xhr); //Вызываем переданный коллбек успеха
                },
                error: function (xhr, status) { //Нет интернета или сервер отдал 200 >= code < 300
                    // var requestCount = self.methods.getSessionKey(this.requestKey); //Какой это по счету запрос
                    // if (requestCount < 1) { //Идем на повторение
                    //     self.methods.setSessionKey(this.requestKey, requestCount + 1);
                    //     self.request(this);
                    //     return;
                    // } else { //Это уже 3й запрос, нужно остановиться и обработать об ошибке
                    //     self.methods.setSessionKey(this.requestKey, 0); //Удалим счетчик
                    var selfRequest = this;
                    setTimeout(function () {

                        var errorOptions = Framework7.utils.extend({ //Мержи настройки ошибки
                            messageCallback: function () { //По умолчанию показывает toast с информацией
                                self.methods.showNetworkErrorToast();
                            },
                            hidePreloader: true, //Спрятать прелоадер
                            callback: undefined //Каллбек не задан
                        }, selfRequest.errorOptions);


                        if (errorOptions.hidePreloader) {
                            self.preloader.hide(); //Прям прелоадер в зависисти от настроек
                        }

                        if (errorOptions.messageCallback !== null) {
                            errorOptions.messageCallback(xhr.response);
                        }
                        if (typeof errorOptions.callback !== 'undefined') { //Если есть коллбек, то вызовем его
                            errorOptions.callback(xhr, status);
                        }
                    }, typeof selfRequest.errorOptions.messageCallback !== 'undefined' ? 1400 : 0);
                    // }
                },
            });
        },
        createPopupView: function () {
            app.views.create('#view-popup');
        },
        authFb: function () {
            FB.login(function (response) {
                // Handle the response object, like in statusChangeCallback() in our demo
                // code.
            });
            FB.getLoginStatus(function (response) {
                fbStatusChangeCallback(response);
            });

        },
        authVk: function () {
            VK.Auth.login(function (response) {
                //console.log(response.session.mid);
            });
        },
        isAuth: function () {
            return this.methods.getToken() !== null;
        },
        getKey: function (id, defaultValue) {
            if (typeof defaultValue === 'undefined') {
                defaultValue = null;
            }
            var value = localStorage.getItem(id);
            if (value !== null && value !== 'null') {
                return value;
            } else {
                return defaultValue;
            }
        },
        getSessionKey: function (id, defaultValue) {
            if (typeof defaultValue === 'undefined') {
                defaultValue = null;
            }
            var value = sessionStorage.getItem(id);
            if (value !== null && value !== 'null') {
                return value;
            } else {
                return defaultValue;
            }
        },
        getToken: function () {
            var token = this.methods.getCookie('auth_token');
            if (token !== null) return token;
            return this.methods.getKey('token');
        },
        getLoginParameter: function () {
            return this.methods.getKey('login_parameter');
        },
        setToken: function (token) {
            this.methods.setCookie('token', null);
            this.methods.setKey('token', token);
        },
        setLoginParameter: function (param) {
            this.methods.setKey('login_parameter', param);
        },
        setKey: function (name, value) {
            localStorage.setItem(name, value);
        },
        setSessionKey: function (name, value) {
            sessionStorage.setItem(name, value);
        },
        getCookie: function (name) {
            var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            return match ? match[2] : null;
        },
        setCookie: function (name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        },
        getUserLanguage: function () {
            return navigator.language || navigator.userLanguage;
        },
        showLiderboard: function () {
            if (this.methods.getDomain() != 'fanbattle.ru') {
                this.$('a[href="#view-leaderboard"]').hide();
            }
        },
        openVersionDialog: function (force, url) {
            var self = this;
            var buttons = [];

            if (!force) {
                buttons.push({
                    text: this.data.alert_btn_cancel,
                });
            }
            buttons.push({
                text: this.data.alert_btn_action,
                bold: true,
                close: false,
                onClick: function (dialog, e) {
                    window.open(url, '_system');
                }
            });
            this.dialog.create({
                title: this.data.alert_title,
                text: this.data.alert_message,
                buttons: buttons,
                destroyOnClose: true,
            }).open();
        },
        compareVersions: function compareVersions(v1, v2) {
            var v1Array = v1.split('.').map(v => parseInt(v));
            var v2Array = v2.split('.').map(v => parseInt(v));
            for (var i = 0; i < 3; i++) {
                if (v1Array[i] > v2Array[i]) return 1;
                if (v1Array[i] < v2Array[i]) return -1;
            }
            return 0;
        }
    }
});
window.APP_FANBATTLE = app;

document.addEventListener('deviceready', function () {
    var myHeaders = new Headers();
    console.log(myHeaders);

    if (window.appMetrica) {
        window.appMetrica.activate({apiKey: 'be7e1730-b804-41d8-9cd8-d485e5477866'});
    }

    app.init();
    // if (app.methods.compareVersions(android_update_version, app.version) == 1) {
    //     app.methods.openVersionDialog(android_update_force == "1", android_update_link);
    // }
    window.navigator.splashscreen.hide();
}, false);


app.views.create('#view-lobby', {
    name: 'lobby',
    main: true,
    url: '/lobby/',

});
app.views.create('#view-games', {
    name: 'games',
    url: '/games/?noload=1',
});
app.views.create('#view-with-friends', {
    name: 'with-friends',
    url: '/with-friends/?noload=1',

});
app.views.create('#view-profile', {
    name: 'profile',
    url: '/profile/?noload=1',
});
app.views.create('#view-leaderboard', {
    name: 'leaderboard',
    url: '/leaderboard/?noload=1',
});

setTimeout(function () {
    //lobbyView.router.navigate('/app/');
}, 300);

setTimeout(function () {
    //app.methods.showNetworkErrorToast();
}, 2000);


// function fbStatusChangeCallback(response) {
//     //console.log('statusChangeCallback');
//     //console.log(response);
//     if (response.status === 'connected') {
//         testAPI();
//     } else {
//         //console.log('Please log into this app.');
//     }
// }

// window.fbAsyncInit = function() {
//     FB.init({
//         appId      : '350753785525366',
//         cookie     : true,
//         xfbml      : false,
//         version    : 'v3.2'
//     });

//     FB.getLoginStatus(function(response) {
//         fbStatusChangeCallback(response);
//     });
// };


// Here we run a very simple test of the Graph API after signin is
// successful.  See statusChangeCallback() for when this call is made.
// function testAPI() {
//     //console.log('Welcome!  Fetching your information.... ');
//     FB.api('/me', function(response) {
//         //console.log('Successful signin for: ' + response.name);
//         //console.log('Thanks for logging in, ' + response.name + '!');
//     });
// }


(function (global) {
    var startY = 0;
    var enabled = false;
    var supportsPassiveOption = false;
    try {
        var opts = Object.defineProperty({}, "passive", {
            get: function () {
                supportsPassiveOption = true
            }
        });
        window.addEventListener("test", null, opts)
    } catch (e) {
    }
    var handleTouchmove = function (evt) {
        var el = evt.target;
        var zoom = window.innerWidth / window.document.documentElement.clientWidth;
        if (evt.touches.length > 1 || zoom !== 1) {
            return
        }
        while (el !== document.body && el !== document) {
            var style = window.getComputedStyle(el);
            if (!style) {
                break
            }
            if (el.nodeName === "INPUT" && el.getAttribute("type") === "range") {
                return
            }
            var scrolling = style.getPropertyValue("-webkit-overflow-scrolling");
            var overflowY = style.getPropertyValue("overflow-y");
            var height = parseInt(style.getPropertyValue("height"), 10);
            var isScrollable = scrolling === "touch" && (overflowY === "auto" || overflowY === "scroll");
            var canScroll = el.scrollHeight > el.offsetHeight;
            if (isScrollable && canScroll) {
                var curY = evt.touches ? evt.touches[0].screenY : evt.screenY;
                var isAtTop = startY <= curY && el.scrollTop === 0;
                var isAtBottom = startY >= curY && el.scrollHeight - el.scrollTop === height;
                if (isAtTop || isAtBottom) {
                    evt.preventDefault()
                }
                return
            }
            el = el.parentNode
        }
        evt.preventDefault()
    };
    var handleTouchstart = function (evt) {
        startY = evt.touches ? evt.touches[0].screenY : evt.screenY
    };
    var enable = function () {
        window.addEventListener("touchstart", handleTouchstart, supportsPassiveOption ? {passive: false} : false);
        window.addEventListener("touchmove", handleTouchmove, supportsPassiveOption ? {passive: false} : false);
        enabled = true
    };
    var disable = function () {
        window.removeEventListener("touchstart", handleTouchstart, false);
        window.removeEventListener("touchmove", handleTouchmove, false);
        enabled = false
    };
    var isEnabled = function () {
        return enabled
    };
    var testDiv = document.createElement("div");
    document.documentElement.appendChild(testDiv);
    testDiv.style.WebkitOverflowScrolling = "touch";
    var scrollSupport = "getComputedStyle" in window && window.getComputedStyle(testDiv)["-webkit-overflow-scrolling"] === "touch";
    document.documentElement.removeChild(testDiv);
    if (scrollSupport) {
        enable()
    }
    var iNoBounce = {enable: enable, disable: disable, isEnabled: isEnabled};
    if (typeof module !== "undefined" && module.exports) {
        module.exports = iNoBounce
    }
    if (typeof global.define === "function") {
        (function (define) {
            define("iNoBounce", [], function () {
                return iNoBounce
            })
        })(global.define)
    } else {
        global.iNoBounce = iNoBounce
    }
})(window);

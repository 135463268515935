const Tournament = {};

Tournament.init = function() {
  return this;
}
Tournament.get7Days = function() {
    let days = [];
    let dimmyWeek = [
      {
        number: 1,
        text: 'ПН',
      },
      {
        number: 2,
        text: 'ВТ',
      },
      {
        number: 3,
        text: 'СР',
      },
      {
        number: 4,
        text: 'ЧТ',
      },
      {
        number: 5,
        text: 'ПТ',
      },
      {
        number: 6,
        text: 'СБ',
      },
      {
        number: 0,
        text: 'ВС',
      }
    ];

    let count = 0;

    while (days.length != 7) {
      const today = new Date()
      const currentDate = new Date(today)
      currentDate.setDate(currentDate.getDate() + count);

      let day = {
        isActive: false,
        date: currentDate,
        day: currentDate.getDate(),
        text: dimmyWeek.find((el) => el.number == currentDate.getDay()).text,
        toDay: false,
        count: count + 1,
      }
      if (count == 0) {
        day.toDay = true;
        day.isActive = true;
      }

      days.push(day);
      count += 1;
    }
    return days;
}

export default Tournament;

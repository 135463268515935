
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      guide: null,
      freeTournament: null,
      clientTournament: null,
      guideTimer: null,
      timer: null,
      paymentGauge: null,
      paymentGaugeValue: null,
      paymentGaugeTimer: null
    };
  },
  methods: {
    setProgress25: function () {
      this.getFreeTrounament();
    },
    setProgress50: function () {
      this.guide.progress = 50;
      this.$setState({ guide: this.guide });
      this.getClientTournament();
    },
    setProgress60: function () {
      var self = this;
      self.destroyProgress75();
      self.guide.progress = 60;
      self.$setState({ guide: self.guide });
      self.setActiveButton(self.$el.find('.button-amount').find('.button')[2]);
      self.$el.find('#pay_timer').data('value', new Date(new Date().getTime() + 10 * 60 * 1000));
      self.$el.find('#payment-link').off('click');
      self.$el.find('#payment-link').on('click', function () {
        self.setProgress75();
      });
    },
    setProgress75: function () {
      var self = this;
      this.guide.progress = 75;
      this.$setState({ guide: this.guide });
      this.paymentGaugeValue = 59;
      this.paymentGauge = this.$app.gauge.create({
        el: this.$el.find('#payment-gauge'),
        type: 'circle',
        value: 1 / 60,
        size: 140,
        borderBgColor: '#FFFFFF',
        borderColor: '#2196f3',
        borderWidth: 10,
        valueText: '0:59',
        valueTextColor: '#2196f3'
      });
    },
    destroyProgress75: function () {
      if (this.paymentGaugeTimer !== null) {
        clearInterval(this.paymentGaugeTimer);
      }
      this.$app.gauge.destroy(this.$el.find('#payment-gauge'));
    },
    setProgress100: function () {
      this.destroyProgress75();
      this.guide.progress = 100;
      this.$setState({ guide: this.guide });
    },
    checkPayment: function () {
      var self = this;
      self.setPaymentCheckLoading(true);
      self.updateGuide(false, true);
      self.paymentGaugeTimer = setInterval(function () {
        if (self.paymentGaugeValue == 0) {
          self.paymentGaugeValue = 59;
        } else {
          self.paymentGaugeValue--;
        }
        self.paymentGauge.update({
          value: 1 - self.paymentGaugeValue / 60,
          valueText: '0:' + (self.paymentGaugeValue < 10 ? '0' : '') + self.paymentGaugeValue
        });
      }, 1000);
    },
    setActiveButton: function (el) {
      this.$(el).parent().find('.button').removeClass('button-active');
      this.$(el).addClass('button-active');
      if (this.$(el).data('bonus') != 0) {
        this.$('#amount-info').html(this.$(el).data('bonus') + '\u20BD Бонус');
      } else {
        this.$('#amount-info').html(this.$(el).data('amount') + '\u20BD');
      }
      var paymentUrl = this.$root.apiBaseUrl + 'app/balance/coins/deposit?amount=' + this.$(el).data('amount') + '&bonus=true&token=' + this.$root.getToken();
      this.$el.find('#payment-link').attr('href', paymentUrl);
    },
    selectAmountButton: function (e) {
      this.setActiveButton(e.target);
    },
    changeAmount: function (e) {
      var self = this;
      this.$app.dialog.prompt('Введите сумму пополнения', 'Сумма', function (value) {
        var isValid = false;
        if (/^\d+$/.test(value)) {
          value = parseInt(value);
          if (value >= 1 && value <= 99999) {
            self.$(e.target).attr('data-amount', value);
            self.setActiveButton(e.target);
            isValid = true;
          }
        }
        if (!isValid) {
          self.$app.dialog.alert('Введите число от 1 до 99999', 'Неправильное число');
        }
      });
    },
    setPaymentCheckLoading: function (loading) {
      if (loading) {
        this.$el.find('#button-check-payment').addClass('disabled').html('<div class="preloader color-white">' + this.$app.utils.iosPreloaderContent + '</div>');
      } else {
        this.$el.find('#button-check-payment').removeClass('disabled').html('Проверить статус платежа');
      }
    },
    getFreeTrounament: function () {
      var self = this;
      this.$root.api('app/tournaments/free', {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
          setTimeout(() => self.back(), 500);
        }
        if (data.status == 'ok') {
          self.$setState({ freeTournament: data.tournament });
        }
      }, true, {});
    },
    getClientTournament: function () {
      var self = this;
      this.$root.api('/app/client_tournaments/free/entry', {}, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
        }
        if (data.status == 'ok') {
          self.$setState({
            clientTournament: {
              namesString: data.tournament.entries[0].players.map(p => p.name).join(', '),
              images: data.tournament.entries[0].players.map(p => p.image),
              started_at: data.tournament.started_at
            }
          });
        }
      }, true);
    },
    updateGuide: function (firstRequest, force) {
      var self = this;
      var url = '/app/profile/guide';
      if (typeof force !== 'undefined' && force) {
        url += '?force_check_payment=true';
      }
      self.$root.api(url, {}, 'GET', function (data, status, xhr) {
        self.setPaymentCheckLoading(false);
        if (data.status == 'ok') {
          if (data.guide.is_finished) {
            self.guide = data.guide;
            self.setProgress100();
          } else {
            if (firstRequest) {
              self.$setState({ guide: data.guide });
              if (self.guide.progress == 25)
                self.setProgress25();
              if (self.guide.progress == 50)
                self.setProgress50();
              if (self.guide.progress == 75)
                self.setProgress75();
              if (self.guide.progress == 100)
                self.setProgress100();
              self.updateTimer();
              self.timer = setInterval(function () {
                self.updateTimer();
              }, 1000);
            }
            self.guideTimer = setTimeout(function () {
              self.updateGuide(false);
            }, 15000);
          }
        }
      }, true, {
        callback: function () {
          self.setPaymentCheckLoading(false);
        }
      });
    },
    openTournament: function (id) {
      this.$root.openTournament(this, id, 'for_coins', true);
    },
    back: function (force) {
      if (typeof force !== 'undefined') {
        this.$router.back({ force: force });
      } else {
        this.$router.back();
      }
    },
    renderColorTimer(el, hours) {
      if (hours >= 1 && hours < 12) {
        el.parent().css('background', '#FAF4A8');
        el.parent().css('color', '#818E95');
      } else if (hours < 1) {
        el.parent().css('background', '#DC5955');
        el.parent().css('color', '#fff');
      } else {
        el.parent().css('background', '#EFEFF4');
        el.parent().css('color', '#818E95');
      }
    },
    updateTimer: function () {
      var self = this;
      self.$el.find('.tournament_timer,.guide_timer,#pay_timer').each(function () {
        var el = self.$(this);
        var timeStarted = new Date(el.data('value'));
        if (timeStarted == '')
          return;
        ;
        var timeCurrent = new Date();
        var distance = timeStarted.getTime() - timeCurrent.getTime();
        if (distance <= 0)
          return;
        var hours = Math.floor(distance / (1000 * 60 * 60));
        var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
        var seconds = Math.floor(distance % (1000 * 60) / 1000);
        if (self.$(this).hasClass('tournament_timer')) {
          self.renderColorTimer(el, hours);
        }
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        el.html(hours + ':' + minutes + ':' + seconds);
      });
    }
  },
  on: {
    pageBeforein: function () {
      this.$(this.$app.navbar.getElByPage(this.$el)).parent().addClass('no-hairline');
      this.$('.toolbar').hide();
    },
    pageAfterout: function () {
      this.$(this.$app.navbar.getElByPage(this.$el)).parent().removeClass('no-hairline');
      this.$('.toolbar').show();
      if (this.timer !== null) {
        clearInterval(this.timer);
      }
      if (this.guideTimer !== null) {
        clearTimeout(this.guideTimer);
      }
      if (this.paymentGaugeTimer !== null) {
        clearInterval(this.paymentGaugeTimer);
      }
    },
    pageInit: function () {
      var self = this;
      this.$root.events.on('team.create.fromGuide', function () {
        setTimeout(function () {
          self.setProgress50();
        }, 700);
      });
      this.updateGuide(true);
    },
    pageAfterOut: function () {
    }
  },
  id: '45d202e42c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page no-toolbar">\n        <div class="navbar">\n            <div class="navbar-inner" style="background-color: #1F375B;">\n                <div class="title">\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.guide, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress == 25', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Вход в турнир';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress == 50', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Вы в турнире';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress == 60', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Получите бонус';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress == 75', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Осталось чуть-чуть';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress == 100', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Поздравляем!';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n                <div class="subnavbar" id="tour-subnavbar" style="display: block;">\n\n                        <div class="tour-banner" style="margin:0;border-radius: 0; height: 102px;">\n                            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.guide, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                            <div class="tour-progress">\n                                <div class="tour-progress-1" style=""></div>\n                                <div class=\'tour-progress-2-3-4 ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress >= 50', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'tour-progress-complete';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\'></div>\n                                <div class=\'tour-progress-2-3-4 ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress >= 75', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'tour-progress-complete';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\'></div>\n                                <div class=\'tour-progress-2-3-4 ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress == 100', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'tour-progress-complete';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\'></div>\n                                <i class="tour-progress-dollar"></i>\n                            </div>\n\n                            <div class="tour-info">\n                                <div>\n                                    <div class="tour-info-left" style="">\n                                        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.guide.progress != 60', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.guide.progress, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '50';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '% Завершено\n                                    </div>\n\n                                </div>\n                                <div>\n                                    <i class="tour-bonus"></i>\n                                </div>\n                            </div>\n                            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                        </div>\n\n                </div>\n            </div>\n        </div>\n\n        <div class="page-content" style="padding-top: 146px;">\n\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.guide !== null && this.guide.progress == 60', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n                <div style="background: #172944;color: #FFFFFF;font-size: 17px; height: 51px; justify-content: center; display: flex; align-items: center; text-align: center;">\n                    Получите 500\u20BD с вашим первым взносом!\n                </div>\n\n                <div style="height: 51px;background: #FECB4C; display: flex;">\n                    <div style="padding: 12px 16px;display: flex; justify-content: center; align-items: center;opacity: 0.7;color: #000000;font-size: 21px;">\n                        <span id="pay_timer" data-value="">10:00:00</span>\n                    </div>\n                    <div style="color: #000000;padding: 0px 16px;opacity: 0.7;font-size: 12px; display:flex;align-items: center;">\n\n                        <span>Чтобы получить <b>БОНУС</b>, пополните баланс до окончания времени</span>\n                    </div>\n                </div>\n\n                <div style="margin: 16px;text-align: center;opacity: 0.7;color: #000000;font-size: 17px;">\n                    Сколько вы готовы внести?\n                </div>\n\n                <div class="block text-align-center" style="margin-bottom: 25px;">\n                    <p class="segmented segmented-raised button-amount" style="background-color: white;">\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.guide.bonuses, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.amount != 5000', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                <a href="#" style="display: flex; align-items: center; height: 44px;"  @click="selectAmountButton" class="button" data-bonus="';
                  r += c(ctx_4.bonus, ctx_4);
                  r += '" data-amount="';
                  r += c(ctx_4.amount, ctx_4);
                  r += '" key="button-amount-';
                  r += c(ctx_4.amout, ctx_4);
                  r += '">';
                  r += c(ctx_4.amount, ctx_4);
                  r += '\u20BD</a>\n                            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        <a href="#" style="display: flex; align-items: center; height: 44px;" class="button" data-bonus="0" data-amount="0" @click="changeAmount" key="button-amount-custom">Другое</a>\n                    </p>\n                </div>\n\n                <div class="block block-strong" id="amount-info"></div>\n\n                <div style="margin: 10px 10px; margin-top: 25px;">\n                    <a id="payment-link" href="#" class="button tour-button external" target="_blank" style="display:flex; align-items: center;">Пополнить</a>\n                </div>\n\n                <div style="margin-top: 5px;">\n                    <button class="button" @click="back">Не сейчас</button>\n                </div>\n\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.guide !== null && this.guide.progress == 25', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n                <div class="block" style="margin-bottom: 20px;">\n                    <div style="font-size: 15px; color: black; text-align:center;">Примите участие в <b>бесплатном</b> турнире, чтобы понять как тут все устроено.</div>\n                    <div style="background-color: white;">\n                    </div>\n                </div>\n\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.freeTournament, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    ';
              r += Template7Helpers.with.call(ctx_3, ctx_3.freeTournament, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                        <div class="block">\n                            <div style="background-color: white; border: 1px solid #CFD6DB;border-radius: 11px;padding: 18px;">\n                                <div style="display: flex; justify-content: space-between">\n\n                                    <div style="display: flex; align-items: center;background: rgb(239, 239, 244); padding: 0px 8px;border-radius:15px;height: 22px;color:rgb(129, 142, 149);;font-size: 12px;">До начала: <span class="tournament_timer" data-value="';
                  r += c(ctx_4.call_for_before, ctx_4);
                  r += '"></span></div>\n                                    <a href="/tournament-popup/info/';
                  r += c(ctx_4.id, ctx_4);
                  r += '/" class="link icon-only slide-info"><i class="f7-icons">info_circle</i></a>\n                                </div>\n\n                                <div style="margin-top: 17px; margin-bottom: 17px;color: #007AFF;font-size: 15px; font-weight: bold;">\n                                    ';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.title, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                </div>\n\n                                <div style="display: flex; justify-content: space-between;">\n                                    <div>\n                                        <div style="color: #445058;font-size: 14px; font-weight: 700;">';
                  r += c(ctx_4.participants.current, ctx_4);
                  r += '</div>\n                                        <div style="color: #818E95;font-size: 10px;">УЧАСТНИКИ</div>\n                                    </div>\n                                    <div>\n                                        <div style="color: #445058;font-size: 14px; font-weight: 700;text-align: center;">Бесплатно</div>\n                                        <div style="color: #818E95;font-size: 10px;text-align: center;">ВЗНОС</div>\n                                    </div>\n                                    <div>\n                                        <div style="color: #445058;font-size: 14px; font-weight: 700;text-align: right;">\n                                            ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.prize.message, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                TBD\n                                            ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                ';
                      r += Template7Helpers.formatPrice.call(ctx_5, ctx_5.prize.value, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += ' ';
                      r += c(ctx_5.prize.currency, ctx_5);
                      r += '\n                                            ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                        </div>\n                                        <div style="color: #818E95;font-size: 10px;text-align: right;">ПРИЗ</div>\n                                    </div>\n\n                                </div>\n\n                            </div>\n                        </div>\n\n                        <div class="block">\n                            <div style="margin: 10px 10px; margin-top: 32px;">\n                                <button class="button tour-button" @click="openTournament(';
                  r += c(ctx_4.id, ctx_4);
                  r += ')">Принять участие в турнире</button>\n                            </div>\n\n                            <div style="margin-top: 5px;">\n                                <button class="button" @click="back">Не сейчас</button>\n                            </div>\n                        </div>\n                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="block block-strong text-align-center" key="pr-25">\n                        <div class="preloader"></div>\n                    </div>\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.guide !== null && this.guide.progress == 50', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.clientTournament, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n                    <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;">\n                        <img src="./static/tour-ingame.svg">\n                    </div>\n\n                    <div class="client-tournament-title block block-strong" style="margin-bottom: 0;height: 27px;padding: 0 18px;background-color: #F5F8FC;">\n                        <span style="position: relative; top: 4px;color: #818E95;font-size: 14px; height: 27px;">Начало ';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.clientTournament.started_at, {
                hash: { 'type': 'full' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</span>\n                    </div>\n\n                    <div class="block block-strong" style="margin-top: 0;">\n                        <div style="color:#818E95;font-size: 11px; margin-bottom: 12px;">\n                            ';
              r += c(ctx_3.clientTournament.namesString, ctx_3);
              r += '\n                        </div>\n                        <div>\n                            ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.clientTournament.images, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                <img class="client-tournament-img" src="';
                  r += c(ctx_4, ctx_4);
                  r += '" style="width: 38px;">\n                            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        </div>\n                    </div>\n\n                    <div class="block">\n                        <div style="margin: 10px 10px; margin-top: 32px;">\n                            <button class="button tour-button" @click="setProgress60">Продолжить</button>\n                        </div>\n\n                        <div style="margin-top: 5px;">\n                            <button class="button" @click="back">Не сейчас</button>\n                        </div>\n                    </div>\n\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="block block-strong text-align-center" key="pr-50">\n                        <div class="preloader"></div>\n                    </div>\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.guide !== null && this.guide.progress == 75', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n                <div class="block text-align-center" style="margin-top: 30px;">\n                    <div class="gauge" id="payment-gauge"></div>\n                </div>\n\n                <div style="opacity: 0.7;color: #000000;font-size: 18px; text-align:center; margin-top: 20px; margin-bottom: 4px;">\n                    Ожидаем платеж\n                </div>\n\n                <div style="opacity: 0.5;color: #000000;font-size: 17px; text-align:center; margin: 0 16px;">\n                    Это займет не больше минуты. Или можете нажать на кнопку внизу для проверки статуса вручную\n                </div>\n\n                <div class="block">\n                    <div style="margin: 10px 10px; margin-top: 32px;">\n                        <button class="button tour-button" @click="checkPayment" id="button-check-payment">Проверить статус платежа</button>\n                    </div>\n\n                    <div style="margin-top: 5px;">\n                        <button class="button" @click="setProgress60">Вернуться назад</button>\n                    </div>\n                </div>\n\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n            ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.guide !== null && this.guide.progress == 100', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.guide, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div style="text-align: center;margin: 20px 0;">\n                        <img src="./static/tour-finish.svg">\n                    </div>\n                    <div style="margin: 0 18px; text-align:center;font-size: 18px;color: #000000;opacity: 0.7;">\n                        ';
              r += c(ctx_3.guide.payment.amount, ctx_3);
              r += '\u20BD + ';
              r += c(ctx_3.guide.payment.bonus, ctx_3);
              r += '\u20BD бонусных успешно зачислены на ваш баланс!\n                    </div>\n\n                    <div style="opacity: 0.5;color: #000000;font-size: 17px; text-align: center; margin: 0 18px; margin-top: 10px;">\n                        Поздравляем, вы закончили обучение! Начните играть уже сегодня и сразитесь с другими болельщиками за главный приз!\n                    </div>\n\n                    <div class="block">\n                        <div style="margin: 10px 10px; margin-top: 32px;">\n                            <button class="button tour-button" @click="back(true)">Завершить</button>\n                        </div>\n\n                        <div style="text-align: center; color: rgba(0, 0, 0, 0.65);font-size: 11px;margin-top: 5px;">\n                            Действие бонуса истечет в течение 14 дней\n\n                        </div>\n                    </div>\n\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="block block-strong text-align-center" key="pr-100">\n                        <div class="preloader"></div>\n                    </div>\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n        </div>\n\n    </div>\n\n';
      return r;
    }(this);
  },
  style: `
    #tour-subnavbar {
        padding-top: 0 !important;
        margin-top: 0!important;
        height: 102px !important;
    }
    #tour-subnavbar:before{
        display: none;
    }
    #tour-subnavbar:after{
        display: none;
    }

    .tour-button{
        color:white;
        height: 44px;
        background: #32BF61;
        border-radius: 4px;
    }
    .tour-button.active-state {
        color:white;
        background: #32BF61;
    }
    .client-tournament-title:after{
        display: none !important;
    }
    .client-tournament-img{
        width: 27px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid white;
    }

`,
  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      menu: null,
      players: null,
      team: null,
      title: this.$route.query.position,
      query: this.$route.query,
      replaceId: typeof this.$route.query.replaceId !== 'undefined' ? this.$route.query.replaceId : 0
    };
  },
  methods: {
    infoPTS: function (e) {
      this.$root.showTooltip(e.target, 'PTS - cреднее количество очков, полученные игроком за все предыдущие игры в этом сезоне.');
    },
    infoPGP: function (e) {
      this.$root.showTooltip(e.target, 'PGP - количество очков, набранных игроком за предыдущий матч.');
    },
    infoRTG: function () {
      this.$app.toast.create({
        text: 'RTG - рейтинг игрока, согласно внутренней системе FanBattle.',
        closeTimeout: 6000,
        position: 'top'
      }).open();
    },
    tryToTeam: function (e) {
      this.$root.showTooltip(e.target, 'На данного игрока вам не хватает бюджета');
    },
    addToTeam: function (id, key) {
      this.$root.pushToTeam(id, key);
      this.$router.back();
    },
    search: function (query) {
      var self = this;
      var params = [];
      params['positions'] = [self.$route.query.position_type];
      params['descending'] = true;
      if (query != '') {
        params['search'] = query;
      }
      var selectIds = [];
      self.$root.getTeam().forEach(function (e) {
        if (typeof self.$route.query.replaceId !== 'undefined') {
          if (self.$route.query.replaceId != e.player_id) {
            selectIds.push(e.player_id);
          }
        } else {
          selectIds.push(e.player_id);
        }
      });
      if (selectIds.length) {
        params['selected'] = selectIds;
      }
      var filter = this.$('html').find('#popover-filter').find('input[name="filter"]:checked').val();
      if (typeof filter !== 'undefined' && filter != 0) {
        params['matches'] = [filter];
      }
      var sort = this.$('html').find('#popover-sort').find('input[name="sort"]:checked').val();
      params['sortBy'] = sort;
      params['entry_num'] = this.$route.query.entry_id;
      self.$setState({ players: null });
      this.$root.abortXHR('players.search'), this.$root.pushXHR('players.search', this.$root.api('app/tournaments/' + self.$route.query.id + '/players', params, 'GET', function (data, status, xhr) {
        if (data.status == 'error') {
          self.$app.dialog.alert(data.message, 'Ошибка');
        }
        if (data.status == 'ok') {
          let team = { ...data.team };
          if (self.replaceId && team.open_position_count == 7) {
            team.balance.value = +team.balance.value + +self.$route.query.playerSalary;
          }
          if (self.replaceId) {
            team.open_position_count = team.open_position_count + 1;
          }
          self.$setState({
            menu: {
              matches: data.matches,
              sortBy: data.sortBy
            },
            players: data.players,
            team: team
          });
          if (self.$root.getKey('current.tour', 1) == 6) {
            self.$root.events.emit('team-create-1.load');
          }
        }
      }, true));
    },
    filterChange: function () {
      this.$app.popover.get('#popover-filter').close();
      this.search('');
    },
    sortChange: function () {
      this.$app.popover.get('#popover-sort').close();
      this.search('');
    },
    close: function () {
      this.$app.popup.close();
    }
  },
  on: {
    pageAfterIn: function () {
    },
    pageInit: function () {
      var self = this;
      self.search();
      var searchbar = this.$app.searchbar.create({
        el: '.searchbar',
        customSearch: true,
        on: {
          search(sb, query, previousQuery) {
            self.search(query);
          }
        }
      });
    }
  },
  id: '5f2f440a11',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page page-with-subnavbar">\n        <div class="navbar">\n            <div class="navbar-inner navbar-popup">\n                <div class="left">\n                    <a href="#" class="link back icon-only">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">\n                  Выбор ';
      r += Template7Helpers.escape.call(ctx_1, ctx_1.title, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                </div>\n                <div class="right">\n                    <a class="link icon-only searchbar-enable" data-searchbar=".searchbar">\n                        <i class="icon f7-icons">search_strong</i>\n                    </a>\n                </div>\n                <form class="searchbar searchbar-expandable">\n                    <div class="searchbar-inner">\n                        <div class="searchbar-input-wrap">\n                            <input type="search" placeholder="Поиск"/>\n                            <i class="searchbar-icon"></i>\n                            <span class="input-clear-button"></span>\n                        </div>\n                        <span class="searchbar-disable-button">Отмена</span>\n                    </div>\n                </form>\n                <div class="md subnavbar" id="subnavbar-create-1">\n                    <div class="subnavbar-inner">\n                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.menu, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                            <a href="#" class="link popover-open" data-popover="#popover-filter">\n                                <span id="filter-title">Выбор матча</span>\n                                <i class="f7-icons icon">chevron_down</i>\n                            </a>\n\n                            <a href="#" class="link popover-open" data-popover="#popover-sort">\n                                <span id="sort-title">Сортировка</span>\n                                <i class="f7-icons icon">chevron_down</i>\n                            </a>\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </div>\n                </div>\n            </div>\n\n        </div>\n\n        <div class="toolbar tabbar tabbar-labels toolbar-bottom toolbar-transitioning">\n            <div class="toolbar-inner toolbar-inner--view-salary">\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.team, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                   <div id="toolbar-bottom-top">Осталось ';
          r += Template7Helpers.formatPrice.call(ctx_2, ctx_2.team.balance.value, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.team.balance.currency, ctx_2);
          r += '</div>\n                   ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.team.open_position_count > 0, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div id="toolbar-bottom-bottom">';
              r += Template7Helpers.js.call(ctx_3, 'parseInt(this.team.balance.value/this.team.open_position_count)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.team.average_salary.currency, ctx_3);
              r += ' за открытую позицию (';
              r += c(ctx_3.team.open_position_count, ctx_3);
              r += ')</div>\n                    ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div id="toolbar-bottom-bottom">';
              r += Template7Helpers.js.call(ctx_3, 'parseInt(this.team.balance.value)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += c(ctx_3.team.average_salary.currency, ctx_3);
              r += ' за открытую позицию (';
              r += c(ctx_3.team.open_position_count, ctx_3);
              r += ')</div>\n                    ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n        </div>\n\n        <div class="page-content" id="page-content-create-1">\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.players, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.players.length', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="list list-create-1 no-chevron">\n                        <ul>\n                            ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.players, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n                                <li class="bg-color-white">\n                                    <div class="item-content list-full-content">\n                                        <div class="item-media">\n                                            <a class="item-media__face" href="/team/create-2/?id=';
                  r += c(root.$route.query.id, ctx_4);
                  r += '&player_id=';
                  r += c(ctx_4.id, ctx_4);
                  r += '&position_key=';
                  r += c(ctx_3.query.position_key, ctx_4);
                  r += '&replaceId=';
                  r += c(root.replaceId, ctx_4);
                  r += '&entry_id=';
                  r += c(root.$route.query.entry_id, ctx_4);
                  r += '">\n                                                <img src="';
                  r += c(ctx_4.image, ctx_4);
                  r += '" class="game-avatar">\n                                                ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.in_lineups, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    <span class="item-media__in_lineups tour-7"></span>\n                                                ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.is_injured, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    ';
                      r += Template7Helpers.if.call(ctx_5, ctx_5.is_injured, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                                        <span class="item-media__is_injured tour-7"></span>\n                                                    ';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n                                                        <span class="tour-7"></span>\n                                                    ';
                          return r;
                        },
                        root: root,
                        parents: [
                          ctx_4,
                          ctx_3,
                          ctx_2,
                          ctx_1
                        ]
                      });
                      r += '\n                                                ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                            </a>\n                                        </div>\n                                        <div class="item-inner">\n                                            <div class="width-100 min-width-0">\n                                                <div class="list-full-top display-flex justify-content-space-between align-content-center">\n                                                    <div class="list-full-top-left">\n                                                        ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.is_having_stats, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                            <i class="icon icon-player-has-stat" style="margin-top: -3px;"></i>\n                                                        ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                                        <a data-force="true" data-ignore-cache="true" href="/team/create-2/?id=';
                  r += c(root.$route.query.id, ctx_4);
                  r += '&player_id=';
                  r += c(ctx_4.id, ctx_4);
                  r += '&position_key=';
                  r += c(ctx_3.query.position_key, ctx_4);
                  r += '&replaceId=';
                  r += c(root.replaceId, ctx_4);
                  r += '&entry_id=';
                  r += c(root.$route.query.entry_id, ctx_4);
                  r += '">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</a>\n                                                    </div>\n                                                    <div class="list-full-top-right">\n                                                        ';
                  r += Template7Helpers.formatPrice.call(ctx_4, ctx_4.salary.value, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += ' ';
                  r += c(ctx_4.salary.currency, ctx_4);
                  r += '\n                                                    </div>\n                                                </div>\n\n                                                <div class="list-full-bottom display-flex justify-content-space-between">\n\n                                                    <div class="list-full-bottom-left min-width-0">\n                                                        <div class="list-full-bottom-left-top">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.team, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                                        <div class="list-full-bottom-left-bottom">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.position, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '</div>\n                                                    </div>\n                                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.pgp != \'0.00\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                        <div class="list-full-bottom-center text-align-right" style="margin-left: auto;">\n                                                            <div class="list-full-bottom-center-top">';
                      r += c(ctx_5.pgp, ctx_5);
                      r += '</div>\n                                                            <div class="list-full-bottom-center-bottom"><span class="param-wrapper"><span class="info-icon" @click="infoPGP"></span> <span>PGP</span></span></div>\n                                                        </div>\n                                                    ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                                    ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.score_average != \'0.00\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                        <div class="list-full-bottom-center text-align-right">\n                                                            <div class="list-full-bottom-center-top tour-6">';
                      r += c(ctx_5.score_average, ctx_5);
                      r += '</div>\n                                                            <div class="list-full-bottom-center-bottom"><span class="param-wrapper"><span class="info-icon" @click="infoPTS"></span> <span>PTS</span></span></div>\n                                                        </div>\n                                                    ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n\n                                                    <!-- <div class="list-full-bottom-center text-align-right">\n                                                        <div class="list-full-bottom-center-top tour-6">';
                  r += c(ctx_4.rating, ctx_4);
                  r += '</div>\n                                                        <div class="list-full-bottom-center-bottom"><img src="./static/info.svg" @click="infoRTG"> RTG</div>\n                                                    </div> -->\n\n                                                </div>\n                                            </div>\n\n                                            <div class="item-after">\n                                                ';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.selectable, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    <i class="icon-plus-circle" @click="addToTeam(\'';
                      r += c(ctx_5.id, ctx_5);
                      r += '\', \'';
                      r += c(root.query.position_key, ctx_5);
                      r += '\')"></i>\n                                                ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n                                                    <i class="icon-plus-circle-disable" @click="tryToTeam"></i>\n                                                ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\n                                            </div>\n                                        </div>\n                                    </div>\n                                </li>\n                            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                        </ul>\n                    </div>\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <div class="block block-strong text-align-center">\n                        Ничего не найдено.\n                    </div>\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <div class="block block-strong text-align-center">\n                    <div class="preloader"></div>\n                </div>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n        </div>\n\n        <div class="popover" id="popover-filter">\n            <div class="popover-angle"></div>\n\n            <div class="popover-inner">\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.menu, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="list">\n                        <ul>\n                            <li>\n                                <label class="item-radio item-content">\n                                    <input @change="filterChange" type="radio" name="filter" value="0" checked />\n                                    <i class="icon icon-radio"></i>\n                                    <div class="item-inner">\n                                        <div class="item-title">\n                                            Все матчи\n                                        </div>\n                                    </div>\n                                </label>\n                            </li>\n                            ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.menu.matches, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                <li>\n                                    <label class="item-radio item-content">\n                                        <input @change="filterChange" type="radio" name="filter" value="';
              r += c(ctx_3.id, ctx_3);
              r += '" ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.checked, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' checked ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' />\n                                        <i class="icon icon-radio"></i>\n                                        <div class="item-inner">\n                                            <div class="item-title">\n                                                ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.localteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' - ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.visitorteam, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                                <div class="item-footer">';
              r += Template7Helpers.formatDate.call(ctx_3, ctx_3.started_at, {
                hash: { 'type': 'full' },
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\n                                            </div>\n                                        </div>\n                                    </label>\n                                </li>\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        </ul>\n                    </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n        </div>\n\n        <div class="popover" id="popover-sort">\n            <div class="popover-angle"></div>\n\n            <div class="popover-inner">\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.menu, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <div class="list">\n                    <ul>\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.menu.sortBy, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <li>\n                            <label class="item-radio item-content">\n                                <input @change="sortChange" type="radio" name="sort" value="';
              r += c(ctx_3.value, ctx_3);
              r += '" ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.selected, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' checked ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' />\n                                <i class="icon icon-radio"></i>\n                                <div class="item-inner">\n                                    <div class="item-title">\n                                        ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.title, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\n                                    </div>\n                                </div>\n                            </label>\n                        </li>\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    </ul>\n                </div>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
    :root {

        --f7-searchbar-bg-color: #007AFF;
        --f7-searchbar-input-bg-color: white;
        --f7-searchbar-link-color: white;
    }
    #popover-filter{
        --f7-popover-width: 97%;
    }
    #page-content-create-1{
        --f7-toolbar-height: 44px;
    }

    @media screen and (max-width: 370px) {
        .only-wide{
            display: none !important;
        }
    }

    @media screen and (min-width: 350px) and (max-width: 380px) {
        .custom-mr {
            position: relative;
            left: 5%;
        }
    }
    @media screen and (min-width: 381px) and (max-width: 430px) {
        .custom-mr {
            position: relative;
            left: 10%;
        }
    }
    @media screen and (min-width: 431px) {
        .custom-mr {
            position: relative;
            left: 15%;
        }
    }

    .list-full-bottom-left-top{
        font-size: 14px;
        color: #445058;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }
    .list-full-bottom-left-bottom{
        font-size: 11px;
        color: #818E95;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .list-full-bottom-center {
        min-width: 46px;
    }
    .list-full-bottom-center-top{
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        text-transform:uppercase;
    }
    .list-full-bottom-center-bottom{
        font-size: 11px;
        color: #818E95;
        text-transform:uppercase;
    }
    .list-full-bottom-center-bottom img{
        width: 11px;
        height: 11px;
        position: relative;
        top: 2px;
    }

    .list-full-bottom-right-top{
        font-size: 14px;
        color: #445058;
        font-weight: 500;
        text-transform:uppercase;
    }
    .list-full-bottom-right-bottom{
        font-size: 11px;
        color: #818E95;
        text-transform:uppercase;
    }

    .list-full-content{
        height: 100px;
    }

    .list-full-top{
        height: 29px;
        flex-direction: row;
        padding-right: 10px;

    }
    .list-full-bottom{
        height: 33px;
        padding-right: 10px;
    }
    .list-full-top-left{
        font-size: 15px;
        color: #007AFF;
        white-space: nowrap;
        overflow: hidden;
        height: 1.3em;
        text-overflow: ellipsis;
    }
    .list-full-top-left a {

    }
    .list-full-top-right{
        position: relative;
        top: 0;
        text-align: right;
        flex-shrink: 0;
    }

    .list-full-top-right{
        font-weight: bold;
        color: #445058;
    }

    .list-create-1.list{
        --f7-list-bg-color: F0F3F8;
        --f7-list-item-title-line-height: 100px;
        --f7-list-item-padding-vertical: 0;
    }
    .list-create-1.list .item-title{
        color: #445058;
    }
    .popover-inner .list ul {
        max-height: 355px;
        overflow: auto;
    }

    #toolbar-create-1{
        height: 44px;
    }

    #toolbar-create-1 .toolbar-inner{
        flex-direction: column;
        justify-content: center;
    }
    #toolbar-bottom-top{
        height: 22px;
        text-align: center;
        color: #445058;
        font-weight: bold;
        font-size: 14px;
    }
    #toolbar-bottom-bottom{
        height: 18px;
        text-align: center;
        color: #445058;
        font-size: 12px;
    }


    #popover-filter .item-title, #popover-sort .item-title{
        font-size: 17px;
        color: #007AFF;
    }
    #popover-filter .item-footer, #popover-sort .item-footer{
        font-size: 11px;
        color:#818E95;
    }

    #subnavbar-create-1{
        background-color: white;
    }
    #subnavbar-create-1 .subnavbar-inner{
        margin-left: 16px;
        margin-right: 3px;
    }
    #subnavbar-create-1 a, #subnavbar-create-1 .icon{
        color: #007AFF;
        font-size: 17px;
    }
    #subnavbar-create-1 .icon{
        position: relative;
        /* top: 3px;
        left: -5px; */
    }

    .item-media__face {
        position: relative;
    }

    .item-media__is_injured {
        position: absolute;
        bottom: 0.035em;
        right: -0.28em;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgb(255, 60, 60);
        border: 1.5px solid #FFFFFF;
    }

    .item-media__in_lineups {
        position: absolute;
        bottom: 0.035em;
        right: -0.28em;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #FEC400;
        border: 1.5px solid #FFFFFF;
    }

    .item-media__in_lineups_empty {
        position: absolute;
        bottom: 0.035em;
        right: -0.28em;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1.5px solid #FFFFFF;
    }

    .toolbar-inner--view-salary {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

`,
  styleScoped: false
};
    
const Duel = {
  firstPlayer: null,
  secondPlayer: null,
};
Duel.init = function() {
  this.firstPlayer = {
    order: null,
    configurate: false,
    match: {
      id: null,
    },
    player: {
      id: null,
      name: null,
    },
    t: 'first',
  };
  this.secondPlayer = {
    order: null,
    configurate: false,
    match: {
      id: null,
    },
    player: {
      id: null,
      name: null,
    },
    t: 'second',
  };

  return this;
};
Duel.changeConfiguratePlayer = function() {
  const confPlayer = this.getConfiguratePlayer();
  if (confPlayer.t === 'first') {
    this.firstPlayer.configurate = false;
    this.secondPlayer.configurate = true;
  } else {
    this.firstPlayer.configurate = true;
    this.secondPlayer.configurate = false;
  }
};
Duel.setConfiguratePlayerWithOrder = function() {
  const playerOrder1 = this.getOrderPlayer(1);
  const playerOrder2 = this.getOrderPlayer(2);
  playerOrder1.configurate = true;
  playerOrder2.configurate = false;
};
Duel.getOrderPlayer = function(id) {
  if (this.firstPlayer.order == id) {
    return this.firstPlayer;
  }
  if (this.secondPlayer.order == id) {
    return this.secondPlayer;
  }
};
Duel.getPlayerById = function(id) {
  if (this.firstPlayer.player.id == id) {
    return this.firstPlayer;
  }
  if (this.secondPlayer.player.id == id) {
    return this.secondPlayer;
  }
};
Duel.getConfiguratePlayer = function() {
  if (this.firstPlayer.configurate == true) {
    return this.firstPlayer;
  }
  if (this.secondPlayer.configurate == true) {
    return this.secondPlayer;
  }
};
Duel.getOrderConfiguratePlayer = function() {
  if (this.firstPlayer.configurate == true) {
    return this.firstPlayer.order;
  }
  if (this.secondPlayer.configurate == true) {
    return this.secondPlayer.order;
  }
};
Duel.destroy = function() {
  this.firstPlayer = {
    order: null,
    match: {
      id: null,
    },
    configurate: false,
    player: {
      id: null,
      name: null,
    },
    t: 'first',
  };
  this.secondPlayer = {
    order: null,
    match: {
      id: null,
    },
    configurate: false,
    player: {
      id: null,
      name: null,
    },
    t: 'second',
  };

  return this;
};

export default Duel;

function GooglePay() {
    this.app = null;
    /*
        Инициализация магазина в приложении
    */
    this.init = (app) => {
        this.app = app;

        if (!window.store) {
            return false;
        }

        let token = localStorage.getItem("token");
        if (token) {
            store.validator = {
                url: "https://api.fanbattle.pro/app/purchase/validator/google",
                headers: {
                    "authorization": `Bearer ${token}`,
                },
            };
        }

        store.register([
            {
                id:    'product.15',
                type:   store.CONSUMABLE,
            },
            {
                id:    'product.100',
                type:   store.CONSUMABLE,
            },
            {
                id:    'product.200',
                type:   store.CONSUMABLE,
            },
            {
                id:    'product.1000',
                type:   store.CONSUMABLE,
            }
        ]);

        store.when('product.15', 'approved', (product) => {
            try {
                product.verify();
                window.APP_FANBATTLE.dialog.alert('', 'Покупка успешно совершена', () => {
                    window.APP_FANBATTLE.view.current.router.back();
                    setTimeout(() => {
                        window.APP_FANBATTLE.data.events.emit('profile.update');
                    }, 314);
                });
            } catch (e) {
                alert(e);
            }
        });

        store.when('product.100', 'approved', (product) => {
            try {
                product.verify();
                window.APP_FANBATTLE.dialog.alert('', 'Покупка успешно совершена', () => {
                    window.APP_FANBATTLE.view.current.router.back();
                    setTimeout(() => {
                        window.APP_FANBATTLE.data.events.emit('profile.update');
                    }, 314);
                });
            } catch (e) {
                alert(e);
            }
        });
        store.when('product.200', 'approved', (product) => {
            try {
                product.verify();
                window.APP_FANBATTLE.dialog.alert('', 'Покупка успешно совершена', () => {
                    window.APP_FANBATTLE.view.current.router.back();
                    setTimeout(() => {
                        window.APP_FANBATTLE.data.events.emit('profile.update');
                    }, 314);
                });
            } catch (e) {
                alert(e);
            }
        });
        store.when('product.1000', 'approved', (product) => {
            try {
                product.verify();
                window.APP_FANBATTLE.dialog.alert('', 'Покупка успешно совершена', () => {
                    window.APP_FANBATTLE.view.current.router.back();
                    setTimeout(() => {
                        window.APP_FANBATTLE.data.events.emit('profile.update');
                    }, 314);
                });
            } catch (e) {
                alert(e);
            }
        });

        store.when('product.15', 'verified', (product) => {
            product.finish();
        });
        store.when('product.15', 'unverified', (product) => {
            product.finish();
        });
        store.when('product.15', 'expired', (product) => {
            product.finish();
        });
        store.when('product.15', 'refunded', (product) => {
            product.finish();
        });
        store.when('product.15', 'cancelled', (product) => {
            product.finish();
        });
        store.when('product.15', 'error', (product) => {
            product.finish();
        });

        store.when('product.100', 'verified', (product) => {
            product.finish();
        });
        store.when('product.100', 'unverified', (product) => {
            product.finish();
        });
        store.when('product.100', 'expired', (product) => {
            product.finish();
        });
        store.when('product.100', 'refunded', (product) => {
            product.finish();
        });
        store.when('product.100', 'cancelled', (product) => {
            product.finish();
        });
        store.when('product.100', 'error', (product) => {
            product.finish();
        });

        store.when('product.200', 'verified', (product) => {
            product.finish();
        });
        store.when('product.200', 'unverified', (product) => {
            product.finish();
        });
        store.when('product.200', 'expired', (product) => {
            product.finish();
        });
        store.when('product.200', 'refunded', (product) => {
            product.finish();
        });
        store.when('product.200', 'cancelled', (product) => {
            product.finish();
        });
        store.when('product.200', 'error', (product) => {
            product.finish();
        });

        store.when('product.1000', 'verified', (product) => {
            product.finish();
        });
        store.when('product.1000', 'unverified', (product) => {
            product.finish();
        });
        store.when('product.1000', 'expired', (product) => {
            product.finish();
        });
        store.when('product.1000', 'refunded', (product) => {
            product.finish();
        });
        store.when('product.1000', 'cancelled', (product) => {
            product.finish();
        });
        store.when('product.1000', 'error', (product) => {
            product.finish();
        });

        store.error(function(error) {});

        store.refresh();
    };

    /*
        Изменяем заголовки в запросе плагина store на верификацию чека на сервере
    */
    this.setValidatorHeaders = ({ token }) => {
        if (token) {
            try {
                store.validator = {
                    url: "https://api.fanbattle.pro/app/purchase/validator/google",
                    headers: {
                        "authorization": `Bearer ${token}`,
                    },
                };
            } catch (e) {}
        }
    }
}

export default new GooglePay();

export default {
  data: function () {
    return {};
  },
  methods: {
    onSignInSubmit() {
    }
  },
  on: {
    pageInit: function () {
    }
  },
  id: '886b400211',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner">\n                <div class="left"></div>\n                <div class="title">\n                    Подтверждение телефона\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="block">\n                <div class="image-success image-block"></div>\n                <h1 class="header mb-0">Успешно</h1>\n                <p class="text">Вы подтвердили свой номер телефона. Вернитесь в профиль и обновите его</p>\n              </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  style: `
[data-f7-886b400211] .list {
        margin-top: 35px;
    }

[data-f7-886b400211] .block {
        text-align: center;
        color: black !important;
    }

[data-f7-886b400211] .image-block {
        margin-top: 75px;
    }
`,
  styleScoped: true
};